import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';

import App from './App';
import { AuthProvider } from './hooks/useAuth';
import NotificationToast from './components/NotificationToast/NotificationToast';
import palette from './theme/palette';

const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container);


root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <SnackbarProvider
          Components={{
            custom: NotificationToast
          }}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <AuthProvider>
            <CssBaseline />
            <GlobalStyles
              styles={{
                html: { height: '100%', width: '100%', overflow: 'auto' },
                body: {
                  backgroundColor: palette.grey['100'],
                  margin: 0,
                  padding: 0,
                  overflow: 'hidden',
                  position: 'relative',
                  height: 'auto',
                  minHeight: '100% !important',
                  '& .excalidraw': {
                    "--color-primary": palette.primary.main
                  },
                  "& .ant-dropdown": {
                    maxWidth: "1000px"
                  }
                },
                '#root': { height: '100dvh', width: '100%', overflow: 'hidden' }
              }}
            />
            <App />
          </AuthProvider>
        </SnackbarProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
