import { State, StateElement, ActionMap, ModalComponentProps, Props } from './ModalContextTypes';

export enum ModalActions {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
  UPDATE = 'UPDATE',
  DESTROY = 'DESTROY',
  DESTROY_BY_ROOT_ID = 'DESTROY_BY_ROOT_ID',
  UNKNOWN = 'UNKNOWN'
}

interface Payload {
  [ModalActions.SHOW]: StateElement & {
    id: string;
  };
  [ModalActions.HIDE]: {
    id: string;
  };
  [ModalActions.UPDATE]: {
    id: string;
    props: ModalComponentProps<Props>;
  };
  [ModalActions.DESTROY]: {
    id: string;
  };
  [ModalActions.DESTROY_BY_ROOT_ID]: {
    rootId: string;
  };
  [ModalActions.UNKNOWN]: undefined;
}

type Action = ActionMap<Payload>[keyof ActionMap<Payload>];

export const initialState: State = {};

export default function reducer(state: State, action: Action) {
  switch (action.type) {
    case ModalActions.SHOW: {
      const { id, component, props, options } = action.payload;

      return {
        ...state,
        [id]: {
          component,
          props: {
            ...props,
            open: true
          },
          options
        }
      };
    }
    case ModalActions.HIDE: {
      const { id } = action.payload;

      if (!state[id]) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...state[id],
          props: {
            ...state[id].props,
            open: false
          }
        }
      };
    }
    case ModalActions.UPDATE: {
      const { id, props } = action.payload;

      if (!state[id]) {
        return state;
      }

      return {
        ...state,
        [id]: {
          ...state[id],
          props: {
            ...state[id].props,
            ...props
          }
        }
      };
    }
    case ModalActions.DESTROY: {
      const { id } = action.payload;
      const newState = { ...state };
      delete newState[id];
      return newState;
    }
    case ModalActions.DESTROY_BY_ROOT_ID: {
      const { rootId } = action.payload;

      return Object.keys(state)
        .filter(key => key.split('.')[0] !== rootId)
        .reduce<State>(
          (acc, key) => ({
            ...acc,
            [key]: state[key]
          }),
          {}
        );
    }
    default:
      throw new Error('Unexpected action');
  }
}
