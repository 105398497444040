import IsPropValid from '@emotion/is-prop-valid';
import {
  Autocomplete,
  Dialog,
  Input,
  InputBase,
  Select,
  Stack,
  TextField,
  Typography,
  alpha,
  styled
} from '@mui/material';

import palette from '../../theme/palette';

const ModalContainer = styled(Dialog)({
  margin: 'auto',
  '.MuiPaper-root': {
    padding: '20px',
    gap: '10px',
    width: '740px',
    background: 'white',
    border: `1px solid ${palette.grey[1800]}`,
    boxShadow: '0px 10px 20px rgba(28, 39, 76, 0.03)',
    borderRadius: '16px'
  }
});

const Title = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 800,
  fontSize: '14px',
  color: palette.common.darkBlue
});

const InputTitle = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '14px',
  color: palette.common.darkBlue
});

export const Description = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '13px',
  color: palette.grey[3100]
});

const InputDescription = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '13px',
  color: palette.grey[3100]
});

const QTitle = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 700,
  fontSize: '14px',
  color: palette.common.darkBlue,
  display: 'flex',
  flexDirection: 'row'
});

const RedAsterisk = styled(Typography)({
  color: 'red'
});

const ValueInput = styled(InputBase)({
  padding: '12px 16px',
  height: '48px',
  background: 'white',
  border: `1px solid ${palette.border.grey}`,
  borderRadius: '8px',
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '13px',
  color: palette.common.darkBlue,
  'input::placeholder': {
    fontSize: '13px',
    fontFamily: "'Plus Jakarta Sans'"
  }
});

const KrValueInput = styled(InputBase)({
  padding: '12px 16px',
  height: '48px',
  background: 'white',
  border: `1px solid ${palette.border.grey}`,
  borderLeft: 'none',
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '13px',
  color: palette.common.darkBlue
});

const FormInput = styled(Input)(({ theme }) => ({
  height: '48px',
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '8px',
  padding: '5px 10px',
  fontSize: theme.typography.body2.fontSize,
  'input::placeholder': {
    fontFamily: "'Plus Jakarta Sans'"
  }
}));

const UnitIcon = styled(Stack)(({ theme }) => ({
  borderRadius: '50%',
  padding: '5px',
  backgroundColor: alpha(theme.palette.primary.main, 0.2),
  color: theme.palette.primary.main,
  svg: {
    width: 17,
    height: 17
  }
}));

export const QTargetDesc = styled(Stack)(() => ({
  height: '48px',
  width: '100%',
  padding: '5px 10px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'start',
  marginBottom: '5px'
}));

export const QTargetDescContainer = styled(Stack)(() => ({
  width: '150px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '8px',
  padding: '5px 10px',
  backgroundColor: alpha(palette.grey[1200], 0.1)
}));

export const QTargetDescTitle = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '14px',
  color: palette.grey[800]
});

export const QTargetDescValue = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '13px',
  color: palette.grey[800]
});

const QIcon = styled(Stack)(() => ({
  borderRadius: '50%',
  width: '20px',
  height: '20px',
  padding: '5px',
  alignItems: 'center',
  justifyContent: 'center',
  color: palette.primary.main
}));

const QIconText = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '13px'
});

const PlanValueInput = styled(InputBase)({
  all: 'unset',
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '13px',
  color: palette.common.darkBlue
});

const SelectInputContainer = styled(Stack)({
  background: 'white',

  '.MuiInputBase-root': {
    padding: 0,
    background: 'white'
  }
});

const KeyResultTypeSelection = styled(Select)({
  '.MuiOutlinedInput-notchedOutline': { border: 0, padding: 0 },
  '& .MuiOutlinedInput-input': {
    marginLeft: '10px'
  }
});

const CancelText = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '14px',
  color: palette.grey[1100],
  cursor: 'pointer'
});

const CreateButton = styled(Stack, { shouldForwardProp: IsPropValid })<{ disabled: boolean }>(
  ({ disabled }) => ({
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 24px',
    width: '140px',
    height: '42px',
    color: 'white',
    opacity: disabled ? 0.4 : 1,
    background: disabled ? palette.grey[1800] : palette.green[1],
    borderRadius: '8px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    ':hover': {
      opacity: !disabled && 0.8
    }
  })
);

const AddTargetsButton = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '6px',
  width: 'max-content',
  cursor: 'pointer',
  ':hover': { opacity: 0.8 },
  backgroundColor: alpha(palette.primary.main, 0.2)
}));

const AddTargetsButtonText = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '11px'
});

export const Qprogress = styled(Stack)(() => ({
  flexDirection: 'row',
  gap: 5,

  alignItems: 'center',
  height: '30px'
}));

export const QprogressTitle = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '11px'
});

export const VisibleToDropDown = styled(Select)(({ theme }) => ({
  height: '48px',
  backgroundColor: theme.palette.background.default,
  borderRadius: '10px',
  border: `1px solid ${palette.border.grey}`,
  '& .MuiSelect-select': {
    backgroundColor: theme.palette.background.default
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));

export const DescriptionContainer = styled(Stack)({
  borderRadius: '8px',
  border: `1px solid ${palette.border.grey}`,
  minHeight: '48px'
});

export const AutoCompleteBlock = styled(Autocomplete)<{ height?: string }>(({ height }) => ({
  '& .MuiFormControl-root': {
    margin: 0
  },
  '& .MuiTextField-root': {
    padding: 0
  },
  '& .MuiInputBase-root': {
    padding: 0,
    display: 'flex',
    alignItems: 'center'
  },
  '& .MuiAutocomplete-inputRoot': {
    height: height && height,
    fontFamily: 'Plus Jakarta Sans',
    width: '250px'
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: '14px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));

export const TextInputSelect = styled(TextField)(() => ({
  border: 'none',
  resize: 'none',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    padding: 0,
    border: `1px solid ${palette.border.grey}`,
    backgroundColor: palette.background.paper,

    '& .MuiOutlinedInput-input': {
      padding: 2,
      paddingLeft: 15,
      '&::placeholder': {
        color: palette.grey[600],
        fontSize: '13px',
        fontFamily: "'Plus Jakarta Sans'"
      }
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    }
  }
}));

export const MetricDropDown = styled(Autocomplete)(() => ({
  '& .MuiFormControl-root': {
    margin: 0,
    width: '280px'
  },
  '& .MuiTextField-root': {
    padding: 0
  },
  '& .MuiInputBase-root': {
    padding: 0,
    display: 'flex',
    alignItems: 'center'
  },
  '& .MuiAutocomplete-inputRoot': {
    height: '48px'
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: '14px',
    fontFamily: "'Plus Jakarta Sans'"
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));

export const MetricDropDownTextInputSelect = styled(TextField)(() => ({
  border: 'none',
  resize: 'none',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '0px 8px 8px 0px',
    padding: 0,
    border: `1px solid ${palette.border.grey}`,
    borderLeft: 'none',
    backgroundColor: palette.background.paper,

    '& .MuiOutlinedInput-input': {
      padding: 2,
      paddingLeft: 15,
      '&::placeholder': {
        color: palette.grey[600],
        fontSize: '13px',
        fontFamily: "'Plus Jakarta Sans'"
      }
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    }
  }
}));

export {
  ModalContainer,
  Title,
  InputTitle,
  RedAsterisk,
  ValueInput,
  PlanValueInput,
  SelectInputContainer,
  KeyResultTypeSelection,
  CancelText,
  CreateButton,
  FormInput,
  UnitIcon,
  KrValueInput,
  QTitle,
  AddTargetsButton,
  AddTargetsButtonText,
  QIcon,
  InputDescription,
  QIconText
};

export const PeriodDropDown = styled(Select)(() => ({
  height: '48px',
  width: '200px',
  backgroundColor: palette.background.default,
  borderRadius: '8px',
  border: `1px solid ${palette.border.grey}`,
  '& .MuiSelect-select': {
    backgroundColor: palette.background.default
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));

export const TrendDropDown = styled(Select)(() => ({
  height: '48px',
  width: '200px',
  backgroundColor: palette.background.default,
  borderRadius: '8px',
  border: `1px solid ${palette.border.grey}`,
  '& .MuiSelect-select': {
    backgroundColor: palette.background.default
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));
