import { abbreviateNumber } from 'js-abbreviation-number';

const stringToNumberString = (stringToConvert: string) =>
  stringToConvert.replace(/[^0-9.]/g, '') || null;

const getAbbreviateNumber = (value: number, digitsAfterDot: number, replace?: boolean) => {
  const valueAsString = abbreviateNumber(value, digitsAfterDot);

  if (
    (valueAsString.includes('.0') && digitsAfterDot === 1) ||
    (valueAsString.includes('.00') && digitsAfterDot <= 2)
  ) {
    return abbreviateNumber(value, 0, ['', 'k', 'm', 'b']);
  } else {
    if (replace) {
      return abbreviateNumber(value, digitsAfterDot, ['', 'k', 'm', 'b']).replace(
        /0+([mkb])$/g,
        '$1'
      );
    } else {
      return abbreviateNumber(value, digitsAfterDot, ['', 'k', 'm', 'b']);
    }
  }
};

export function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export { stringToNumberString, getAbbreviateNumber };
