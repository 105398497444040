import { useCallback, useMemo } from 'react';

import {
  UpdateAppNotificationStatusRequestStatus,
  useBulkUpdateNotificationsStatus,
  useListAppNotifications,
  useUpdateNotificationStatus
} from '../api/generated';


export const useAppNotification = (unreadOnly = false) => {
  const {
    data: notificationsData,
    isLoading: isNotificationListLoading,
    refetch: refetchNotifications } =
    useListAppNotifications({ unreadOnly: unreadOnly }, {
      query: { queryKey: ['listAppNotifications'], enabled: true }
    });
  const notificationList = useMemo(() => notificationsData?.items || [], [notificationsData?.items]);

  const { mutateAsync: mutateUpdateNotificationStatus } = useUpdateNotificationStatus();
  const { mutateAsync: mutateBulkUpdateNotificationsStatus } = useBulkUpdateNotificationsStatus();

  const updateNotificationStatus = useCallback(
    async (id: string, status: UpdateAppNotificationStatusRequestStatus) => {
      await mutateUpdateNotificationStatus({ id: id, data: { status: status } });
    },
    [mutateUpdateNotificationStatus]
  );

  const updateNotificationsStatus = useCallback(
    async (ids: string[], status: UpdateAppNotificationStatusRequestStatus) => {
      await mutateBulkUpdateNotificationsStatus({ data: { notificationIds: ids, status: status } });
    },
    [mutateBulkUpdateNotificationsStatus]
  );

  return {
    notificationList,
    refetchNotifications,
    isNotificationListLoading,
    updateNotificationStatus,
    updateNotificationsStatus
  };
};

export default useAppNotification;
