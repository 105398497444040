import React, { MouseEvent, useCallback, useState } from 'react';


import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Stack, Tooltip } from '@mui/material';

import {
  ActionPlanActionItemCommentDto,
  ActionPlanInitiativeCommentDto,
  ActionPlanObjectiveCommentDto
} from '../../api/generated';
import useModal from '../../hooks/context-providers/useModal/useModal';
import useAuth from '../../hooks/useAuth';
import AppPopover from '../../screens/ActionPlan/components/AppPopover/AppPopover';
import Editor from '../../screens/BizAreaModules/components/Editor';
import FreeTextViewer from '../../screens/BizAreaModules/components/FreeTextViewer';

import UserView from '../User/UserView';
import {
  ActionsContainer,
  CancelText,
  CommentInputContainer,
  CommentItem,
  Container,
  ContentBlock,
  DaysAgoTitle,
  HeaderBlock,
  SaveButton,
  UserInfoBlock
} from './CommentCardStyles';
import palette from '../../theme/palette';

type CommentCardProps = {
  comment:
  | ActionPlanInitiativeCommentDto
  | ActionPlanObjectiveCommentDto
  | ActionPlanActionItemCommentDto;
  deleteComment: (id: string) => Promise<void>;
  patchComment: (
    id: string,
    comment:
      | Partial<ActionPlanInitiativeCommentDto>
      | Partial<ActionPlanObjectiveCommentDto>
      | Partial<ActionPlanActionItemCommentDto>
  ) => Promise<void>;
  refetchComments: () => void;
  title?: string;
};

const LazyLoadedAreYouSureModal = React.lazy(
  () => import('../../modals/AreYouSureModal/AreYouSureModal')
);

const CommentCard = ({
  comment,
  deleteComment,
  title,
  refetchComments,
  patchComment
}: CommentCardProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [content, setContent] = useState(comment.content);
  const { showModal } = useModal();
  const { user } = useAuth();
  const [taggedUserIds, setTaggedUserIds] = useState<string[]>(comment.taggedUserIds);
  const updateComment = useCallback(() => {
    if (comment.content !== content) {
      patchComment(comment.id, { content: content, taggedUserIds: taggedUserIds })
        .then(() => refetchComments())
        .then(() => setIsEdit(!isEdit));
    } else {
      setIsEdit(!isEdit);
    }
  }, [comment.content, content, comment.id, refetchComments, patchComment, isEdit, taggedUserIds]);

  const deleteCommentClick = useCallback(() => {
    setEditAndDeleteAnchorEl(null);
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        modal.hide();
      },
      onConfirm: () => {
        deleteComment(comment.id).then(() => refetchComments());
        modal.hide();
      },
      modalTitle: `Are you sure you want to delete this ${title ? title : "comment"}?`,
      confirmButtonIcon: <DeleteOutlineIcon />,
      confirmButtonTitle: 'Delete'
    });
  }, [comment.id, deleteComment, refetchComments, showModal, title]);

  const [editAndDeleteAnchorEl, setEditAndDeleteAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const editAndDeletePopoverOpen = Boolean(editAndDeleteAnchorEl);

  const [hovered, setHovered] = useState<boolean>(false);

  return (
    <CommentItem onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <Container>
        <HeaderBlock>
          <UserInfoBlock>
            <UserView
              color={palette.common.darkBlue}
              userId={comment.userId} />
            <Tooltip
              title={new Date(comment.createdAt).toLocaleString([], {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit"
              })}>


              <DaysAgoTitle>
                {new Date(comment.createdAt).toLocaleString([], {
                  day: '2-digit',
                  month: 'short',
                  year: 'numeric'
                })}
              </DaysAgoTitle>
            </Tooltip>
          </UserInfoBlock>

          {comment.userId === user.id && hovered && (
            <Stack>
              <ActionsContainer
                alignSelf="end"
                onClick={(event: MouseEvent<HTMLDivElement>) => {
                  event.stopPropagation();
                  setEditAndDeleteAnchorEl(event.currentTarget);
                }}
              >
                <MoreHorizIcon />
              </ActionsContainer>

              <AppPopover
                anchorEl={editAndDeleteAnchorEl}
                open={editAndDeletePopoverOpen}
                handleClose={() => setEditAndDeleteAnchorEl(null)}
                onDelete={deleteCommentClick}
                onEdit={() => {
                  setIsEdit(!isEdit);
                  setEditAndDeleteAnchorEl(null);
                }}
              />
            </Stack>
          )}
        </HeaderBlock>
        <ContentBlock marginLeft="27px">
          {isEdit ? (
            <Stack marginTop={1}>
              <CommentInputContainer>
                <Editor
                  includeMentions={true}
                  addTaggedUserId={(userId: string) =>
                    setTaggedUserIds(prevState => [...prevState, userId])
                  }
                  alwaysShowToolbar={true}
                  showBorder={false}
                  defaultValue={content}
                  value={content}
                  onChange={setContent}
                />
              </CommentInputContainer>
              <Stack alignSelf="end" marginTop={2} direction="row" alignItems="center" gap={2}>
                <CancelText
                  onClick={() => {
                    setIsEdit(!isEdit);
                  }}
                >
                  Cancel
                </CancelText>
                <SaveButton onClick={() => updateComment()}>Save</SaveButton>
              </Stack>
            </Stack>
          ) : (
            <FreeTextViewer value={content} />
          )}
        </ContentBlock>
      </Container>
    </CommentItem>
  );
};

export default CommentCard;
