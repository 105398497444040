import styled from '@emotion/styled';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Stack } from '@mui/material';

import { SmallPrioritySelectionOptionContainer } from '../../InitiativesCard/InitiativesCardStyles';

export const PriorityContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center'
});

export const PrioritySelectionContainer = styled(SmallPrioritySelectionOptionContainer)({
  cursor: 'pointer',
  height:"32px",
  borderRadius:"10px"
});

export const ArrowDownIcon = styled(KeyboardArrowDown)<{ open: boolean }>(({ open }) => ({
  transform: open && 'rotate(180deg)',
  transition: '.3s ease-in-out'
}));
