import { useCallback, useMemo } from 'react';

import { MetricDataPointDto, MetricDefinitionDto, SearchEntityRequest, SearchMetricMetadataRequest, useBulkPatchMetricDefinitions, useCalcMetricDefinitionDataPoints, useCalcMetricDefinitionProgress, useCreateMetricDefinition, useDeleteMetricDataPoint, useDeleteMetricDefinition, useGetMetricDefinition, useGetMetricDefinitionsProgress, useListMetricDefinitions, usePatchMetricDataPoint, usePatchMetricDefinition, useResetMetricDefinitionIntegration, useSearchMetricDefinitions, useSearchMetricsMetadata, useUpdateMetricDefinition } from '../api/generated';

const useMetricDefinition = (metricDefinitionId?: string, nonActionPlan?: boolean) => {
  const {
    data: metricViewListResponse,
    isLoading: metricListLoading,
    refetch: refetchMetricList
  } = useListMetricDefinitions({
    nonActionPlan: nonActionPlan && nonActionPlan
  },
    {
      query: {
        queryKey: ['metricViewList', nonActionPlan]
      }
    });

  const {
    data: metricDefinitionData,
    isLoading: metricDefinitionLoading,
    refetch: refetchMetricDefinition
  } = useGetMetricDefinition(metricDefinitionId, {
    query: {
      queryKey: ['getMetricDefinition', metricDefinitionId],
      enabled: !!metricDefinitionId
    }
  });

  const { mutateAsync: mutateCreateMetricDefinition } = useCreateMetricDefinition();
  const { mutateAsync: mutatePatchMetricDefinition } = usePatchMetricDefinition();
  const { mutateAsync: mutateUpdateMetricDefinition } = useUpdateMetricDefinition();
  const { mutateAsync: mutateResetMetricDefinitionIntegration } = useResetMetricDefinitionIntegration();
  const { mutateAsync: mutateDeleteMetricDefinition } = useDeleteMetricDefinition();
  const { mutateAsync: mutateCalcMetricDefinitionDataPoints } = useCalcMetricDefinitionDataPoints();
  const { mutateAsync: mutateBulkPatchMetricDefinitions } = useBulkPatchMetricDefinitions();
  const { mutateAsync: mutateSearchMetricsMetadata } = useSearchMetricsMetadata();
  const { mutateAsync: mutateSearchMetricDefinitions, isPending: searchMetricDefinitionsLoading } = useSearchMetricDefinitions();



  const createMetricDefinition = useCallback(
    async (data: Partial<MetricDefinitionDto>) => {
      const response = await mutateCreateMetricDefinition({ data });
      return response?.items?.[0] || null;
    },
    [mutateCreateMetricDefinition]
  );

  const searchMetricsMetadata = useCallback(
    async (data: SearchMetricMetadataRequest) => {
      const response = await mutateSearchMetricsMetadata({ data });
      return response?.items || [];
    },
    [mutateSearchMetricsMetadata]
  );

  const searchMetricDefinitions = useCallback(
    async (data: SearchEntityRequest) => {
      const response = await mutateSearchMetricDefinitions({ data });
      return response?.items || [];
    },
    [mutateSearchMetricDefinitions]
  );


  const patchMetricDefinition = async (id: string, data: Partial<MetricDefinitionDto>) =>
    await mutatePatchMetricDefinition({ id, data });

  const updateMetricDefinition = async (id: string, data: Partial<MetricDefinitionDto>) =>
    await mutateUpdateMetricDefinition({ id, data });

  const resetMetricDefinitionIntegration = async (id: string) =>
    await mutateResetMetricDefinitionIntegration({ id });


  const deleteMetricDefinition = useCallback(
    async (id: string) => {
      await mutateDeleteMetricDefinition({ id });
    },
    [mutateDeleteMetricDefinition]
  );

  const calcMetricDefinitionDataPoints = useCallback(
    async (id: string) => {
      const response = await mutateCalcMetricDefinitionDataPoints({ id });
      return response?.items?.[0] || null;
    },
    [mutateCalcMetricDefinitionDataPoints]
  );

  const bulkPatchMetricDefinitions = useCallback(
    async (data: MetricDefinitionDto[]) => {

      await mutateBulkPatchMetricDefinitions({ data: { items: data } });
    },
    [mutateBulkPatchMetricDefinitions]
  );

  const metrics = useMemo(() => metricViewListResponse?.items || [], [metricViewListResponse]);






  const { mutateAsync: mutatePatchMetricDataPoint } = usePatchMetricDataPoint();
  const { mutateAsync: mutateDeleteMetricDataPoint } = useDeleteMetricDataPoint();
  const { mutateAsync: mutateGetMetricDefinitionsProgress, isPending: isMetricsProgressLoading } = useGetMetricDefinitionsProgress();
  const { mutateAsync: mutateCalcMetricDefinitionProgress, isPending: isMetricsDataPointsLoading } = useCalcMetricDefinitionProgress();






  const patchMetricDataPoint = async (id: string, data: Partial<MetricDataPointDto>) =>
    await mutatePatchMetricDataPoint({ id, data });

  const calcMetricDefinitionProgress = async (id: string) =>
    await mutateCalcMetricDefinitionProgress({ id });

  const deleteMetricDataPoint = useCallback(
    async (id: string) => {
      await mutateDeleteMetricDataPoint({ id });
    },
    [mutateDeleteMetricDataPoint]
  );

  const getMetricsProgress = useCallback(
    async (metricDefinitionIds?: string[]) => {
      const response = await mutateGetMetricDefinitionsProgress({ data: { ids: metricDefinitionIds } });
      return response?.items;
    },
    [mutateGetMetricDefinitionsProgress]
  );



  const metricDefinition = useMemo(() => metricDefinitionData?.items?.[0] || null, [metricDefinitionData]);



  return {
    metrics,
    metricListLoading,
    refetchMetricList,
    createMetricDefinition,
    patchMetricDefinition,
    updateMetricDefinition,
    deleteMetricDefinition,
    patchMetricDataPoint,
    deleteMetricDataPoint,
    getMetricsProgress,
    isMetricsProgressLoading,
    calcMetricDefinitionProgress,
    metricDefinition,
    refetchMetricDefinition,
    metricDefinitionLoading,
    calcMetricDefinitionDataPoints,
    bulkPatchMetricDefinitions,
    isMetricsDataPointsLoading,
    searchMetricsMetadata,
    searchMetricDefinitions,
    searchMetricDefinitionsLoading,
    resetMetricDefinitionIntegration
  };
};

export default useMetricDefinition;
