import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  Badge,
  BadgeProps,
  Box,
  SxProps,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses
} from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Stack } from '@mui/system';


import { NavListProps } from '../../hooks/useNavigationListManifest';
import useUserFeature from '../../hooks/useUserFeature';
import Icon from './Icon';
import NavigationItem from './NavigationItem';

import palette from '../../theme/palette';
import { SidebarProps } from './Sidebar';




const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: 'transparent',
    top: 2
  }
}));


const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: palette.common.darkBlue,
    color: palette.background.default,
    fontFamily: "'Plus Jakarta Sans'",
    fontSize: '13px',
    height: "30px",
    borderRadius: "0px 6px 6px 0px"
  }
}));

interface NavigationListProps extends SidebarProps {
  list: NavListProps[];
  onClick: () => void;
  containerSx: SxProps;
}

const NavigationList = ({
  list,
  isOpen,
  onClick,
  containerSx
}: NavigationListProps) => {
  const location = useLocation();

  const { navigationPermissionMap, isFeatureBlocked } = useUserFeature();


  return (
    <List sx={{ ...containerSx }}>
      {list.filter(({ key, isHidden }) => !isFeatureBlocked(navigationPermissionMap[key]?.feature?.feature) && !isHidden)
        .map(({ label, icon, slug }) => {
          const selectedItem = slug === location.pathname;
          return (
            <StyledTooltip
              key={slug}
              arrow
              title={!isOpen && label}
              placement="right" >
              <Box  >

                <NavigationItem
                  to={slug}
                  key={label}
                  selected={selectedItem}
                  onClick={onClick}
                  isSideBarOpen={isOpen}
                >
                  <ListItemButton

                    sx={{

                      minHeight: 40,
                      px: isOpen ? 0 : 1,
                      display: 'flex',
                      alignItems: 'center',
                      mb: '3px'
                    }}
                    disableTouchRipple
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                    >
                      <Icon selectedItem={selectedItem} icon={icon} isOpen={isOpen} />
                      {label === 'Connect' && isOpen ? (
                        <StyledBadge
                          badgeContent={
                            <Typography variant="caption" color="primary">
                              Beta
                            </Typography>
                          }
                          color="primary"
                        >
                          <ListItemText
                            primary={label}
                            primaryTypographyProps={{ sx: { fontSize: 16 } }}
                            sx={{
                              color: 'white',
                              '& .MuiTypography-root': {
                                fontWeight: selectedItem ? 700 : 500,
                                fontFamily: 'Plus Jakarta Sans',
                                fontSize: '14px'
                              }
                            }}
                          />
                        </StyledBadge>
                      ) :
                        isOpen && (
                          <Stack>
                            <ListItemText
                              primary={label}
                              sx={{
                                color: 'white',
                                '& .MuiTypography-root': {
                                  fontWeight: selectedItem ? 700 : 500,
                                  fontFamily: 'Plus Jakarta Sans',
                                  fontSize: '14px'
                                }
                              }}
                            />
                          </Stack>
                        )
                      }
                    </Stack>
                  </ListItemButton>
                </NavigationItem>

              </Box>
            </StyledTooltip>
          );
        })}
    </List>
  );
};

export default NavigationList;
