import React, { useCallback, useState } from 'react';

import { IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ResourceDto } from '../../api/generated';
import { LinkTitle } from './HomeStyles';
import useResource from '../../hooks/useResource';
import useModal from '../../hooks/context-providers/useModal/useModal';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import palette from '../../theme/palette';

interface HomeLinkrops {
  resource: ResourceDto;
  refetch: () => void;
}

const LazyLoadedAreYouSureModal = React.lazy(
  () => import('../../modals/AreYouSureModal/AreYouSureModal')
);

const HomeLink = ({ resource, refetch }: HomeLinkrops) => {
  const { deleteResource } = useResource();
  const { showModal } = useModal();
  const [hovered, setHovered] = useState<boolean>(false);

  const deleteClick = useCallback(() => {
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        modal.hide();
      },
      onConfirm: () => {
        deleteResource(resource.id).then(() => refetch());
        modal.hide();
      },
      modalTitle: `Are you sure you want to delete \n${resource.title}?`,
      confirmButtonTitle: 'Delete'
    });
  }, [deleteResource, resource, refetch, showModal]);


  return (

    <Stack
      height="30px"
      direction="row"
      alignItems="end"
      justifyContent="space-between"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >

      <Stack
        direction="row"
        alignItems="center"
        gap="10px"
        sx={{cursor:"pointer"}}>
        <Stack width="10px">
          {hovered && (
            <Stack data-movable-handle>
              <DragIndicatorIcon sx={{ cursor: 'grab', width:"20px", color:palette.grey[3100] }} />
            </Stack>
          )}
        </Stack>
        <Stack

          onClick={() => window.open(resource.link.startsWith("http") ? resource.link : "//" + resource.link, "_blank", "noreferrer")}
          marginBottom={1}>
          <LinkTitle>{resource.title}</LinkTitle>
        </Stack>
      </Stack>


      <Stack
        width="20px">
        {hovered &&

          <IconButton
            size="small"
            onClick={deleteClick}
          ><DeleteOutlineOutlinedIcon sx={{ width: "20px", height: "20px" }} /></IconButton>}
      </Stack>

    </Stack>

  );
};

export default React.memo(HomeLink);
