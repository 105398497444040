import React, { createContext, useContext, useState } from 'react';

import { ActionPlanMetricMetadataDto, MetricDefinitionDto } from '../../api/generated';

const Context = createContext(null);

type UseMetricSidebarView = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentMetric: MetricDefinitionDto;
  setCurrentMetric: React.Dispatch<React.SetStateAction<MetricDefinitionDto>>;
  currentMetricMetadata: ActionPlanMetricMetadataDto;
  setCurrentMetricMetadata: React.Dispatch<React.SetStateAction<ActionPlanMetricMetadataDto>>;
  refetch?: () => void;
  setRefetch?: (value: () => void) => void;
};

const useMetricSidebarView = () => useContext<UseMetricSidebarView | null>(Context);

export const MetricSidebarViewProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [currentMetric, setCurrentMetric] = useState<MetricDefinitionDto | null>(null);
  const [currentMetricMetadata, setCurrentMetricMetadata] = useState<ActionPlanMetricMetadataDto | null>(null);
  const [refetch, setRefetch] = useState<() => void>();

  const value = {
    open,
    setOpen,
    currentMetric,
    setCurrentMetric,
    currentMetricMetadata,
    setCurrentMetricMetadata,
    refetch,
    setRefetch
  };

  return <Context.Provider value={value}> {children}</Context.Provider>;
};

export default useMetricSidebarView;
