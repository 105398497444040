import { createContext } from 'react';

import { State, HideFn, ShowFn, DestroyFn, DestroyByRootIdFn, UpdateFn } from './ModalContextTypes';
import { initialState } from './ModalReducer';

export interface ModalContextState {
  state: State;
  updateModal: UpdateFn;
  hideModal: HideFn;
  destroyModal: DestroyFn;
  destroyModalsByRootId: DestroyByRootIdFn;
  showModal: ShowFn;
}

export const initialContextState = {
  state: initialState,
  hideModal: () => null,
  showModal: () => ({
    id: 'id',
    hide: () => null,
    destroy: () => null,
    update: () => null
  }),
  destroyModal: () => null,
  updateModal: () => null,
  destroyModalsByRootId: () => null
};

const ModalContext = createContext<ModalContextState>(initialContextState);

export default ModalContext;
