import React, { useCallback, useRef, useState } from 'react';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Stack } from '@mui/material';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import { NoteDto } from '../../api/generated';
import useNotes from '../../hooks/useNotes';
import { QuickNoteInput } from './HomeStyles';
import useModal from '../../hooks/context-providers/useModal/useModal';
import AppPopover from '../ActionPlan/components/AppPopover/AppPopover';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';

const LazyLoadedAreYouSureModal = React.lazy(
  () => import('../../modals/AreYouSureModal/AreYouSureModal')
);

const LazyLoadedEditInitiativeModal = React.lazy(
  () => import('../../modals/InitiativeModal/InitiativeModal')
);

const LazyLoadedActionItemModal = React.lazy(
  () => import('../../modals/ActionItemModal/ActionItemModal')
);

const LazyLoadedAddToSpaceModal = React.lazy(
  () => import('../../modals/AddToSpaceModal/AddToSpaceModal')
);


const QuickNote = ({
  note,
  refetch
}: {
  note: NoteDto;
  refetch: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [noteDraft, setNoteDraft] = useState(note.content || '');
  const { patchNote, deleteNote } = useNotes();
  const [hovered, setHovered] = useState(false);
  const { showModal } = useModal();

  const noteRef = useRef(null);

  const updateNoteContent = () => {
    patchNote(note.id, { content: noteDraft }).then(() => {
      noteRef?.current?.children?.[0].blur();
    });
  };

  const onContentKeyDown = async event => {
    if (event.key === 'Enter') {
      updateNoteContent();
    }
  };

  const deleteNoteClick = useCallback(() => {
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        setAnchorEl(null);
        deleteNote(note.id).then(() => refetch());
        modal.hide();
      },
      modalTitle: `Are you sure you want to delete \n${
        note.content ? + note.content  : 'the selected note?'
      }?`,
      confirmButtonTitle: 'Delete'
    });
  }, [deleteNote, note, refetch, showModal]);

  const convertToInInitiativeClick = useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedEditInitiativeModal, {
      onClose: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onCancel: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        setAnchorEl(null);
        deleteNote(note.id).then(() => refetch());
        modal.hide();
      },
      initiativeSuggestion: noteDraft
    });
  }, [deleteNote, showModal, noteDraft, refetch, note.id]);

  const addtoSpaceClick = useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedAddToSpaceModal, {
      onClose: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        setAnchorEl(null);
        deleteNote(note.id).then(() => refetch());
        modal.hide();
      },
      content: noteDraft
    });
  }, [deleteNote, showModal, noteDraft, refetch, note.id]);

  const onAddActionItemWithSuggestion = useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedActionItemModal, {
      onClose: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onCancel: () => {
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        setAnchorEl(null);
        deleteNote(note.id).then(() => refetch());
        modal.hide();
      },
      suggestedTitle: noteDraft
    });
  }, [deleteNote, refetch, noteDraft, showModal, note.id]);


  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      gap="2px"
      minHeight="30px"
      sx={{ borderRadius: "8px" }}

    >

      <QuickNoteInput
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => setHovered(false)}
        multiline
        ref={noteRef}
        hovered={hovered}
        value={noteDraft}
        onChange={event => {
          setNoteDraft(event.currentTarget.value);
        }}
        onKeyDown={onContentKeyDown}
        onBlur={updateNoteContent}
      />

      <IconButton
        onClick={event => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
        sx={{ p: 0 }}
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>

      <AppPopover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          handleClose={() => setAnchorEl(null)}
          onDelete={deleteNoteClick}
          customActions={[
            {
              title: 'Add as Task',
              icon: <PostAddOutlinedIcon />,
              onAction: onAddActionItemWithSuggestion
            },
            {
              title: 'Add as Initiative',
              icon: <AccountTreeOutlinedIcon fontSize="small" />,
              onAction: convertToInInitiativeClick
            },
            {
              title: 'Add to Spaces',
              icon: <WorkspacesOutlinedIcon fontSize="small" />,
              onAction: addtoSpaceClick
            }
            
          ]}
        />
    </Stack>
  );
};

export default QuickNote;
