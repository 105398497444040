
import { Stack, Switch, Typography, alpha, styled } from '@mui/material';
import palette from '../../theme/palette';







export const TaskTitle = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: "bold",
  fontSize: '14px',
  color: palette.common.darkBlue,
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));


export const EmptyStateText = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '13px',
  color: palette.grey[2000]
}));


export const AddNew = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: "bold",
  fontSize: '14px',
  cursor: "pointer",
  ":hover": { opacity: 0.8 },
  color: palette.primary.main
}));


export const DueDate = styled(Typography)<{
  expired?: boolean;
}>(({ expired }) => ({
  fontFamily: 'Plus Jakarta Sans',
  width: 'max-content',
  color: expired ? palette.red[2] : palette.grey[1100],
  fontSize: '11px',
  fontWeight: 500,
  ':hover': {
    opacity: 0.7,
    backgroundColor: palette.background.paper,
    padding: 1,
    borderRadius: '6px',
    border: `0.5px solid ${palette.border.grey}`
  }
}));




export const StatusTitle = styled(Stack)<{
  color: string;
}>(({ color }) => ({
  borderRadius: "16px",
  padding: "2px 5px",
  width: "74px",
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '11px',
  textAlign: "center",
  color: palette.common.darkBlue,
  backgroundColor: alpha(color, 0.2)
}));





export const ArchivedToggle = styled(Switch)({
  marginTop: 2,
  width: 25,
  height: 13,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: "1.5px 2px",
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: palette.primary.main
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    backgroundColor: '#fff',
    width: 10,
    height: 10,
    borderRadius: 6
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    boxSizing: 'border-box',
    backgroundColor: palette.grey[700]
  }
});

export const ViewArchivedTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '13px',
  fontWeight: 700,
  color: palette.grey[3100]
});

