import React from 'react';

import useAuth from './useAuth';

const useAccountFiscalMonth = () => {
  const { currentAccount } = useAuth();
  const monthToStart = React.useMemo(
    () => currentAccount?.config?.fiscalYearStartMonth || 1,
    [currentAccount?.config?.fiscalYearStartMonth]
  );

  const currentMonth = new Date().getMonth();
  const currentYear =
    monthToStart > currentMonth ? new Date().getFullYear() - 1 : new Date().getFullYear();

  const monthToStartDate = React.useMemo(() => {
    const tempDate = new Date();
    tempDate.setTime(0);
    tempDate.setDate(1);
    tempDate.setMonth(monthToStart - 1);
    tempDate.setFullYear(currentYear);
    return tempDate;
  }, [currentYear, monthToStart]);

  const monthToEndDate = React.useMemo(() => {
    const tempDate = new Date(monthToStartDate);
    tempDate.setMonth(monthToStart + 11);
    return tempDate;
  }, [monthToStart, monthToStartDate]);

  return { monthToStart, monthToStartDate, monthToEndDate };
};

export default useAccountFiscalMonth;
