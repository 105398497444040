import { Stack, Typography, styled } from '@mui/material';
import palette from '../../../theme/palette';


export const SortValue = styled(Typography)<{ selected: boolean }>(({ selected }) => ({
  fontSize: '14px',
  fontWeight: '500',
  cursor: 'pointer',
  color: selected ? palette.green[1] : palette.common.darkBlue
}));

export const SortValueAdditional = styled(Typography)<{ selected: boolean }>(({ selected }) => ({
  fontSize: '14px',
  fontWeight: '500',
  cursor: 'pointer',
  color: selected ? palette.green[1] : palette.grey[1100],
  paddingLeft: '4px'
}));

export const SortContainer = styled(Stack)({
  border: `1px solid ${palette.border.grey}`,
  borderRadius: '8px',
  background: palette.background.default
});

export const ControlItem = styled(Stack)<{
  selected?: boolean;
}>(({ selected }) => ({
  flexDirection: "row",
  background: selected ? palette.background.lightGreen : palette.background.default,
  padding: '10px 20px'
}));

export const Title = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '13px',
  fontWeight: 700
});


export const SortHeader = styled(Stack)<{
  selected?: boolean;
}>(({ selected }) => ({
  flexDirection: "row",
  alignItems: "end",
  gap: '2px',
  cursor: 'pointer',
  backgroundColor: selected && palette.background.lightGreen,
  color: selected ? palette.common.darkBlue : palette.grey[3100],
  borderRadius: "8px",
  padding: "2px 5px",
  svg: { path: { fill: selected ? palette.common.darkBlue : palette.grey[3100] } },
  ":hover": {
    svg: { path: { fill: palette.common.darkBlue } },
    color: palette.common.darkBlue,
    backgroundColor: !selected && palette.background.grey
  }
}));


export const ClearButton = styled(Stack)({
  marginLeft: 4,
  paddingBottom: 1,
  svg: { path: { fill: palette.grey[800] } },
  ":hover": { opacity: 0.7 }
});


