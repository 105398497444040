import { useMemo } from 'react';

import {
  AggregateMetricDataPointsParams,
  MetricDataPointDto,
  useAggregateMetricDataPoints,
  useCreateMetricDataPoints,
  useListMetricDataPoints
} from '../api/generated';

const useMetricDataPoints = (
  metricId?: string,
  params?: AggregateMetricDataPointsParams,
  fetchRaw?: boolean
) => {
  const {
    data: metricDataPointsResponse,
    refetch: refetchMetricDataPoints,
    isLoading: metricDataPointsLoading
  } = useAggregateMetricDataPoints(metricId, params, {
    query: { queryKey: ['aggregatedDataPoints', metricId, params], enabled: !!metricId }
  });

  const {
    data: metricDataPointsData,
    isLoading: rawMetricDataPointsLoading,
    refetch: refetchRawMetricDataPoints
  } = useListMetricDataPoints(metricId, {
    query: {
      queryKey: ['listMetricDataPoints', metricId],
      enabled: !!metricId && fetchRaw
    }
  });

  const metricDatapoints = useMemo(
    () =>
      metricDataPointsResponse?.items?.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
      ) || [],
    [metricDataPointsResponse?.items]
  );

  const rawMetricDatapoints = useMemo(() => metricDataPointsData?.items || [], [metricDataPointsData]);

  const { mutateAsync: mutateCreateMetricDataPoints } = useCreateMetricDataPoints();

  const createMetricDataPoints = async (
    dataPoints: MetricDataPointDto[]
  ) => await mutateCreateMetricDataPoints({ data: { dataPoints: dataPoints } });

  return { metricDatapoints, metricDataPointsLoading, refetchMetricDataPoints, rawMetricDatapoints, rawMetricDataPointsLoading, refetchRawMetricDataPoints, createMetricDataPoints };
};

export default useMetricDataPoints;
