import { ArrowCircleDown, CheckRounded, Circle } from '@mui/icons-material';
import { Accordion, Checkbox, Stack, Typography, styled } from '@mui/material';

import { DropDownSelect } from '../../../../modals/InitiativeModal/InitiativeModalStyles';
import palette from '../../../../theme/palette';
import { FormCheckbox } from '../../../BusinessArea/components/BusinessArea/BusinessAreaStyles';

const ExpandIcon = styled(ArrowCircleDown)({
  fontSize: '20px'
});

const FilterCheckbox = styled(Checkbox)(({ theme, checked }) => ({
  backgroundColor: checked ? theme.palette.green[2] : theme.palette.background.default,
  '& .MuiSvgIcon-root': { fontSize: 24 }
}));

const CancelButton = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[1100],
  fontSize: '14px',
  fontWeight: 500,
  fontFamily: 'Plus Jakarta Sans',
  cursor: 'pointer',
  ':hover': {
    transform: 'scale(1.03)'
  }
}));

const ApplyButton = styled(Typography)(({ theme }) => ({
  color: theme.palette.green[1],
  fontSize: '14px',
  fontWeight: 700,
  fontFamily: 'Plus Jakarta Sans',
  cursor: 'pointer',
  ':hover': {
    transform: 'scale(1.03)'
  }
}));

const ClearAllButton = styled(CancelButton)({
  fontSize: '11px',
  fontWeight: 600,
  fontFamily: 'Plus Jakarta Sans'
});

const SelectAllButton = styled(ApplyButton)({
  fontSize: '11px'
});

const MonthCheckbox = styled(FormCheckbox)<{ checked: boolean }>(({ theme, checked }) => ({
  backgroundColor: checked ? theme.palette.green[1] : theme.palette.grey[300],
  borderRadius: '15px',
  padding: '4px 13px 4px 6px'
}));

const FilterContainer = styled(Stack)({
  maxWidth: '246px',
  minWidth: '200px',
  border: `2px solid ${palette.border.grey}`,
  borderRadius: '5px',
  maxHeight: '500px'
});

const UncheckedCircle = styled(Circle)({
  color: '#fff',
  height: '18px',
  width: '18px'
});

const CheckedCircle = styled(CheckRounded)({
  color: palette.green[1],
  height: '18px',
  width: '18px'
});

const YearDropDown = styled(DropDownSelect)({
  minWidth: 0
});

const YearDropdownOption = styled(Typography)({
  fontSize: '13px',
  fontWeight: 400,
  fontFamily: 'Plus Jakarta Sans',
  color: palette.grey[110]
});

const ScrollableAccordion = styled(Accordion)({
  '.MuiCollapse-wrapperInner': {
    maxHeight: '200px',
    overflowY: 'auto'
  },
  ':last-of-type': {
    borderRadius: '0px'
  }
});

export {
  FilterCheckbox,
  CancelButton,
  ApplyButton,
  SelectAllButton,
  ClearAllButton,
  MonthCheckbox,
  FilterContainer,
  ExpandIcon,
  UncheckedCircle,
  CheckedCircle,
  YearDropDown,
  YearDropdownOption,
  ScrollableAccordion
};
