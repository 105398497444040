
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import React, { ReactNode, useState } from "react";
import { Stack } from '@mui/material';
import palette from '../../theme/palette';
import { BaseSingleInputFieldProps, DateValidationError, DateView, FieldSection, UseDateFieldProps } from '@mui/x-date-pickers';


interface AppDatePickerProps {
  value: Dayjs;
  onChange?: (newValue: Dayjs) => void;
  height?: string;
  component?: ReactNode;
  views?: DateView[]
  inputFormat?: string;
}

interface ButtonFieldProps
  extends UseDateFieldProps<Dayjs>,
  BaseSingleInputFieldProps<
    Dayjs | null,
    Dayjs,
    FieldSection,
    DateValidationError
  > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  component: ReactNode;
}


function ButtonField(props: ButtonFieldProps) {
  const {
    setOpen,
    component,
    id,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {}
  } = props;

  return (
    <Stack
      id={id}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.(prev => !prev)}
    >
      {component}
    </Stack>
  );
}

const AppDatePicker = ({ value, onChange, height, component, views = ["year", "month", "day"], inputFormat }: AppDatePickerProps) => {
  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {component ?
        <DatePicker
          format={inputFormat ? inputFormat : null}
          views={views}
          slots={{ field: ButtonField }}
          slotProps={{ field: { setOpen, component: component } as ButtonFieldProps }}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          value={value}
          onChange={onChange}
        />
        :
        <Stack
          sx={{
            svg: { path: { fill: palette.grey[300] } }
          }}
          height={height}>
          <DatePicker
            format={inputFormat ? inputFormat : null}
            views={views}
            slotProps={{
              textField: {
                sx: {
                  borderRadius: "10px",
                  padding: 0,
                  margin: 0,
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: "none"
                  },
                  '& .MuiOutlinedInput-root': {
                    padding: 0,
                    background: "none",
                    border: "none"
                  },
                  '& .MuiInputAdornment-root button': {
                    cursor: "pointer"
                  },
                  '& .MuiOutlinedInput-input': {
                    '&::placeholder': { fontSize: "13px" }
                  }
                }
              }
            }
            }
            value={value}
            onChange={onChange}

          />
        </Stack>}
    </LocalizationProvider>

  );
};


export default AppDatePicker;