import React, { useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { MeetingDto } from '../../api/generated';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import FlagCircleOutlinedIcon from '@mui/icons-material/FlagCircleOutlined';
import Editor from '../../screens/BizAreaModules/components/Editor';
import useMeetings from '../../hooks/useMeetings';
import { CircularProgress, Stack } from '@mui/material';
import { HeaderContainer, Title } from './MeetingNotesStyles';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import palette from '../../theme/palette';
import useModal from '../../hooks/context-providers/useModal/useModal';
import AppPopover from '../../screens/ActionPlan/components/AppPopover/AppPopover';

interface MeetingNotesProps {
  meeting: MeetingDto;
  title?: string;
  alwaysShowToolbar?: boolean;
  enableTextContextMenu?: boolean;
}

const LazyLoadedActionItemModal = React.lazy(
  () => import('../../modals/ActionItemModal/ActionItemModal')
);

const LazyLoadedEditInitiativeModal = React.lazy(
  () => import('../../modals/InitiativeModal/InitiativeModal')
);

const LazyLoadedObjectiveModal = React.lazy(
  () => import('../../modals/ObjectiveModal/ObjectiveModal')
);

const MeetingNotes = ({ meeting, title, alwaysShowToolbar = false, enableTextContextMenu }: MeetingNotesProps) => {
  const [editorChangeLoading, setEditorChangeLoading] = useState(false);
  const [notes, setNotes] = useState(meeting?.notes);

  const { showModal } = useModal();

  const [textAnchorEl, setTextAnchorEl] = useState(null);

  const [selectedText, setSelectedText] = useState<string>('');

  const openContextMenu = (anchorEl: null | HTMLElement, text: string) => {
    setSelectedText(text);
    setTextAnchorEl(anchorEl);
  }

  useEffect(() => {
    if (meeting) {
      setNotes(meeting.notes);
    }
  }, [meeting]);

  const {
    updateMeetingNotes
  } = useMeetings();

  const debouncedUpdateFollowUp = useDebouncedCallback(
    (value: string) =>
      updateMeetingNotes(meeting.id, value).then(() => setEditorChangeLoading(false)),
    500
  );

  const onAddTask = useCallback(() => {
    setTextAnchorEl(null);
    const modal = showModal(LazyLoadedActionItemModal, {
      onClose: () => {
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
      onConfirm: () => {
        modal.hide();
      },
      suggestedTitle: selectedText
    });
  }, [selectedText, showModal]);

  const onInInitiativeClick = useCallback(() => {
    setTextAnchorEl(null);
    const modal = showModal(LazyLoadedEditInitiativeModal, {
      onClose: () => {
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
      onConfirm: () => {
        modal.hide();
      },
      initiativeSuggestion: selectedText
    });
  }, [showModal, selectedText]);

  const addObjectiveClick = useCallback(() => {
    setTextAnchorEl(null);
    const modal = showModal(LazyLoadedObjectiveModal, {
      onClose: () => {
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
      onConfirm: () => {
        modal.hide();
      },
      title: selectedText
    });
  }, [showModal, selectedText]);

  return (
    <Stack>
      <HeaderContainer>
        <StickyNote2OutlinedIcon width="25px" height="25px" sx={{ color: palette.primary.main }} />
        <Title >{title ? title : "Notes"}</Title>
        {editorChangeLoading &&
          <CircularProgress size="20px" sx={{ marginLeft: '10px' }} />
        }
      </HeaderContainer>

      <Editor
        showBorder={false}
        sx={{ height: "100%" }}
        placeholder='Type here to add notes'
        alwaysShowToolbar={alwaysShowToolbar}
        onChange={value => {
          setEditorChangeLoading(true);
          setNotes(value);
          debouncedUpdateFollowUp(value);
        }}
        value={notes}
        openContextMenu={enableTextContextMenu && openContextMenu}
      />

      <AppPopover
        sx={{
          boxShadow: `2px 10px 10px ${palette.grey[600]}`
        }}
        anchorEl={textAnchorEl}
        open={Boolean(textAnchorEl)}
        handleClose={() => {
          setTextAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
        customActions={[
          {
            title: 'Add as objective',
            icon: <FlagCircleOutlinedIcon fontSize="small" />,
            onAction: addObjectiveClick
          },
          {
            title: 'Add as initiative',
            icon: <AccountTreeOutlinedIcon fontSize="small" />,
            onAction: onInInitiativeClick
          },
          {
            title: 'Add as task',
            icon: <FormatListBulletedOutlinedIcon fontSize='small' />,
            onAction: onAddTask
          }

        ]}
      />

    </Stack>
  );
};

export default MeetingNotes;
