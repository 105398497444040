import React, { Suspense, createContext, useContext, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Stack } from '@mui/system';

import useInitiativeSidebar from '../../hooks/context-providers/useInitiativeSidemenu';
import Header, { NavigationFlowItem } from '../Header/Header';
import InitiativeSidebar from '../InitiativeSidebar/InitiativeSidebar';
import MeetingNoteDrawer from '../MeetingNoteDrawer/MeetingNoteDrawer';
import { Loader } from '../ProtectedRoute/ProtectedRoute';
import useTaskSidebar from '../../hooks/context-providers/useTaskSidebar';
import TaskSidebar from '../TaskSidebar/TaskSidebar';
import SharedWorkspacePointSidebar from '../SharedWorkspacePointSidebar/SharedWorkspacePointSidebar';
import useSharedWorkspacePointSidebar from '../../hooks/context-providers/useSharedWorkspacePointSidebar';
import Sidebar, { sidebarWidth } from '../Sidebar/Sidebar';
import useObjectiveSidebar from '../../hooks/context-providers/useObjectiveSidebar';
import ObjectiveSidebar from '../ObjectiveSidebar/ObjectiveSidebar';
import useMetricSidebarView from '../../hooks/context-providers/useMetricSidebarView';
import MetricViewSidebar from '../../screens/MetricsManagement/components/MetricViewSidebar/MetricViewSidebar';

interface LayoutContextProps {
  setNavigationFlowItems: React.Dispatch<React.SetStateAction<Array<NavigationFlowItem>>>;
}

const Context = createContext<LayoutContextProps | null>(null);

const useLayoutContext = () => useContext<LayoutContextProps | null>(Context);
export const LayoutProvider = () => {
  const { currentInitiative } = useInitiativeSidebar();
  const { currentActionItem } = useTaskSidebar();
  const { currentPoint } = useSharedWorkspacePointSidebar();
  const { currentObjective } = useObjectiveSidebar();
  const { currentMetric } = useMetricSidebarView();

  // Local context states
  const [navigationFlowItems, setNavigationFlowItems] = useState([]);

  const value = useMemo(
    () => ({
      setNavigationFlowItems
    }),
    [setNavigationFlowItems]
  );

  return (
    <Context.Provider value={value}>
      <Stack
        className="tooltipBoundary"
        direction="row"
        height="100%"
        width="100%"
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        <Sidebar />
        <Stack
          height="100%"
          width={`calc(100% - ${sidebarWidth.closed + 'px'})`}
          marginLeft={sidebarWidth.closed + "px"}
        >

          <Header navigationFlowItems={navigationFlowItems} />


          <MeetingNoteDrawer />
          {currentInitiative && <InitiativeSidebar />}
          {currentActionItem && <TaskSidebar />}
          {currentPoint && <SharedWorkspacePointSidebar />}
          {currentObjective && <ObjectiveSidebar />}
          {currentMetric && <MetricViewSidebar />}
          <Stack
            direction="row"
            height="100%"
            width="100%"
            marginTop="56px"
            sx={{
              backgroundColor: 'grey.100'
            }}
          >
            <Suspense fallback={<Loader />}>
              <Outlet />
            </Suspense>
          </Stack>
        </Stack>
      </Stack>
    </Context.Provider>
  );
};

export default useLayoutContext;
