
import { Button, Input, InputBase, Select, Stack, Typography, alpha, styled } from '@mui/material';
import palette from '../../theme/palette';

export const Section = styled(Stack)(() => ({
  border: `1px solid ${palette.grey[1000]}`,
  backgroundColor: palette.secondary.main,
  boxShadow: '0px 10px 20px rgba(28, 39, 76, 0.03)',
  borderRadius: '16px',
  padding: '0 10px 20px',
  marginTop: '20px'
}));

export const OkrContainer = styled(Stack)(() => ({
  backgroundColor: alpha(palette.blue[8], 0.5),
  borderRadius: '8px',
  cursor: "pointer",
  height: "140px",
  width: "200px",
  padding: "5px",
  marginRight: 10,
  transition: 'all .2s ease-in-out',
  ':hover': {
    backgroundColor: palette.grey[3000],
    transform: 'scale(1.02)'
  }
}));

export const AddPoint = styled(Input)({
  width: "100%",
  marginBottom: 5,
  border: `1px solid ${palette.grey[1800]}`,
  padding: '5px 10px',
  borderRadius: '8px',
  input: { fontWeight: 400, fontSize: '14px', color: palette.common.darkBlue },
  textArea: { fontWeight: 400, fontSize: '14px', color: palette.common.darkBlue }
});



export const TimeFrameSelect = styled(Select)({
  height: "20px",
  fontSize: '12px',
  backgroundColor: palette.background.default,
  borderRadius: '8px',
  fontFamily: 'Plus Jakarta Sans',
  border: `1px solid ${palette.border.grey}`,
  '& .MuiOutlinedInput-input': {
    width: '35px'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: "none"
  }
});

export const TimeframeTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '11px',
  fontWeight: 500,
  color: palette.common.darkBlue

});

export const HeaderTitle = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: "15px",
  color: palette.common.darkBlue
}));

export const QuickNoteInput = styled(InputBase)<{
  hovered?: boolean;
}>(({ hovered }) => ({
  padding: 0,
  marginLeft: "2px",
  paddingLeft: 3,
  minHeight: "25px",
  paddingRight: 3,
  border: 'none',
  backgroundColor: hovered ? palette.background.grey : palette.background.default,
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px',
  borderRadius: "8px",
  width: "90%",
  '& .MuiInputBase-input': {
    cursor: "pointer",
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  color: palette.common.darkBlue,
  ':focus-within': { border: `1px solid ${palette.border.grey}`, backgroundColor: palette.background.default }
}));

export const LinkTitle = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '14px',
  color: palette.blue[4],
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: "350px"
});

export const SideMenuContainer = styled(Stack)({
  background: 'white',
  padding: 10,
  paddingTop: 2,
  height: '100%',
  borderRadius: "0px 0px 0px 10px"
});

export const ViewAllButton = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 24px',
  width: '130px',
  height: '30px',
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 700,
  color: palette.secondary.main,
  opacity: 1,
  background: palette.green[1],
  borderRadius: '8px',
  cursor: 'pointer',
  ':hover': {
    opacity: 0.8
  }
}));

export const NoOkrsTitle = styled(Typography)(() => ({
  fontSize: "15px",
  fontFamily: "'Plus Jakarta Sans'",
  color: palette.grey[1100]
}));

export const StartHere = styled(Typography)(() => ({
  fontSize: "15px",
  fontWeight: "bold",
  cursor: "pointer",
  fontFamily: "'Plus Jakarta Sans'",
  color: palette.primary.main
}));


export const BusinessAreaButton = styled(Button)(
  () => ({
    padding: '5px',
    textAlign: "center",
    width: "180px",
    alignItems: "center",
    height: "45px",
    backgroundColor: alpha(palette.grey[3200], 1),
    border: `1px solid ${palette.border.grey}`,
    borderRadius: '8px',
    justifyContent: 'center',
    fontWeight: 700,
    fontSize: '13px',
    color: palette.common.darkBlue,
    transition: 'all .2s ease-in-out',
    ':hover': {
      textDecoration: 'none',
      backgroundColor: alpha(palette.grey[3200], 0.5),
      transform: 'scale(1.03)'
    }
  })
);
