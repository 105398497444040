import { useGetAccountTags } from "../api/generated";


const useTags = () => {
  const { data: tagsData, refetch: refetchTags, isLoading: tagsLoading } = useGetAccountTags();

  const accountTags = tagsData?.items?.[0] || null;

  return {
    accountTags,
    tagsLoading,
    refetchTags
  };
};

export default useTags;
