import React from 'react';

import { Stack } from '@mui/material';

import {
  ActionPlanActionItemCommentDto,
  ActionPlanInitiativeCommentDto,
  ActionPlanObjectiveCommentDto
} from '../../api/generated';
import { ReactComponent as EmptyState } from '../../assets/updatesEmpty.svg';
import CommentCard from '../CommentCard/CommentCard';
import { Container, EmptyText } from './CommentsListStyles';

type CommentsListProps = {
  comments:
  | ActionPlanInitiativeCommentDto[]
  | ActionPlanObjectiveCommentDto[]
  | ActionPlanActionItemCommentDto[];
  deleteComment: (id: string) => Promise<void>;
  refetchComments: () => void;
  patchComment: (
    id: string,
    comment:
      | Partial<ActionPlanInitiativeCommentDto>
      | Partial<ActionPlanObjectiveCommentDto>
      | Partial<ActionPlanActionItemCommentDto>
  ) => Promise<void>;
  title?: string;
};

const CommentsList = ({
  comments,
  deleteComment,
  refetchComments,
  patchComment,
  title
}: CommentsListProps) => (
  <Container gap={3}>
    <Stack sx={{ overflow: 'auto', gap: '9px', marginBottom: '10px' }}>
      {comments.length > 0 ? (
        comments
          .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
          .map(comment => (
            <CommentCard
              patchComment={patchComment}
              key={comment.id}
              comment={comment}
              deleteComment={deleteComment}
              refetchComments={refetchComments}
              title={title}
            />
          ))
      ) : (
        <Stack alignItems="center" marginTop={1}>
          <EmptyState width="60px" height="60px" />
          <EmptyText>No {title ? title + "s" : "Comments"}</EmptyText>
        </Stack>
      )}
    </Stack>
  </Container>
);

export default CommentsList;
