import React from 'react';

import { Stack, SxProps } from '@mui/material';

import useAuth from '../../hooks/useAuth';
import palette from '../../theme/palette';
import UserView from '../User/UserView';
import { Title } from './OwnerAndAssigneeStyles';

export type AcademyBannerProps = {
  ownerId?: string;
  updateOwnerId?: (ownerId: string) => void;
  assigneeId?: string;
  updateAssigneeId?: (assigneeId: string) => void;
  sx?: SxProps;
  onlyAvatar?: boolean;
  chooseDefaultOwner?: boolean;
  size?: string;
  includeTitle?: boolean;
  viewOnly?: boolean;
};

const OwnerAndAssignee = ({
  ownerId,
  updateOwnerId,
  assigneeId,
  updateAssigneeId,
  sx,
  onlyAvatar,
  viewOnly,
  chooseDefaultOwner,
  includeTitle,
  size
}: AcademyBannerProps) => {
  const { user } = useAuth();

  const ownerIdToUse = !ownerId && chooseDefaultOwner ? user.id : ownerId;

  return (
    <Stack gap="5px" sx={sx} direction="row" alignItems="center" justifyContent="space-between" color={palette.grey[500]}>
      <Stack
        gap="3px"
      >
        {includeTitle && <Title>Owner</Title>}
        <UserView
          viewOnly={viewOnly}
          size={size}
          onlyAvatar={onlyAvatar}
          tooltip="Owner"
          userId={ownerIdToUse}
          onChange={updateOwnerId}
        />
      </Stack>
      <Stack
        gap="3px"
      >
        {includeTitle && <Title>Assignee</Title>}

        <UserView
          viewOnly={viewOnly}
          size={size}
          onlyAvatar={onlyAvatar}
          tooltip="Assignee"
          incldueAssigneUsers
          userId={assigneeId}
          onChange={updateAssigneeId}
        />
      </Stack>
    </Stack>
  );
};

export default OwnerAndAssignee;
