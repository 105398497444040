import React, { useMemo, useState, MouseEvent } from 'react';
import { ClickAwayListener, Stack, Tooltip } from '@mui/material';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';


import {  ActionPlanActionItemDtoStatus } from '../../../api/generated';
import FilterPopper from '../../FilterPopper/FilterPopper';
import { ControlItem } from '../../../screens/ActionPlan/components/FilterAndSort/FilterAndSortRowStyles';

import AppCheckbox from '../../Checkbox/AppCheckbox';
import {  actionItemStatusMap } from '../../../utils/actionPlanUtil';
import { ClearButton, ControlButton, FilterContainer, FormCheckbox, Title } from './ActionItemFiltersStyles';





type ActionItemStatusFilterProps = {
  appliedStatues: ActionPlanActionItemDtoStatus[];
  onApplyStatues: (priorities: ActionPlanActionItemDtoStatus[]) => void;
};

const ActionItemStatusFilter = ({ appliedStatues, onApplyStatues }: ActionItemStatusFilterProps) => {
  const [openFilter, setOpenFilter] = useState<HTMLDivElement | null>(null);
  const isOpenFilter = useMemo(() => !!openFilter, [openFilter]);

  const openFilterPopup = (event: React.MouseEvent<HTMLDivElement>) => {
    setOpenFilter(event.currentTarget);
  };

  const closeFilterPopup = () => {
    setOpenFilter(null);
  };


  const onChangeStatus = (status: ActionPlanActionItemDtoStatus) => {
    onApplyStatues(
      appliedStatues.includes(status)
        ? appliedStatues.filter(s => s !== status)
        : [...appliedStatues, status]);
  };


  const applyClearAll = () => {
    onApplyStatues([]);
    closeFilterPopup();
  };

  const selected = appliedStatues?.length > 0;

  return (
    <ClickAwayListener onClickAway={closeFilterPopup}>
      <Stack>
        <ControlButton
          selected={selected}
          onClick={openFilterPopup}
          direction="row">
          <Title>Status</Title>
          <ArrowDropDownOutlinedIcon />
          {selected &&
            <ClearButton
              onClick={(event: MouseEvent<HTMLDivElement>) => {
                event.stopPropagation();
                applyClearAll();
              }}>
              <Tooltip
                title="Clear">
                <ClearOutlinedIcon sx={{ width: "18px", height: "18px" }} />
              </Tooltip>
            </ClearButton>}
        </ControlButton>
        <FilterPopper open={isOpenFilter} anchorEl={openFilter}>
          <FilterContainer>


            {Object.keys(actionItemStatusMap).map((status: ActionPlanActionItemDtoStatus, index) =>
              <ControlItem
                key={index}
                disableTopBorder={index === 0}
                disableBottomBorder
              >
                <FormCheckbox
                  label={<Stack>{actionItemStatusMap[status].label}</Stack>}
                  control={
                    <Stack marginRight={1}>
                      <AppCheckbox width="18px" height="18px" checked={appliedStatues.includes(status)} checkboxOnClick={() => onChangeStatus(status)} /></Stack>
                  }
                />
              </ControlItem>)

            }





          </FilterContainer>
        </FilterPopper>
      </Stack>
    </ClickAwayListener >
  );
};

export default ActionItemStatusFilter;
