import React, { FC } from 'react';
import { Avatar, AvatarGroup, Box, Button, Typography } from '@mui/material';
import { ReactComponent as ExpandArrow } from '../../../assets/expandMore.svg';
import palette from '../../../theme/palette';

interface UserMenuButtonProps {
  avatars: string[];
  open: boolean;
  name: string;
  industry?: string;
}

const UserMenuButton: FC<UserMenuButtonProps> = ({ name, open, avatars, ...nextProps }) => (
  <Button
    {...nextProps}
    sx={{
      width: '100%',
      color: 'common.black',
      p: 0,
      '&:hover': {
        textDecoration: 'none'
      },
      '& .MuiButton-endIcon': {
        marginLeft: 2
      },
      '& .MuiButton-endIcon>*:nth-of-type(1)': {
        fontSize: 14
      }
    }}
    endIcon={
      <Box
        sx={{
          transform: `rotate(${open ? 0.5 : 0}turn)`,
          transition: 'transform 0.25s ease-in-out'
        }}
      >
        <ExpandArrow />
      </Box>
    }
  >
    <AvatarGroup
      sx={{
        mr: 1.5,
        '& .MuiAvatar-root': { border: `1px solid ${palette.border.grey}`, boxSizing: 'border-box' }
      }}
    >
      {avatars.map((avatar, index) => (
        <Avatar key={`${avatar}-${index}`} sx={{ width: 36, height: 36 }} alt="user avatar" src={avatar} />
      ))}
    </AvatarGroup>
    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
      <Typography variant="body2" color="common.black">
        {name}
      </Typography>
    </Box>
  </Button>
);

export default UserMenuButton;
