import { Stack, Typography, styled } from '@mui/material';
import palette from '../../../theme/palette';


export const CardContainer = styled(Stack)(() => ({
  backgroundColor: palette.background.default,
  justifyContent:"space-between",
  borderRadius: "6px",
  cursor: "pointer",
  minHeight:"75px",
  fontFamily: 'Plus Jakarta Sans',
  margin: 2,
  marginBottom: 5,
  padding: 5,
  color: palette.common.darkBlue,
  ":hover":{opacity:0.8}
}));

export const CardTitle = styled(Typography)(() => ({
  width:"80%",
  fontFamily: 'Plus Jakarta Sans',
  fontSize: "14px",
  color: palette.common.darkBlue
}));

export const DueDate = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  width: "max-content",
  fontSize: '11px',
  fontWeight: 500
}));

