import React, { useCallback } from 'react';
import { Grid, Stack } from '@mui/material';
import { ReactComponent as EmptyState } from '../../assets/attachmentsEmpty.svg';
import {

  BrowseText,
  Container, EmptyText,
  UploadFileArea,
  UploadText
} from './AttachmentsStyles';
import { AttachmentDto } from '../../api/generated';
import { useDropzone } from 'react-dropzone';
import useFiles from '../../hooks/useFiles';
import { generateEmptySpaces } from '../../utils/textFormater';
import AttachmentFile from './AttachmentFile';

type AttachmentsProps = {
  parnetId: string;
  attachments: AttachmentDto[];
  onDelete?: (name: string) => void;
  onUpload: (name: string) => void;
};

const Attachments = ({
  parnetId,
  attachments,
  onDelete,
  onUpload
}: AttachmentsProps) => {

  const { generateUploadUrl } = useFiles();

  const uploadFile = useCallback((file: File) => {
    generateUploadUrl(parnetId, file.name).then(
      url => {
        fetch(url, {
          method: 'PUT',
          body: file,
          headers: {
            'Content-Type': file.type
          }
        }).then(() => {
          onUpload(file.name);
        });
      }
    );


  }, [generateUploadUrl, onUpload, parnetId]);


  const onDrop = useCallback((acceptedFiles: File[]) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      uploadFile(acceptedFiles[0]);
    };

    reader.readAsArrayBuffer(acceptedFiles[0]);

  }, [uploadFile]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop
  });

  return (
    <Container gap={3}>

      <UploadFileArea
        {...getRootProps()}>
        <Stack
          direction="row"
          alignItems="center">
          <input width="100%" {...getInputProps()} />
          <UploadText>Drag and drop files to attach, or</UploadText>
          <BrowseText>{generateEmptySpaces(1)}browse</BrowseText>
        </Stack>

      </UploadFileArea>



      <Stack >
        {attachments?.length > 0 ?
          <Grid container rowSpacing={1} columnSpacing={2}>
            {attachments.map(attachment =>
              <Grid  key={attachment.name} item xs={4} sm={6}>
                <AttachmentFile
           
                  onDeleteFile={file => onDelete(file.name)}
                  parentId={parnetId}
                  file={attachment} />
              </Grid>)}
          </Grid>

          :
          <Stack
            alignItems="center"
            marginTop={1}
          >
            <EmptyState width="40px" height="40px" />
            <EmptyText>No Attachments</EmptyText>
          </Stack>}
      </Stack>

    </Container>
  );
};


export default Attachments;
