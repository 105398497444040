import React, { } from 'react';
import Avatar from 'boring-avatars';
import { Author, AuthorText } from '../ForumStyles';
import Moment from 'react-moment';
import useAuth from '../../../hooks/useAuth';
import { generateEmptySpaces } from '../../../utils/textFormater';

interface AuthorProps {
  userId: string;
  userType: string;
  industry: string;
  createdAt: string;
}


const AuthorView = ({ userId, userType, industry, createdAt }: AuthorProps) => {
  const { user } = useAuth();
  let displayName = `${industry} CEO`;

  if (userId === user.id) {
    displayName = "you";
  } else {
    if (userType) {
      displayName = userType === "jp" ? "Journey Partner" : userType === "user" ? `${industry} Executive` : `${industry} CEO`;
    }
  }

  return (
    <Author>
      <Avatar
        size="30px"
        variant="marble"
        name={userId} />
      <AuthorText>
        Posted by {displayName}{generateEmptySpaces(1)}
        {<Moment fromNow ago>
          {createdAt}
        </Moment>} ago
      </AuthorText>
    </Author>
  );
};


export default AuthorView;
