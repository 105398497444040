import { Stack, Typography, alpha, styled } from '@mui/material';

import palette from '../../theme/palette';

export const Container = styled(Stack)({
  padding: '0 18px',
  height: '100%',
  width: "100%"
});


export const AttachmentCard = styled(Stack)({
  padding: '10 15px',
  borderRadius:"8px",
  border: `1px solid ${palette.border.grey}`
});

export const AttachmentName = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '13px',
  color: palette.common.darkBlue
});

export const UploadFileArea = styled(Stack)({
  padding: '10 15px',
  borderRadius:"6px",
  justifyContent:"center",
  alignItems:"center",
  height:"56px",
  backgroundColor:alpha( palette.grey[2000], 0.15),
  border: `1px solid ${palette.border.grey}`
});

export const UploadText = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '13px',
  color: palette.common.darkBlue
});

export const BrowseText = styled(Typography)({
  cursor:"pointer",
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '13px',
  color: palette.primary.main,
  fontWeight:"bold"
});

export const EmptyText = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '13px',
  color: palette.grey[700]
});


