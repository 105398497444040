import React from 'react';

import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ForumIcon from '@mui/icons-material/Forum';
import HomeIcon from '@mui/icons-material/Home';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import WidgetsIcon from '@mui/icons-material/Widgets';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import {
  toActionPlan,
  toForum,
  toHome,
  toMetrics,
  toSharedWorkspacesManagement,
  toStrategyCenter,
  toWhiteboards
} from '../services/linker';


export enum NavigationItemsKey {
  Home = 'home',
  ControlPanel = 'controlPanel',
  StrategyCenter = 'strategyCenter',
  SharedWorkspaces = 'sharedWorkspaces',
  Metrics = 'metrics',
  MetricsOld = 'metricsOld',
  Meetings = 'meetings',
  AskJourney = 'askJourney',
  CeoForum = 'ceoForum',
  ActionPlan = 'actionPlan',
  AccountSettings = 'settings',
  Integrations = 'integrations',
  Whiteboards = 'whiteboards',
  StrategicPlanning = 'strategicPlanning'
}

export interface NavListProps {
  key: NavigationItemsKey;
  label: string;
  icon?: React.ReactElement;
  slug?: string;
  subList?: { label: string; key: string; slug: string }[];
  isHidden?: boolean;
}

const useNavigationListManifest = (): NavListProps[] => {

  const list = [
    {
      key: NavigationItemsKey.Home,
      label: 'Home',
      icon: <HomeIcon />,
      slug: toHome(),
      isHidden: true
    },
    {
      key: NavigationItemsKey.SharedWorkspaces,
      label: 'Spaces',
      icon: <WorkspacesIcon />,
      slug: toSharedWorkspacesManagement()
    },
    {
      key: NavigationItemsKey.ActionPlan,
      label: 'Action Plan',
      icon: <AccountTreeIcon />,
      slug: toActionPlan()
    },
    {
      key: NavigationItemsKey.Metrics,
      label: 'Metrics',
      icon: <InsertChartIcon />,
      slug: toMetrics()
    },
    {
      key: NavigationItemsKey.StrategyCenter,
      label: 'Strategy Center',
      icon: <WidgetsIcon />,
      slug: toStrategyCenter()
    },
    {
      key: NavigationItemsKey.CeoForum,
      label: 'Connect',
      icon: <ForumIcon />,
      slug: toForum()
    },
    {
      key: NavigationItemsKey.Whiteboards,
      label: 'Whiteboards',
      icon: <NoteAltIcon />,
      slug: toWhiteboards(),
      isHidden: true
    }
  ];


  return list;

};

export default useNavigationListManifest;
