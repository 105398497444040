import { useCallback, useMemo } from 'react';

import { groupBy } from 'lodash';

import {
  BusinessAreaMetadataDto,
  CustomModuleRequestBusinessArea,
  ModuleDataDto,
  ModuleMetadataDto,
  useCreateCustomBusinessArea,
  useCreateCustomModule,
  useDeleteCustomBusinessArea,
  useDeleteCustomModule,
  useGetModulesMetadata,
  useListBusinessAreaMetadata,
  usePatchCustomModule,
  useUpdateCustomBusinessArea
} from '../api/generated';
import ModulesKeys from '../enums/ModulesKeys';
import { LandscapeProps } from '../screens/BizAreaModules/CompetitiveLandscape/CompetitiveLandscape';
import useBusinessArea from './useBusinessArea';

interface BizAreaModuleData {
  isModuleMetadataLoding: boolean;
  currentModuleMetaData: ModuleMetadataDto;
  currentBusinessAreaModules: ModuleMetadataDto[];
  currentBusinessAreaModulesPerScope: { [key: string]: ModuleMetadataDto[] };
  businessAreasMetadata: BusinessAreaMetadataDto[];
  businessAreaMetadataLoading: boolean;
  createCustomBusinessArea: (name: string, boardIds?: string[]) => Promise<void>;
  updateCustomBusinessArea: (id: string, name: string, boardIds?: string[]) => Promise<void>;
  deleteCustomBusinessArea: (id: string) => Promise<void>;
  createCustomModule: (
    name: string,
    description: string,
    businessArea: CustomModuleRequestBusinessArea
  ) => Promise<void>;
  patchCustomModule: (id: string, name: string, description: string) => Promise<void>;
  deleteCustomModule: (id: string) => Promise<void>;
}

export type CompetititiveLandscapeData = { landscapes: LandscapeProps[] };
export type DrillDownData = Record<
  'businessArea' | 'rating' | 'startingPoint' | 'explanation',
  string
>;
export type AdvantagesModuleData = { keyAdvantages: { key: string; explanation: string }[] };
export type MissionAndVisionData = { missionVisions: { companyName: string; vision: string }[] };
export type GoalExample = { industry: string; stage: string; size: string; goal: string };
export type GoalData = {
  goal: string;
  goalExamples: GoalExample[];
  recommendations: string[];
};

// TODO: change to orval generic type after it will be exposed in the swagger (instead of using wide union of all)
export interface AppModuleDataDto extends ModuleDataDto {
  data?: ModuleDataDto['data'] & { drillDowns?: DrillDownData[] } & GoalData &
    AdvantagesModuleData &
    MissionAndVisionData &
    CompetititiveLandscapeData;
}

const useBusinessAreaModulesMetadata = (moduleKey?: ModulesKeys): BizAreaModuleData => {
  const { businessArea: businessAreaKey } = useBusinessArea();

  const { data: moduleMetadata, isLoading: isModuleMetadataLoding } = useGetModulesMetadata(
    {
      businessArea: businessAreaKey
    },
    { query: { queryKey: ['modulesMetadata', businessAreaKey], enabled: !!businessAreaKey } }
  );

  const {
    data: businessAreaMetadataResponse,
    isLoading: businessAreaMetadataLoading,
    refetch: refetchList
  } = useListBusinessAreaMetadata();

  const { mutateAsync: createCustomBA } = useCreateCustomBusinessArea();

  const { mutateAsync: updateCustomBA } = useUpdateCustomBusinessArea();

  const { mutateAsync: deleteCustomBA } = useDeleteCustomBusinessArea();

  const { mutateAsync: mutateCreateCustomModule } = useCreateCustomModule();

  const { mutateAsync: mutatePatchCustomModule } = usePatchCustomModule();

  const { mutateAsync: mutateDeleteCustomModule } = useDeleteCustomModule();

  const businessAreasMetadata = useMemo(
    () => businessAreaMetadataResponse?.items || [],
    [businessAreaMetadataResponse]
  );

  const currentBusinessAreaModules = useMemo(
    () => moduleMetadata?.items.sort((a, b) => a.index - b.index) || null,
    [moduleMetadata?.items]
  );
  const currentBusinessAreaModulesPerScope = useMemo(
    () => groupBy(currentBusinessAreaModules, module => module.scope),
    [currentBusinessAreaModules]
  );
  const currentModuleMetaData = useMemo(
    () => currentBusinessAreaModules?.find(({ id }) => id === moduleKey) || null,
    [currentBusinessAreaModules, moduleKey]
  );

  const createCustomBusinessArea = useCallback(
    async (name: string, boardIds?: string[]) => {
      await createCustomBA({ data: { name: name, boardIds: boardIds } });
      refetchList();
    },
    [createCustomBA, refetchList]
  );

  const updateCustomBusinessArea = useCallback(
    async (id: string, name: string, boardIds?: string[]) => {
      await updateCustomBA({ id: id, data: { name: name, boardIds: boardIds } });
      refetchList();
    },
    [updateCustomBA, refetchList]
  );

  const deleteCustomBusinessArea = useCallback(
    async (id: string) => {
      await deleteCustomBA({ id: id });
      refetchList();
    },
    [deleteCustomBA, refetchList]
  );

  const createCustomModule = useCallback(
    async (name: string, description: string, businessArea: CustomModuleRequestBusinessArea) => {
      await mutateCreateCustomModule({
        data: { name: name, description: description, businessArea: businessArea }
      });
    },
    [mutateCreateCustomModule]
  );

  const patchCustomModule = useCallback(
    async (id: string, name: string, description: string) => {
      await mutatePatchCustomModule({ id: id, data: { name: name, description: description } });
    },
    [mutatePatchCustomModule]
  );

  const deleteCustomModule = useCallback(
    async (id: string) => {
      await mutateDeleteCustomModule({ id: id });
    },
    [mutateDeleteCustomModule]
  );

  return {
    currentBusinessAreaModules,
    currentBusinessAreaModulesPerScope,
    currentModuleMetaData,
    isModuleMetadataLoding,
    businessAreasMetadata,
    businessAreaMetadataLoading,
    createCustomBusinessArea,
    updateCustomBusinessArea,
    deleteCustomBusinessArea,
    createCustomModule,
    patchCustomModule,
    deleteCustomModule
  };
};

export default useBusinessAreaModulesMetadata;
