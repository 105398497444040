import { Stack, Typography, styled } from '@mui/material';
import palette from '../../../../../../theme/palette';


export const AddButtonContainer = styled(Stack)(() => ({
  color: palette.primary.main,
  cursor: 'pointer',
  ':hover': { opacity: 0.8 }
}));

export const EmptyDataPointsTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '16px',
  color: palette.grey[3100]
});

export const TooltipText = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '12px',
  color: palette.common.darkBlue
});

export const FileUplodaErrorMessage = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '15px',
  color: palette.red[2]
});

export const AddNewText = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  cursor:"pointer",
  fontSize: '16px',
  color: palette.primary.main
});

export const ColumnTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '16px',
  fontWeight: "bold",
  color: palette.common.darkBlue
});




