import { Accordion, Stack, Tab, Typography, alpha, styled } from '@mui/material';

import palette from '../../theme/palette';

export const Container = styled(Stack)({
  width: '100%',
  gap: '35px',
  padding: '15px 25px 15px 25px',
  overflowX: 'auto'
});

export const Header = styled(Stack)({
  width: '100%',
  height: 'fit-content',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  alignContent: 'center'
});

export const PageTitle = styled(Typography)({
  fontSize: '18px',
  fontWeight: 400
});

export const PageSubTitle = styled(Typography)({
  fontSize: '16px',
  fontWeight: 700
});

export const EmptyStateText = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  color: palette.grey[2000]
});

export const FlowTitle = styled(Typography)({
  fontSize: '13px',
  fontWeight: 400
});

export const Card = styled(Stack)({
  padding: '15px 10px',
  borderRadius: '16px',
  gap: '10px',
  border: `1px solid ${palette.border.grey}`,
  background: 'white',
  boxShadow: '0px 10px 20px 0px rgba(28, 39, 76, 0.03)',
  minHeight: '120px',
  minWidth: '315px'
});

export const CardTitle = styled(Typography)({
  fontSize: '14px',
  fontWeight: 400,
  color: palette.common.darkBlue
});

export const StartMeetingButton = styled(Stack)<{
  disabled: boolean;
}>(({ disabled }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '9px 12px',
  height: '32px',
  gap: '4px',
  color: 'white',
  backgroundColor: disabled ? alpha(palette.grey[1800], 0.6) : palette.primary.main,
  borderRadius: '8px',
  cursor: !disabled && 'pointer',
  ':hover': {
    opacity: !disabled && 0.8
  }
}));

export const StartMeetingButtonText = styled(Typography)({
  fontWeight: 700,
  fontSize: '12px',
  fontFamily: 'Plus Jakarta Sans'
});

export const SectionTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '18px'
});

export const GrayTitle = styled(Typography)({
  fontSize: '14px',
  opacity: 0.5
});

export const SectionAccordion = styled(Accordion)(() => ({
  padding: '10px 0px',
  borderRadius: '16px',
  border: `1px solid ${palette.border.grey}`,
  background: 'white',
  boxShadow: '0px 10px 20px 0px rgba(28, 39, 76, 0.03)',
  '.MuiCollapse-wrapperInner': {},
  '&:before': {
    backgroundColor: 'transparent !important'
  },
  ':last-of-type': {
    borderRadius: '16px'
  },
  ':first-of-type': {
    borderRadius: '16px'
  }
}));

export const PointsCount = styled(Stack)({
  borderRadius: '10px',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100px',
  height: '30px',
  backgroundColor: palette.background.grey
});

export const PointsCountText = styled(Typography)({
  fontSize: '12px',
  fontFamily: 'Plus Jakarta Sans',
  color: palette.grey[1100]
});

export const ConnectButton = styled(Stack)({
  padding: '4px 2px 4px 8px',
  gap: '4px',
  borderRadius: '5px',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  color: palette.primary.main,
  cursor: 'pointer',
  ':hover': {
    opacity: 0.8
  }
});

export const ConnectButtonTitle = styled(Typography)({
  fontSize: '12px',
  fontWeight: 700,
  color: palette.blue[11]
});

export const IconCircle = styled(Stack)({
  width: '28px',
  height: '28px',
  borderRadius: '50%',
  backgroundColor: 'white',
  justifyContent: 'center',
  alignItems: 'center',
  border: `1px solid ${palette.border.grey3}`,
  cursor: 'pointer'
});

export const IconContainer = styled(Stack)({
  cursor: 'pointer',
  alignItems: 'center',
  gap: '2px',
  flexDirection: 'row',
  padding: '2px 5px',
  background: palette.blue[5],
  borderRadius: '8px',
  ':hover': { opacity: 0.6 }
});

export const IconText = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '12px',
  fontWeight: 'bold',
  color: palette.common.darkBlue
});

export const ShowMoreButton = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: '13px',
  color: palette.green[1],
  cursor: 'pointer',
  ':hover': {
    opacity: 0.7,
    textDecoration: 'underline'
  }
}));

export const ClearAllButton = styled(Stack)({
  cursor: 'pointer',
  gap: '3px',
  borderRadius: '8px',
  flexDirection: 'row',
  svg: { path: { fill: alpha(palette.grey[3100], 0.7) } },
  alignItems: 'center',
  ':hover': { backgroundColor: palette.background.grey }
});



export const ClearAllButtonText = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '12px',
  color: palette.grey[3100]
}));


export const TabContainer = styled(Tab)(() => ({
  minWidth: '130px',
  padding: '15px 20px',
  color: palette.grey[3100],
  '&.Mui-selected': {
    color: palette.primary.main
  },

  background: palette.secondary.main
}));

export const TabTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: '15px',
  textTransform: 'none'
});

export const ActionsButton = styled(Stack)({
  cursor: 'pointer',
  borderRadius: '4px',
  svg: { path: { fill: alpha(palette.grey[3100], 0.8) } },
  ':hover': { opacity: 0.6 }
});
