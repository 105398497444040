
export const linkify = (text: string) => {
  // eslint-disable-next-line no-useless-escape
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text.replace(urlRegex, function (url) {
    return '<a target="_blank" href="' + url + '">' + url + '</a>';
  });
};

/**
 * Generates empty spaces for string
 *
 * @param   amount  Number of spaces.
 * @returns An empty space string based on \xa0
 */
export const generateEmptySpaces = (amount: number) => '\xa0'.repeat(amount);



