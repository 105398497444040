import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack, styled } from '@mui/material';

import palette from '../../../theme/palette';

const ExpandMoreIconContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${palette.border.grey}`,
  borderRadius: '50%'
});

const ShowMoreIcon = styled(ExpandMoreIcon)({
  fontSize: '20px',
  color: palette.grey[1100]
});

export { ExpandMoreIconContainer, ShowMoreIcon };
