import React from 'react';
import { ActionPlanObjectiveDetailedDto } from '../../api/generated';
import KeyResultView from '../../components/KeyResultView/KeyResultView';
import { OkrContainer } from './HomeStyles';

import useObjectiveSidebar from '../../hooks/context-providers/useObjectiveSidebar';

type OkrCardProps = {
  objective?: ActionPlanObjectiveDetailedDto;
  refetch: () => void;

};

const OkrCard = ({ objective, refetch }: OkrCardProps) => {

  const { setCurrentObjective, setRefetch, setOpen } = useObjectiveSidebar();
  const onViewClick = () => {
    setCurrentObjective(objective);
    setRefetch(() => refetch);
    setOpen(true);
  };


  return (
    <OkrContainer
      onClick={onViewClick}>
      <KeyResultView
        small
        refetch={refetch}
        objective={objective}
      />
    </OkrContainer>

  );
};

export default OkrCard;
