import { Box, Input, Typography, styled } from '@mui/material';
import palette from '../../../theme/palette';
import typography from '../../../theme/typography';

const AnswerContent = styled(Box)(() => ({
  maxHeight:500,
  overflow:"auto",
  // display: 'flex',
  flexDirection: 'column-reverse',
  width: '100%',
  border: `1px solid ${palette.border.grey}`,
  borderRadius: '8px',
  backgroundColor: palette.grey[1500],
  padding: '16px',
  marginBottom: '10px',
  '&::-webkit-scrollbar': {
    width: '5px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: palette.border.grey,
    borderRadius: '14px'
  }
}));

const ContentText = styled(Typography)(() => ({
  whiteSpace: 'pre-line',
  fontSize: typography.body1.fontSize
}));

const InputForAnswer = styled(Input)(() => ({
  backgroundColor: '#fff',
  borderRadius: '8px',
  paddingBottom: '10px',
  paddingTop: '10px',
  fontSize: '16px',
  width: '100%',
  fontWeight: 700,
  marginBottom: '5px',
  paddingLeft:"0px"
}));

export { AnswerContent, ContentText, InputForAnswer };
