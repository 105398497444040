import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Stack, Typography, styled } from '@mui/material';

import palette from '../../theme/palette';

const Container = styled(Stack)({
  padding: '14px 12px 8px',
  background: 'white',
  border: `1px dashed ${palette.border.grey}`,
  boxShadow: '0px 10px 20px rgba(28, 39, 76, 0.03)',
  borderRadius: '8px'
});

const Description = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 800,
  fontSize: '14px',
  color: palette.common.darkBlue
});

const AddIcon = styled(AddCircleIcon)({
  color: palette.green[1],
  fontSize: '19px',
  cursor: 'pointer'
});

export { Container, Description, AddIcon };
