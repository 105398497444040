import React, { MouseEvent, ReactNode, useCallback, useState } from 'react';

import AddBoxIcon from '@mui/icons-material/AddBox';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import UploadIcon from '@mui/icons-material/Upload';
import { Box, CircularProgress, Stack } from '@mui/material';

import { CreateGoogleDriveFileRequestType } from '../../api/generated';
import { ReactComponent as EmptyDriveIcon } from '../../assets/emptyDrive.svg';
import { ReactComponent as GoogleDocs } from '../../assets/google-docs.svg';
import { ReactComponent as GoogleSlides } from '../../assets/google-slides.svg';
import { ReactComponent as GoogleSheets } from '../../assets/integrations/google-sheets.svg';
import useModal from '../../hooks/context-providers/useModal/useModal';
import useAuth from '../../hooks/useAuth';
import useResource from '../../hooks/useResource';
import AppPopover from '../../screens/ActionPlan/components/AppPopover/AppPopover';
import Resource from '../../screens/Dashboard/components/Resource';
import useCustomAxios from '../../services/customAxios';
import palette from '../../theme/palette';
import { Container, EmptyTitle, InputKeyword } from './GoogleDriveStyles';

type GoogleDriveProps = {
  folderName?: string;
  title?: ReactNode;
  maxHeight?: string;
};

const LazyLoadedFileUploadModal = React.lazy(
  () => import('../../modals/FileDragAndDropModal/FileDragAndDropModal')
);

const GoogleDrive = ({ title, folderName, maxHeight }: GoogleDriveProps) => {
  const {
    createGoogleDriveFile,
    isCreateFileLoading,
    driveFiles,
    driveFilesLoading,
    refetchDriveFiles
  } = useResource(folderName);

  const { showModal } = useModal();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const popoverOpen = Boolean(anchorEl);
  const { tokens } = useAuth();
  const createFile = async (type: CreateGoogleDriveFileRequestType) => {
    const file = await createGoogleDriveFile(type, folderName || null);
    window.open(file.link, '_blank', 'noreferrer');
    setAnchorEl(null);
  };

  const [isUploadLoading, setIsUploadLoading] = useState<boolean>(false);
  const [driveFilter, setDriveFilter] = useState('');

  const uploadClick = useCallback(() => {
    const modal = showModal(LazyLoadedFileUploadModal, {
      onClose: () => {
        modal.hide();
      },
      onConfirm: async (file: Blob) => {
        setAnchorEl(null);
        setIsUploadLoading(true);
        const formData = new FormData();
        formData.append('file', file);

        if (folderName) {
          formData.append('folderName', folderName);
        }

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useCustomAxios()({
          url: '/workspace/resource/drive/file/upload',
          method: 'post',
          data: formData,
          headers: { Authorization: `Bearer ${tokens.idToken}` }
        }).then(() => {
          setIsUploadLoading(false);
          refetchDriveFiles();
        });

        modal.hide();
      },
      modalTitle: 'Upload File',
      confirmButtonIcon: <UploadIcon />,
      confirmButtonTitle: 'Upload'
    });
  }, [showModal, tokens, refetchDriveFiles, folderName]);

  return (
    <Container maxHeight={maxHeight && maxHeight}>
      <Stack direction="row" alignItems="center" gap={2} marginBottom={1}>
        {title && title}
        {(isUploadLoading || isCreateFileLoading) && <CircularProgress size="20px" />}
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <InputKeyword
          disableUnderline
          placeholder="Search drive"
          onChange={event => setDriveFilter(event.target.value)}
        />
        <Box
          onClick={(event: MouseEvent<HTMLDivElement>) => {
            event.stopPropagation();
            setAnchorEl(event.currentTarget);
          }}
          sx={{
            color: palette.primary.main,
            cursor: 'pointer',
            ':hover': { opacity: 0.8 }
          }}
          alignItems="center"
          justifyContent="center"
          width={30}
          height={30}
        >
          <AddBoxIcon
            sx={{
              width: 30,
              height: 30
            }}
          />
        </Box>
        <AppPopover
          anchorEl={anchorEl}
          open={popoverOpen}
          handleClose={() => setAnchorEl(null)}
          customActions={[
            {
              title: 'New document',
              icon: <GoogleDocs width="25px" height="25px" />,
              onAction: () => createFile(CreateGoogleDriveFileRequestType.doc)
            },
            {
              title: 'New sheet',
              icon: <GoogleSheets width="25px" height="25px" />,
              onAction: () => createFile(CreateGoogleDriveFileRequestType.sheet)
            },
            {
              title: 'New slide',
              icon: <GoogleSlides width="25px" height="25px" />,
              onAction: () => createFile(CreateGoogleDriveFileRequestType.slide)
            },
            {
              title: 'Upload file',
              icon: <CloudUploadOutlinedIcon />,
              onAction: uploadClick
            }
          ]}
        />
      </Stack>

      {driveFilesLoading ? (
        <Stack margin={2} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      ) : driveFiles.length > 0 ? (
        <Stack mt={1}>
          {driveFiles
            .filter(item =>
              driveFilter != ''
                ? item.title.toLocaleLowerCase().includes(driveFilter.toLocaleLowerCase())
                : item
            )
            .sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt))
            .map(resource => (
              <Resource key={resource.id} resource={resource} refetch={refetchDriveFiles} />
            ))}
        </Stack>
      ) : (
        <Stack marginTop={3} alignItems="center">
          <EmptyTitle>No Files</EmptyTitle>
          <EmptyDriveIcon width="50px" height="50px" />
        </Stack>
      )}
    </Container>
  );
};

export default GoogleDrive;
