import React from 'react';

import { PostDto } from '../../../api/generated';
import { ReactComponent as SavePostIcon } from '../../../assets/savePost.svg';
import { ReactComponent as SavedPostIcon } from '../../../assets/savedPost.svg';
import useForum from '../../../hooks/useForum';
import { SaveButton, SaveButtonText } from '../ForumStyles';

interface SavePostProps {
  post: PostDto;
}

const SavePost = ({ post }: SavePostProps) => {
  const { createSavedPosts, patchSavedPosts, refetchSavedPosts, savedPosts } = useForum();
  const isSavedPost = savedPosts?.postIds?.includes(post.id);

  const onSaveClicked = () => {
    if (savedPosts) {
      const postIds = [...savedPosts.postIds, post.id];
      patchSavedPosts({ postIds: postIds }).then(() => refetchSavedPosts());
    } else {
      createSavedPosts({ postIds: [post.id] }).then(() => refetchSavedPosts());
    }
  };

  const onUnSaveClicked = () => {
    const postIds = savedPosts.postIds.filter(id => id !== post.id);
    patchSavedPosts({ postIds: postIds }).then(() => refetchSavedPosts());
  };

  return isSavedPost ? (
    <SaveButton
      onClick={event => {
        event.stopPropagation();
        onUnSaveClicked();
      }}
    >
      <SavedPostIcon />
      <SaveButtonText fontWeight={700}>Saved</SaveButtonText>
    </SaveButton>
  ) : (
    <SaveButton
      onClick={event => {
        event.stopPropagation();
        onSaveClicked();
      }}
    >
      <SavePostIcon />
      <SaveButtonText fontWeight={500}>Save</SaveButtonText>
    </SaveButton>
  );
};

export default SavePost;
