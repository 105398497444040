import React from 'react';
import { Helmet } from 'react-helmet-async';

const TITLE = 'The Journey Platform';

const Head = ({ template }: { template?: string | React.ReactElement }) => (
  <Helmet>
    <title>{template ? `${TITLE} | ${template}` : TITLE}</title>
  </Helmet>
);

export default Head;
