import React, { useState } from 'react';

import { Avatar, Divider, Menu, MenuItem, Stack, Tooltip, Typography, styled } from '@mui/material';

import { AccountUserDto, AccountUserDtoType } from '../../api/generated';
import useUsers from '../../hooks/useUsers';
import palette from '../../theme/palette';
import { randomBgColor } from '../../utils/avatarHelper';
import { generateEmptySpaces } from '../../utils/textFormater';
import SearchInput from '../Input/SearchInput';

interface UserViewProps {
  userId: string;
  onChange?: (userId: string) => void;
  color?: string;
  incldueAssigneUsers?: boolean;
  tooltip?: string;
  nameTooltip?: boolean;
  onlyAvatar?: boolean;
  size?: string;
  viewOnly?: boolean;
  fontSize?: string;
  limitWidth?: boolean;
}

const UserTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '12px',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});

const UserName = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});

const UserView = ({
  userId,
  incldueAssigneUsers = false,
  onChange,
  color,
  tooltip,
  viewOnly,
  fontSize,
  onlyAvatar,
  nameTooltip,
  limitWidth = true,
  size
}: UserViewProps) => {
  const { user, usersList } = useUsers(userId);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [filter, setFilter] = useState<string>('');

  const isMatchFilter = (user: AccountUserDto) => {
    const filtervalue = filter.toLowerCase();
    return user?.firstName?.toLowerCase().includes(filtervalue) ||
      user?.lastName?.toLowerCase().includes(filtervalue) ||
      filter === '';
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!viewOnly) {
      event.stopPropagation();
      setAvatrtHover(false);
      setHover(false);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelected = (userId: string) => {
    onChange(userId);
    handleClose();
  };

  const [avatrtHover, setAvatrtHover] = useState(false);
  const [hover, setHover] = useState(false);

  return (
    <Tooltip
      disableHoverListener
      open={hover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      title={
        tooltip && user
          ? `${tooltip}: ${user.firstName + generateEmptySpaces(1) + user.lastName}`
          : nameTooltip && user ? `${user.firstName + generateEmptySpaces(1) + user.lastName}` : ''
      }
    >
      <Stack direction="row" alignItems="center">
        <Stack>
          <Tooltip
            disableHoverListener
            open={avatrtHover}
            title={tooltip && !user ? `Choose ${tooltip}` : ''}
          >
            <Avatar
              onMouseEnter={() => setAvatrtHover(true)}
              onMouseLeave={() => setAvatrtHover(false)}
              onClick={handleClick}
              src={user?.photoUrl}
              variant="rounded"
              sx={{
                cursor: onChange && 'pointer',
                ':hover': { opacity: viewOnly || !onChange ? 1 : 0.5 },
                fontSize: '13px',
                borderRadius: '100px',
                height: size || '21px',
                width: size || '21px',
                background: user
                  ? randomBgColor(user.firstName + generateEmptySpaces(1) + user.lastName)
                  : palette.background.lightGrey
              }}
            >
              {user?.firstName[0]?.toUpperCase()}
            </Avatar>
          </Tooltip>

          <Menu
            anchorEl={anchorEl}
            open={!!onChange && open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 25,
              horizontal: 'left'
            }}
            sx={{
              filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.13))',
              '& .MuiPaper-root': { borderRadius: '10px' }
            }}
          >

            <SearchInput
              value={filter}
              name="search"
              noBorder
              height='25px'
              onChange={value => setFilter(value)}
              placeholder="Search"
            />
            <Divider />
            {usersList
              .filter(user =>
                incldueAssigneUsers ? user : user.type !== AccountUserDtoType.assigneeUser
              ).filter(isMatchFilter)
              .map(userItem => (
                <Stack key={userItem.id}>
                  <MenuItem onClick={() => handleSelected(userItem.id)}>
                    <Stack direction="row" alignItems="center">
                      <Avatar
                        src={userItem?.photoUrl}
                        variant="rounded"
                        sx={{
                          fontSize: '13px',
                          borderRadius: '16px',
                          height: '20px',
                          width: '20px',
                          background: randomBgColor(
                            userItem.firstName + generateEmptySpaces(1) + userItem.lastName
                          )
                        }}
                      >
                        {userItem.firstName[0]}
                      </Avatar>
                      <UserTitle marginLeft="6px">
                        {userItem.firstName + generateEmptySpaces(1) + userItem.lastName}
                      </UserTitle>
                    </Stack>
                  </MenuItem>
                </Stack>
              ))}
            {user && (
              <MenuItem onClick={() => handleSelected(null)}>
                <Stack direction="row" alignItems="center">
                  <Avatar
                    variant="rounded"
                    sx={{
                      fontSize: '13px',
                      borderRadius: '16px',
                      height: '20px',
                      width: '20px',
                      background: palette.background.lightGrey
                    }}
                  />
                  <UserTitle marginLeft="6px">None</UserTitle>
                </Stack>
              </MenuItem>
            )}
          </Menu>
        </Stack>
        {!onlyAvatar && (
          <UserName
            sx={{
              color: color || palette.grey[1100],
              maxWidth: limitWidth ? '90px' : '100%',
              fontSize: fontSize || '12px'
            }}
            marginLeft="6px"
          >
            {user && user.firstName + generateEmptySpaces(1) + user.lastName}
          </UserName>
        )}
      </Stack>
    </Tooltip>
  );
};

export default UserView;
