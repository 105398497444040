import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconButton, Stack, Tooltip } from '@mui/material';

import { ActionPlanInitiativeDto, Goal, MetricDefinitionProgressDto } from '../../api/generated';
import { ReactComponent as GoalIcon } from '../../assets/goal.svg';
import useModal from '../../hooks/context-providers/useModal/useModal';
import useMetricDefinition from '../../hooks/useMetricDefinition';
import palette from '../../theme/palette';
import { formatKeyResult, parseValue } from '../../utils/actionPlanUtil';
import { getAbbreviateNumber } from '../../utils/numberUtil';
import CurrentValuePopover from './CurrentValuePopover';
import {
  AddData,
  AddGoal,
  Baseline,
  CurrentValueTitle,
  EmptyGoalTitle,
  GoalDescription,
  TargetPercentageView
} from './GoalViewStyles';

type GoalViewProps = {
  initiative: ActionPlanInitiativeDto;
  progress: number;
  refetch: () => void;
  businessArea: string;
  setCurrentInitiative: (initiative: ActionPlanInitiativeDto) => void;
};

const LazyLoadedAddInitiativeGoalModal = React.lazy(
  () => import('../../modals/InitiativeModal/InitiativeGoalModal')
);

const GoalView = ({
  initiative,
  progress,
  setCurrentInitiative,
  businessArea,
  refetch
}: GoalViewProps) => {
  const { showModal } = useModal();
  const [goal, setGoal] = useState<Goal>(initiative.goal);

  const { getMetricsProgress } = useMetricDefinition();
  const [metricProgress, setMetricProgress] = useState<MetricDefinitionProgressDto>();

  useEffect(() => {
    if (initiative.goal?.metricId) {
      getMetricsProgress([initiative.goal?.metricId]).then(response => {
        setMetricProgress(response?.[0] || null);
      });
    }
  }, [initiative.goal?.metricId, getMetricsProgress]);

  const editGoalClick = useCallback(() => {
    const modal = showModal(LazyLoadedAddInitiativeGoalModal, {
      onClose: () => {
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
      onConfirm: (goal: Goal) => {
        setGoal(goal);
        setCurrentInitiative({ ...initiative, goal: goal });
        refetch();
        modal.hide();
      },
      initiative: initiative,
      businessArea: businessArea
    });
  }, [showModal, initiative, refetch, businessArea, setCurrentInitiative]);

  const description = formatKeyResult(goal?.unit, goal?.value, goal?.subject);

  const [hovered, setHovered] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const openPopover = Boolean(anchorEl);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const onUpdateCurrentValue = (value: number) => {
    setGoal(prevState => ({ ...prevState, currentValue: value }));
    refetch();
    getMetricsProgress([initiative.goal?.metricId]).then(response => {
      setMetricProgress(response?.[0] || null);
    });
  };

  return goal ? (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        width="40%"
        height="20px"
        paddingRight={1}
        sx={{
          borderRight: `1px solid ${palette.border.grey}`
        }}
      >
        <Tooltip title={description}>
          <GoalDescription width="95%">{description}</GoalDescription>
        </Tooltip>

        {hovered && (
          <Tooltip title="Edit Goal">
            <IconButton onClick={() => editGoalClick()}>
              <EditOutlinedIcon sx={{ width: '15px', height: '15px' }} />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      <Stack
        width="35%"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        height="20px"
        sx={{
          borderRight: `1px solid ${palette.border.grey}`
        }}
      >
        {metricProgress ? (
          <Stack direction="row" paddingLeft="25px" gap={1} alignItems="center">
            <CurrentValueTitle>Current value</CurrentValueTitle>
            <Stack width="40px" height="40px">
              <CircularProgressbarWithChildren
                value={progress || 0}
                text={parseValue(metricProgress?.currentValue || 0, 1, goal?.unit)}
                styles={buildStyles({
                  textSize: '30px',
                  pathColor: palette.primary.main,
                  textColor: palette.common.darkBlue
                })}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack paddingLeft="50px">
            <AddData onClick={event => handleOpenPopover(event)}>Update data</AddData>
          </Stack>
        )}

        {metricProgress && hovered && (
          <Tooltip title="Update value">
            <IconButton onClick={event => handleOpenPopover(event)}>
              <AddchartOutlinedIcon sx={{ width: '15px', height: '15px' }} />
            </IconButton>
          </Tooltip>
        )}

        <CurrentValuePopover
          open={openPopover}
          anchorEl={anchorEl}
          initiativeId={initiative.id}
          onClose={handleClosePopover}
          goal={goal}
          updateCurrentValue={onUpdateCurrentValue}
        />
      </Stack>

      <Stack width="30%" alignItems="center">
        <Baseline>Baseline: {getAbbreviateNumber(goal?.startingPoint, 2)}</Baseline>
        <TargetPercentageView>{Math.trunc(progress || 0)}% Progress</TargetPercentageView>
      </Stack>
    </Stack>
  ) : (
    <Stack gap="5px" direction="row" justifyContent="center" alignItems="center">
      <GoalIcon width="30px" height="30px" />
      <EmptyGoalTitle>No Goal defined</EmptyGoalTitle>
      <AddGoal onClick={() => editGoalClick()}>Add Goal</AddGoal>
    </Stack>
  );
};

export default GoalView;
