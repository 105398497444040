import { useCallback, useMemo } from 'react';

import { UserFeature, UserFeatureAccess, UserFeatureFeature } from '../api/generated';
import featureRoutes from '../mappings/featureRoutes';
import { UserTypes } from '../utils/commonTypes';
import useAuth from './useAuth';
import { NavigationItemsKey } from './useNavigationListManifest';

export type FeaturePermissionMap = { [key in UserFeatureFeature]?: UserFeature };

export type NavigationPermissionMap = {
  [key in NavigationItemsKey]?: { feature: UserFeature; route: string };
};

export const transformBusinessAreaKeyToUserFeature = (businessArea: string) =>
  ('businessArea:' + businessArea) as UserFeatureFeature;

// TODO add getFeatureAccessLevel method
const useUserFeature = () => {
  const { user } = useAuth();
  const userFeatures = useMemo(() => user?.features || [], [user?.features]) as UserFeature[];

  const featurePermissionMap: FeaturePermissionMap = useMemo(
    () =>
      Object.keys(UserFeatureFeature).reduce<{
        [key in UserFeatureFeature]?: UserFeature;
      }>((acc, current) => {
        // eslint-disable-next-line no-param-reassign
        acc[UserFeatureFeature[current]] = userFeatures.find(
          feature => feature.feature === UserFeatureFeature[current]
        );
        return acc;
      }, {}),
    [userFeatures]
  );

  const navigationPermissionMap: NavigationPermissionMap = useMemo(
    () =>
      Object.keys(featureRoutes).reduce<{
        [key in NavigationItemsKey]?: { feature: UserFeature; route: string };
      }>((acc, current) => {
        // eslint-disable-next-line no-param-reassign
        acc[current] = {
          feature: userFeatures.find(feature => feature.feature === featureRoutes[current].feature),
          route: featureRoutes[current].route
        };
        return acc;
      }, {}),
    [userFeatures]
  );

  const isFeatureBlocked = useCallback(
    (feature?: UserFeatureFeature) => {
      if (user?.type !== UserTypes.USER) {
        return false;
      }
      if (feature) {
        return featurePermissionMap[feature]?.access === UserFeatureAccess.block;
      }
      return false;
    },
    [featurePermissionMap, user?.type]
  );

  return {
    userFeatures,
    featurePermissionMap,
    navigationPermissionMap,
    isFeatureBlocked
  };
};

export default useUserFeature;
