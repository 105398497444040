import React from 'react';
import { Stack } from '@mui/material';
import { ReactComponent as Logo } from '../../assets/journeyColored.svg';
import { ThreeCircles } from 'react-loader-spinner'
import palette from '../../theme/palette';

type Props = {
    width?: string;
    height?: string;
    iconWidth?: string;
    iconHeight?: string;
}


const LogoLoader = ({ width, height, iconHeight, iconWidth }: Props) => (

    <Stack
        direction="row"
        alignItems="center">
        <Logo width={width ? width : "300px"} height={height ? height : "100px"} />
        <ThreeCircles
            height={iconHeight ? iconHeight : "60"}
            width={iconWidth ? iconWidth : "50"}
            color={palette.green[8]}
            visible={true}
        />
    </Stack>

);

export default LogoLoader;
