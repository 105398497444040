import { Stack, Typography, alpha, styled } from '@mui/material';

import palette from '../../theme/palette';

const Container = styled(Stack)({
  height: '29px',
  padding: '4px 8px 4px 8px',
  borderRadius: '16px',
  gap: '6px',
  background: alpha(palette.blue[3], 0.1),
  cursor: 'pointer'
});

const Title = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '12px',
  color: palette.common.darkBlue
});

const PlayVideoContainer = styled(Stack)({
  padding: '3px',
  background: palette.blue[12],
  borderRadius: '50%'
});

const PlayVideoTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: '10px',
  color: 'white',
  whiteSpace: 'nowrap'
});

export { Container, Title, PlayVideoContainer, PlayVideoTitle };
