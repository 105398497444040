import { Params } from 'react-router';
import { generatePath as routerGeneratePath } from 'react-router-dom';

// TODO: this is not a service (currently is an enum + utill functions) and should be separated to different files under different folders
export enum RoutesMap {
  HOME = '/*',
  SHARED_WORKSPACES_MANAGEMENT = '/spaces',
  SHARED_WORKSPACE = '/spaces/:workspaceId',
  SPACE_MEETING = '/spaces/:spaceId/meeting/:spaceMeetingId',
  MEETINGS = '/meetings/*',
  MEETING_VIEW = '/meetings/view/:meetingId',
  METRICS = '/metrics',
  BUSINESS_AREA = '/business-area/:businessAreaKey',
  BUSINESS_AREA_STRATEGY = '/business-area/strategicPlanning',
  BUSINESS_AREA_PLAYBOOK = '/business-area/:businessAreaKey/playbook/:moduleKey',
  BUSINESS_AREA_MODULE = '/business-area/:businessAreaKey/:moduleKey',
  CONTROL_PANEL = '/control-panel',
  STRATEGY_CENTER = '/strategy-center',
  ASK_JOURNEY = '/ask/*',
  AUTH = '/auth',
  AUTH_LOGIN = '/auth/login',
  AUTH_FORGOT_PASSWORD = '/auth/forgot-password',
  AUTH_RESET = '/auth/reset',
  AUTH_SIGNUP = '/auth/signup',
  CHOOSE_ACCOUNT = '/choose',
  ACCOUNT = '/account',
  MEETING_MODAL = 'meeting/:type/:meetingId',
  FORUM = '/connect',
  FORUM_POST = '/connect/posts/:postId',
  STRATEGY_DRILL_DOWN = '/business-area/:businessAreaKey/strategyDrillDown',
  PRIORITIZATION_MAP = '/business-area/:businessAreaKey/prioritizationMap',
  COMPETITIVE_LANDSCAPE = '/business-area/:businessAreaKey/strategyCompetitiveLandscape',
  COMPETITIVE_ADVANTAGES = '/business-area/:businessAreaKey/strategyCompetitiveAdvantage',
  MISSION_VISION = '/business-area/:businessAreaKey/strategyMissionVision',
  BIG_GOAL = '/business-area/:businessAreaKey/strategyBigGoal',
  PLAYGROUND = '/business-area/:businessAreaKey/strategyPlayGround',
  ROADMAP = '/business-area/:businessAreaKey/strategyRoadmap',
  DIRECTIONS = '/business-area/:businessAreaKey/strategyDirections',
  ACTION_PLAN = '/action-plan',
  ACTION_PLAN_INITIATIVE = '/action-plan/initiative/:initiativeId',
  ACCOUNT_SETTINGS = '/settings',
  INTEGRATIONS = '/integrations',
  USER_PROFILE = '/user-profile',
  WHITEBOARDS = '/whiteboards',
  WHITEBOARD = '/whiteboards/:whiteboardId',
  HUBSPOT_AUTH = '/auth/hubspot',
  QUICKBOOKS_AUTH = '/auth/quickbooks',
  GOOGLE_AUTH = '/auth/google'
}

// TODO change method declarations to hook usage with memo caching

export const generatePath = (path: string, params?: Params) =>
  routerGeneratePath(path, { '*': '', ...params });

export const toHome = () => generatePath(RoutesMap.HOME);
export const toSharedWorkspacesManagement = () =>
  generatePath(RoutesMap.SHARED_WORKSPACES_MANAGEMENT);
export const toSharedWorkspace = (workspaceId: string) =>
  generatePath(RoutesMap.SHARED_WORKSPACE, { workspaceId });
export const toSpaceMeeting = (spaceId: string, spaceMeetingId: string) =>
  generatePath(RoutesMap.SPACE_MEETING, { spaceId, spaceMeetingId });

export const toLogin = () => generatePath(RoutesMap.AUTH_LOGIN);
export const toForgotPassword = () => generatePath(RoutesMap.AUTH_FORGOT_PASSWORD);
export const toBusinessArea = (businessAreaKey: string) =>
  generatePath(RoutesMap.BUSINESS_AREA, { businessAreaKey });
export const toBusinessAreaModule = (businessAreaKey: string, moduleKey: string) =>
  generatePath(RoutesMap.BUSINESS_AREA_MODULE, { businessAreaKey, moduleKey });
export const toBusinessAreaPlaybook = (businessAreaKey: string, moduleKey: string) =>
  generatePath(RoutesMap.BUSINESS_AREA_PLAYBOOK, { businessAreaKey, moduleKey });
export const toControlPanel = () => generatePath(RoutesMap.CONTROL_PANEL);
export const toStrategyCenter = () => generatePath(RoutesMap.STRATEGY_CENTER);
export const toMeetings = () => generatePath(RoutesMap.MEETINGS);
export const toAccountSettings = () => generatePath(RoutesMap.ACCOUNT_SETTINGS);
export const toIntegrations = () => generatePath(RoutesMap.INTEGRATIONS);
export const toMeetingView = (meetingId: string) => `/meetings/view/${meetingId}`;
export const toMetrics = () => generatePath(RoutesMap.METRICS);
export const toChooseAccount = () => generatePath(RoutesMap.CHOOSE_ACCOUNT);
export const toAccount = () => generatePath(RoutesMap.ACCOUNT);
export const toForum = () => RoutesMap.FORUM;
export const toPostView = (postId: string) => generatePath(RoutesMap.FORUM_POST, { postId });
export const toAskJourney = () => RoutesMap.ASK_JOURNEY;
export const toActionPlan = () => RoutesMap.ACTION_PLAN;
export const toActionPlanInitiative = (initiativeId: string) =>
  generatePath(RoutesMap.ACTION_PLAN_INITIATIVE, { initiativeId });
export const toWhiteboards = () => generatePath(RoutesMap.WHITEBOARDS);
export const toWhiteboard = (whiteboardId: string) =>
  generatePath(RoutesMap.WHITEBOARD, { whiteboardId });
export const toUserProfile = () => generatePath(RoutesMap.USER_PROFILE);
