import React, { useState } from 'react';

import { SharedWorkspacePointDtoPriority } from '../../../api/generated';
import {
  ArrowDownIcon,
  CheckCircle,
  PriorityContainer,
  PrioritySelectionContainer,
  SelectionTitle
} from './SharedWorkspacePointPriorityStyles';
import palette from '../../../theme/palette';
import { Menu, Stack } from '@mui/material';

export const priorityColorsMap = {
  [SharedWorkspacePointDtoPriority.L]: {
    backgroundColor: palette.green[6],
    checkedColor: palette.green[7],
    label: 'Low'
  },
  [SharedWorkspacePointDtoPriority.M]: {
    backgroundColor: palette.yellow[2],
    checkedColor: palette.yellow[3],
    label: 'Medium'
  },

  [SharedWorkspacePointDtoPriority.H]: {
    backgroundColor: palette.red[3],
    checkedColor: palette.red[4],
    label: 'High'
  }
};

type Props = {
  priority: SharedWorkspacePointDtoPriority;
  onSelection: (priority: SharedWorkspacePointDtoPriority) => void;
};

const SharedWorkspacePointPriority = ({ priority, onSelection }: Props) => {
  const [priorityPopoverAnchor, setPriorityPopoverAnchor] = useState(null);
  const priorityPopoverOpen = !!priorityPopoverAnchor;

  return (
    <PriorityContainer>
      <PrioritySelectionContainer
        onClick={event => setPriorityPopoverAnchor(event.currentTarget)}
        backgroundColor={priorityColorsMap[priority].backgroundColor}
      >
        <CheckCircle
          checkedColor={priorityColorsMap[priority].checkedColor}
          checked={true} />
        <SelectionTitle>
          {priorityColorsMap[priority].label}
        </SelectionTitle>
        <ArrowDownIcon open={priorityPopoverOpen} />
      </PrioritySelectionContainer>

      <Menu
        id="edit-priority-menu"
        aria-labelledby="edit-priority-button"
        anchorEl={priorityPopoverAnchor}
        open={priorityPopoverOpen}
        onClose={() => setPriorityPopoverAnchor(null)}
        anchorOrigin={{
          vertical: 22,
          horizontal: 'right'
        }}
        sx={{
          filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.13))',
          borderRadius: '6px'
        }}
      >
        <Stack sx={{ padding: '6px 10px' }}>
          <Stack>
            <Stack direction="row" gap="6.5px" marginTop="2px">
              <PrioritySelectionContainer
                onClick={() => onSelection(SharedWorkspacePointDtoPriority.H)}
                backgroundColor={
                  priorityColorsMap[SharedWorkspacePointDtoPriority.H].backgroundColor
                }
              >
                <CheckCircle
                  checked={priority === SharedWorkspacePointDtoPriority.H}
                  checkedColor={
                    priorityColorsMap[SharedWorkspacePointDtoPriority.H].checkedColor
                  }
                />
                <SelectionTitle>
                  {priorityColorsMap[SharedWorkspacePointDtoPriority.H].label}
                </SelectionTitle>
              </PrioritySelectionContainer>
              <PrioritySelectionContainer
                onClick={() => onSelection(SharedWorkspacePointDtoPriority.M)}
                backgroundColor={
                  priorityColorsMap[SharedWorkspacePointDtoPriority.M].backgroundColor
                }
              >
                <CheckCircle
                  checked={priority === SharedWorkspacePointDtoPriority.M}
                  checkedColor={
                    priorityColorsMap[SharedWorkspacePointDtoPriority.M].checkedColor
                  }
                />
                <SelectionTitle>
                  {priorityColorsMap[SharedWorkspacePointDtoPriority.M].label}
                </SelectionTitle>
              </PrioritySelectionContainer>
              <PrioritySelectionContainer
                onClick={() => onSelection(SharedWorkspacePointDtoPriority.L)}
                backgroundColor={
                  priorityColorsMap[SharedWorkspacePointDtoPriority.L].backgroundColor
                }
              >
                <CheckCircle
                  checked={priority === SharedWorkspacePointDtoPriority.L}
                  checkedColor={
                    priorityColorsMap[SharedWorkspacePointDtoPriority.L].checkedColor
                  }
                />
                <SelectionTitle>
                  {priorityColorsMap[SharedWorkspacePointDtoPriority.L].label}
                </SelectionTitle>
              </PrioritySelectionContainer>
            </Stack>
          </Stack>
        </Stack>
      </Menu>
    </PriorityContainer>
  );
};

export default SharedWorkspacePointPriority;
