import React, { useState } from 'react';
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputBase, Stack } from '@mui/material';

import { ErrorTitle } from '../../screens/AuthSignIn/AuthSignInStyles';

type PasswordInputProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  errors: FieldErrorsImpl<{ email: string; password: string }>;
  registerName?: string;
  rulesOptions?: { [key: string]: unknown };
  placeholder?: string;
  mb?: number;
  onFocus?: () => void;
};

const PasswordInput = ({
  register,
  errors,
  registerName,
  rulesOptions,
  mb,
  onFocus,
  placeholder
}: PasswordInputProps) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const toggleIsShowPassword = () => {
    setIsShowPassword(value => !value);
  };
  const registerProps = register
    ? {
      ...register(registerName || 'password', {
        required: 'Password is required',
        pattern: {
          value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/i,
          message: 'Invalid password'
        },
        ...rulesOptions
      })
    }
    : [];
  return (
    <Stack position="relative" direction="row" width="100%">
      <Stack sx={{ mb: mb !== null ? mb : 2 }} width="100%">
        <InputBase
          onFocus={onFocus && onFocus}
          name={'password'}
          {...registerProps}
          placeholder={placeholder || 'Enter your password'}
          autoComplete="password"
          defaultValue=""
          sx={{ zIndex: 1 }}
          fullWidth
          type={isShowPassword ? 'text' : 'password'}
        />
        {errors?.password?.message && (
          <ErrorTitle marginLeft="4px" marginTop="4px">
            {errors.password.message}
          </ErrorTitle>
        )}
      </Stack>
      <IconButton
        sx={{ position: 'absolute', right: 15, top: 10, zIndex: 2 }}
        onClick={toggleIsShowPassword}
        edge="end"
      >
        {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
      </IconButton>
    </Stack>
  );
};

export default PasswordInput;
