import styled from '@emotion/styled';
import { Button, Paper } from '@mui/material';

import palette from '../../theme/palette';

const AuthCard = styled(Paper)({
  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px',
  borderRadius: '3px',
  padding: 4,
  overflow: 'auto',
  marginBottom: '50px'
});

const SubmitButton = styled(Button)({
  padding: '0px 20px',
  width: '30%',
  height: '40px',
  background: palette.green[1],
  borderRadius: '10px',
  color: 'white',
  textOverflow: 'ellipsis',
  ':hover': {
    background: palette.green[1],
    textDecoration: 'none',
    transform: 'scale(1.03)',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
  }
});

export { AuthCard, SubmitButton };
