import React, { createContext, useContext, useState } from 'react';


interface SideBarContext {
  isSidebarOpened: boolean;
  setIsSidebarOpened: (isOpen: boolean) => void;
}

const Context = createContext<SideBarContext | null>(null);

const useSidebar = () => useContext(Context);

export const AppSidebarProvider = ({ children }: { children: React.ReactNode }) => {


  const [isSidebarOpened, setIsSidebarOpened] = useState<boolean>(
    false
  );

  const value = {
    isSidebarOpened,
    setIsSidebarOpened
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default useSidebar;
