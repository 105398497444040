import React, { useEffect, useState } from 'react';

import AddBoxIcon from '@mui/icons-material/AddBox';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  alpha,
  Box,
  CircularProgress,
  Divider,
  Stack,
  styled,
  Typography
} from '@mui/material';

import {
  ResourceDto,
  SharedWorkspacePointDto,
  UserDetailsType,
  UserFeatureFeature
} from '../../api/generated';
import useModal from '../../hooks/context-providers/useModal/useModal';
import useResource from '../../hooks/useResource';
import useUserFeature from '../../hooks/useUserFeature';
import palette from '../../theme/palette';
import ExpandIcon from '../../components/ExpandIcon/ExpandIcon';
import GoogleDrive from '../../components/GoogleDrive/GoogleDrive';
import useAuth from '../../hooks/useAuth';
import useSharedWorkspacePoints from '../../hooks/useSharedWorkspacePoints';
import PointOfDiscussion from '../../components/PointOfDiscussion/PointOfDiscussion';
import { AddPoint, SideMenuContainer } from './HomeStyles';
import useNotes from '../../hooks/useNotes';
import QuickNote from './QuickNote';
import HomeLink from './HomeLink';
import useSharedWorkspaces from '../../hooks/useSharedWorkspaces';
import { List, arrayMove } from 'react-movable';
import { orderBy } from 'lodash';



const SubTitle = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 700,
  fontSize: '15px',
  color: palette.primary.main
});


const MenuAccordion = styled(Accordion)(() => ({
  '.MuiCollapse-wrapperInner': {
    maxHeight: '500px',
    overflowY: 'auto'
  },
  '&:before': {
    backgroundColor: 'transparent !important'
  }
}));

const LazyLoadedResourceModal = React.lazy(
  () => import('../../modals/AddResourceModal/AddResourceModal')
);

interface MultiSpacePoint extends SharedWorkspacePointDto {
  spaceIds: string[];
}

const HomeSideMenu = () => {
  const { showModal } = useModal();
  const { isFeatureBlocked } = useUserFeature();

  const showAddResourceModal = React.useCallback(() => {
    const modal = showModal(LazyLoadedResourceModal, {
      onCancel: () => modal.hide(),
      onConfirm: () => modal.hide()
    });
  }, [showModal]);

  const { resourceFilesList, refetch: refetchResources, bulkPatchResources } = useResource();
  const [openTopOfMind, setOpenTopOfMind] = useState<boolean>(true);
  const [openLinks, setOpenLinks] = useState<boolean>(true);
  const [openDrive, setOpenDrive] = useState<boolean>(true);

  const { user, currentAccount } = useAuth();
  const [newNoteContent, setNewNoteContent] = useState('');
  const { starredPoints, refetchStarredPoints } = useSharedWorkspacePoints();
  const { notesList, createNote, refetchNotes } = useNotes();
  const { sharedWorkspaces } = useSharedWorkspaces();

  const [pointsOfDiscussion, setPointsOfDiscussion] = useState<MultiSpacePoint[]>([]);


  const mergePointsOfDiscussion = (pointsOfDiscussion: SharedWorkspacePointDto[]) => {
    const points = new Map<string, SharedWorkspacePointDto[]>();
    pointsOfDiscussion.forEach(point => {
      const currPoints = points.get(point.content);

      if (currPoints) {
        currPoints.push(point);
      } else {
        points.set(point.content, [point]);
      }
    });

    const pod: MultiSpacePoint[] = [];
    Array.from(points.values()).map(currPoints => {
      const spaceIds = currPoints.map(point => point.sharedWorkspaceId);
      pod.push({ ...currPoints[0], spaceIds });
    }
    )

    setPointsOfDiscussion(pod);

  }

  useEffect(() => {
    if (starredPoints) {
      mergePointsOfDiscussion(starredPoints);
    }
  }, [starredPoints, sharedWorkspaces]);

  const [resources, setResources] = useState<ResourceDto[]>([]);

  useEffect(() => {
    if (resourceFilesList) {
      setResources(orderBy(resourceFilesList, item => item.index, 'asc'));
    }
  }, [resourceFilesList]);

  const [listReorderLoading, setListReorderLoading] = useState(false);

  const refetch = () => {
    refetchStarredPoints();
    refetchNotes();
    refetchResources();
  }

  return (
    <SideMenuContainer >
      <Stack
        height="100%">

        {(user.type !== UserDetailsType.jp || currentAccount?.config?.shareCeoDataWithJp) &&
          <MenuAccordion
            disableGutters
            sx={{
              boxShadow: 0,
              "& .MuiCollapse-wrapperInner": {
                maxHeight: "100%"
              }
            }}
            expanded={openTopOfMind}
          >
            <AccordionSummary
              sx={{ padding: 0 }}
              expandIcon={
                <Stack onClick={() => setOpenTopOfMind(!openTopOfMind)}>
                  <ExpandIcon expanded={openTopOfMind} />
                </Stack>
              }
            >
              <SubTitle>Top of mind</SubTitle>

            </AccordionSummary>
            <AccordionDetails
              sx={{ padding: "10px 0px" }}>
              <Stack
                gap={1}>
                <AddPoint
                  disableUnderline
                  placeholder="Type and enter.."
                  value={newNoteContent}
                  onChange={event => setNewNoteContent(event.currentTarget.value)}
                  onKeyDown={event => {
                    if (event.key === 'Enter') {
                      if (newNoteContent !== '') {
                        createNote({ content: newNoteContent })
                          .then(() => {
                            refetchNotes();
                            setNewNoteContent('');
                          });
                      } else {
                        event.stopPropagation();
                      }
                    } else {
                      event.stopPropagation();
                    }
                  }}
                />
                {notesList?.map(note =>
                  <Stack
                    key={note.id}
                    gap="10px">
                    <QuickNote
                      key={note.id}
                      note={note}
                      refetch={refetch} />
                    <Divider />
                  </Stack>
                )}


                {
                  pointsOfDiscussion.length > 0 && orderBy(pointsOfDiscussion, point => point.updatedAt, 'desc').map(point =>

                    <Stack
                      width="100%"
                      key={point.id}
                      gap="10px">
                      <PointOfDiscussion
                        sharedWorkspaces={sharedWorkspaces?.filter(space => point.spaceIds.includes(space.id))}
                        refetch={refetchStarredPoints}
                        collaboratorUserIds={[]}
                        point={point}
                        sharedWorkspaceId={point.sharedWorkspaceId} />
                      <Divider />
                    </Stack>)







                }


              </Stack>
            </AccordionDetails>
          </MenuAccordion>}

        <MenuAccordion
          disableGutters
          sx={{
            boxShadow: 0,
            "& .MuiCollapse-wrapperInner": {
              maxHeight: "100%"
            }
          }}
          expanded={openLinks}
        >
          <AccordionSummary
            sx={{ padding: 0 }}
            expandIcon={
              <Stack onClick={() => setOpenLinks(!openLinks)}>
                <ExpandIcon expanded={openLinks} />
              </Stack>
            }
          >
            <Stack
              marginRight={1}
              width="100%"
              direction="row"
              alignItems="center"
              justifyContent="space-between">
              <SubTitle>Useful links</SubTitle>
              <Stack gap="10px" direction="row" alignItems="center">
                {listReorderLoading && <CircularProgress size={20} />}
              </Stack>
              <Box
                onClick={showAddResourceModal}
                sx={{ color: palette.primary.main, cursor: 'pointer', ':hover': { opacity: 0.8 } }}
                alignItems="center"
                justifyContent="center"
                width={30}
                height={30}
              >
                <AddBoxIcon
                  sx={{
                    width: 30,
                    height: 30
                  }}
                />
              </Box>
            </Stack>

          </AccordionSummary>
          <AccordionDetails
            sx={{ padding: "10px 0px" }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
            </Stack>
            {resources?.length > 0 && (

              <List
                lockVertically
                values={resources}
                onChange={({ oldIndex, newIndex }) => {
                  setListReorderLoading(true);
                  const newList = arrayMove(resources, oldIndex, newIndex);
                  const items = newList.map((obj, i) => ({
                    id: obj.id,
                    index: i
                  }));
                  bulkPatchResources(items)
                    .then(() => setResources(newList))
                    .finally(() => {
                      setListReorderLoading(false);
                    });
                }}
                renderList={({ children, props }) => <Stack {...props}>{children}</Stack>}
                renderItem={({ value, props, index, isDragged, isSelected }) => (
                  <Stack {...props}>
                    <Stack
                      {...props}
                      key={index}
                      sx={{
                        borderRadius: '6px',
                        border:
                          (isDragged || isSelected) && `2px solid ${alpha(palette.primary.main, 0.4)}`
                      }}
                    >

                      <HomeLink
                        resource={value}
                        refetch={refetch} />

                    </Stack>
                    <Divider sx={{ marginTop: '3px', marginLeft: "10px" }} />
                  </Stack>
                )}
              />

            )}
          </AccordionDetails>
        </MenuAccordion >


        {!isFeatureBlocked(UserFeatureFeature.drive) && (
          <MenuAccordion
            disableGutters
            sx={{
              boxShadow: 0,
              "& .MuiCollapse-wrapperInner": {
                maxHeight: "100%"
              }
            }}
            expanded={openDrive}
          >
            <AccordionSummary
              sx={{ padding: 0 }}
              expandIcon={
                <Stack onClick={() => setOpenDrive(!openDrive)}>
                  <ExpandIcon expanded={openDrive} />
                </Stack>
              }
            ><SubTitle>My Drive</SubTitle></AccordionSummary>
            <AccordionDetails sx={{ padding: "2px" }}>
              <GoogleDrive
              />
            </AccordionDetails>
          </MenuAccordion>
        )}


      </Stack >
    </SideMenuContainer >
  );
};

export default HomeSideMenu;
