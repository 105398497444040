/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Axios from 'axios';
import * as Sentry from '@sentry/browser';
import decodeJwt from 'jwt-decode';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import {
  AccountDetails,
  SignInDetails,
  SignInMfaRequest,
  SignInRequest,
  SignInResponse,
  SignUpRequest,
  UserDetails,
  UserMfaSettingsDto,
  useAccountSignIn,
  useGetUserDetails,
  useGetUserMfaSettings,
  useMfaSignIn,
  useSignIn,
  useSignOut,
  useSignUp
} from '../api/generated';
import { EnvVariables } from '../enums/EnvVariables';

import { generateEmptySpaces } from '../utils/textFormater';
import useStorage, { STORAGE_TYPE } from './useStorage';

type JourneyJwt = Record<string, string>;
type Tokens = SignInDetails;
type IdToken = Tokens['idToken'];
type AccessToken = Tokens['accessToken'];
type RefreshToken = Tokens['refreshToken'];

type AuthContext = {
  tokens: Tokens;
  isAuthenticated: boolean;
  signInLoading: boolean;
  accountSignInLoading: boolean;
  isLoadingAuthRefresh: boolean;
  userDetailsLoading: boolean;
  user: UserDetails;
  displayName: string;
  currentAccount: AccountDetails;
  signup: (data: SignUpRequest) => Promise<void>;
  signOut: () => void;
  signIn: (data: SignInRequest) => Promise<SignInDetails>;
  mfaSignIn: (data: SignInMfaRequest) => Promise<void>;
  isLoadingAuthMfaSignin: boolean;
  accountSignin: (accountId: number) => Promise<void>;
  refreshTokens: () => Promise<{
    refreshToken: string;
    idToken?: string;
    accessToken?: string;
    socketToken?: string;
    type?: string;
  }>;
  refetchUserDetails: () => void;
  userMfaSettings: UserMfaSettingsDto;
  getUserMfaSettings: () => void;
};

const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.REACT_APP_API_BASE as EnvVariables.REACT_APP_API_BASE
});

const Context = createContext<AuthContext | null>(null);

const useAuth = () => useContext(Context);

const logRocketKey = process.env
  .REACT_APP_LOGROCKET_PROJECT_ID as EnvVariables.REACT_APP_LOGROCKET_PROJECT_ID;

// init LogRocket
if (logRocketKey) {
  LogRocket.init(logRocketKey);
  setupLogRocketReact(LogRocket);
}

export const AdminRoleId = 'admin';

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [idToken, setIdToken] = useStorage<NonNullable<IdToken>>(STORAGE_TYPE.LOCAL, 'idToken', '');
  const [signInLoading, setSignInLoading] = useState(false);
  const [accountSignInLoading, setAccountSignInLoading] = useState(false);
  const [userDetailsLoading, setUserDetailsLoading] = useState(false);

  const [accessToken, setAccessToken] = useStorage<NonNullable<AccessToken>>(
    STORAGE_TYPE.LOCAL,
    'accessToken',
    ''
  );
  const [refreshToken, setRefreshToken] = useStorage<NonNullable<RefreshToken>>(
    STORAGE_TYPE.LOCAL,
    'refreshToken',
    ''
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [currentAccountId, setCurrentAccountId] = useStorage(
    STORAGE_TYPE.LOCAL,
    'currentAccountId',
    ''
  );

  const socketToken = JSON.parse(localStorage.getItem('socketToken'));

  const { mutateAsync: authAccountSignin } = useAccountSignIn();
  const { mutateAsync: authSignin, isPending: isLoadingAuthSignin } = useSignIn();
  const { mutateAsync: authMfaSignin, isPending: isLoadingAuthMfaSignin } = useMfaSignIn();
  const { mutateAsync: authSignOut } = useSignOut();
  const [isLoadingAuthRefresh, setIsLoadingAuthRefresh] = useState<boolean>(false);
  const { mutateAsync: authSignup } = useSignUp();

  const { data: userDetailsResponse, refetch: refetchUserDetails } = useGetUserDetails({
    query: {
      queryKey: [idToken],
      enabled: false
    }
  });

  const { data: userMfaSettingsResponse, refetch: getUserMfaSettings } = useGetUserMfaSettings({
    query: {
      queryKey: ["userMfaSettings"],
      enabled: false
    }
  });

  const fetchUserDetails = useCallback(async () => {
    setUserDetailsLoading(true);
    refetchUserDetails().then(() => {
      setUserDetailsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (idToken) {
      fetchUserDetails();
    }
  }, [idToken, refetchUserDetails]);

  const tokens = { idToken, accessToken, refreshToken, socketToken };
  const isAuthenticated = useMemo(() => !!idToken, [idToken]);

  const currentUser = useMemo(() => userDetailsResponse?.items?.[0], [userDetailsResponse]);

  const userMfaSettings = useMemo(() => userMfaSettingsResponse?.items?.[0], [userMfaSettingsResponse]);

  const currentAccount = currentUser?.accounts.find(
    item => item.id === currentUser.currentAccountId
  );

  const displayName = useMemo(
    () => currentUser?.firstName + generateEmptySpaces(1) + currentUser?.lastName,
    [currentUser]
  );

  useEffect(() => {
    if (currentUser) {
      LogRocket.identify(currentUser.id, {
        name: displayName,
        email: currentUser.email,
        userType: currentUser.type,
        accountName: currentUser.accounts.find(
          account => account.id === currentUser.currentAccountId
        )?.name
      });
      Sentry.setUser({ id: currentUser.id, email: currentUser.email, username: displayName });
    }
  }, [currentAccount?.name, displayName]);

  const setTokens = (tokens?: Tokens) => {
    const { idToken = '', accessToken = '', refreshToken = '', socketToken = '' } = tokens || {};
    setIdToken(idToken);
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    localStorage.setItem('socketToken', JSON.stringify(socketToken));
  };

  const signUp = async (data: SignUpRequest) => {
    const { items } = await authSignup({ data });
    await fetchUserDetails();
    const { ...tokens } = items[0];
    setTokens(tokens);
  };

  const signIn = async (data: SignInRequest) => {
    setSignInLoading(true);

    try {
      const { items } = await authSignin({ data });
      const signInDetails = items[0];

      if (signInDetails.idToken) {
        const { ...tokens } = items[0];
        setTokens(tokens);
        await fetchUserDetails();
      }

      setSignInLoading(false);
      return signInDetails;
    } catch (error) {
      setSignInLoading(false);
      throw error;
    }
  };

  const mfaSignIn = async (data: SignInMfaRequest) => {
    setSignInLoading(true);

    try {
      const { items } = await authMfaSignin({ data });
      const { ...tokens } = items[0];
      setTokens(tokens);
      await fetchUserDetails();
    } catch (error) {
      setSignInLoading(false);
      throw error;
    }

    setSignInLoading(false);
  };

  const accountSignin = async (accountId: number) => {
    if (!isAuthenticated) return;
    setAccountSignInLoading(true);
    const { items } = await authAccountSignin({ data: { accountId } });
    await fetchUserDetails();
    const { ...tokens } = items[0];
    setTokens(tokens);
    setCurrentAccountId(String(accountId));
    setAccountSignInLoading(false);
  };

  const signOut = () => {
    authSignOut()
      .then(() => {
        setTokens();
        setCurrentAccountId('');
      })
      .finally(() => {
        // hacky and wrong! change to a state flag and check that in protected route.
        setTimeout(() => {
          localStorage.setItem('lastRedirectedUrl', '');
        }, 500);
      });
  };



  // todo - there is a problem with the reset of the refreshToken in signOut action and with the refreshTokens function
  // even after the sign out the refreshToken is still exists somehow and the use is not signed out from the system
  // the work around is to call the sign out route (anyway this is a good approach) and after it to catch the failure of
  // the refresh action and then to sign out the user from the system
  const refreshTokens = async () => {
    // FIXME Directly getting from the localstorage because sometimes the hook sends no value.
    const localCurrentAccountId = JSON.parse(localStorage.getItem('currentAccountId') ?? '');
    const localRefreshToken = JSON.parse(localStorage.getItem('refreshToken') ?? '');
    if (!localRefreshToken || isLoadingAuthRefresh) return;

    setIsLoadingAuthRefresh(true);

    try {
      const email = decodeJwt<JourneyJwt>(idToken).email;

      const response = await AXIOS_INSTANCE.post<SignInResponse>('/auth/signIn/refresh', { email, refreshToken: localRefreshToken }, { skipAuthRefresh: true });
      const items = response.data.items;
      let tokens = { ...items?.[0], refreshToken: items?.[0].refreshToken || localRefreshToken };

      if (localCurrentAccountId) {
        AXIOS_INSTANCE.defaults.headers['Authorization'] = `Bearer ${tokens.idToken}`;
        const response = await AXIOS_INSTANCE.post<SignInResponse>('/auth/accountSignIn', { accountId: Number(localCurrentAccountId) }, { skipAuthRefresh: true });
        const items = response.data.items;
        tokens = { ...items?.[0], refreshToken: items?.[0].refreshToken || refreshToken };
      }

      setTokens(tokens);
      return tokens;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (_error) {
      // TODO Log error
      setTokens();
      setCurrentAccountId('');
    } finally {
      setIsLoadingAuthRefresh(false);
    }
  };

  const value = useMemo(
    () => ({
      tokens,
      isAuthenticated,
      signInLoading,
      accountSignInLoading,
      isLoadingAuthRefresh,
      userDetailsLoading,
      user: currentUser,
      displayName,
      currentAccount,
      signup: signUp,
      signOut: signOut,
      signIn: signIn,
      mfaSignIn: mfaSignIn,
      isLoadingAuthMfaSignin,
      accountSignin,
      refreshTokens,
      refetchUserDetails,
      userMfaSettings,
      getUserMfaSettings
    }),
    [
      accountSignin,
      currentAccount,
      currentUser,
      displayName,
      isAuthenticated,
      isLoadingAuthRefresh,
      isLoadingAuthSignin,
      userDetailsLoading,
      refreshTokens,
      signIn,
      mfaSignIn,
      isLoadingAuthMfaSignin,
      signOut,
      signUp,
      tokens,
      refetchUserDetails,
      userMfaSettings,
      getUserMfaSettings
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default useAuth;
