import { Components } from '@mui/material/styles';
import palette from '../../theme/palette';
import typography from '../typography';

const MuiOutlinedInput: Components['MuiOutlinedInput'] = {
  styleOverrides: {
    root: {
      backgroundColor: palette.grey['400'],
      borderRadius: 35,
      ...typography.body2,
      border: 'none',
      color: palette.common.black,
      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: palette.common.black
        }
      },
      '&.Mui-disabled': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent'
        }
      }
    },
    input: {
      padding: '0.625rem 0.75rem',
      '&::placeholder': {
        color: palette.grey['500']
      },
      '&:-webkit-autofill': {
        transitionDelay: '999999999999999s',
        transitionProperty: 'background-color, color'
      },
      '&:read-only': {
        backgroundColor: palette.background.default,
        padding: 0
      },
      '&::-webkit-search-cancel-button': {
        appearance: 'none'
      },
      '&.Mui-disabled': {
        WebkitTextFillColor: 'unset',
        color: palette.common.black
      }
    },
    notchedOutline: {
      borderColor: 'transparent',
      top: 0,
      '& legend': {
        display: 'none'
      }
    }
  }
};

export default MuiOutlinedInput;
