import styled from "@emotion/styled";
import palette from "../../theme/palette";
import { Box, Stack, Tab, TextField, Typography, alpha } from "@mui/material";
import { Bolt } from "@mui/icons-material";
import { Link } from "react-router-dom";
import isPropValid from '@emotion/is-prop-valid';
import FreeTextViewer from "../BizAreaModules/components/FreeTextViewer";

export const ButtonContainer = styled(Stack)({
  background: palette.primary.main,
  cursor: 'pointer',
  padding: 10,
  width: "150px",
  alignItems: "center",
  borderRadius: '10px',
  ":hover": { opacity: 0.8 }

});

export const ButtonText = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 700,
  fontSize: '15px',
  color: 'white',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
});

export const TabsContainer = styled(Box)({
  marginTop: 10,
  marginRight: 50,

  borderBottom: `1px solid ${palette.border.grey}`,
  position: 'sticky',
  zIndex: 1
});

export const TabContainer = styled(Tab)(() => ({
  padding: '14px 25px 22px',
  width: '50%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

export const TabLabel = styled(Stack)({
  width: '100%',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
});

export const TabLabelIcons = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: 3
});

export const TabTitleCount = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 400,
  fontSize: '13px',
  marginLeft: '3px',
  textTransform: 'none'
});

export const TabTitle = styled(TabTitleCount)({
  marginLeft: '5px'
});

export const BoltIcon = styled(Bolt)({
  fontSize: '15px'
});

export const Author = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  gap: 3
});

export const AuthorText = styled(Typography)(() => ({
  marginLeft: 2,
  color: palette.grey[1100],
  fontWeight: 600,
  fontSize: '12px'
}));

export const ValidatedAnswerContainer = styled(Stack)({
  flexDirection: "row",
  paddingRight: 6,
  gap: 3,
  alignItems: "center",
  borderRadius: "30px",
  backgroundColor: palette.green[6]
});

export const ValidatedAnswerText = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  color: palette.common.darkBlue,
  fontWeight: 600,
  fontSize: '11px'
}));

export const SaveButton = styled(Stack, { shouldForwardProp: isPropValid })<{
  disbaled?: boolean;
}>(({ disbaled = false }) => ({
  cursor: "pointer",
  padding: 6,
  flexDirection: "row",
  alignItems: "center",
  backgroundColor: "white",
  borderRadius: "20px",
  width: "68px",
  height: "28px",
  gap: 4,
  ":hover": !disbaled && { opacity: 0.7 },
  border: `1px solid ${palette.border.grey}`
}));


export const SaveButtonText = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  color: palette.grey[1100],
  fontSize: '12px'
}));

export const TagsContainer = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  gap: 8
});




export const InputText = styled(TextField)(() => ({
  border: "none",
  padding: 0,
  margin: 0,
  '& .MuiOutlinedInput-notchedOutline': {
    border: "none"
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    fontFamily: 'Plus Jakarta Sans',
    padding: 0,
    fontSize: "13px",
    backgroundColor: palette.background.paper,
    border: `1px solid ${palette.border.grey}`,
    '& .MuiOutlinedInput-input': {
      '&::placeholder': { color: palette.grey[600], opacity: 1 }
    }

  }
}));

export const Tag = styled(Stack)({
  cursor: "pointer",
  background: alpha(palette.blue[11], 0.2),
  ":hover": { opacity: 0.8 },
  paddingLeft: 8,
  paddingRight: 8,
  paddingTop: 3,
  paddingBottom: 3,
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 600,
  color: palette.common.darkBlue,
  fontSize: '12px',
  borderRadius: "10px"
});

export const SavedPosts = styled(Stack)({
  padding: 15,
  cursor: "pointer",
  minHeight: "300px",
  borderRadius: "12px",
  backgroundColor: palette.background.paper,
  border: `1px solid ${palette.border.grey}`

});

export const SavedPostsTitle = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  gap: 10,
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '15px',
  marginLeft: '3px',
  textTransform: 'none'
});

export const SavedPostContainer = styled(Stack)({
  marginBottom: 14,
  padding: 12,
  gap: 2,
  borderRadius: "12px",
  backgroundColor: palette.background.grey,
  border: `1px solid ${palette.border.grey}`,
  boxShadow: `2px 2px 8px ${palette.grey[500]}`,
  ":hover": { boxShadow: `6px 6px 10px ${palette.grey[500]}` }
});

export const SavedPostTitle = styled(Typography)({
  fontWeight: 800,
  fontSize: "14px",
  fontFamily: 'Plus Jakarta Sans',
  color: palette.common.darkBlue
});

export const SavedPostContent = styled(FreeTextViewer)(
  {
    '& .ql-editor p': {

      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap"
    }
  }
);


export const ReadMore = styled(Link)({
  cursor: "pointer",
  fontFamily: 'Plus Jakarta Sans',
  fontSize: "13px",
  color: palette.common.darkBlue
});

export const SavedPostsEmptyContainer = styled(Stack)({
  marginTop: 50,
  alignItems: "center",
  gap: 5,
  marginLeft: 1,
  marginRight: 1,
  paddingTop: 20,
  paddingBottom: 20,
  borderRadius: "12px",
  backgroundColor: palette.background.grey
});

export const SavedPostEmptyText = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px',
  fontWeight: 700,
  color: palette.grey[600]
}));

export const CancelEditlButton = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 700,
  fontSize: '14px',
  color: palette.grey[1100],
  cursor: 'pointer'
});

export const SaveEditButton = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2px 5px',
  gap: '4px',
  background: palette.green[1],
  borderRadius: '8px',
  cursor: 'pointer',
  color: 'white',
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 700,
  fontSize: '14px',
  '&:hover': {
    opacity: 0.8
  }
});

export const EditorBorder = styled(Box, { shouldForwardProp: isPropValid })(() => ({
  border: `1px solid ${palette.border.grey}`,
  borderRadius: '8px'
}));

export const CommentsCount = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  color: palette.common.darkBlue,
  fontWeight: 700,
  fontSize: '13px'
}));

export const EmptyMyQuestionState = styled(Stack)({
  alignItems: "center",
  padding: 20,
  gap: 20,
  borderRadius: "12px",
  fontWeight: 700,
  backgroundColor: palette.background.paper,
  fontFamily: 'Plus Jakarta Sans',
  color: palette.grey[1200]
});
