import { Stack, styled, Typography } from '@mui/material';


const Title = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize, fontWeight: 700
}));

const HeaderContainer = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap:"5px"
}));

export {
  HeaderContainer,
  Title
};
