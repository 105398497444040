import { Components } from '@mui/material/styles';
import palette from '../../theme/palette';
import typography from '../typography';

const MuiInputLabel: Components['MuiInputLabel'] = {
  styleOverrides: {
    root: {
      color: palette.grey['700'],
      ...typography.body2,
      lineHeight: '17px',
      position: 'relative',
      transform: 'unset',
      marginBottom: '0.5rem'
    }
  }
};

export default MuiInputLabel;
