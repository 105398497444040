import React, { SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import { ActionPlanBoardDto } from '../../api/generated';
import useActionPlanBoard from '../useActionPlanBoard';


type ActionPlanBoardStateContext = {
  selectedBoard: ActionPlanBoardDto;
  setSelectedBoard: React.Dispatch<SetStateAction<ActionPlanBoardDto>>;
};

export const mainBoardID = "main";

export const mainBoard = {
  id: mainBoardID, name: "Main"
}

export const ActionPlanBoardContext =
  createContext<ActionPlanBoardStateContext | null>(null);

const useActionPlanBoardContext = () => useContext(ActionPlanBoardContext);
export const ActionPlanBoardProvider = ({ children }: { children: React.ReactNode }) => {
  const { boardList } = useActionPlanBoard();
  const defaultBoard = boardList.find(board => board.isDefault);
  const [selectedBoard, setSelectedBoard] = useState<ActionPlanBoardDto>(defaultBoard?.id ? defaultBoard : mainBoard);

  useEffect(() => {
    if (boardList && defaultBoard) {
      setSelectedBoard(defaultBoard);
    }
  }, [boardList, defaultBoard]);

  const value = { selectedBoard, setSelectedBoard };


  return (
    <ActionPlanBoardContext.Provider value={value}>
      {children}
    </ActionPlanBoardContext.Provider>
  );
};

export default useActionPlanBoardContext;






