import { Stack, Typography, styled } from '@mui/material';

import palette from '../../theme/palette';

const Title = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 400,
  fontSize: '14px',
  color: palette.grey[1700]
});

const Chip = styled(Stack)<{ checked: boolean }>(props => ({
  padding: '6px 8px',
  background: props.checked ? palette.background.lightGreen : palette.background.grey,
  border: '1px solid white',
  borderRadius: '16px',
  cursor: 'pointer',
  ':hover': {
    transform: 'scale(1.03)',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
  }
}));

const ChipText = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '13px',
  lineHeight: '16px',
  color: palette.common.darkBlue
});

export { Chip, ChipText, Title };
