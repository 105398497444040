import { useCallback, useMemo } from 'react';

import {
  ActionPlanInitiativeCommentDto,
  UserFeatureFeature,
  useCreateActionPlanInitiativeComment,
  useDeleteActionPlanInitiativeComment,
  useListActionPlanInitiativeComments,
  usePatchActionPlanInitiativeComment,
  useSearchActionPlanInitiativeComments
} from '../api/generated';
import useUserFeature from './useUserFeature';

const useInitiativeComments = (initiativeId?: string) => {
  const { isFeatureBlocked } = useUserFeature();

  const isActionPlanEnabled = !isFeatureBlocked(UserFeatureFeature.actionPlan);

  const {
    data: initiativeCommentsResponse,
    isLoading: initiativeCommentsLoading,
    refetch: refetchComments
  } = useListActionPlanInitiativeComments(initiativeId, {
    query: {
      queryKey: ['initiativeComments', initiativeId],
      enabled: !!initiativeId && isActionPlanEnabled
    }
  });

  const {
    mutateAsync: mutateSearchInitativesComments,
    isPending: searchInitiativeCommentsLoading
  } = useSearchActionPlanInitiativeComments();
  const { mutateAsync: mutateCreateInitiativeComment } = useCreateActionPlanInitiativeComment();
  const { mutateAsync: mutatePatchInitiativeComment } = usePatchActionPlanInitiativeComment();
  const { mutateAsync: mutateDeleteInitiativeComment } = useDeleteActionPlanInitiativeComment();

  const searchInitiativeComments = useCallback(
    async (ownerOrAssigneeUserIds?: string[], entityIds?: string[]) => {
      const response = await mutateSearchInitativesComments({ data: { ownerOrAssigneeUserIds: ownerOrAssigneeUserIds, entityIds: entityIds } });
      return response?.items;
    },
    [mutateSearchInitativesComments]
  );

  const createInitiativeComment = useCallback(
    async (actionItem: Partial<ActionPlanInitiativeCommentDto>) => {
      const response = await mutateCreateInitiativeComment({ data: actionItem });
      return response?.items[0]?.id;
    },
    [mutateCreateInitiativeComment]
  );

  const deleteInitiativeComment = useCallback(
    async (id: string) => {
      await mutateDeleteInitiativeComment({ id });
    },
    [mutateDeleteInitiativeComment]
  );

  const patchInitiativeComment = useCallback(
    async (id: string, comment: Partial<ActionPlanInitiativeCommentDto>) => {
      await mutatePatchInitiativeComment({ id, data: comment });
    },
    [mutatePatchInitiativeComment]
  );

  const initiativeComments = useMemo(
    () => initiativeCommentsResponse?.items || [],
    [initiativeCommentsResponse?.items]
  );

  return {
    initiativeComments,
    searchInitiativeCommentsLoading,
    initiativeCommentsLoading,
    createInitiativeComment,
    deleteInitiativeComment,
    patchInitiativeComment,
    refetchComments,
    searchInitiativeComments
  };
};

export default useInitiativeComments;
