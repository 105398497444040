import styled from '@emotion/styled';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';

import { SmallPrioritySelectionOptionContainer } from '../../InitiativesCard/InitiativesCardStyles';
import palette from '../../../theme/palette';

export const PriorityContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center'
});

export const PrioritySelectionContainer = styled(SmallPrioritySelectionOptionContainer)({
  cursor: 'pointer',
  height:"32px",
  borderRadius:"10px"
});

export const ArrowDownIcon = styled(KeyboardArrowDown)<{ open: boolean }>(({ open }) => ({
  transform: open && 'rotate(180deg)',
  transition: '.3s ease-in-out',
  color: palette.common.outlinedIcon
}));

const Title = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 700,
  fontSize: '14px',
  color: palette.common.darkBlue
});

const PrioritisationToolTitle = styled(Typography)({
  color: palette.green[1],
  textDecoration: 'underline',
  fontWeight: 600,
  fontSize: '12px'
});

const RedAsterisk = styled(Typography)({
  color: 'red'
});


const CheckCircle = styled(Box)<{
  checked: boolean;
  checkedColor: string;
}>(({ checked, checkedColor }) => ({
  background: 'white',
  borderRadius: '50%',
  height: '15px',
  width: '15px',
  border: checked && `5.6px solid ${checkedColor}`,
  margin: '6px'
}));

const SelectionTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '13px',
  color: palette.common.darkBlue
});

export {
  Title,
  PrioritisationToolTitle,
  RedAsterisk,
  CheckCircle,
  SelectionTitle
};
