import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Button, Paper, Stack, Typography, styled } from '@mui/material';

import { useVerifySignUp } from '../../api/generated';
import Head from '../../components/Head/Head';
import PasswordInput from '../../components/Input/PasswordInput';
import FormLayout from '../../components/Layouts/FormLayout';
import Loader from '../../components/Loader/Loader';
import useAuth from '../../hooks/useAuth';
import { toHome } from '../../services/linker';
import palette from '../../theme/palette';
import { UserTypes } from '../../utils/commonTypes';
import { generateEmptySpaces } from '../../utils/textFormater';

const AuthCard = styled(Paper)({
  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px',
  borderRadius: '3px',
  padding: 15,
  overflow: 'auto',
  marginBottom: '50px',
  maxHeight: '674px'
});

const SubmitButton = styled(Button)({
  padding: '0px 20px',
  width: '49%',
  height: '40px',
  background: palette.green[1],
  borderRadius: '10px',
  color: 'white',
  textOverflow: 'ellipsis',
  ':hover': {
    background: palette.green[1],
    textDecoration: 'none',
    transform: 'scale(1.03)',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
  }
});

const AuthSignUp = () => {
  const {
    handleSubmit,
    watch,
    register,
    formState: { errors }
  } = useForm({
    reValidateMode: 'onSubmit',
    shouldFocusError: true,
    mode: 'onBlur'
  });
  console.log(errors);

  const { signup } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { data, isLoading } = useVerifySignUp(
    { token },
    {
      query: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false
      }
    }
  );
  const userInfo = [
    {
      labelText: 'Company',
      defaultValue: data?.items[0]?.type !== UserTypes.JP ? data?.items[0]?.accounts?.[0].name : '',
      isVisible: data?.items[0]?.type !== UserTypes.JP
    },
    {
      labelText: 'Full Name',
      defaultValue: data ? `${data?.items[0]?.firstName} ${data?.items[0]?.lastName}` : '',
      isVisible: true
    },
    {
      labelText: 'Email',
      defaultValue: data?.items[0]?.email,
      isVisible: true
    }
  ];

  const onSubmit = async ({ password }: FieldValues) => {
    try {
      await signup({
        password: password,
        email: data?.items[0]?.email,
        registrationToken: token
      });
      navigate(toHome(), { state: { isFirstLogin: true } });
    } catch (error) {
      console.error(error);
    }
  };

  if (isLoading)
    return (
      <Box marginTop={30}>
        <Loader />
      </Box>
    );

  return (
    <AuthCard>
      <Head template="set account" />
      <FormLayout
        header={
          !data
            ? 'Your Invitation Expired'
            : data?.items[0]?.type !== UserTypes.JP
              ? 'Set Your Account'
              : 'Set Your Password'
        }
        subHeader={
          !data && 'Please contact support@thejourneyplatform.com to receive a new invitation'
        }
      >
        {data && data?.items[0]?.type !== UserTypes.JP && data?.items[0]?.accounts?.[0].logoUrl && (
          <Box my={4} textAlign="center">
            <img
              style={{ borderRadius: '30px', width: '100px' }}
              src={data?.items[0]?.accounts?.[0].logoUrl}
              alt="user logo"
            />
          </Box>
        )}
        <Box
          sx={{ mt: 2, width: '350px' }}
          component="form"
          noValidate
          autoComplete="off"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="flex-start"
          onSubmit={handleSubmit(onSubmit)}
        >
          {userInfo.map(
            ({ labelText, defaultValue, isVisible }) =>
              isVisible && defaultValue && (
                <Stack direction="row" alignItems="center" sx={{ mb: 1 }} key={labelText}>
                  <Typography>{labelText}:</Typography>
                  <Typography color={palette.grey[600]}>
                    {generateEmptySpaces(1) + defaultValue}
                  </Typography>
                </Stack>
              )
          )}
          <Stack width="100%" mt="10px" gap={2}>
            <PasswordInput
              errors={errors}
              register={register}
              registerName="password"
              placeholder="Password"
            />
            <PasswordInput
              errors={errors}
              register={register}
              registerName="confirmPassword"
              placeholder="Confirm password"
              rulesOptions={{
                validate: (val: string) => {
                  if (watch('password') != val) {
                    return 'Passwords are different';
                  }
                }
              }}
            />
          </Stack>
          <SubmitButton
            type="submit"
            size="medium"
            sx={{
              mx: 'auto',
              mt: 3
            }}
          >
            Set password
          </SubmitButton>
        </Box>
      </FormLayout>
    </AuthCard>
  );
};

export default AuthSignUp;
