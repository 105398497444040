import React from 'react';
import { Navigate, Outlet, matchPath, useLocation } from 'react-router-dom';

import { Stack, alpha } from '@mui/material';

import { UserDetailsType } from '../../api/generated';
import useAuth from '../../hooks/useAuth';
import useUserFeature from '../../hooks/useUserFeature';
import { RoutesMap, toChooseAccount, toHome, toLogin } from '../../services/linker';
import palette from '../../theme/palette';
import LogoLoader from '../Loader/LogoLoader';

export const Loader = () => (
  <Stack
    sx={{
      width: '100%',
      height: '100%',
      backgroundColor: alpha(palette.primary.main, 0.02),
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Stack>
      <LogoLoader />
    </Stack>
  </Stack>
);

export const ProtectedRoute = () => {
  const {
    userDetailsLoading,
    isAuthenticated,
    currentAccount,
    user,
    isLoadingAuthRefresh,
    signInLoading,
    accountSignInLoading
  } = useAuth();
  const location = useLocation();
  const { navigationPermissionMap, isFeatureBlocked } = useUserFeature();

  if (!isAuthenticated) {
    localStorage.setItem('lastRedirectedUrl', location.pathname + location.search);
    return <Navigate to={toLogin()} />;
  }

  if (!user || userDetailsLoading || isLoadingAuthRefresh || signInLoading || accountSignInLoading)
    return <Loader />;

  if (
    location.pathname !== RoutesMap.CHOOSE_ACCOUNT &&
    user.type === UserDetailsType.jp &&
    !currentAccount
  ) {
    return <Navigate to={toChooseAccount()} />;
  }

  // check if the current route is included in a blocked feature, redirect (hardcoded preventing jp's to access user settings)
  if (
    isFeatureBlocked(
      Object.values(navigationPermissionMap).find(navigationPermission =>
        matchPath(navigationPermission.route, location.pathname)
      )?.feature?.feature
    ) ||
    (location.pathname === RoutesMap.ACCOUNT_SETTINGS && user.type === UserDetailsType.jp)
  ) {
    return <Navigate to={toHome()} />;
  }

  return <Outlet />;
};
