import React, { useCallback } from 'react';

import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import {
  ActionPlanActionItemDto,
  SharedWorkspaceMeetingActivityDtoAction,
  SharedWorkspaceMeetingActivityDtoEntityType,
  SharedWorkspaceMeetingDto
} from '../../api/generated';
import useModal from '../../hooks/context-providers/useModal/useModal';
import useActionPlanActionItem from '../../hooks/useActionPlanActionItem';
import useSharedWorkspaceMeetingActivity from '../../hooks/useSharedWorkspaceMeetingActivity';
import AppPopover from '../../screens/ActionPlan/components/AppPopover/AppPopover';

const LazyLoadedAreYouSureModal = React.lazy(
  () => import('../../modals/AreYouSureModal/AreYouSureModal')
);

const LazyLoadedAttachToInitiativeModal = React.lazy(
  () => import('../../modals/AttachToInitiativeModal/AttachToInitiativeModal')
);

type TaskActionsProps = {
  anchorEl: HTMLElement;
  setAnchorEl: (element: HTMLElement) => void;
  currentTask: ActionPlanActionItemDto;
  setCurrentTask: (task: ActionPlanActionItemDto) => void;
  refetch: () => void;
  meeting?: SharedWorkspaceMeetingDto;
};

const TaskActions = ({
  anchorEl,
  setAnchorEl,
  currentTask,
  refetch,
  setCurrentTask,
  meeting
}: TaskActionsProps) => {
  const { showModal } = useModal();
  const { deleteActionPlanActionItem, patchActionPlanActionItem, createActionPlanActionItem } = useActionPlanActionItem();
  const { createActivity } = useSharedWorkspaceMeetingActivity();

  const deleteTaskClick = useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        setCurrentTask(null);
        modal.hide();
      },
      onConfirm: () => {
        deleteActionPlanActionItem(currentTask?.id).then(() => {
          if (meeting)
            createActivity({
              meetingId: meeting.id,
              entityType: SharedWorkspaceMeetingActivityDtoEntityType.task,
              entityId: currentTask?.id,
              entityName: currentTask?.title,
              action: SharedWorkspaceMeetingActivityDtoAction.delete
            });
          setCurrentTask(null);
          refetch();
        });
        modal.hide();
      },
      modalTitle: `Are you sure you want to delete \n${currentTask?.title}?`,
      confirmButtonTitle: 'Delete'
    });
  }, [
    currentTask,
    createActivity,
    meeting,
    setAnchorEl,
    setCurrentTask,
    deleteActionPlanActionItem,
    showModal,
    refetch
  ]);

  const archiveActionItemClick = useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        setCurrentTask(null);
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        patchActionPlanActionItem(currentTask?.id, { isArchived: true }).then(() => refetch());
        setAnchorEl(null);
        setCurrentTask(null);
        modal.hide();
      },
      modalTitle: `Are you sure you want to archive \n"${currentTask?.title}"?`,
      confirmButtonIcon: <ArchiveOutlinedIcon />,
      confirmButtonTitle: 'Archive'
    });
  }, [patchActionPlanActionItem, currentTask, refetch, showModal, setAnchorEl, setCurrentTask]);

  const duplicateClick = useCallback(() => {
    setAnchorEl(null);
    createActionPlanActionItem({ ...currentTask, title: `Copy of ${currentTask.title}` }).then(() => refetch());
  }, [createActionPlanActionItem, currentTask, refetch, setAnchorEl]);

  const unarchiveActionItemClick = useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        setCurrentTask(null);
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        patchActionPlanActionItem(currentTask?.id, { isArchived: false }).then(() => refetch());
        setAnchorEl(null);
        setCurrentTask(null);
        modal.hide();
      },
      modalTitle: `Are you sure you want to unarchive \n"${currentTask?.title}"?`,
      confirmButtonIcon: <UnarchiveOutlinedIcon />,
      confirmButtonTitle: 'Unarchive'
    });
  }, [patchActionPlanActionItem, currentTask, refetch, showModal, setAnchorEl, setCurrentTask]);

  const attachToInitiativeClick = useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedAttachToInitiativeModal, {
      onClose: () => {
        setCurrentTask(null);
        setAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        setCurrentTask(null);
        setAnchorEl(null);
        modal.hide();
      },
      actionItem: currentTask,
      refetch: refetch
    });
  }, [currentTask, refetch, showModal, setAnchorEl, setCurrentTask]);

  const actions = [
    {
      title: currentTask?.isArchived ? 'Unarchive' : 'Archive',
      icon: currentTask?.isArchived ? (
        <UnarchiveOutlinedIcon fontSize="small" />
      ) : (
        <ArchiveOutlinedIcon fontSize="small" />
      ),
      onAction: currentTask?.isArchived ? unarchiveActionItemClick : archiveActionItemClick
    },
    {
      title: "Duplicate",
      icon: <ContentCopyOutlinedIcon fontSize='small' />,
      onAction: duplicateClick
    }
  ];

  if (!currentTask?.initiativeId) {
    actions.push({
      title: 'Attach to Initiative',
      icon: <AccountTreeOutlinedIcon fontSize="small" />,
      onAction: attachToInitiativeClick
    });
  }

  return (
    <AppPopover
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      handleClose={() => {
        setCurrentTask(null);
        setAnchorEl(null);
      }}
      onDelete={deleteTaskClick}
      customActions={actions}
    />
  );
};

export default TaskActions;
