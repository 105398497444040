import React from "react";
import Card from "./Card/Card";

import { Droppable } from "react-beautiful-dnd";
import { BoardContainer, CardsContainer, Header, TasksCount } from "./KanbanBoardStyles";
import { ActionPlanActionItemDtoStatus } from "../../api/generated";
import { Skeleton } from "@mui/material";



export type TagData = {
  tagName: string;
  color: string;
}

export type CardData = {
  id: string;
  title: string;
  ownerId?: string;
  assigneeId?: string;
  dueDate?: string;
  status: ActionPlanActionItemDtoStatus;
  isArchived?: boolean;
  tags?: TagData[];
};

export type KanbanBoardData = {
  id: string;
  color: string;
  boardName: string;
  card: CardData[];
};

type KanbanBoardProps = {
  data: KanbanBoardData;
  loading: boolean;
  onCardClick: (id: string) => void;
  addCard: (title: string, bid: string) => void;
  removeCard: (boardId: string, cardId: string) => void;
};

const KanbanBoard = ({ loading, data, removeCard, onCardClick }: KanbanBoardProps) =>



(

  <BoardContainer
    sx={{
      backgroundColor: data.color
    }}>
    <Header>
      {data?.boardName}
      <TasksCount>({data.card?.length})</TasksCount>
    </Header>
    {loading ?
      ['i', 'am', 'a', 'skeleton', 'element', '!'].map((_, index) => (
        <Skeleton
          key={index}
          sx={{ marginTop: 1 }}
          variant="rounded"
          animation="wave"
          height={50} />
      ))
      :
      <Droppable droppableId={data.id.toString()}>
        {provided => (
          <CardsContainer
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {data.card?.map((cardItem, index) => (
              <Card
                boardId={data.id}
                id={cardItem.id}
                index={index}
                key={cardItem.id}
                removeCard={removeCard}
                card={cardItem}
                onClick={onCardClick}
              />
            ))}
            {provided.placeholder}
          </CardsContainer>
        )}
      </Droppable>
    }
  </BoardContainer>
);

export default KanbanBoard;