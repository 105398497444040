import React from 'react';

import { LayoutProvider } from '../components/Layouts/LayoutContext';
import { AcademyModeProvider } from '../hooks/context-providers/useAcademyMode';
import { AcountSettingsProvider } from '../hooks/context-providers/useAcountSettingsContext';
import { ActionPlanArchivedProvider } from '../hooks/context-providers/useActionPlanArchivedContext';
import { ActionPlanBoardProvider } from '../hooks/context-providers/useActionPlanBoardContext';
import { AppNotificationProvider } from '../hooks/context-providers/useAppNotificationContext';
import { AskJourneyDrawerProvider } from '../hooks/context-providers/useAskJourneyDrawer';
import { CompanyObjectivesToggleProvider } from '../hooks/context-providers/useCompanyObjectivesToggle';
import { ContactUsProvider } from '../hooks/context-providers/useContactUs';
import { InitiativeSidebarProvider } from '../hooks/context-providers/useInitiativeSidemenu';
import { MeetingNotesDrawerProvider } from '../hooks/context-providers/useMeetingNotesDrawer';

import { MetricSidebarViewProvider } from '../hooks/context-providers/useMetricSidebarView';
import ModalProvider from '../hooks/context-providers/useModal/ModalProvider';
import { SharedWorkspacePointSidebarProvider } from '../hooks/context-providers/useSharedWorkspacePointSidebar';
import { SharedWorkspaceSidebarProvider } from '../hooks/context-providers/useSharedWorkspaceSidemenu';
import { AppSidebarProvider } from '../hooks/context-providers/useSidebar';
import { SpaceMeetingNotesSidebarProvider } from '../hooks/context-providers/useSpaceMeetingNotesSidebar';
import { TaskSidebarProvider } from '../hooks/context-providers/useTaskSidebar';
import { WorkspaceSidebarProvider } from '../hooks/context-providers/useWorkspaceSidebar';
import AskJourneyDrawer from '../screens/AskJourney/components/AskJourneyDrawer';
import { ObjectiveSidebarProvider } from '../hooks/context-providers/useObjectiveSidebar';

export const ProtectedProviders = () => (
  <ActionPlanBoardProvider>
      <ActionPlanArchivedProvider>
        <AcountSettingsProvider>
          <AppSidebarProvider>
            <AppNotificationProvider>
              <ModalProvider>
                <AcademyModeProvider>
                  <CompanyObjectivesToggleProvider>
                    <AskJourneyDrawerProvider>
                      <WorkspaceSidebarProvider>
                        <SharedWorkspacePointSidebarProvider>
                          <ContactUsProvider>
                            <ObjectiveSidebarProvider>
                              <InitiativeSidebarProvider>
                                <SpaceMeetingNotesSidebarProvider>
                                  <SharedWorkspaceSidebarProvider>
                                    <TaskSidebarProvider>
                                      <MetricSidebarViewProvider>
                                        <MeetingNotesDrawerProvider>
                                          <LayoutProvider />
                                          <AskJourneyDrawer />
                                        </MeetingNotesDrawerProvider>
                                      </MetricSidebarViewProvider>
                                    </TaskSidebarProvider>
                                  </SharedWorkspaceSidebarProvider>
                                </SpaceMeetingNotesSidebarProvider>
                              </InitiativeSidebarProvider>
                            </ObjectiveSidebarProvider>
                          </ContactUsProvider>
                        </SharedWorkspacePointSidebarProvider>
                      </WorkspaceSidebarProvider>
                    </AskJourneyDrawerProvider>
                  </CompanyObjectivesToggleProvider>
                </AcademyModeProvider>
              </ModalProvider>
            </AppNotificationProvider>
          </AppSidebarProvider>
        </AcountSettingsProvider>
      </ActionPlanArchivedProvider>
  </ActionPlanBoardProvider>
);
