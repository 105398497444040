import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, IconButton, Stack, Typography, styled } from '@mui/material';

import useMeetingNotesDrawer from '../../hooks/context-providers/useMeetingNotesDrawer';
import useMeetings from '../../hooks/useMeetings';
import palette from '../../theme/palette';
import ActionItemsList from '../ActionItemsList/ActionItemsList';
import FollowUp from '../FollowUp/FollowUp';
import MeetingNotes from '../MeetingNotes/MeetingNotes';

const Container = styled(Stack)({
  height: "100%",
  padding: '24px 20px 20px',
  gap: '24px',
  width: '800px'
});

const HeaderTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 800,
  fontSize: '14px',
  color: palette.common.darkBlue
});

const ListCard = styled(Box)({
  padding: '16px',
  gap: '18px',
  border: `1px solid ${palette.border.grey}`,
  boxShadow: '0px 10px 20px rgba(28, 39, 76, 0.03)',
  borderRadius: '16px'
});

const FollowUpCard = styled(Stack)({
  border: `1px solid ${palette.border.grey}`,
  borderRadius: '16px'
});

const MeetingNoteDrawer = () => {
  const { isOpen, setIsOpen } = useMeetingNotesDrawer();
  const { liveMeetingDetails } = useMeetings();

  const from = Math.round(new Date(liveMeetingDetails?.meeting?.startTime).getTime());

  return (
    <>
      {liveMeetingDetails && (
        <Drawer
          sx={{ height: '100%' }}
          anchor="right"
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <Container>
            <Stack alignItems="center" justifyContent="space-between" direction="row">
              <Stack ml="7px" alignItems="center" direction="row">
                <Stack alignItems="center" justifyContent="center" color={palette.green[1]}>
                </Stack>
                <HeaderTitle ml="8px">Meeting summary</HeaderTitle>
              </Stack>
              <IconButton onClick={() => setIsOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <ListCard sx={{}}>
              <MeetingNotes
                title='Meeting Notes'
                alwaysShowToolbar={true}
                meeting={liveMeetingDetails?.meeting} />
            </ListCard>
            <ListCard >
              <ActionItemsList
                showStatusFiltering={false}
                timeFrame={{ from }}
                showInitiative={true}
              />
            </ListCard>
            <FollowUpCard>
              <FollowUp meeting={liveMeetingDetails.meeting} />
            </FollowUpCard>
          </Container>
        </Drawer>
      )}
    </>
  );
};

export default MeetingNoteDrawer;
