import { Stack, Typography, styled } from '@mui/material';
import palette from '../../theme/palette';

export const BoardContainer = styled(Stack)(() => ({
  minWidth: "150px",
  maxWidth:"300px",
  border: `1px solid ${palette.border.grey}`,
  borderRadius: "8px",
  padding: 4,
  color: palette.common.darkBlue
}));

export const Header = styled(Stack)(() => ({
  flexDirection: "row",
  alignItems: "center",
  fontWeight: "bold",
  gap: 5
}));

export const CardsContainer = styled(Stack)(() => ({
  marginTop: 5,
  width: "100%"
}));


export const TasksCount = styled(Typography)(() => ({
  fontSize: "12px",
  fontFamily: 'Plus Jakarta Sans',
  borderRadius: "15px 15px 15px 15px"
}));
