import { Input, InputBase, Stack, Typography, alpha, styled } from '@mui/material';

import palette from '../../theme/palette';

export const Container = styled(Stack)({
  gap: '10px',
  width: '100%'
});

export const Title = styled(Typography)({
  fontWeight: 700,
  fontSize: '14px'
});

export const AddPoint = styled(Input)({
  border: `1px solid ${palette.grey[1800]}`,
  padding: '5px 10px',
  borderRadius: '8px',
  input: { fontWeight: 400, fontSize: '14px', color: palette.common.darkBlue },
  textArea: { fontWeight: 400, fontSize: '14px', color: palette.common.darkBlue }
});

export const PointInput = styled(InputBase)<{
  hovered?: boolean;
}>(({ hovered }) => ({
  padding: 0,
  paddingLeft: 3,
  minHeight: '25px',
  paddingRight: 3,
  width: '95%',
  border: 'none',
  backgroundColor: hovered ? palette.background.grey : palette.background.default,
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px',
  borderRadius: '8px',
  '& .MuiInputBase-input': {
    cursor: 'pointer',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  color: palette.common.darkBlue,
  ':focus-within': {
    border: `1px solid ${palette.border.grey}`,
    backgroundColor: palette.background.default
  }
}));

export const PointTitle = styled(Typography)({
  fontWeight: 400,
  fontSize: '13px',
  wordBreak: 'break-word'
});

export const SpaceNameContainer = styled(Stack)({
  padding: '2px 3px',
  borderRadius: '5px',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '3px',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: alpha(palette.grey[3200], 0.5)
  }
});

export const SpaceName = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '11px',
  color: palette.blue[4],
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});

export const MoreSpaces = styled(Stack)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '15px',
  fontWeight: 'bold',
  borderRadius: '5px',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  ':hover': {
    backgroundColor: alpha(palette.grey[3200], 0.5)
  },
  color: palette.blue[4]
});

export const MoreSpacesTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '13px',
  color: palette.common.darkBlue
});
