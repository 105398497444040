import React, { ReactNode } from 'react';

import { Alert, AlertTitle, Button } from '@mui/material';
import { SnackbarContent, CustomContentProps, closeSnackbar } from 'notistack';

enum TOAST_VARIANTS {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning'
}

interface NotificationToastProps {
  caption: ReactNode;
  cta?: {
    onClick: () => void;
    label?: string;
  };
  variant: TOAST_VARIANTS;
  title?: ReactNode;
}

interface ToastCompleteProps extends CustomContentProps {
  customProps: NotificationToastProps;
}

declare module 'notistack' {
  interface VariantOverrides {
    custom: {
      customProps: NotificationToastProps;
    };
  }
}

const notificationToastCopy = {
  [`${TOAST_VARIANTS.SUCCESS}Title`]: 'Success',
  [`${TOAST_VARIANTS.ERROR}Title`]: 'Error',
  [`${TOAST_VARIANTS.INFO}Title`]: 'Info',
  [`${TOAST_VARIANTS.WARNING}Title`]: 'Warning'
};

const NotificationToast = React.forwardRef<HTMLDivElement, ToastCompleteProps>((props, ref) => {
  const { cta, variant, title, caption } = props.customProps;
  return (
    <SnackbarContent ref={ref}>
      <Alert
      
        sx={{ boxShadow: 3 }}
        severity={variant}
        onClose={() => closeSnackbar()}
        action={
          cta && (
            <Button color="inherit" size="small" onClick={cta.onClick}>
              {cta.label}
            </Button>
          )
        }
      >
        <AlertTitle>{title || notificationToastCopy[`${variant}Title`]}</AlertTitle>
        {caption}
      </Alert>
    </SnackbarContent>
  );
});

NotificationToast.displayName = 'NotificationToast';

export default NotificationToast;
export { TOAST_VARIANTS };
