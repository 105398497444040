import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ModuleMetadataDtoBusinessArea } from '../api/generated';
import businessAreasMetaData, { AppBusinessAreaMetadata } from '../mappings/businessAreasMetaData';

export interface AppBusinessArea {
  businessArea: ModuleMetadataDtoBusinessArea;
  currentBusinessArea: AppBusinessAreaMetadata;
}

const useBusinessArea = (): AppBusinessArea | null => {
  const { businessAreaKey } = useParams();

  const currentBusinessArea = useMemo(
    () => businessAreasMetaData[businessAreaKey as keyof typeof businessAreasMetaData],
    [businessAreaKey]
  );

  return {
    businessArea: businessAreaKey as keyof typeof businessAreasMetaData,
    currentBusinessArea
  };
};

export default useBusinessArea;
