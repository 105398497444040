import React, { useRef } from 'react';
import ReactQuill from 'react-quill';

import isPropValid from '@emotion/is-prop-valid';
import { Box, BoxProps, styled } from '@mui/material';

import palette from '../../../theme/palette';

const DynamicToolbarReactQuillContainer = styled(Box, { shouldForwardProp: isPropValid })<
  BoxProps & {
    $showBorder: boolean;
    $fontSize: string;
    $fontFamily: string;
    $fontColor: string;
    $cursor: string;
    $ellipsis: boolean;
  }
>`
  @keyframes fade_in_show {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  font-family: Plus Jakarta Sans;
  font-weight: 500;
  font-size: 13px;
  color: ${palette.common.darkBlue};

  .ql-toolbar {
    display: 'none';
    animation: fade_in_show 0.5s ease-in-out;
    border-top: ${({ $showBorder }) => !$showBorder && 0};
    border-right: ${({ $showBorder }) => !$showBorder && 0};
    border-left: ${({ $showBorder }) => !$showBorder && 0};
    border-radius: 3px 3px 0 0;
  }

  .ql-container {
    white-space: pre-line;
    color: ${({ $fontColor }) => ($fontColor ? $fontColor : palette.common.darkBlue)};
    font-family: ${({ $fontFamily }) => ($fontFamily ? $fontFamily : 'Plus Jakarta Sans')};
    font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : '13px')};
    border: ${({ $showBorder }) => ($showBorder ? '1px solid #ccc' : '0 !important')};
    border-radius: 3px;
    border-color: ${palette.border.grey};
  }

  .ql-editor {
    padding: 8px 0px;
  }

  .ql-editor p {
    cursor: ${({ $cursor }) => $cursor || 'auto'};
    text-overflow: ${({ $ellipsis }) => ($ellipsis ? 'ellipsis' : 'unset')};
    overflow: ${({ $ellipsis }) => ($ellipsis ? 'hidden' : 'visible')};
    white-space: ${({ $ellipsis }) => ($ellipsis ? 'nowrap' : 'normal')};
  }

  border-radius: 3px;
  border-top: ${({ $showBorder }) => $showBorder && '1px solid #ccc'};

  .ql-blank::before {
    color: ${palette.grey[500]};
    font-size: 14px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
  }
`;
interface FreeTextViewerProps {
  value?: string;
  showBorder?: boolean;
  fontSize?: string;
  fontFamily?: string;
  fontColor?: string;
  cursor?: string;
  ellipsis?: boolean;
  openContextMenu?: (anchorEl: null | HTMLElement, text: string) => void;
}

const FreeTextViewer = ({
  showBorder = false,
  fontSize,
  fontFamily,
  fontColor,
  cursor,
  value,
  ellipsis = false,
  openContextMenu,
  ...props
}: FreeTextViewerProps) => {
  const quillRef = useRef(null);

  return (
    <DynamicToolbarReactQuillContainer
      ref={quillRef}
      $showBorder={showBorder}
      $fontSize={fontSize}
      $fontFamily={fontFamily}
      $fontColor={fontColor}
      $cursor={cursor}
      $ellipsis={ellipsis}
      sx={{
        '& .wrapper': {
          borderRadius: '3px',
          minHeight: 110,
          '&:hover': { border: `1px solid ${palette.grey['700']}` },
          '&:focus-visible': { border: `1px solid ${palette.grey['700']}` }
        }
      }}
    >
      <ReactQuill
        {...props}
        modules={{ toolbar: false }}
        readOnly={true}
        onChangeSelection={(selection, source, editor) => {
          if (openContextMenu) {
            if (source !== 'silent' && selection && selection.length > 0) {
              const text = editor.getText(selection.index, selection.length);
              openContextMenu(quillRef.current.children?.[0], text);
            }
          }
        }}
        value={value}
      />
    </DynamicToolbarReactQuillContainer>
  );
};

export default React.memo(FreeTextViewer);
