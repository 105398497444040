import React from 'react';
import { Outlet } from 'react-router-dom';

import { Box, Stack, styled } from '@mui/material';

import JourneyLogoSection from '../../screens/AuthSignUp/components/JourneyLogoSection';
import palette from '../../theme/palette';

const Container = styled(Stack)({
  backgroundImage: `linear-gradient(325deg ,${palette.green[3]}  25%, ${palette.common.darkBlue} 75%)`
});

const AuthLayout = () => (
  <Container alignItems="center" justifyContent="center" height="100vh">
    <Box marginBottom="40px">
      <JourneyLogoSection />
    </Box>
    <Outlet />
  </Container>
);

export default AuthLayout;
