import React, { useState } from 'react';

import { alpha, IconButton, styled, Tooltip, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import DeleteIcon from '@mui/icons-material/Delete';
import palette from '../../theme/palette';
import { getFileType, getResourceFileExtenstionIcon } from '../../utils/filesUtil';
import { AttachmentDto } from '../../api/generated';
import useFiles from '../../hooks/useFiles';


const ResourceText = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 400,
  fontSize: '13px',
  color: palette.common.darkBlue,
  cursor: 'pointer',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: "220px",
  overflow: 'hidden'
});



interface ResourceProps {
  parentId: string;
  file: AttachmentDto;
  onDeleteFile: (file: AttachmentDto) => void;
}


const AttachmentFile = ({ parentId, file, onDeleteFile }: ResourceProps) => {
  const [hover, setHover] = useState<boolean>(false);
  const { generateDownloadUrl, deleteFile } = useFiles();

  const onDeleteClick = (file: AttachmentDto) => {
    deleteFile(parentId, file.name).then(() => onDeleteFile(file));
  }


  const downloadFile = () => {
    generateDownloadUrl(parentId, file.name).then(
      fileUrl => {
        try {
          fetch(fileUrl).then(
            response => {
              response.blob().then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = file.name;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
              });

            }
          );

        } catch (error) {
          console.error('Error downloading the file', error);
        }
      }
    );
  }


  return (

    <Stack
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        padding: 1,
        borderRadius: "8px",
        height: "32px",
        cursor: "pointer",
        ':hover': { backgroundColor: alpha(palette.grey[2000], 0.10) }
      }}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >

      <Stack
        onClick={() => downloadFile()}
        direction="row"
        alignItems="center"
        justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={1} >
          <Stack width="30px" minHeight="30px">
            {getResourceFileExtenstionIcon(getFileType(file.name))}
          </Stack>
          <Tooltip
            title={file.name}>
            <ResourceText>{file.name}</ResourceText>
          </Tooltip>

        </Stack>

      </Stack>


      {hover &&
        <IconButton
          onClick={() => onDeleteClick(file)}>
          <DeleteIcon sx={{ height: "20px", width: "20px" }} />
        </IconButton>

      }
    </Stack>


  );
};

export default React.memo(AttachmentFile);
