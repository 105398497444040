import React, { MouseEvent, useCallback, useEffect, useState } from 'react';

import { DeleteOutline } from '@mui/icons-material';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import { CircularProgress, IconButton, Stack, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDebouncedCallback } from 'use-debounce';

import { ActionPlanObjectiveDetailedDto, AttachmentDto } from '../../api/generated';
import useMetricSidebarView from '../../hooks/context-providers/useMetricSidebarView';
import useModal from '../../hooks/context-providers/useModal/useModal';
import useObjectiveSidebar from '../../hooks/context-providers/useObjectiveSidebar';
import useActionPlanObjective from '../../hooks/useActionPlanObjective';
import useAuth from '../../hooks/useAuth';
import useMetricDefinition from '../../hooks/useMetricDefinition';
import useObjectiveComments from '../../hooks/useObjectiveComments';
import AppPopover from '../../screens/ActionPlan/components/AppPopover/AppPopover';
import Editor from '../../screens/BizAreaModules/components/Editor';
import palette from '../../theme/palette';
import AddUpdate from '../AddUpdate/AddUpdate';
import Attachments from '../Attachments/Attachments';
import CommentsList from '../CommentsList/CommentsList';
import KeyResultView from '../KeyResultView/KeyResultView';
import TeamsSelect from '../Team/TeamsSelect';
import UserView from '../User/UserView';
import {
  InitiativePropertiesContainer,
  ObjectiveSidebarContainer,
  InitiativeInformation,
  InitiativeInfoContainer,
  ValueInput,
  InitiativeActions,
  ActionsContainer,
  MoreHorizIcon,
  CloseIcon,
  DescriptionEdtiorContainer,
  SectionTitle,
  GoToMetric
} from './ObjectiveSidebarStyles';
import SpaceInitiativesTable from '../../screens/Space/components/SpaceInitiativesTable/SpaceInitiativesTable';

const LazyLoadedAreYouSureModal = React.lazy(
  () => import('../../modals/AreYouSureModal/AreYouSureModal')
);

const LazyLoadedObjectiveKeyResultModal = React.lazy(
  () => import('../../modals/ObjectiveModal/ObjectiveKeyResultModal')
);

const LazyLoadedInitiativeModal = React.lazy(
  () => import('../../modals/InitiativeModal/InitiativeModal')
);

const ObjectiveSidebar = () => {
  const theme = useTheme();
  const {
    open,
    setOpen,
    currentObjective: selectedObjective,
    setCurrentObjective: setSelectedObjective,
    refetch: refetchData
  } = useObjectiveSidebar();

  const { setOpen: setOpenMetric, setCurrentMetric, setRefetch } = useMetricSidebarView();

  const [currentObjective, setCurrentObjective] =
    useState<ActionPlanObjectiveDetailedDto>(selectedObjective);
  const [includeArchived, setIncludeArchived] = useState<boolean>(false);

  const { detailedObjective, refetchDetailedObjective, updateActionPlanObjective, patchActionPlanObjective, deleteActionPlanObjective } = useActionPlanObjective(selectedObjective?.id, null, null, null, includeArchived);

  const [metricId, setMetricId] = useState<string>(currentObjective?.keyResult.metricId);
  useEffect(() => {
    if (detailedObjective) {

      setCurrentObjective(detailedObjective);
      setMetricId(detailedObjective.keyResult.metricId);
    }
  }, [detailedObjective]);

  const { metricDefinition: metric } = useMetricDefinition(metricId);

  const [titleValue, setTitleValue] = useState(currentObjective?.title);
  const [description, setDescription] = useState<string>(currentObjective?.description);
  const { showModal } = useModal();
  const [ownerId, setOwnerId] = useState<string>(currentObjective.ownerId);
  const [teamIds, setTeamIds] = useState<string[]>(currentObjective?.teamIds || []);

  const {
    comments,
    createObjectiveComment,
    deleteObjectiveComment,
    patchObjectiveComment,
    refetchComments
  } = useObjectiveComments(currentObjective?.id);

  const updateTeams = (teamIds: string[]) => {
    setTeamIds(teamIds);
    if (teamIds?.length > 0) {
      patchActionPlanObjective(currentObjective.id, { teamIds: teamIds }).then(() => refetch());
    } else {
      updateActionPlanObjective(currentObjective.id, {
        ...currentObjective,
        teamIds: null
      }).then(() => refetch());
    }
  };

  const { user } = useAuth();

  const refetch = useCallback(() => {
    if (refetchData) refetchData();
    refetchDetailedObjective();
  }, [refetchDetailedObjective, refetchData]);

  const createComment = async (content: string, taggedUserIds: string[]) => {
    await createObjectiveComment({
      userId: user.id,
      content: content,
      objectiveId: currentObjective.id,
      taggedUserIds: taggedUserIds
    });
  };

  const debouncedTitle = useDebouncedCallback((title: string) => {
    patchActionPlanObjective(currentObjective.id, { title }).then(() => refetch());
  }, 500);

  const editKrClick = useCallback(() => {
    const modal = showModal(LazyLoadedObjectiveKeyResultModal, {
      onClose: () => {
        modal.hide();
      },
      onCancel: () => {
        modal.hide();
      },
      onConfirm: () => {
        refetchData();
        modal.hide();
      },
      objective: currentObjective,
      openQuraterlyTargets: true
    });
  }, [showModal, currentObjective, refetchData]);

  const deleteObjectiveClick = useCallback(() => {
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        setEditAndDeleteAnchorEl(null);
        modal.hide();
      },
      onConfirm: (deleteMetric: boolean) => {
        deleteActionPlanObjective(currentObjective.id, deleteMetric)
          .then(() => refetch())
          .finally(() => setOpen(false));
        setEditAndDeleteAnchorEl(null);
        modal.hide();
      },
      ...(currentObjective.keyResult && {
        additionalProprty: {
          title: 'Delete Key Result metric',
          defaultValue: true
        }
      }),
      modalTitle: `Are you sure you want to delete \n"${currentObjective.title}"?`,
      confirmButtonIcon: <DeleteOutline />,
      confirmButtonTitle: 'Delete'
    });
  }, [currentObjective, deleteActionPlanObjective, refetch, setOpen, showModal]);

  const onClose = useCallback(() => {
    setSelectedObjective(null);
    setOpen(false);
  }, [setOpen, setSelectedObjective]);

  const archiveObjectiveClick = useCallback(() => {
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        setEditAndDeleteAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        patchActionPlanObjective(currentObjective.id, { isArchived: true }).then(() => refetch());
        setEditAndDeleteAnchorEl(null);
        modal.hide();
        onClose();
      },
      modalTitle: `Are you sure you want to archive \n"${currentObjective.title}"?`,
      confirmButtonIcon: <ArchiveOutlinedIcon />,
      confirmButtonTitle: 'Archive'
    });
  }, [onClose, patchActionPlanObjective, currentObjective, refetch, showModal]);

  const unarchiveObjectiveClick = useCallback(() => {
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        setEditAndDeleteAnchorEl(null);
        modal.hide();
      },
      onConfirm: () => {
        patchActionPlanObjective(currentObjective.id, { isArchived: false }).then(() => refetch());
        setEditAndDeleteAnchorEl(null);
        modal.hide();
        onClose();
      },
      modalTitle: `Are you sure you want to unarchive \n"${currentObjective.title}"?`,
      confirmButtonIcon: <UnarchiveOutlinedIcon />,
      confirmButtonTitle: 'Unarchive'
    });
  }, [onClose, patchActionPlanObjective, currentObjective, refetch, showModal]);

  const onAddInitiativeClick = useCallback(() => {
    const modal = showModal(LazyLoadedInitiativeModal, {
      onCancel: () => {
        modal.hide();
      },
      onConfirm: () => {
        refetch();
        modal.hide();
      },

      objective: currentObjective,
      businessArea: currentObjective.businessArea,
      ownerIdSuggestion: ownerId

    });
  }, [showModal, refetch, currentObjective, ownerId]);

  const [editAndDeleteAnchorEl, setEditAndDeleteAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const editAndDeletePopoverOpen = Boolean(editAndDeleteAnchorEl);

  const onClickActions = (event: MouseEvent<HTMLDivElement>) => {
    setEditAndDeleteAnchorEl(event.currentTarget);
  };

  const onChangeTitle = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setTitleValue(event.currentTarget.value);
    debouncedTitle(event.currentTarget.value);
  };

  const onBlurTitle = () => {
    patchActionPlanObjective(currentObjective.id, {
      title: titleValue
    }).then(() => refetch());
  };

  const updateOwnerId = (userId: string) => {
    setOwnerId(userId);
    if (userId) {
      patchActionPlanObjective(currentObjective.id, { ownerId: userId }).then(() => refetch());
    } else {
      updateActionPlanObjective(currentObjective.id, {
        ...currentObjective,
        ownerId: null
      }).then(() => refetch());
    }
  };

  const [descriptionUpdating, setDescriptionUpdating] = useState<boolean>(false);

  const updateDescription = useDebouncedCallback(
    (text: string) =>
      patchActionPlanObjective(currentObjective.id, { description: text }).then(() => {
        refetch();
        setDescriptionUpdating(false);
      }),
    1000
  );

  const [attachments, setAttachments] = useState<AttachmentDto[]>(
    currentObjective.attachments || []
  );

  const addAttachment = (name: string) => {
    patchActionPlanObjective(currentObjective.id, { attachments: [...attachments, { name }] }).then(
      () => {
        setAttachments([...attachments, { name }]);
        refetch();
      }
    );
  };

  const deleteAttachment = (name: string) => {
    const newList = attachments?.filter(att => att.name !== name);
    setAttachments(newList);
    patchActionPlanObjective(currentObjective.id, { attachments: newList }).then(() => refetch());
  };

  const openMeric = () => {
    setCurrentMetric(metric);
    setRefetch(() => refetch);
    setOpenMetric(true);
    setOpen(false);
  };

  return (
    <ObjectiveSidebarContainer anchor="right" open={open} onClose={onClose} theme={theme}>
      <Stack
        paddingRight={1}>
        <InitiativeInfoContainer
          paddingLeft={1}>
          <ValueInput
            multiline
            value={titleValue}
            onChange={onChangeTitle}
            placeholder="Type an initiative name"
            onBlur={onBlurTitle}
          />
          <InitiativeActions>
            <ActionsContainer onClick={onClickActions}>
              <MoreHorizIcon />
            </ActionsContainer>
            <ActionsContainer>
              <CloseIcon onClick={onClose} />
            </ActionsContainer>
          </InitiativeActions>
        </InitiativeInfoContainer>
        <Stack
          width="100%"
          direction="row"
          paddingLeft={1}
          gap={1}
          justifyContent="space-between"
          alignItems="center">
          <DescriptionEdtiorContainer
            width="95%">
            <Editor
              showBorder={false}
              value={description}
              defaultValue={description}
              onChange={content => {
                setDescription(content);
                setDescriptionUpdating(true);
                updateDescription(content);
              }}
              placeholder='Add a description...'
            />
          </DescriptionEdtiorContainer>
          {descriptionUpdating && <CircularProgress size={20} />}
        </Stack>
      </Stack>

      <InitiativeInformation>
        <InitiativePropertiesContainer>
          <Stack width="100%" gap="3px">

            <Stack marginTop={1}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center">
                  <SectionTitle>Key Result</SectionTitle>

                  <Tooltip title="Edit Key Result">
                    <IconButton onClick={() => editKrClick()}>
                      <EditOutlinedIcon sx={{ width: '18px', height: '18px' }} />
                    </IconButton>
                  </Tooltip>
                </Stack>

                <GoToMetric onClick={openMeric}>Go to Metric</GoToMetric>
              </Stack>

              <Stack paddingLeft={1}>
                <KeyResultView
                  includeChart
                  objective={currentObjective}
                  refetch={refetch}
                  header={
                    <Stack
                      width="100%"
                      direction="row"
                      alignItems="start"
                      gap={1}
                      paddingLeft="20px"
                    >
                      <Stack paddingTop="3px">
                        <UserView
                          tooltip="Owner"
                          onlyAvatar
                          size="40px"
                          userId={ownerId}
                          onChange={updateOwnerId}
                        />
                      </Stack>
                      <TeamsSelect size="40px" tooltip teamIds={teamIds} onChange={updateTeams} />
                    </Stack>
                  }
                />
              </Stack>
            </Stack>
          </Stack>
        </InitiativePropertiesContainer>

        <Stack height="100%" gap={3}>
          <Stack>
            <SectionTitle>Initiatives</SectionTitle>


            <SpaceInitiativesTable
              includeOwner
              includeTeams
              includeSuggestions
              includeArchived={includeArchived}
              setIncludeArchived={setIncludeArchived}
              initiatives={currentObjective?.initiatives}
              objective={currentObjective}
              refetch={() => { refetchData(); refetch(); }}
              onAddClick={onAddInitiativeClick}
              small
            />
          </Stack>

          <Stack marginTop={2} gap={2}>
            <SectionTitle>Attachments</SectionTitle>
            <Attachments
              parnetId={currentObjective.id}
              attachments={attachments}
              onUpload={addAttachment}
              onDelete={name => deleteAttachment(name)}
            />
          </Stack>


        </Stack>
      </InitiativeInformation>

      <Stack
        paddingTop={1}
        paddingLeft="10px"
        minHeight="500px"
        marginTop={2}
        sx={{ backgroundColor: palette.grey[1500] }}>
        <SectionTitle>Comments</SectionTitle>
        <AddUpdate refetch={refetchComments} createComment={createComment} />
        <Stack paddingLeft="10px">
          <CommentsList
            patchComment={patchObjectiveComment}
            comments={comments}
            deleteComment={deleteObjectiveComment}
            refetchComments={refetchComments}
          />
        </Stack>
      </Stack>
      <AppPopover
        anchorEl={editAndDeleteAnchorEl}
        open={editAndDeletePopoverOpen}
        handleClose={() => setEditAndDeleteAnchorEl(null)}
        onDelete={deleteObjectiveClick}
        customActions={[
          {
            title: currentObjective.isArchived ? 'Unarchive' : 'Archive',
            icon: currentObjective.isArchived ? (
              <UnarchiveOutlinedIcon fontSize="small" />
            ) : (
              <ArchiveOutlinedIcon fontSize="small" />
            ),
            onAction: currentObjective.isArchived ? unarchiveObjectiveClick : archiveObjectiveClick
          }
        ]}
      />
    </ObjectiveSidebarContainer>
  );
};

export default ObjectiveSidebar;
