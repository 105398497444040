import { Stack, styled } from '@mui/material';

import palette from '../../../../theme/palette';

export const Container = styled(Stack)({
  paddingTop: 20,
  gap: 10,
  flexDirection: "row",
  alignItems: "start",
  justifyContent: "space-between",
  color: palette.common.darkBlue
});

