import React from 'react';

import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { Tooltip } from '@mui/material';

import { SuggestionsButtonContainer } from './SuggestionsButtonStyles';

export type SuggestionsButtonProps = {
  opened: boolean;
  isLoading: boolean;
  onClick: () => void;
};

const SuggestionsButton = ({ opened, isLoading, onClick }: SuggestionsButtonProps) => (
  <SuggestionsButtonContainer opened={opened} onClick={onClick}>
    <Tooltip title={!opened ? 'See Suggestions' : 'Close'}>
      <AutoAwesomeIcon
        sx={{
          color: 'white',
          fontSize: '12px',
          ...(isLoading ? { transform: 'rotate(360deg)' } : {}),
          transition: 'transform 3s linear'
        }}
      />
    </Tooltip>
    {/* <SuggestionsText >
      {!opened ? 'See Suggestions' : 'Close'}
    </SuggestionsText> */}
  </SuggestionsButtonContainer>
);

export default SuggestionsButton;
