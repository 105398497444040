import React, { FC } from 'react';

import { Menu as MuiMenu, MenuProps as MuiMenuProps } from '@mui/material';

export { default as MenuItem } from './MenuItem';

export enum MenuSize {
  Small = 200,
  Large = 300
}

export enum MenuArrowPosition {
  RIGHT = 'right',
  CENTER = 'center'
}

interface MenuProps extends Omit<MuiMenuProps, 'open'> {
  arrowPosition?: MenuArrowPosition;
  size?: MenuSize;
  Component: React.ElementType;
}

const Menu: FC<MenuProps> = ({
  children,
  MenuListProps,
  arrowPosition = MenuArrowPosition.CENTER,
  size = MenuSize.Small,
  Component
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isArrowCenter = arrowPosition === MenuArrowPosition.CENTER;

  return (
    <>
      <Component onClick={e => setAnchorEl(e.currentTarget)} open={open} />
      <MuiMenu
        anchorEl={anchorEl}
        open={open}
        onClick={() => setAnchorEl(null)}
        MenuListProps={MenuListProps}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: arrowPosition
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            mt: 3.75,
            overflow: 'visible',
            boxShadow: 3,
            minWidth: size,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: isArrowCenter ? '18%' : 32,
              width: 16,
              height: 16,
              backgroundColor: 'background.paper',
              transform: `translate(${isArrowCenter ? '50%' : 0}, -50%) rotate(45deg)`,
              zIndex: 0
            }
          }
        }}
      >
        {children}
      </MuiMenu>
    </>
  );
};

export default Menu;
