import IsPropValid from '@emotion/is-prop-valid';
import { Box, Stack, Typography, styled } from '@mui/material';

import palette from '../../theme/palette';

const Title = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '14px',
  color: palette.common.darkBlue
});

const PrioritisationToolTitle = styled(Typography)({
  color: palette.green[1],
  textDecoration: 'underline',
  fontWeight: 600,
  fontSize: '12px'
});

const RedAsterisk = styled(Typography)({
  color: 'red'
});

const BigPrioritySelectionOptionContainer = styled(Stack, { shouldForwardProp: IsPropValid })<{
  backgroundColor: string;
}>(({ backgroundColor }) => ({
  background: backgroundColor,
  flexDirection: 'row',
  alignItems: 'center',
  padding: '4px 12px 4px 12px',
  gap: '4px',
  width: 'fit-content',
  height: '48px',
  borderRadius: '16px',
  cursor: 'pointer'
}));

const CheckCircle = styled(Box, { shouldForwardProp: IsPropValid })<{
  checked: boolean;
  checkedColor: string;
}>(({ checked, checkedColor }) => ({
  background: 'white',
  borderRadius: '50%',
  height: '15px',
  width: '15px',
  border: checked && `5.6px solid ${checkedColor}`,
  margin: '6px'
}));

const SelectionTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '13px',
  color: palette.common.darkBlue
});

export {
  Title,
  PrioritisationToolTitle,
  RedAsterisk,
  BigPrioritySelectionOptionContainer,
  CheckCircle,
  SelectionTitle
};
