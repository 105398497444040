import { Components } from '@mui/material/styles';

const MuiTypography: Components['MuiTypography'] = {
  styleOverrides: {
    root: {
      fontFamily: 'Plus Jakarta Sans'
    }
  }
};

export default MuiTypography;
