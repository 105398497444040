import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PowerIcon from '@mui/icons-material/Power';
import { Avatar, AvatarGroup, Paper, Stack, Typography } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';


import { ReactComponent as JourneyLogo } from '../../assets/journey.svg';
import { ReactComponent as JourneyLogoShort } from '../../assets/logoShort.svg';
import useAuth from '../../hooks/useAuth';
import { toHome, toIntegrations } from '../../services/linker';
import palette from '../../theme/palette';
import NavigationList from './NavigationList';

import useUserFeature from '../../hooks/useUserFeature';
import { UserDetailsType, UserFeatureFeature } from '../../api/generated';

import useNavigationListManifest, { NavigationItemsKey } from '../../hooks/useNavigationListManifest';

export interface SidebarProps {
  isOpen: boolean;
  onOpen?: (isSidebarOpen: boolean) => void;
  rotationDegree?: number;
}

export const sidebarWidth = { open: 238, closed: 65 };

const Sidebar = () => {

  const { user, currentAccount } = useAuth();
  const { isFeatureBlocked } = useUserFeature();
  const [isSidebarOpened, setIsSidebarOpened] = React.useState(false);
  const [onOptionClicked, setOnOptionClicked] = React.useState(false);
  const navigate = useNavigate();
  const navList = useNavigationListManifest();

  return (
    <MuiDrawer
      variant="permanent"
      onMouseEnter={() => !onOptionClicked && setIsSidebarOpened(true)}
      onMouseLeave={() => { setOnOptionClicked(false); setIsSidebarOpened(false); }}

      PaperProps={{
        style: {
          width: isSidebarOpened ? sidebarWidth.open : sidebarWidth.closed,
          overflow: 'hidden'
        }
      }}
      sx={{

        zIndex: 10000,
        '& .MuiPaper-root': {
          border: "none",

          backgroundColor: palette.common.darkBlue
        }
      }}
    >

      <Stack
        alignItems="center"
        justifyContent="space-between"
        height="100%">
        <Stack
          width="100%"
          alignItems="center"
        >


          <Stack alignItems="center" gap="18px">
            <Stack
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingTop: "20px"
              }}
            >
              {isSidebarOpened ? (
                <Link
                  style={{ marginTop: '-40px', marginBottom: '-30px', textDecoration: 'none' }}
                  to={toHome()}
                >
                  <JourneyLogo width={120} height={112} />
                </Link>
              ) : (
                <Link style={{ marginTop: '-10px', textDecoration: 'none' }} to={toHome()}>
                  <JourneyLogoShort width={52} height={52} />
                </Link>
              )}
            </Stack>
            <Stack
              gap="3px"
              alignItems="center" >


              <AvatarGroup
                sx={{
                  '& .MuiAvatar-root': {
                    border: `2px solid ${palette.common.black}`,
                    boxSizing: 'border-box'
                  }
                }}
              >

                <Avatar
                  onClick={() => navigate(toHome())}
                  sx={{ width: 45, height: 45, cursor: "pointer" }}
                  alt="user avatar"
                  src={currentAccount?.logoUrl}
                  component={Paper}
                  elevation={5}
                />

              </AvatarGroup>


              <Stack
                height="10px">


                {isSidebarOpened && <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '12.5px',
                    color: palette.green[1]
                  }}
                >
                  {currentAccount?.name}
                </Typography>}
              </Stack>
            </Stack>

          </Stack>

          <NavigationList
            list={navList}
            containerSx={{
              mt: '30px',
              gap: '7px',
              '& :not(:first-of-type)': {
                mt: '6px'
              }
            }}
            onClick={() => { setIsSidebarOpened(false); setOnOptionClicked(true); }}
            isOpen={isSidebarOpened}
          />
        </Stack>


        {!isFeatureBlocked(UserFeatureFeature.settings) && user.type !== UserDetailsType.jp &&
          <Stack
            width="100%"
            alignItems="center"
            marginBottom="30px"
          >
            <NavigationList
              list={[
                {
                  label: "Integrations",
                  icon: <PowerIcon />,
                  slug: toIntegrations(),
                  key: NavigationItemsKey.Integrations
                }
              ]}
              containerSx={{
                mt: '30px',
                gap: '7px',
                '& :not(:first-of-type)': {
                  mt: '6px'
                }
              }}
              onClick={() => { setIsSidebarOpened(false); setOnOptionClicked(true); }}
              isOpen={isSidebarOpened}
            />

          </Stack>}

      </Stack>

    </MuiDrawer>
  );
};

export default React.memo(Sidebar);
