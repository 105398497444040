export default {
  fontFamily: ['Brown LL', 'sans-serif', 'Plus Jakarta Sans'].join(','),
  h1: {
    fontSize: '1.75rem',
    lineHeight: '28.8px',
    fontWeight: 'bold'
  },
  h2: {
    fontSize: '1.5rem',
    lineHeight: '28.8px',
    fontWeight: 400
  },
  h3: {
    fontSize: '1.125rem',
    lineHeight: '28.8px',
    fontWeight: 400
  },
  body1: {
    fontSize: '1.125rem',
    fontWeight: 400
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: 400
  },
  body3: {
    fontSize: '0.75rem',
    fontWeight: 400
  },
  base: {
    fontSize: '1rem'
  }
};
