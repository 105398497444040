import { useCallback, useMemo } from 'react';

import {
  ActionPlanBoardDto,
  UserFeatureFeature,
  useCreateActionPlanBoard,
  useDeleteActionPlanBoard,
  useListActionPlanBoards,
  usePatchActionPlanBoard,
  usePatchActionPlanMainBoard,
  useUpdateActionPlanBoard
} from '../api/generated';
import useUserFeature from './useUserFeature';

export const useActionPlanBoard = (includeArchived?: boolean) => {
  const { isFeatureBlocked } = useUserFeature();
  const isActionPlanEnabled = !isFeatureBlocked(UserFeatureFeature.actionPlan);


  const { data: boardsData, isLoading: boardListLoading, refetch: refetchBoardList } = useListActionPlanBoards(
    {
      includeArchived: includeArchived ? includeArchived : null
    },
    {
      query: {
        queryKey: ['actionPlanBoards', includeArchived],
        enabled: isActionPlanEnabled
      }
    });

  const { mutateAsync: mutateCreateBoard } = useCreateActionPlanBoard();

  const { mutateAsync: mutatePatchBoard } = usePatchActionPlanBoard();
  const { mutateAsync: mutatePatchMainBoard } = usePatchActionPlanMainBoard();
  const { mutateAsync: mutateUpdateBoard } = useUpdateActionPlanBoard();
  const { mutateAsync: mutateDeleteActionPlanBoard } = useDeleteActionPlanBoard();

  const createBoard = useCallback(
    async (board: Partial<ActionPlanBoardDto>) => {
      const response = await mutateCreateBoard({ data: board });
      return response?.items[0];
    },
    [mutateCreateBoard]
  );

  const deleteBoard = useCallback(
    async (id: string) => {
      await mutateDeleteActionPlanBoard({ id });
    },
    [mutateDeleteActionPlanBoard]
  );

  const patchBoard = useCallback(
    async (id: string, board: Partial<ActionPlanBoardDto>) => {
      await mutatePatchBoard({ id, data: board });
    },
    [mutatePatchBoard]
  );

  const updateBoard = useCallback(
    async (id: string, board: Partial<ActionPlanBoardDto>) => {
      await mutateUpdateBoard({ id, data: board });
    },
    [mutateUpdateBoard]
  );

  const patchMainBoard = useCallback(
    async (board: Partial<ActionPlanBoardDto>) => {
      await mutatePatchMainBoard({ data: board });
    },
    [mutatePatchMainBoard]
  );

  const boardList = useMemo(() => boardsData?.items || [], [boardsData?.items]);

  return {
    boardList,
    boardListLoading,
    refetchBoardList,
    createBoard,
    patchBoard,
    updateBoard,
    patchMainBoard,
    deleteBoard
  };
};

export default useActionPlanBoard;
