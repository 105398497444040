import React, { useState } from 'react';

import { InputAdornment, Popover, Stack } from '@mui/material';
import { unabbreviateNumber } from 'js-abbreviation-number';

import { Goal } from '../../api/generated';
import useActionPlanInitiative from '../../hooks/useActionPlanInitiative';
import useMetricDefinition from '../../hooks/useMetricDefinition';
import { keyResultTypes } from '../../modals/ObjectiveModal/ObjectiveModal';
import { getAbbreviateNumber } from '../../utils/numberUtil';
import { CancelValueButton, CurrentValueInput, SaveValueButton, UnitIcon } from './GoalViewStyles';

type CurrentValuePopoverProps = {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  initiativeId: string;
  goal: Goal;
  updateCurrentValue: (value: number) => void;
};

const CurrentValuePopover = ({
  goal,
  initiativeId,
  anchorEl,
  updateCurrentValue,
  open,
  onClose
}: CurrentValuePopoverProps) => {
  const { patchActionPlanInitiative } = useActionPlanInitiative();

  const currentValueStr = getAbbreviateNumber(goal.currentValue, 2) || '0';

  const [localCurrentValue, setLocalCurrentValue] = useState<string>(currentValueStr);

  const { calcMetricDefinitionProgress } = useMetricDefinition();

  const onSave = () => {
    const newValue = unabbreviateNumber(localCurrentValue || '0', ['', 'k', 'm', 'b']);

    patchActionPlanInitiative(initiativeId, {
      goal: {
        unit: goal.unit,
        value: goal.value,
        subject: goal.subject,
        startingPoint: goal.startingPoint,
        currentValue: newValue
      }
    }).then(() => {
      updateCurrentValue(newValue);
      calcMetricDefinitionProgress(goal.metricId);
      onClose();
    });
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      sx={{ borderRadius: '8px', filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.13))' }}
    >
      <Stack padding={1}>
        <CurrentValueInput
          value={localCurrentValue}
          disableUnderline
          onChange={e => setLocalCurrentValue(e.target.value.replace(/[^kmb.\d-]/g, ''))}
          startAdornment={
            <InputAdornment position="start">
              <UnitIcon>
                {Object.values(keyResultTypes).find(type => type.value === goal.unit)?.icon}
              </UnitIcon>
            </InputAdornment>
          }
        />

        <Stack direction="row" alignItems="center" marginTop="3px" alignSelf="end" gap={1}>
          <CancelValueButton onClick={onClose}>Cancel</CancelValueButton>
          <SaveValueButton onClick={onSave} disabled={localCurrentValue === currentValueStr}>
            Save
          </SaveValueButton>
        </Stack>
      </Stack>
    </Popover>
  );
};

export default CurrentValuePopover;
