import IsPropValid from '@emotion/is-prop-valid';
import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Dialog,
  InputBase,
  InputLabel,
  Select,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material';

import palette from '../../theme/palette';

export const CustomDialog = styled(Dialog)({
  margin: 'auto',
  '.MuiPaper-root': {
    padding: '20px',
    gap: '20px',
    width: '800px',
    background: '#FFFFFF',
    border: '1px solid #EFF3F4',
    boxShadow: '0px 10px 20px rgba(28, 39, 76, 0.03)',
    borderRadius: '16px'
  }
});

export const DropDownSelect = styled(Select)(() => ({
  backgroundColor: 'transparent',
  minWidth: '220px',
  marginTop: '8px',
  borderRadius: '8px',
  border: `1px solid ${palette.border.grey}`,
  '& .MuiSelect-select': {
    width: '100%'
  }
}));

export const HeaderBlock = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between'
});

export const RowBlock = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center'
});

export const CloseIcon = styled(Close)({
  cursor: 'pointer',
  ':hover': { transform: 'scale:1.5' }
});

export const EmptyObjective = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px',
  color: theme.palette.grey[1100]
}));

export const GoalInputBlock = styled(Stack)({
  width: '60%'
});

export const ButtonContainer = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingTop: '10px',
  borderTop: `1px solid ${theme.palette.grey[1800]}`
}));

export const Title = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 700,
  fontSize: '18px',
  color: palette.common.darkBlue
});

export const TitleDescription = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 600,
  fontSize: '14px',
  marginTop: '4px',
  color: palette.common.darkBlue
});

export const InputTitle = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '14px',
  color: palette.common.darkBlue
});

export const DescriptionContainer = styled(Stack)({
  borderRadius: '8px',
  border: `1px solid ${palette.border.grey}`,
  minHeight: '48px'
});

export const TimeframeText = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '13px',
  color: palette.grey[800]
});

export const RedAsterisk = styled(Typography)({
  color: 'red'
});

export const ValueInput = styled(InputBase)({
  padding: '12px 16px',
  width: '100%',
  minHeight: '48px',
  background: 'white',
  border: `1px solid ${palette.border.grey}`,
  borderRadius: '8px',
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '13px',
  color: palette.common.darkBlue,

  '& .MuiInputBase-input': {
    '&::placeholder': {
      fontFamily: "'Plus Jakarta Sans'"
    }
  }
});

export const PlanValueInput = styled(InputBase)({
  all: 'unset',
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '13px',
  color: palette.common.darkBlue
});

export const SelectInputContainer = styled(Stack)({
  height: '40px',
  width: '90px',
  justifyContent: 'center',
  flexDirection: 'row',
  alignItems: 'center',
  background: 'white',
  border: `1px solid ${palette.border.grey}`,
  borderRadius: '8px',

  '.MuiInputBase-root': {
    padding: 0,
    background: 'white'
  }
});

export const KeyResultTypeSelection = styled(Select)({
  '.MuiOutlinedInput-notchedOutline': { border: 0, padding: 0 },
  '& .MuiOutlinedInput-input': {
    marginLeft: '14px'
  }
});

export const CancelText = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '14px',
  color: palette.grey[1100],
  cursor: 'pointer'
});

export const Category = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 700,
  fontSize: '14px',
  color: palette.grey[1100]
});

export const CreateButton = styled(Stack, { shouldForwardProp: IsPropValid })<{
  disabled: boolean;
}>(({ disabled }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 24px',
  width: '140px',
  height: '42px',
  color: 'white',
  opacity: disabled ? 0.4 : 1,
  background: disabled ? '#DBE1E2' : palette.green[1],
  borderRadius: '8px',
  cursor: disabled ? 'not-allowed' : 'pointer',
  ':hover': {
    transform: !disabled && 'scale(1.06)'
  }
}));

export const InitiaiveModalInputLabel = styled(InputLabel)({
  fontSize: '12px',
  marginBottom: '0px'
});

export const MonthsDuration = styled(InputBase)({
  height: '40px',
  width: '60px',
  pl: '50px',
  paddingTop: 0,
  textAlign: 'center',
  paddingBottom: 0,
  paddingRight: 10,
  justifyContent: 'space-around',
  alignItems: 'center',
  border: `1px solid ${palette.border.grey}`,
  borderRadius: '8px',
  fontSize: '15px'
});

export const AutoCompleteBlock = styled(Autocomplete)({
  '& .MuiFormControl-root': {
    margin: 0
  },
  '& .MuiTextField-root': {
    padding: 0
  },
  '& .MuiInputBase-root': {
    padding: 0,
    display: 'flex',
    alignItems: 'center'
  },
  '& .MuiAutocomplete-inputRoot': {
    height: '48px'
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: '14px',
    fontFamily: "'Plus Jakarta Sans'"
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
});

export const TextInputSelect = styled(TextField)(() => ({
  border: 'none',
  resize: 'none',
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '8px',
    padding: 0,
    border: `1px solid ${palette.border.grey}`,
    backgroundColor: palette.background.paper,

    '& .MuiOutlinedInput-input': {
      padding: 2,
      paddingLeft: 15,
      '&::placeholder': {
        color: palette.grey[600],
        fontSize: '13px',
        fontFamily: "'Plus Jakarta Sans'"
      }
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
      }
    }
  }
}));

export const DropDownContainer = styled(Stack)({
  paddingTop: '6px',
  width: '100%'
});
