import { Stack, styled } from '@mui/material';

import { StatusTitle } from '../../InitiativesCard/InitiativesCardStyles';

export const InitiativeStatusLabelWrapper = styled(Stack)({
  flexDirection: 'row'
});

export const InitiativeStatusLabel = styled(StatusTitle)({
  marginLeft: '6px'
});

export const InitiativeStatusCircleWrapper = styled(Stack)({
  marginTop: '6px'
});
