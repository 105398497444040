import React from 'react';

import { AccountUserDto } from '../../../../../../api/generated';
import { OwnerInfoBlock } from '../../../../../../components/SelectUserAutoComplete/SelectUserOption/SelectUserOptionStyles';
import { OwnerAvatar } from '../../../../../../modals/ActionItemModal/ActionItemModalStyles';
import { OwnerTitle } from './SelectOwnerOptionStyles';

type Props = {
  owner: AccountUserDto;
};

const SelectOwnerOption = ({ owner }: Props) => (
  <OwnerInfoBlock>
    <OwnerAvatar name={owner.firstName} variant="rounded">
      {owner && owner.firstName[0].toLocaleUpperCase()}
    </OwnerAvatar>
    <OwnerTitle>{`${owner.firstName} ${owner.lastName}`}</OwnerTitle>
  </OwnerInfoBlock>
);

export default SelectOwnerOption;
