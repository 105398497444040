import styled from '@emotion/styled';
import { LoadingButton } from '@mui/lab';
import { Input, Paper, Typography } from '@mui/material';

import palette from '../../theme/palette';

const AuthCard = styled(Paper)({
  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 25px 50px -12px',
  borderRadius: '3px',
  padding: 4,
  overflow: 'hidden',
  marginBottom: '50px',
  width: '400px'
});

const InputLabel = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 700,
  fontSize: '13px',
  color: palette.common.darkBlue
});

const SubmitButton = styled(LoadingButton)({
  padding: '0px 20px',
  width: '49%',
  height: '40px',
  background: palette.green[1],
  borderRadius: '10px',
  color: 'white',
  textOverflow: 'ellipsis',
  ":disabled": {
    background:palette.border.grey,
    color:palette.secondary.main
  },
  ':hover': {
    background: palette.green[1],
    textDecoration: 'none',
    transform: 'scale(1.03)',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
  }
});

const ErrorTitle = styled(Typography)({
  color: 'red',
  fontSize: '13px',
  fontWeight: '400'
});

export const ValueInput = styled(Input)({
  padding: '10px 12px 12px 12px',
  height: '42px',
  width:"180px",
  textAlign: 'center',
  background: 'white',
  border: `1px solid ${palette.border.grey}`,
  borderRadius: '8px',
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 700,
  fontSize: '18px',

  color: palette.common.darkBlue
});

export { AuthCard, InputLabel, SubmitButton, ErrorTitle };
