import React, { ReactNode } from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Divider, ListItemIcon, Menu, PopoverOrigin, SxProps } from '@mui/material';

import { ActionTitle } from '../../../../components/ObjectiveCard/ObjectiveCardStyles';
import { Item } from './AppPopoverStyles';

type CustomAction = {
  title: string;
  icon: ReactNode;
  onAction: () => void;
};

type AppPopoverProps = {
  anchorEl: Element;
  open: boolean;
  handleClose: () => void;
  onDelete?: () => void;
  onEdit?: () => void;
  onView?: () => void;
  viewIcon?: ReactNode;
  viewTitle?: string;
  customActions?: CustomAction[];
  anchorOrigin?: PopoverOrigin;
  sx?: SxProps;
};

const AppPopover = ({
  anchorEl,
  open,
  handleClose,
  onDelete,
  onEdit = null,
  onView = null,
  viewIcon,
  viewTitle,
  anchorOrigin,
  customActions,
  sx
}: AppPopoverProps) => (
  <Menu
    id="edit-objective-menu"
    aria-labelledby="edit-objective-button"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    autoFocus={false}
    disableEnforceFocus={true}
    disableAutoFocusItem={true}
    disableAutoFocus={true}
    anchorOrigin={anchorOrigin ? anchorOrigin : {
      vertical: 22,
      horizontal: 'left'
    }}
    sx={{
      zIndex: 10000000,
      filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.13))',
      '& .MuiPaper-root': { borderRadius: '6px', ...sx }
    }}
  >
    {customActions &&
      customActions.length > 0 &&
      customActions.map((action, index) => (
        <div key={action.title}>
          <Item
            sx={{ padding: "0px 5px" }}
            onClick={event => {
              event.stopPropagation();
              action.onAction();
            }}
          >
            <ListItemIcon>{action.icon}</ListItemIcon>
            <ActionTitle>{action.title}</ActionTitle>
          </Item>
          {(onEdit || onDelete || onView || index + 1 !== customActions.length) && <Divider />}
        </div>
      ))}

    {onEdit != null && (
      <div>
        <Item
          sx={{ padding: "0px 5px" }}
          onClick={event => {
            event.stopPropagation();
            onEdit();
          }}
        >
          <ListItemIcon
          >
            <ModeEditOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ActionTitle>Edit</ActionTitle>
        </Item>
        {(onView || onDelete) && <Divider />}
      </div>
    )}

    {onView && (
      <div>
        <Item
          sx={{ padding: "0px 5px" }}
          onClick={onView}>
          <ListItemIcon>{viewIcon || <VisibilityIcon fontSize="small" />}</ListItemIcon>
          <ActionTitle>{viewTitle || 'View'}</ActionTitle>
        </Item>
        <Divider />
      </div>
    )}

    {onDelete && (
      <div>
        <Item
          sx={{ padding: "0px 5px" }}
          onClick={event => {
            event.stopPropagation();
            onDelete();
          }}
        >
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ActionTitle>Delete</ActionTitle>
        </Item>
      </div>
    )}
  </Menu>
);

export default AppPopover;
