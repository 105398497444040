import React, { ForwardRefExoticComponent, RefAttributes } from 'react';
import { MenuItem as MuiMenuItem, MenuItemProps as MuiMenuItemProps } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

interface MenuItemProps extends MuiMenuItemProps {
  children: React.ReactNode;
  to?: string;
  component?: ForwardRefExoticComponent<LinkProps & RefAttributes<HTMLAnchorElement>>;
}
const MenuItem = ({ children, to, ...nextProps }: MenuItemProps) => (
  <MuiMenuItem {...nextProps}>
    {to ? (
      <Link style={{width:"100%",  textDecoration: 'none', color: 'inherit' }} to={to}>
        {children}
      </Link>
    ) : (
      children
    )}
  </MuiMenuItem>
);

export default MenuItem;
