import { useCallback, useMemo } from 'react';

import {
  SharedWorkspaceMeetingDto,
  StartMeetingRequest,
  useDeleteSharedWorkspaceMeeting,
  useEndSharedWorkspaceMeeting,
  useGetSharedWorkspaceLastMeeting,
  useGetSharedWorkspaceMeeting,
  useListSharedWorkspaceMeetings,
  usePatchSharedWorkspaceMeeting,
  useStartSharedWorkspaceMeeting
} from '../api/generated';

const useSharedWorkspaceMeeting = (sharedWorkspaceId?: string, meetingId?: string) => {
  const {
    data: meetingsResponse,
    isLoading: meetingsLoading,
    refetch: refetchMeetings
  } = useListSharedWorkspaceMeetings(sharedWorkspaceId, {
    query: {
      queryKey: ['sharedWorkspaceMeetings', sharedWorkspaceId],
      enabled: !!sharedWorkspaceId
    }
  });

  const {
    data: meetingResponse,
    isLoading: meetingLoading,
    refetch: refetchMeeting
  } = useGetSharedWorkspaceMeeting(sharedWorkspaceId, meetingId, {
    query: {
      queryKey: ['sharedWorkspaceMeeting', sharedWorkspaceId, meetingId],
      enabled: !!sharedWorkspaceId && !!meetingId
    }
  });

  const {
    data: lastMeetingResponse,
    isLoading: lastMeetingLoading
  } = useGetSharedWorkspaceLastMeeting(sharedWorkspaceId, {
    query: {
      queryKey: ['sharedWorkspaceLastMeeting', sharedWorkspaceId],
      enabled: !!sharedWorkspaceId
    }
  });

  const { mutateAsync: mutateStartSharedWorkspaceMeeting } = useStartSharedWorkspaceMeeting();
  const { mutateAsync: mutateEndSharedWorkspaceMeeting } = useEndSharedWorkspaceMeeting();
  const { mutateAsync: mutatePatchSharedWorkspaceMeeting } = usePatchSharedWorkspaceMeeting();
  const { mutateAsync: mutateDeleteSharedWorkspaceMeeting } = useDeleteSharedWorkspaceMeeting();

  const startMeeting = useCallback(
    async (sharedWorkspaceId: string, data: StartMeetingRequest) => {
      const response = await mutateStartSharedWorkspaceMeeting({ id: sharedWorkspaceId, data });
      return response?.items?.[0];
    },
    [mutateStartSharedWorkspaceMeeting]
  );

  const endMeeting = useCallback(
    async (sharedWorkspaceId: string, meetingId: string) => {
      await mutateEndSharedWorkspaceMeeting({ id: sharedWorkspaceId, meetingId: meetingId });
    },
    [mutateEndSharedWorkspaceMeeting]
  );

  const patchMeeting = async (
    id: string,
    meetingId: string,
    data: Partial<SharedWorkspaceMeetingDto>
  ) => await mutatePatchSharedWorkspaceMeeting({ id, meetingId, data });


  const deleteSharedWorkspaceMeeting = useCallback(
    async (sharedWorkspaceId: string, meetingId: string) => {
      await mutateDeleteSharedWorkspaceMeeting({ id: sharedWorkspaceId, meetingId: meetingId });
    },
    [mutateDeleteSharedWorkspaceMeeting]
  );

  const meetings = useMemo(() => meetingsResponse?.items || [], [meetingsResponse]);

  const meeting = useMemo(() => meetingResponse?.items?.[0] || null, [meetingResponse]);

  const lastMeeting = useMemo(() => lastMeetingResponse?.items?.[0] || null, [lastMeetingResponse]);

  return {
    meetings,
    meetingsLoading,
    refetchMeetings,
    meeting,
    meetingLoading,
    refetchMeeting,
    startMeeting,
    endMeeting,
    patchMeeting,
    lastMeeting,
    lastMeetingLoading,
    deleteSharedWorkspaceMeeting
  };
};

export default useSharedWorkspaceMeeting;
