import { Close } from '@mui/icons-material';
import {  Stack, Typography, styled } from '@mui/material';
import palette from '../../theme/palette';
import IsPropValid from '@emotion/is-prop-valid';

export const CloseIcon = styled(Close)(() => ({
  cursor: 'pointer',
  zIndex: 2,
  padding: 1,
  ':hover': { backgroundColor: palette.background.grey },
  borderRadius: '6px'
}));

export const SendButton = styled(Stack, { shouldForwardProp: IsPropValid })<{ disabled: boolean }>(
  ({ disabled }) => ({
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px 24px',
    width: '100px',
    fontWeight:500,
    height: '35px',
    color: 'white',
    opacity: disabled ? 0.4 : 1,
    background: disabled ? palette.grey[1800] : palette.green[1],
    borderRadius: '8px',
    cursor: disabled ? 'not-allowed' : 'pointer',
    ':hover': {
      opacity: !disabled && 0.8
    }
  })
);

export const Title = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize:"16px",
  color: palette.primary.main
}));
