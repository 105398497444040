import { useCallback, useMemo } from 'react';
import { TeamDto, useCreateTeam, useDeleteTeam, useGetTeam, useListMyTeams, useListTeams, usePatchTeam } from '../api/generated';



const useTeams = (teamId?: string) => {
  const { data: teamsData, refetch: refetchTeams, isLoading: isTeamsLoading } = useListTeams();

  const { data: myTeamsData } = useListMyTeams();
  const teams = teamsData?.items || [];
  const myTeams = myTeamsData?.items || [];

  const { data: teamData, refetch: refetchTeam, isLoading: isTeamLoading } =
    useGetTeam(teamId, {
      query: { queryKey: ['getTeam', teamId], enabled: !!teamId }
    });

  const team = useMemo(
    () => teamData?.items[0],
    [teamData]
  );

  const { mutateAsync: mutateCreateTeam } = useCreateTeam();
  const { mutateAsync: mutatePatchTeam } = usePatchTeam();
  const { mutateAsync: mutateDeleteTeam } = useDeleteTeam();


  const deleteTeam = useCallback(
    async (id: string) => {
      await mutateDeleteTeam({ id });
    },
    [mutateDeleteTeam]
  );

  const createTeam = useCallback(
    async (data: Partial<TeamDto>) => {
      const response = await mutateCreateTeam({ data: data });
      return response?.items?.[0];
    },
    [mutateCreateTeam]
  );



  const patchTeam = useCallback(
    async (id: string, user: Partial<TeamDto>) => {
      await mutatePatchTeam({ id, data: { ...user } });
    },
    [mutatePatchTeam]
  );



  return {
    teams,
    myTeams,
    refetchTeams,
    isTeamsLoading,
    createTeam,
    patchTeam,
    deleteTeam,
    team,
    refetchTeam,
    isTeamLoading
  };
};

export default useTeams;
