import IsPropValid from '@emotion/is-prop-valid';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Stack, Typography, styled } from '@mui/material';

import palette from '../../theme/palette';

const Container = styled(Stack, { shouldForwardProp: IsPropValid })<{ done?: boolean }>(
  ({ done }) => ({
    padding: '5px 8px 4px',
    width: '245px',
    background: 'white',
    opacity: done && 0.5,
    boxShadow: '0px 10px 20px rgba(28, 39, 76, 0.03)',
    borderRadius: '8px'
  })
);

const ProgressTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 600,
  fontSize: '11px',
  color: palette.grey[1100]
});

const BigPrioritySelectionOptionContainer = styled(Stack, { shouldForwardProp: IsPropValid })<{
  backgroundColor: string;
}>(({ backgroundColor }) => ({
  background: backgroundColor,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '2px 5px',
  width: '90px',
  height: '20px',
  borderRadius: '16px'
}));

const SmallPrioritySelectionOptionContainer = styled(Stack, { shouldForwardProp: IsPropValid })<{
  backgroundColor: string;
}>(({ backgroundColor }) => ({
  background: backgroundColor,
  flexDirection: 'row',
  alignItems: 'center',
  padding: '2px 5px',
  gap: '4px',
  width: 'fit-content',
  height: '24px',
  borderRadius: '16px',
  cursor: 'pointer'
}));

const CheckCircle = styled(Box, { shouldForwardProp: IsPropValid })<{
  checkedColor: string;
}>(({ checkedColor }) => ({
  background: 'white',
  borderRadius: '50%',
  height: '12px',
  width: '12px',
  border: `4px solid ${checkedColor}`,
  margin: '6px'
}));

const SelectionTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '13px',
  color: palette.common.darkBlue,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});

const DoneIcon = styled(CheckIcon)({
  color: palette.green[1]
});

const StatusTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '12px',
  color: palette.grey[1100]
});

const StatusCircle = styled(Box, { shouldForwardProp: IsPropValid })<{
  circleColor: string;
}>(({ circleColor }) => ({
  background: circleColor,
  borderRadius: '50%',
  marginRight: '6px',
  height: '7px',
  width: '7px'
}));

const InitiativeGoalAndTimeRangeContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '5px 8px',
  gap: 0,
  height: '26px',
  background: palette.blue[8],
  borderRadius: '4px',
  cursor: 'pointer'
});

const InitiativeGoalAndTimeRangeTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 600,
  fontSize: '12px',
  color: palette.grey[1100]
});

const InitiativeDetailedTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 800,
  fontSize: '14px',
  color: palette.common.darkBlue,
  overflowWrap: 'break-word',
  whiteSpace: 'normal'
});

const ActionItemCountTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '13px',
  color: palette.grey[1100]
});

const OwnerAndDateContainer = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '10px',
  height: '42px',
  borderTop: `1px solid ${palette.grey[200]}`
});

const OwnerAndDateTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '11px',
  color: palette.grey[1100],
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  maxWidth: '80px'
});

const ActionsContainer = styled(Stack)({
  background: 'white',
  borderRadius: '6px',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer'
});

const DescriptionAndTitleContainer = styled(Stack)({
  gap: '5px',
  borderRadius: '8px'
});

export {
  Container,
  BigPrioritySelectionOptionContainer,
  SmallPrioritySelectionOptionContainer,
  CheckCircle,
  SelectionTitle,
  DoneIcon,
  StatusTitle,
  StatusCircle,
  InitiativeGoalAndTimeRangeContainer,
  InitiativeGoalAndTimeRangeTitle,
  InitiativeDetailedTitle,
  ActionItemCountTitle,
  OwnerAndDateContainer,
  OwnerAndDateTitle,
  ProgressTitle,
  ActionsContainer,
  DescriptionAndTitleContainer
};
