enum ResourceFileTypes {
  GOOGLE_SHEETS = 'googleSheets',
  GOOGLE_DOCS = 'googleDocs',
  GOOGLE_SLIDES = 'googleSlides',
  PDF = 'pdf',
  IMAGE_PNG = 'image/png',
  IMAGE_JPEG = 'image/jpeg',
  DRIVE_FOLDER= 'application/vnd.google-apps.folder',
  DRIVE_DOC = 'application/vnd.google-apps.document',
  DRIVE_SHEET = 'application/vnd.google-apps.spreadsheet',
  DRIVE_PDF = 'application/pdf',
  DRIVE_SLIDE = 'application/vnd.google-apps.presentation',
  OFFICE_DOC = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  OFFICE_EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  OFFICE_POWER_POINT = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
}

export default ResourceFileTypes;
