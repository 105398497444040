import isPropValid from '@emotion/is-prop-valid';
import { ChatBubbleOutline, KeyboardArrowDown } from '@mui/icons-material';
import { Close, MoreHoriz } from '@mui/icons-material';
import {
  CSSObject,
  CircularProgress,
  InputBase,
  MenuItem,
  Select,
  Stack,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  alpha,
  styled
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';

import { ActionPlanActionItemDtoStatus } from '../../api/generated';
import palette from '../../theme/palette';
import { InitiativeGoalAndTimeRangeContainer } from '../InitiativesCard/InitiativesCardStyles';

export const SidebarModal = styled(MuiDrawer, { shouldForwardProp: isPropValid })<{
  whenOpen?: CSSObject;
}>(({ whenOpen, theme }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  scrollbarWidth: 'none',
  borderLeft: `1px solid ${theme.palette.border.grey}`,
  boxShadow: '0px 10px 20px rgba(28, 39, 76, 0.03)',
  '& ::-webkit-scrollbar': {
    width: 0,
    height: 0
  },
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box'
  },
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.secondary.main
  },
  ...{
    ...whenOpen,
    '& .MuiDrawer-paper': whenOpen
  }
}));

export const ContentBlock = styled(Stack)({
  height: '100%'
});

export const TaskPropertiesContainer = styled(Stack)(({ theme }) => ({
  padding: '0px 12px 10px',
  background: theme.palette.secondary.main,
  gap: 10,
  paddingBottom: '30px',
  backgroundColor: 'white'
}));

export const GoalProgressContainer = styled(Stack)({
  padding: '0px 12px 10px'
});

export const HeaderBlock = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
});

export const RowBlock = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '10px',
  gap: '11px'
});

export const KeyResultDescriptionContainer = styled(Stack)({
  paddingLeft: '16px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
});

export const InitiativePropertyTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '18px',
  color: palette.grey[1100]
});

export const KeyboardArrow = styled(KeyboardArrowDown, { shouldForwardProp: isPropValid })<{
  priorityPopoverOpen?: boolean;
}>(({ priorityPopoverOpen }) => ({
  transform: priorityPopoverOpen && 'rotate(180deg)',
  transition: '.3s ease-in-out'
}));

export const BusinessAreaTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '18px',
  color: palette.grey[1100]
});

export const KeyResultBlock = styled(Stack)({
  marginLeft: '16px',
  marginBottom: '12px'
});

export const StatusBlock = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center'
});

export const DropDownSelect = styled(Select)(() => ({
  backgroundColor: 'transparent',
  width: '100%',
  borderRadius: '8px',
  border: `1px solid ${palette.border.grey}`,
  padding: '12px 8px',
  gap: '10px',
  height: '32px',
  background: 'white',
  '.MuiOutlinedInput-notchedOutline': { border: 0, padding: 0 }
}));

export const InitiativeActionItemsAndCommentsContainer = styled(Stack)({
  height: '100%',
  marginTop: '15px',
  zIndex: 2
});

export const ChatBubbleIcon = styled(ChatBubbleOutline)({
  fontSize: '15px',
  marginTop: '3px'
});

export const TabTitleBlock = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center'
});

export const TabActionItems = styled(Stack)({
  padding: '8px 24px'
});

export const TabInitiative = styled(Stack)(({ theme }) => ({
  background: theme.palette.grey[1500],
  height: '100%'
}));

export const CircleProgress = styled(CircularProgress)({
  margin: 'auto'
});

const SIDEBAR_WIDTH = 650;
const whenOpen = ({ transitions: { create, easing, duration } }: Theme): CSSObject => ({
  width: SIDEBAR_WIDTH,
  transition: create('width', {
    easing: easing.sharp,
    duration: duration.enteringScreen
  }),
  overflowX: 'hidden'
});

export const Container = styled(MuiDrawer)<{ theme: Theme }>(({ theme }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  scrollbarWidth: 'none',
  borderLeft: `1px solid ${palette.border.grey}`,
  boxShadow: '0px 10px 20px rgba(28, 39, 76, 0.03)',
  '& ::-webkit-scrollbar': {
    width: 0,
    height: 0
  },
  '& .MuiDrawer-paper': {
    boxSizing: 'border-box'
  },
  '& .MuiPaper-root': {
    backgroundColor: palette.grey[1500],
    width: "770px"
  },
  ...{
    ...whenOpen(theme),
    '& .MuiDrawer-paper': whenOpen(theme)
  }
}));

export const ActionItemListContainer = styled(Stack)({
  paddingRight: 3,
  paddingLeft: 3,
  paddingTop: 1,
  paddingBottom: 1
});

export const LoaderContainer = styled(Stack)({
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center'
});

export const InitiativeCommentsListContainer = styled(Stack)({
  background: palette.grey[1500],
  height: '100%'
});

export const ChangeFormsContainer = styled(Stack)({
  paddingLeft: 2,
  paddingRight: 2,
  marginTop: '15px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
});

export const InitiativeGoalAndTimeRange = styled(InitiativeGoalAndTimeRangeContainer)({
  padding: '4px',
  paddingLeft: '5px',
  gap: 0
});

export const InitiativeTimeFrame = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center'
});

export const StatusDropDownSelect = styled(DropDownSelect)({
  marginLeft: '11px',
  width: '130px'
});

export const TaskInfoContainer = styled(Stack)({
  marginTop: 10,
  flexDirection: 'row',
  alignItems: 'start',
  justifyContent: 'space-between'
});

export const ValueInput = styled(InputBase)({
  padding: '0px 10px',
  width: '100%',
  minHeight: '41px',
  border: 'none',
  ':hover': { backgroundColor: palette.background.grey },
  borderRadius: '8px',
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 700,
  fontSize: '20px',
  color: palette.common.darkBlue,
  ':focus-within': { background: 'white', border: `1px solid ${palette.border.grey}` }
});

export const MoreHorizIcon = styled(MoreHoriz)(({ theme }) => ({
  cursor: 'pointer',
  zIndex: 2,
  padding: 1,
  ':hover': { backgroundColor: theme.palette.background.grey },
  borderRadius: '6px'
}));

export const ActionsContainer = styled(Stack)({
  flexDirection: 'row',
  gap: '8px'
});

export const TaskActions = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px'
});

export const CloseIcon = styled(Close)(({ theme }) => ({
  cursor: 'pointer',
  zIndex: 2,
  padding: 1,
  ':hover': { backgroundColor: theme.palette.background.grey },
  borderRadius: '6px'
}));

export const DescriptionEdtiorContainer = styled(Stack)({
  minHeight: '45px',
  marginLeft: 2,
  borderRadius: '8px',
  ':hover': { border: `1px solid ${palette.border.grey}` },
  ':focus-within': { border: `1px solid ${palette.border.grey}` }
});

export const SectionTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  paddingLeft: '10px',
  fontWeight: 'bold',
  fontSize: '16px',
  color: palette.common.darkBlue
});

export const InitiativeTitle = styled(Typography)({
  paddingLeft: '10px',
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px',
  color: palette.grey[1100],
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});

export const StatusSelect = styled(Select)<{
  status?: ActionPlanActionItemDtoStatus;
}>(({ status }) => ({
  width: '120px',
  '& .MuiOutlinedInput-input:read-only': {
    backgroundColor:
      status === ActionPlanActionItemDtoStatus.completed
        ? alpha(palette.primary.main, 0.2)
        : status === ActionPlanActionItemDtoStatus.inProgress
          ? alpha(palette.blue[8], 0.9)
          : alpha(palette.grey[1400], 0.2),
    padding: '8px 1px',
    borderRadius: '6px'
  },

  '&.MuiInputBase-root': {
    padding: '0px',
    textAlign: 'center'
  },

  fontSize: '11px',
  fontFamily: 'Plus Jakarta Sans',
  borderRadius: '6px',
  '.MuiOutlinedInput-notchedOutline': { border: 0, padding: 0 }
}));

export const StatusOption = styled(MenuItem)<{
  color: string;
}>(({ color }) => ({
  color: palette.grey[700],
  height: '25px',
  fontSize: '11px',
  fontFamily: 'Plus Jakarta Sans',
  '&:hover': { backgroundColor: color },
  '&.Mui-selected': {
    backgroundColor: color,
    '&:hover': { backgroundColor: color }
  }
}));

export const DueDate = styled(Typography, { shouldForwardProp: isPropValid })<{
  expired?: boolean;
}>(({ expired }) => ({
  fontFamily: 'Plus Jakarta Sans',
  width: 'max-content',
  color: expired ? palette.red[2] : palette.grey[1100],
  fontSize: '11px',
  fontWeight: 500,
  ':hover': {
    opacity: 0.7,
    backgroundColor: palette.background.paper,
    padding: 1,
    borderRadius: '6px',
    border: `0.5px solid ${palette.border.grey}`
  }
}));

export const Title = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '12px',
  color: palette.common.darkBlue
});


export const VisibilityToggle = styled(ToggleButtonGroup)(() => ({
  backgroundColor: palette.blue[5],
  height: "22px",
  borderRadius: '20px',
  gap: "4px",
  padding: '3px',
  '& .MuiToggleButton-root': {
    border: 'none',
    '&.Mui-selected': {
      backgroundColor: 'white',
      color: palette.common.darkBlue,
      fontWeight: 'bold'
    },
    '&:hover': {
      backgroundColor: 'white'
    },
    "&.MuiToggleButtonGroup-firstButton": {
      borderRadius: '20px'
    },
    "&.MuiToggleButtonGroup-lastButton": {
      borderRadius: '20px'
    }
  }
}));

export const VisibilityToggleButton = styled(ToggleButton)(() => ({
  borderRadius: "30px",
  border: "none",
  padding:"5px 10px",
  alignItems: "center",
  textTransform: 'none',
  color: palette.grey[3400],
  fontFamily: 'Plus Jakarta Sans',
  fontSize: "10px",
  '&.Mui-selected': {
    backgroundColor: 'white',
    color: 'black'
  },
  '&:hover': {
    backgroundColor: 'white'
  }
}));

