import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  UpdateMeetingFollowUpMutationBody,
  UpdateMeetingInsightsRequest,
  UpdateMeetingNotesMutationBody,
  UserFeatureFeature,
  useGetLatestMeeting,
  useGetLiveMeeting,
  useGetMeetingDetails,
  useGetMeetingRecording,
  useGetMeetingTranscript,
  useListMeetings,
  useSendMeetingFollowUps,
  useStartMeeting,
  useUpdateMeetingFollowUp,
  useUpdateMeetingInsights,
  useUpdateMeetingNotes
} from '../api/generated';
import useUserFeature from './useUserFeature';

const useMeetings = (isVideoLoading?: boolean) => {
  const { meetingId: currentMeetingId } = useParams();
  const { isFeatureBlocked } = useUserFeature();
  const meetingsFeatureEnabled = useMemo(
    () => !isFeatureBlocked(UserFeatureFeature.meetings),
    [isFeatureBlocked]
  );

  const {
    data: currentMeetingDetails,
    isLoading: currentMeetingDetailsLoading,
    refetch: refetchCurrentMeetingDetails
  } = useGetMeetingDetails(currentMeetingId, {
    query: { queryKey: ['currentMeeting', currentMeetingId], enabled: !!currentMeetingId }
  });

  const { data: currentMeetingVideoData, isLoading: currentMeetingVideoDataLoading } =
    useGetMeetingRecording(currentMeetingId, {
      query: {
        queryKey: ['currentMeetingVideo', currentMeetingId],
        enabled: !!currentMeetingId && !!isVideoLoading
      }
    });

  const { data: currentMeetingTranscriptData, isLoading: currentMeetingTranscriptDataLoading } =
    useGetMeetingTranscript(currentMeetingId, {
      query: {
        queryKey: ['currentMeetingTranscript', currentMeetingId],
        enabled: !!currentMeetingId
      }
    });

  const { data: latestMeetingResponse, isLoading: latestMeetingLoading } = useGetLatestMeeting({
    query: {
      queryKey: ['latestMeeting'],
      enabled: meetingsFeatureEnabled
    }
  });

  const { mutateAsync: startMeeting } = useStartMeeting();

  const {
    data: liveMeetingResponse,
    isLoading: liveMeetingLoading,
    refetch: refetchLiveMeeting
  } = useGetLiveMeeting({
    query: { queryKey: ['liveMeeting'], enabled: meetingsFeatureEnabled }
  });

  const [liveMeeting] = useMemo(() => liveMeetingResponse?.items || [], [liveMeetingResponse]);

  const {
    data: liveMeetingDetailsData,
    refetch: refetchLiveMeetingDetails,
    isLoading: liveMeetingDetailsLoading
  } = useGetMeetingDetails(liveMeeting?.meetingId, {
    query: {
      queryKey: ['liveMeetingDetails', liveMeeting?.meetingId],
      enabled: !!liveMeeting?.meetingId && meetingsFeatureEnabled
    }
  });

  const [liveMeetingDetails] = useMemo(
    () => liveMeetingDetailsData?.items || [],
    [liveMeetingDetailsData?.items]
  );

  const { data: meetingsData, isLoading: meetingsLoading } = useListMeetings({
    query: { queryKey: ['meetings'], enabled: meetingsFeatureEnabled }
  });

  const { mutateAsync: updateInsight } = useUpdateMeetingInsights();

  const updateMeetingInsights = useCallback(
    async (meetingId: string, value: UpdateMeetingInsightsRequest['insights']) => {
      await updateInsight({ meetingId, data: { insights: value } });
    },
    [updateInsight]
  );

  const { mutateAsync: updateFollowUp } = useUpdateMeetingFollowUp();

  const updateMeetingFollowUp = useCallback(
    async (meetingId: string, value: UpdateMeetingFollowUpMutationBody['followUp']) => {
      await updateFollowUp({ meetingId, data: { followUp: value } });
    },
    [updateFollowUp]
  );

  const { mutateAsync: updateNotes } = useUpdateMeetingNotes();

  const updateMeetingNotes = useCallback(
    async (meetingId: string, value: UpdateMeetingNotesMutationBody['content']) => {
      await updateNotes({ meetingId, data: { content: value } });
    },
    [updateNotes]
  );

  const { mutateAsync: sendFollowUp, isPending: sendFollowUpLoading } = useSendMeetingFollowUps();

  const sendMeetingFollowUp = useCallback(
    async (meetingId: string) => {
      await sendFollowUp({ meetingId });
    },
    [sendFollowUp]
  );

  const meetings = useMemo(() => meetingsData?.items || [], [meetingsData?.items]);

  const latestMeeting = latestMeetingResponse?.items?.[0];

  return {
    meetings,
    meetingsLoading,
    currentMeetingDetails,
    currentMeetingDetailsLoading,
    startMeeting,
    updateMeetingInsights,
    updateMeetingFollowUp,
    updateMeetingNotes,
    sendMeetingFollowUp,
    sendFollowUpLoading,
    liveMeeting,
    liveMeetingLoading,
    liveMeetingDetails,
    refetchLiveMeetingDetails,
    liveMeetingDetailsLoading,
    latestMeeting,
    latestMeetingLoading,
    refetchLiveMeeting,
    refetchCurrentMeetingDetails,
    currentMeetingVideoData,
    currentMeetingVideoDataLoading,
    currentMeetingTranscriptData,
    currentMeetingTranscriptDataLoading
  };
};

export default useMeetings;
