import React, { SetStateAction, createContext, useContext } from 'react';

import useStorage, { STORAGE_TYPE } from '../useStorage';

type CompanyObjectivesToggleStateContext = {
  show: boolean;
  setShow: React.Dispatch<SetStateAction<boolean>>;
};

export const CompanyObjectivesToggleContext =
  createContext<CompanyObjectivesToggleStateContext | null>(null);

const useCompanyObjectivesToggleMode = () => useContext(CompanyObjectivesToggleContext);
export const CompanyObjectivesToggleProvider = ({ children }: { children: React.ReactNode }) => {
  const [show, setShow] = useStorage<boolean>(
    STORAGE_TYPE.LOCAL,
    'isCompanyObjectivesVisible',
    true
  );

  const value = { show, setShow };

  return (
    <CompanyObjectivesToggleContext.Provider value={value}>
      {children}
    </CompanyObjectivesToggleContext.Provider>
  );
};

export default useCompanyObjectivesToggleMode;
