import styled from "@emotion/styled";
import { Stack, Typography, alpha } from "@mui/material";
import palette from "../../../theme/palette";


export const PostContainer = styled(Stack)({
  marginBottom: 14,
  padding: 10,
  cursor: "pointer",
  borderRadius: "12px",
  backgroundColor: palette.background.paper,
  border: `1px solid ${palette.border.grey}`,
  boxShadow: `1px 1px 10px ${alpha(palette.grey[500], 0.6)}`,
  ":hover": { boxShadow: `6px 6px 10px ${alpha(palette.blue[7],0.5)}` }
});

export const TitleContainer = styled(Stack)({
  justifyContent: "space-between",
  flexDirection: "row",
  alignItems: "center",
  marginBottom: 2,
  paddingLeft: 10,
  paddingRight:10,
  paddingTop:5,
  paddingBottom:5,
  borderRadius: "12px",
  backgroundColor: palette.background.grey 
});

export const TitleText = styled(Typography)({
  fontWeight: 800,
  fontSize: "15px",
  fontFamily: 'Plus Jakarta Sans',
  color: palette.common.darkBlue,
  textOverflow: 'ellipsis',
  maxWidth:"70%",
  whiteSpace:"nowrap",
  overflow: 'hidden'
});



export const PostFooter = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  marginTop: 10,
  justifyContent: "space-between",
  marginLeft: 8
});




export const PostContent = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize:"15px",
  marginTop:10,
  marginLeft: 8,
  color: palette.grey[1100],
  textOverflow: 'ellipsis',
  maxWidth:"80%",
  whiteSpace:"nowrap",
  overflow: 'hidden'
});




