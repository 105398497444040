import React, { createContext, useContext, useEffect } from 'react';
import { AppNotificationDto } from '../../api/generated';

import useAppNotification from '../useAppNotification';


type AppNotificationStateContext = {
  unreadNotifications: AppNotificationDto[];
  refetchUnreadNotifications: () => void;
};

export const AppNotificationContext =
  createContext<AppNotificationStateContext | null>(null);

const useAppNotificationContext = () => useContext(AppNotificationContext);
export const AppNotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const { notificationList: unreadNotifications, refetchNotifications: refetchUnreadNotifications } = useAppNotification(true);


  const value = { unreadNotifications, refetchUnreadNotifications };

  useEffect(() => {
    setInterval(() => {
      refetchUnreadNotifications();
    }, 1000 * 60 * 5);
  }, [refetchUnreadNotifications]);

  return (
    <AppNotificationContext.Provider value={value}>
      {children}
    </AppNotificationContext.Provider>
  );
};

export default useAppNotificationContext;






