import { Stack, Typography, styled } from '@mui/material';

import palette from '../../theme/palette';

const Container = styled(Stack)({
  padding: '0px',
  background: palette.grey[1500],
  height: '100%',
  width: "100%"
});

export const EmptyText = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '13px',
  color: palette.grey[700]
});


export { Container };
