import { useState } from 'react';
import React from 'react';

import { Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { enqueueSnackbar } from 'notistack';

import { TOAST_VARIANTS } from '../components/NotificationToast/NotificationToast';

const useNewVersionUpdate = () => {
  const [waitingWorker, setWaitingWorker] = useState(null);
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [refreshToastTriggered, setRefreshToastTriggered] = useState(false);

  const onServiceWorkerUpdate = registration => {
    setWaitingWorker(registration?.waiting);
    setNewVersionAvailable(true);
  };

  const updateServiceWorker = () => {
    waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
    setNewVersionAvailable(false);
    window.location.reload();
  };

  if (newVersionAvailable && !refreshToastTriggered) {
    setRefreshToastTriggered(true);
    // show snackbar with refresh button
    enqueueSnackbar({
      variant: 'custom',
      persist: true,
      customProps: {
        title: 'There is a new version available.',
        caption: (
          <Stack width="100%" justifyContent="center" alignItems="center">
            <Button color="success" variant="outlined" size="small" onClick={updateServiceWorker}>
              {'update'}
            </Button>
          </Stack>
        ),
        variant: TOAST_VARIANTS.SUCCESS
      }
    });
  }

  return {
    newVersionAvailable,
    updateServiceWorker,
    onServiceWorkerUpdate
  };
};

export default useNewVersionUpdate;
