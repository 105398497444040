import { Input, Stack, Typography, alpha, styled } from '@mui/material';

import palette from '../../theme/palette';

export const KeyResultDescription = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 'bolder',
  color: palette.grey[1900],
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 3
}));

export const ProgressTitle = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 700,
  fontSize: '13px',
  lineHeight: '16px',
  color: palette.common.darkBlue
}));

export const TargetPercentageView = styled(Typography)(() => ({
  fontWeight: 700,

  textAlign: 'center',
  color: palette.primary.main
}));

export const Baseline = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '11px',
  color: palette.common.darkBlue
}));

export const KrValueText = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '13px',
  color: palette.common.darkBlue
}));

export const KeyResultEmpty = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 400,
  cursor: 'pointer',
  fontSize: '12px',
  width: 'max-content',
  borderBottom: `0.5px solid ${palette.primary.main}`,
  ':hover': { opacity: 0.8 },
  color: palette.primary.main
}));

export const UpdateData = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '13px',
  borderBottom: `1px solid ${palette.primary.main}`,
  color: palette.primary.main,
  cursor: 'pointer',
  ':hover': { opacity: 0.8 }
});

export const CancelValueButton = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '12px',
  color: palette.grey[1100],
  cursor: 'pointer'
});

export const SaveValueButton = styled(Stack)<{
  disabled: boolean;
}>(({ disabled }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 5px',
  fontSize: '13px',
  color: 'white',
  opacity: disabled ? 0.4 : 1,
  background: disabled ? '#DBE1E2' : palette.green[1],
  borderRadius: '6px',
  cursor: disabled ? 'not-allowed' : 'pointer',
  ':hover': {
    opacity: 0.8
  }
}));

export const CurrentValueInput = styled(Input)(() => ({
  backgroundColor: palette.secondary.main,
  borderRadius: '8px',
  padding: '5px 10px',
  fontSize: '14px'
}));

export const UnitIcon = styled(Stack)(({ theme }) => ({
  borderRadius: '50%',
  padding: '5px',
  backgroundColor: alpha(theme.palette.primary.main, 0.2),
  color: theme.palette.primary.main,
  svg: {
    width: 17,
    height: 17
  }
}));
