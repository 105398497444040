
import { Input, Stack, Typography, styled } from '@mui/material';

import palette from '../../theme/palette';


export const Container = styled(Stack)({
  width:"100%"
});

export const InputKeyword = styled(Input)(() => ({
  fontSize: '12px',
  padding: '0 10px',
  width: '250px',
  '&.Mui-focused': {
    borderColor: palette.primary.main
  }
}));

export const SubTitle = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 700,
  fontSize: '15px',
  color: palette.primary.main
});

export const EmptyTitle = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '15px',
  color: palette.common.darkBlue
});
