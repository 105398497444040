import React from 'react';

 import {
Handshake,
 Factory
} from '@mui/icons-material';

import { ModuleMetadataDtoBusinessArea } from '../api/generated';
import BusinessAreaGroup from '../enums/businessAreaGroup';


import { ReactComponent as StratgeyIcon } from '../assets/businessAreaIconsSmall/strategicPlaning.svg';
import { ReactComponent as FinanceIcon } from '../assets/businessAreaIconsSmall/finance.svg';
import { ReactComponent as PeopleIcon } from '../assets/businessAreaIconsSmall/people.svg';
import { ReactComponent as ProductIcon } from '../assets/businessAreaIconsSmall/product.svg';
import { ReactComponent as MarketingIcon } from '../assets/businessAreaIconsSmall/marketing.svg';
import { ReactComponent as SalesIcon } from '../assets/businessAreaIconsSmall/sales.svg';
import { ReactComponent as CustomerSuccessIcon } from '../assets/businessAreaIconsSmall/customerSuccess.svg';
import { ReactComponent as OperationsIcon } from '../assets/businessAreaIconsSmall/operations.svg';
import { ReactComponent as EsgIcon } from '../assets/businessAreaIconsSmall/esg.svg';
import { ReactComponent as MarketIcon } from '../assets/businessAreaIconsSmall/market.svg';
import { ReactComponent as CompetitionIcon } from '../assets/businessAreaIconsSmall/competition.svg';
import { ReactComponent as CeoIcon } from '../assets/businessAreaIconsSmall/ceo.svg';
import { ReactComponent as BoardIcon } from '../assets/businessAreaIconsSmall/board.svg';


export interface AppBusinessAreaMetadata {
  id: keyof BusinessAreasMetaData;
  name: string;
  icon: React.ReactNode;
  validationNote?: string;
  group: BusinessAreaGroup;
  active?: boolean;
  actionPlanIndex?:number;
}

export type BusinessAreasMetaData = {
  [key in ModuleMetadataDtoBusinessArea]: AppBusinessAreaMetadata;
};

const businessAreasMetaData: BusinessAreasMetaData = {
  strategicPlanning: {
    id: 'strategicPlanning',
    name: 'Strategic Planning',
    icon: <StratgeyIcon fontSize="small" />,
    validationNote: `The strategy business area was built specifically for CEOs of growth stage companies to support the strategic thinking process, decision making and execution.\n\nWe’ve built the following modules and tools based on multiple validated resources and experts practice.\n\nThese modules are live modules and are being updated based on usability, business outcomes and our proprietary cumulative knowledge engine.`,
    group: BusinessAreaGroup.INTERNAL,
    active: true
  },
  strategy: {
    id: 'strategy',
    name: 'Strategy',
    icon: <StratgeyIcon fontSize="small" />,
    validationNote: `The strategy business area was built specifically for CEOs of growth stage companies to support the strategic thinking process, decision making and execution.\n\nWe’ve built the following modules and tools based on multiple validated resources and experts practice.\n\nThese modules are live modules and are being updated based on usability, business outcomes and our proprietary cumulative knowledge engine.`,
    group: BusinessAreaGroup.INTERNAL,
    active: true
  },
  finance: {
    id: 'finance',
    name: 'Finance',
    icon: <FinanceIcon fontSize="small" />,
    group: BusinessAreaGroup.INTERNAL,
    active: true,
    actionPlanIndex: 5
  },
  people: {
    id: 'people',
    name: 'People',
    icon: <PeopleIcon fontSize="small" />,
    group: BusinessAreaGroup.INTERNAL,
    active: true,
    actionPlanIndex: 7
  },
  product: {
    id: 'product',
    name: 'Product',
    icon: <ProductIcon fontSize="small" />,
    group: BusinessAreaGroup.INTERNAL,
    active: true,
    actionPlanIndex: 3
  },
  marketing: {
    id: 'marketing',
    name: 'Marketing',
    icon: <MarketingIcon fontSize="small" />,
    validationNote:
      'The marketing business area was built specifically for CEOs of growth stage companies to support the strategic thinking process, decision making and execution.\n\nWe’ve built the following modules and tools based on multiple validated resources and experts practice.\n\nThese modules are live modules and are being updated based on usability, business outcomes and our proprietary cumulative knowledge engine.',
    group: BusinessAreaGroup.INTERNAL,
    active: true,
    actionPlanIndex: 2
  },
  sales: {
    id: 'sales',
    name: 'Sales',
    icon: <SalesIcon fontSize="small" />,
    group: BusinessAreaGroup.INTERNAL,
    active: true,
    actionPlanIndex: 1
  },
  customerSuccess: {
    id: 'customerSuccess',
    name: 'Customer Success',
    icon: <CustomerSuccessIcon fontSize="small" />,
    group: BusinessAreaGroup.INTERNAL,
    active: true,
    actionPlanIndex: 4
  },
  operations: {
    id: 'operations',
    name: 'Operations',
    icon: <OperationsIcon fontSize="small" />,
    group: BusinessAreaGroup.INTERNAL,
    active: true,
    actionPlanIndex: 6
  },
  esg: {
    id: 'esg',
    name: 'ESG',
    icon: <EsgIcon fontSize="small" />,
    group: BusinessAreaGroup.INTERNAL,
    active: true,
    actionPlanIndex: 8
  },
  market: {
    id: 'market',
    name: 'Market',
    icon: <MarketIcon fontSize="small" />,
    group: BusinessAreaGroup.EXTERNAL,
    active: true
  },
  competition: {
    id: 'competition',
    name: 'Competition',
    icon: <CompetitionIcon fontSize="small" />,
    group: BusinessAreaGroup.EXTERNAL,
    active: true
  },
  partnerships: {
    id: 'partnerships',
    name: 'Partnerships',
    icon: <Handshake fontSize="small" />,
    group: BusinessAreaGroup.EXTERNAL,
    active: false
  },
  suppliers: {
    id: 'suppliers',
    name: 'Suppliers',
    icon: <Factory fontSize="small" />,
    group: BusinessAreaGroup.EXTERNAL,
    active: false
  },
  ceo: { id: 'ceo', name: 'CEO', icon: <CeoIcon fontSize="small"/>, group: BusinessAreaGroup.ADDITIONAL, active: false },
  board: {
    id: 'board',
    name: 'Board',
    icon: <BoardIcon fontSize="small"/>,
    group: BusinessAreaGroup.ADDITIONAL,
    active: false
  }
} as const;

export default businessAreasMetaData;
