import { KeyResultUnit } from "../api/generated";

export interface ActionPlanTemplate {
  businessArea: string;
  objective: string;
  krValue: string;
  krSubject: string;
  krUnit: KeyResultUnit;
  krStartingPoint: string;
  initiative: string;
}


const actionPlanTemplates: ActionPlanTemplate[] = [
  {
    businessArea: "sales",
    objective: "Accelerate financial growth",
    krValue: "20m",
    krSubject: "Revenue",
    krUnit: "currency",
    krStartingPoint:"10m",
    initiative: "Restructure pricing plan"
  },
  {
    businessArea: "marketing",
    objective: "Increase brand awareness",
    krValue: "250",
    krSubject: "Inbound leads",
    krUnit: "percentage",
    krStartingPoint:"0",
    initiative: "Content creation"
  },
  {
    businessArea: "product",
    objective: "Enhance customer 's stickiness",
    krValue: "80",
    krSubject: "Weekly active users",
    krUnit: "percentage",
    krStartingPoint:"0",
    initiative: "Features surveys"
  },
  {
    businessArea: "customerSuccess",
    objective: "Deliver service beyond any of their expectations",
    krValue: "5",
    krSubject: "Churn",
    krUnit: "percentage",
    krStartingPoint:"10",
    initiative: "Adding 2 product & pricing packages"
  },
  {
    businessArea: "finance",
    objective: "Achieve record revenue and profitability",
    krValue: "2m",
    krSubject: "Operational expenses",
    krUnit: "currency",
    krStartingPoint:"3m",
    initiative: "Streamline and optimize business processes"
  },
  {
    businessArea: "operations",
    objective: "Maintain a world-class office",
    krValue: "10",
    krSubject: "Office supplies spending",
    krUnit: "percentage",
    krStartingPoint:"0",
    initiative: "Implement centralized purchasing system"
  },
  {
    businessArea: "people",
    objective: "Build the foundations of the company's people experience",
    krValue: "10",
    krSubject: "Employee turnover",
    krUnit: "percentage",
    krStartingPoint:"0",
    initiative: "Implement Comprehensive Employee Onboarding Program"
  }

];

export default actionPlanTemplates;
