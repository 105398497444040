import styled from '@emotion/styled';
import { Stack, Typography, keyframes } from '@mui/material';

import palette from '../../../../theme/palette';

const Container = styled(Stack)({
  width: '100%',
  height: '38px',
  background: palette.common.darkBlue,
  borderRadius: '10px',
  alignItems: 'center'
});

const StartMeetingTitle = styled(Typography)({
  cursor: 'pointer',
  ':hover': {
    textDecoration: 'underline'
  }
});

const InProgressText = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: "15px",
  padding: 5

});

const MeetingNameText = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: "12px",
  padding: 4
});

const MeetingStatusButton = styled(Stack)({
  gap: '2px',
  width: '120px',
  height: '28px',
  background: palette.green[1],
  borderRadius: '10px',
  cursor: 'pointer',
  ':hover': { opacity: 0.8 },
  marginLeft: '10px'
});

const MeetingNotesText = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 700,
  fontSize: '12px'
});

const JoinText = styled(Stack)({
  marginLeft: 3,
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 600,
  alignSelf: "start",
  cursor: "pointer",
  ":hover": { opacity: 0.8 },
  fontSize: '13px',
  paddingLeft: 6,
  paddingRight: 6,
  color: "white",
  borderRadius: "6px",
  backgroundColor: palette.primary.main
});


const blink = keyframes`
  from { opacity: 0.5 }
  to { opacity: 1}
`;

const LiveMeetingIcon = styled(Typography)({
  animation: `${blink} 1.5s linear infinite`
});

export { JoinText, MeetingNameText, InProgressText, Container, StartMeetingTitle, MeetingStatusButton, MeetingNotesText, LiveMeetingIcon };
