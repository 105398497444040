import React, { useState } from 'react';

import { ActionPlanInitiativeDtoPriority } from '../../../api/generated';
import PrioritySelectorPopover from '../../../screens/ActionPlan/components/PrioritySelectorPopover/PrioritySelectorPopover';
import { initiativesPriorityColorsMap } from '../../../utils/actionPlanUtil';
import { CheckCircle, SelectionTitle } from '../../InitiativesCard/InitiativesCardStyles';
import {
  ArrowDownIcon,
  PriorityContainer,
  PrioritySelectionContainer
} from './InitiativePriorityStyles';

type Props = {
  priority: ActionPlanInitiativeDtoPriority;
  onSelection: (priority: ActionPlanInitiativeDtoPriority) => void;
};

const InitiativePriority = ({ priority, onSelection }: Props) => {
  const [priorityPopoverAnchor, setPriorityPopoverAnchor] = useState(null);
  const priorityPopoverOpen = !!priorityPopoverAnchor;

  return (
    <PriorityContainer>
      <PrioritySelectionContainer
        onClick={event => setPriorityPopoverAnchor(event.currentTarget)}
        backgroundColor={initiativesPriorityColorsMap[priority].backgroundColor}
      >
        <CheckCircle
          checkedColor={initiativesPriorityColorsMap[priority].checkedColor}
        />
        <SelectionTitle>
          {initiativesPriorityColorsMap[priority].label}
        </SelectionTitle>
        <ArrowDownIcon open={priorityPopoverOpen} />
      </PrioritySelectionContainer>
      <PrioritySelectorPopover
        anchorEl={priorityPopoverAnchor}
        open={priorityPopoverOpen}
        priority={priority}
        handleClose={() => setPriorityPopoverAnchor(null)}
        onSelection={onSelection}
      />
    </PriorityContainer>
  );
};

export default InitiativePriority;
