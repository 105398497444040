import React, { FC, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Divider, Stack, Typography, styled } from '@mui/material';

import { UserFeatureFeature } from '../../api/generated';
import useSidebar from '../../hooks/context-providers/useSidebar';
import useUserFeature from '../../hooks/useUserFeature';
import LiveMeetingStatus from '../../screens/Dashboard/components/LiveMeetingStatus/LiveMeetingStatus';
import palette from '../../theme/palette';
import ContactUs from '../ContactUs/ContactUs';
import UserMenu from './components/UserMenu';
import NotificationPopover from '../AppNotification/NotificationPopover';
import { sidebarWidth } from '../Sidebar/Sidebar';



const NavFlotTitle = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '11px',
  color: palette.common.darkBlue
});

export type NavigationFlowItem = {
  label: string;
  slug?: string;
};

interface HeaderProps {
  withMenus?: boolean;
  navigationFlowItems?: NavigationFlowItem[];
}

const Header: FC<HeaderProps> = ({ withMenus = true, navigationFlowItems = [] }) => {
  const navigate = useNavigate();
  const { isFeatureBlocked } = useUserFeature();
  const { isSidebarOpened } = useSidebar();

  return (
    <Box
      component="header"
      sx={({ transitions: { create, easing, duration } }) => ({
        width: `calc(100% - ${sidebarWidth.closed + 'px'})`,
        height: '56px',
        borderBottom: `1px solid ${palette.border.grey}`,
        backgroundColor: 'background.default',
        py: 1.2,
        px: 4,
        position: 'fixed',
        top: 0,
        zIndex: 1200,
        transition: create('width', {
          easing: easing.sharp,
          duration: isSidebarOpened ? duration.enteringScreen : duration.leavingScreen
        })
      })}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          {navigationFlowItems.map((navFlowItem, index) => (
            <Fragment key={index}>
              <NavFlotTitle
                fontWeight={index !== navigationFlowItems.length - 1 ? 300 : 600}
                sx={{ cursor: navFlowItem.slug && 'pointer' }}
                onClick={() => navFlowItem.slug && navigate(navFlowItem.slug)}
              >
                {navFlowItem.label}
              </NavFlotTitle>
              {index !== navigationFlowItems.length - 1 && (
                <Stack marginX="6px" justifyContent="center" alignItems="center">
                  <Typography
                    sx={{
                      fontSize: '10px',
                      fontWeight: index !== navigationFlowItems.length - 1 ? 300 : 600
                    }}
                  >
                    /
                  </Typography>
                </Stack>
              )}
            </Fragment>
          ))}
        </Stack>
        {!isFeatureBlocked(UserFeatureFeature.meetings) && <LiveMeetingStatus />}
        <Box display="flex" gap="10px" alignItems="center" sx={{ color: 'common.black' }}>
          {withMenus && (
            <>
              <ContactUs />
              <NotificationPopover />
              <Divider orientation="vertical" flexItem sx={{ mr: 1 }} />
              <UserMenu />
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default React.memo(Header);
