import { Components } from '@mui/material/styles';

import palette from '../../theme/palette';

const MuiInputBase: Components['MuiInputBase'] = {
  styleOverrides: {
    root: {
      color: palette.grey['700'],
      padding: '12px 16px',
      background: 'white',
      border: `1px solid ${palette.border.grey}`,
      borderRadius: '8px',
      input: {
        '&::placeholder': {
          color: palette.grey[1100],
          fontSize: '13px'
        }
      }
    }
  }
};

export default MuiInputBase;
