import React, { MouseEvent, useCallback, useState } from 'react';


import { Popover } from '@mui/material';
import { Stack } from '@mui/system';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import palette from '../../theme/palette';
import { Container, EmptyState, LinkDescription, MarkAsRead, NotificationBadge, NotificationContainer, NotificationDescription, NotificationList, NotificationTitle, TitleContainer, TitleText, ViewAll, ViewAllText } from './AppNotificationStyles';

import useModal from '../../hooks/context-providers/useModal/useModal';
import { orderBy } from 'lodash';
import UnfoldMoreOutlinedIcon from '@mui/icons-material/UnfoldMoreOutlined';
import useAppNotificationContext from '../../hooks/context-providers/useAppNotificationContext';
import { useNavigate } from 'react-router-dom';
import useAppNotification from '../../hooks/useAppNotification';
import { UpdateAppNotificationStatusRequestStatus } from '../../api/generated';
const LazyLoadedNotificationCenterModal = React.lazy(
  () => import('./NotificationCenterModal')
);

const NotificationPopover = () => {
  const { unreadNotifications, refetchUnreadNotifications } = useAppNotificationContext()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { showModal } = useModal();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const notificationsToDisplay = orderBy(unreadNotifications, item => item.createdAt, "desc").slice(0, 5);
  const { updateNotificationsStatus } = useAppNotification();

  const markAllAsRead = () => {
    updateNotificationsStatus(notificationsToDisplay.map(notification => notification.id), UpdateAppNotificationStatusRequestStatus.read).then(() => refetchUnreadNotifications());
  }


  const viewAllClicked = useCallback(
    (notificationId?: string) => {
      handleClose();
      const modal = showModal(LazyLoadedNotificationCenterModal, {
        onClose: () => {
          modal.hide();
        },

        readNotificationId: notificationId ? notificationId : null
      });
    },
    [showModal]
  );

  const navigate = useNavigate();


  const open = Boolean(anchorEl);

  const showMeClicked = (event: MouseEvent<HTMLDivElement>, link: string) => {
    handleClose();
    event.stopPropagation();

    if (link.startsWith("/")) {
      navigate(link);
    } else {
      window.open(link, "_blank", "noreferrer");
    }

  }

  return (

    <Stack>
      <Stack
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          ':hover': {
            svg: {
              path: {
                fill: palette.primary.main
              }
            }
          }
        }}
      >
        <NotificationBadge
          badgeContent={notificationsToDisplay?.length}
        >
          <NotificationsNoneOutlinedIcon sx={{ opacity: 0.5, width: '30px', height: '30px' }} />
        </NotificationBadge>
      </Stack>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{ borderRadius: "8px", zIndex: 100000 }}
      >
        <Container>
          <TitleContainer>
            <TitleText>Notifications</TitleText>
          </TitleContainer>

          <NotificationList>
            {notificationsToDisplay?.length > 0 ? notificationsToDisplay.map(
              notification =>
                <NotificationContainer
                  onClick={() => viewAllClicked(notification.id)}
                  key={notification.id}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between">
                    <NotificationTitle>{notification.title}</NotificationTitle>
                    {notification.link && <LinkDescription
                      onClick={(event: MouseEvent<HTMLDivElement>) => showMeClicked(event, notification.link)}
                    >Show me</LinkDescription>}
                  </Stack>
                  <NotificationDescription
                    value={notification.description} />

                </NotificationContainer>
            ) :
              <NotificationContainer>
                <EmptyState>You have no new notifications</EmptyState>
              </NotificationContainer>}
          </NotificationList>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            padding="3px">
            <ViewAll
              onClick={() => viewAllClicked()}
            >
              <UnfoldMoreOutlinedIcon fontSize='small' sx={{ color: palette.grey[700] }} />
              <ViewAllText>View all notifications</ViewAllText></ViewAll>
            {notificationsToDisplay.length > 0 && <Stack
              onClick={markAllAsRead}>
              <MarkAsRead>Mark {notificationsToDisplay.length} as read</MarkAsRead>
            </Stack>}
          </Stack>


        </Container>
      </Popover>
    </Stack>

  );
};
export default NotificationPopover;
