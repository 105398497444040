import React, { createContext, useContext, useState } from 'react';

import { SharedWorkspacePointDto } from '../../api/generated';



interface useSharedWorkspacePointSidebarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  currentPoint: SharedWorkspacePointDto;
  setCurrentPoint: (point: SharedWorkspacePointDto) => void;
  refetch?: () => void;
  setRefetch?: (value: () => void) => void;
  currentMeetingId?: string;
  setCurrentMeetingId?: (id: string) => void;

}

const Context = createContext<useSharedWorkspacePointSidebarProps | null>(null);

const useSharedWorkspacePointSidebar = () => useContext(Context);

export const SharedWorkspacePointSidebarProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [currentMeetingId, setCurrentMeetingId] = useState<string>('');
  const [currentPoint, setCurrentPoint] = useState<SharedWorkspacePointDto>(null);
  const [refetch, setRefetch] = useState<() => void>();


  const value = {
    open,
    setOpen,
    currentPoint,
    setCurrentPoint,
    refetch,
    setRefetch,
    currentMeetingId,
    setCurrentMeetingId
  };

  return <Context.Provider value={value}> {children}</Context.Provider>;
};

export default useSharedWorkspacePointSidebar;
