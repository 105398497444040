import { useCallback, useMemo } from 'react';

import {
  ActionPlanActionItemCommentDto,
  UserFeatureFeature,
  useCreateActionPlanActionItemComment,
  useDeleteActionPlanActionItemComment,
  useListActionPlanActionItemComments,
  usePatchActionPlanActionItemComment,
  useSearchActionPlanActionItemComments
} from '../api/generated';
import useUserFeature from './useUserFeature';

const useActionItemComments = (actionItemId?: string) => {
  const { isFeatureBlocked } = useUserFeature();

  const isActionPlanEnabled = !isFeatureBlocked(UserFeatureFeature.actionPlan);

  const {
    data: commentsResponse,
    isLoading: commentsLoading,
    refetch: refetchComments
  } = useListActionPlanActionItemComments(actionItemId, {
    query: {
      queryKey: ['actionItemComments', actionItemId],
      enabled: !!actionItemId && isActionPlanEnabled
    }
  });


  const { mutateAsync: mutateCreateActionItemComment } = useCreateActionPlanActionItemComment();
  const { mutateAsync: mutatePatchActionItemComment } = usePatchActionPlanActionItemComment();
  const { mutateAsync: mutateDeleteActionItemComment } = useDeleteActionPlanActionItemComment();
  const {
    mutateAsync: mutateSearchActionItemsComments,
    isPending: actionItemsCommentsLoading
  } = useSearchActionPlanActionItemComments();

  const createActionItemComment = useCallback(
    async (comment: Partial<ActionPlanActionItemCommentDto>) => {
      const response = await mutateCreateActionItemComment({ data: comment });
      return response?.items[0]?.id;
    },
    [mutateCreateActionItemComment]
  );

  const deleteActionItemComment = useCallback(
    async (id: string) => {
      await mutateDeleteActionItemComment({ id });
    },
    [mutateDeleteActionItemComment]
  );

  const patchActionItemComment = useCallback(
    async (id: string, comment: Partial<ActionPlanActionItemCommentDto>) => {
      await mutatePatchActionItemComment({ id, data: comment });
    },
    [mutatePatchActionItemComment]
  );

  const searchActionItemsComments = useCallback(
    async (ownerOrAssigneeUserIds?: string[], entityIds?: string[]) => {
      const response = await mutateSearchActionItemsComments({ data: { ownerOrAssigneeUserIds: ownerOrAssigneeUserIds, entityIds: entityIds } });
      return response?.items;
    },
    [mutateSearchActionItemsComments]
  );

  const comments = useMemo(
    () => commentsResponse?.items || [],
    [commentsResponse?.items]
  );

  return {
    comments,
    commentsLoading,
    createActionItemComment,
    deleteActionItemComment,
    patchActionItemComment,
    refetchComments,
    searchActionItemsComments,
    actionItemsCommentsLoading
  };
};

export default useActionItemComments;
