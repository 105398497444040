import React, { useCallback, useEffect, useState } from 'react';
import { ActionPlanActionItemDto, ActionPlanActionItemDtoSprint, ActionPlanActionItemDtoStatus } from '../../../../api/generated';
import { DragDropContext, DraggableLocation, DropResult } from 'react-beautiful-dnd';
import KanbanBoard, { CardData, KanbanBoardData } from '../../../../components/KanbanBoard/KanbanBoard';
import useActionPlanActionItem from '../../../../hooks/useActionPlanActionItem';
import { Container } from './TasksBoardStyles';
import { alpha } from '@mui/material';
import palette from '../../../../theme/palette';
import useTaskSidebar from '../../../../hooks/context-providers/useTaskSidebar';
import useModal from '../../../../hooks/context-providers/useModal/useModal';
import { SortType } from '../../../../components/ActionItemsList/ActionItemsSort/ActionItemsSort';

const LazyLoadedAreYouSureModal = React.lazy(
    () => import('../../../../modals/AreYouSureModal/AreYouSureModal')
);

const boardStatus = {
    "backlog": "backlog",
    "todo": ActionPlanActionItemDtoStatus.pending,
    "inProgress": ActionPlanActionItemDtoStatus.inProgress,
    "completed": ActionPlanActionItemDtoStatus.completed
}

interface TasksBoardProps {
    actionItemsList: ActionPlanActionItemDto[];
    actionItemsLoading: boolean;
    sortType: string;
}


const TasksBoard = ({ actionItemsList, actionItemsLoading, sortType }: TasksBoardProps) => {
    const { showModal } = useModal();
    const [data, setData] = useState<KanbanBoardData[]>([]);
    const { deleteActionPlanActionItem, refetchActionItems, patchActionPlanActionItem } = useActionPlanActionItem();
    const maxDate = Date.parse('01 Jan 4000 00:00:00 GMT');

    const sortActionItem = useCallback((a: ActionPlanActionItemDto, b: ActionPlanActionItemDto) => {
        if (sortType) {
            return sortType === SortType.DUE_DATE
                ? (a.dueDate ? Date.parse(a.dueDate) : maxDate) -
                (b.dueDate ? Date.parse(b.dueDate) : maxDate)
                : new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        } else {
            return a.index === b.index
                ? new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                : a.index - b.index;
        }
    }, [
        maxDate,
        sortType
    ]);


    const buildTasksBoardData = useCallback((tasks: ActionPlanActionItemDto[], id: string, name: string, color: string) => {
        const cardList: CardData[] = [];

        tasks
            .sort((a, b) => sortActionItem(a, b))
            .forEach(task => {
                cardList.push({
                    id: task.id,
                    title: task.title,
                    ownerId: task.ownerId,
                    assigneeId: task.assigneeId,
                    dueDate: task.dueDate,
                    status: task.status,
                    isArchived: task.isArchived
                })
            });

        return { id: id, boardName: name, card: cardList, color: color };
    }, [
        sortActionItem
    ]);



    useEffect(() => {
        const todoActionItems = actionItemsList.filter(ai => ai.status === ActionPlanActionItemDtoStatus.pending);
        const inProgressTasks = actionItemsList.filter(ai => ai.status === ActionPlanActionItemDtoStatus.inProgress);
        const completedTasks = actionItemsList.filter(ai => ai.status === ActionPlanActionItemDtoStatus.completed);
        const backlogTasks = todoActionItems.filter(task => task.sprint === ActionPlanActionItemDtoSprint.backlog);
        const todoTasks = todoActionItems.filter(task => task.sprint === ActionPlanActionItemDtoSprint.current);


        const backlogBoard = buildTasksBoardData(backlogTasks, boardStatus.backlog, "Backlog", alpha(palette.grey[1400], 0.1));
        const todoBoard = buildTasksBoardData(todoTasks, boardStatus.todo, "To do", alpha(palette.grey[1400], 0.2));
        const inProgressBoard = buildTasksBoardData(inProgressTasks, boardStatus.inProgress, "In Progress", alpha(palette.blue[8], 0.9));
        const completedBoard = buildTasksBoardData(completedTasks, boardStatus.completed, "Completed", alpha(palette.primary.main, 0.2));

        setData([backlogBoard, todoBoard, inProgressBoard, completedBoard])

    }, [actionItemsList, sortType, buildTasksBoardData]);

    const dragCardInBoard = (source: DraggableLocation, destination: DraggableLocation) => {
        const tempData = [...data];
        const destinationBoardIdx = tempData.findIndex(
            item => item.id.toString() === destination.droppableId
        );

        const sourceBoardIdx = tempData.findIndex(
            item => item.id.toString() === source.droppableId
        );

        const actionItemId = tempData[sourceBoardIdx]?.card[source.index]?.id;
        const newStatus = boardStatus[destination.droppableId];

        if (actionItemId) {
            if (destination.droppableId === "backlog") {
                patchActionPlanActionItem(actionItemId, { sprint: ActionPlanActionItemDtoSprint.backlog }).then(() => refetchActionItems());
            } else {
                patchActionPlanActionItem(actionItemId, { status: newStatus, sprint: ActionPlanActionItemDtoSprint.current }).then(() => refetchActionItems());
            }
        }

        tempData[destinationBoardIdx].card.splice(
            destination.index,
            0,
            tempData[sourceBoardIdx].card[source.index]
        );
        tempData[sourceBoardIdx].card.splice(source.index, 1);


        return tempData;
    };

    const addCard = (title: string, bid: string) => {
        const index = data.findIndex(item => item.id === bid);
        const tempData = [...data];
        tempData[index].card.push({
            id: crypto.randomUUID(),
            title: title,
            tags: [],
            status: ActionPlanActionItemDtoStatus.pending
        });
        setData(tempData);
    };

    const removeCard = useCallback((boardId: string, cardId: string) => {
        const index = data.findIndex(item => item.id === boardId);
        const tempData = [...data];
        const cardIndex = data[index].card.findIndex(item => item.id === cardId);

        tempData[index].card.splice(cardIndex, 1);
        setData(tempData);
    }, [
        data,
        setData
    ]);

    const deleteActionItem = useCallback((boardId: string, cardId: string) => {
        const modal = showModal(LazyLoadedAreYouSureModal, {
            onClose: () => {
                modal.hide();
            },
            onConfirm: () => {

                deleteActionPlanActionItem(cardId).then(() => {
                    removeCard(boardId, cardId);
                    refetchActionItems();
                });
                modal.hide();
            },
            modalTitle: `Are you sure you want to delete this task?`,
            confirmButtonTitle: 'Delete'
        });
    }, [
        deleteActionPlanActionItem,
        showModal,
        refetchActionItems,
        removeCard
    ]);




    const onDragEnd = (result: DropResult) => {
        const { source, destination } = result;
        if (!destination) return;

        if (source.droppableId === destination.droppableId) return;

        setData(dragCardInBoard(source, destination));
    };

    const { setOpen: openSidebar, setCurrentActionItem } = useTaskSidebar();


    return (
        <DragDropContext onDragEnd={onDragEnd}>

            <Container>
                {data.map(item => (
                    <KanbanBoard
                        loading={actionItemsLoading}
                        key={item.id}
                        data={item}
                        addCard={addCard}
                        removeCard={deleteActionItem}
                        onCardClick={(id: string) => {
                            openSidebar(true);
                            setCurrentActionItem(actionItemsList.find(ai => ai.id === id))
                        }}
                    />
                ))}
            </Container>


        </DragDropContext>
    );
};

export default TasksBoard;
