import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Stack } from '@mui/material';

import { useResetPassword } from '../../api/generated';
import Head from '../../components/Head/Head';
import PasswordInput from '../../components/Input/PasswordInput';
import FormLayout from '../../components/Layouts/FormLayout';
import { AuthCard, SubmitButton } from './AuthResetPasswordStyles';

const AuthResetPassword = () => {
  const {
    handleSubmit,
    watch,
    register,
    formState: { errors }
  } = useForm({
    reValidateMode: 'onSubmit',
    shouldFocusError: true,
    mode: 'onBlur'
  });
  const { mutateAsync: authReset } = useResetPassword();
  const [searchParams] = useSearchParams();
  const confirmationToken = searchParams.get('confirmationToken');
  const confirmationCode = searchParams.get('confirmationCode');
  const navigate = useNavigate();

  const onSubmit = async ({ newPassword }: FieldValues) => {
    try {
      await authReset({ data: { newPassword, confirmationToken, confirmationCode } });
      navigate('/auth/login');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthCard>
      <Head template="reset" />
      <FormLayout
        header="Reset Your Password"
        subHeader="Password must contain at least 8 characters, letters, numbers and symbols"
      >
        <Stack
          component="form"
          noValidate
          autoComplete="off"
          mt={3}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Stack width="100%" sx={{ mb: 3 }}>
            <PasswordInput
              placeholder="Type your new password"
              register={register}
              registerName="newPassword"
              rulesOptions={{
                required: 'Field is required'
              }}
              errors={errors}
            />
            <PasswordInput
              errors={errors}
              placeholder="Confirm your new password"
              register={register}
              registerName="confirmPassword"
              rulesOptions={{
                required: 'Field is required',
                validate: (val: string) => {
                  if (watch('newPassword') != val) {
                    return 'Passwords are different';
                  }
                }
              }}
            />
          </Stack>
          <SubmitButton
            type="submit"
            variant="contained"
            size="medium"
            sx={{
              mx: 'auto',
              mt: 3
            }}
          >
            Set password
          </SubmitButton>
        </Stack>
      </FormLayout>
    </AuthCard>
  );
};
export default AuthResetPassword;
