import { useEffect, useState } from 'react';

export enum STORAGE_TYPE {
  LOCAL = 'LOCAL',
  SESSION = 'SESSION'
}

type UseStorageTuple<T> = [T, React.Dispatch<React.SetStateAction<T>>];

type Storage = typeof localStorage | typeof sessionStorage | undefined;

const getStorage = (storage: Storage, key: string) => {
  const fromStorage = storage?.getItem(key);
  const parse = JSON.parse(fromStorage ?? 'null');

  return parse;
};

const setStorage = (storage: Storage, key: string, newValue: unknown) =>
  storage?.setItem(key, JSON.stringify(newValue));

// TODO buggy unpredictable hook, should be deleted and get data straight from local/session storages
const useStorage = <T extends string | boolean | Array<unknown>>(
  storageType: Readonly<STORAGE_TYPE>,
  key: Readonly<string>,
  initialValue: unknown
): UseStorageTuple<T> => {
  const storage = storageType === STORAGE_TYPE.SESSION ? sessionStorage : localStorage;
  const [value, setValue] = useState<T>(getStorage(storage, key) ?? initialValue);

  useEffect(() => {
    setStorage(storage, key, value);
  }, [key, storage, value]);

  return [value, setValue];
};

export default useStorage;
