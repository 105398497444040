const defaultColors = ['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90'];
const letters = ['A', 'B', 'C', 'D', 'E', 'F'];

export const getColors = (id: string) => {
  let retVal = [];

  if (id != null && id.length > 6) {
    let color1 = '#';
    let color2 = '#';
    let color3 = '#';
    let color4 = '#';
    for (let j = 1; j < 4; j++) {
      const value = Number(id.charAt(j));
      color1 += value + letters[value % letters.length];
      color2 += letters[value % letters.length] + value;
      color3 += value + value;
      color4 += letters[value % letters.length] + letters[value % letters.length];
    }
    retVal.push(color1);
    retVal.push(color2);
    retVal.push(color3);
    retVal.push(color4);
  } else {
    retVal = defaultColors;
  }

  return retVal;
};

export const randomBgColor = (string: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};
