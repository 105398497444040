import React from 'react';

import { Insights, SettingsSuggest, TipsAndUpdates } from '@mui/icons-material';
import EmojiEvents from '@mui/icons-material/EmojiEvents';
import FolderIcon from '@mui/icons-material/Folder';
import { alpha } from '@mui/material';

import { ReactComponent as BoardIcon } from '../assets/businessArea/board.svg';
import { ReactComponent as CompetitionIcon } from '../assets/businessArea/competition.svg';
import { ReactComponent as CustomerSuccsess } from '../assets/businessArea/customerSuccess.svg';
import { ReactComponent as EsgIcon } from '../assets/businessArea/esg.svg';
import { ReactComponent as FinanceIcon } from '../assets/businessArea/finance.svg';
import { ReactComponent as MarketIcon } from '../assets/businessArea/market.svg';
import { ReactComponent as MarketingIcon } from '../assets/businessArea/marketing.svg';
import { ReactComponent as OperationsIcon } from '../assets/businessArea/operations.svg';
import { ReactComponent as PeopleIcon } from '../assets/businessArea/people.svg';
import { ReactComponent as ProductIcon } from '../assets/businessArea/product.svg';
import { ReactComponent as SalesIcon } from '../assets/businessArea/sales.svg';
import { ReactComponent as StrategyIcon } from '../assets/businessArea/strategicPlaning.svg';
import { ReactComponent as ExcelIcon } from '../assets/excel.svg';
import { ReactComponent as GoogleDocsIcon } from '../assets/google-docs.svg';
import { ReactComponent as GoogleSheetsIcon } from '../assets/integrations/google-sheets.svg';
import { ReactComponent as GoogleSlidesIcon } from '../assets/google-slides.svg';
import { ReactComponent as JpgIcon } from '../assets/jpg.svg';
import { ReactComponent as LinkIcon } from '../assets/link.svg';
import { ReactComponent as Partnerships } from '../assets/partnerships.svg';
import { ReactComponent as PdfIcon } from '../assets/pdf.svg';
import { ReactComponent as PngIcon } from '../assets/png.svg';
import { ReactComponent as PowerpointIcon } from '../assets/powerpoint.svg';
import { ReactComponent as SuppliersIcon } from '../assets/suppliersIcon.svg';
import { ReactComponent as VideoFileIcon } from '../assets/videoFile.svg';
import { ReactComponent as WordIcon } from '../assets/word.svg';
import LinkExtension from '../enums/LinkExtension';
import ResourceFileTypes from '../enums/ResourceFileTypes';
import palette from '../theme/palette';

const size = "30px";

export const getResourceFileExtenstionIcon = (type: string) => {
  switch (type) {
    case ResourceFileTypes.GOOGLE_SHEETS:
    case ResourceFileTypes.DRIVE_SHEET: {
      return <GoogleSheetsIcon width={size} height={size} />;
    }
    case ResourceFileTypes.GOOGLE_DOCS:
    case ResourceFileTypes.DRIVE_DOC: {
      return <GoogleDocsIcon width={size} height={size} />;
    }
    case ResourceFileTypes.GOOGLE_SLIDES:
    case ResourceFileTypes.DRIVE_SLIDE: {
      return <GoogleSlidesIcon width={size} height={size} />;
    }
    case ResourceFileTypes.PDF:
    case ResourceFileTypes.DRIVE_PDF: {
      return <PdfIcon width={size} height={size} />;
    }
    case ResourceFileTypes.IMAGE_PNG: {
      return <PngIcon width={size} height={size} />;
    }
    case ResourceFileTypes.IMAGE_JPEG: {
      return <JpgIcon width={size} height={size} />;
    }
    case ResourceFileTypes.DRIVE_FOLDER: {
      return (
        <FolderIcon
          sx={{
            alignSelf: 'flex-end',
            width: size,
            height: size,
            color: palette.grey[1900]
          }}
        />
      );
    }
    case ResourceFileTypes.OFFICE_DOC: {
      return <WordIcon width={size} height={size} />;
    }
    case ResourceFileTypes.OFFICE_EXCEL: {
      return <ExcelIcon width={size} height={size} />;
    }
    case ResourceFileTypes.OFFICE_POWER_POINT: {
      return <PowerpointIcon width={size} height={size} />;
    }
    default: {
      if (type.includes('video')) {
        return <VideoFileIcon width={size} height={size} />;
      } else {
        return <LinkIcon width={size} height={size} />;
      }
    }
  }
};

export const linkExtensionToResourceType = (linkExtension: LinkExtension) => {
  switch (linkExtension) {
    case LinkExtension.GOOGLE_SHEETS:
      return ResourceFileTypes.GOOGLE_SHEETS;
    case LinkExtension.GOOGLE_DOCS:
      return ResourceFileTypes.GOOGLE_DOCS;
    case LinkExtension.GOOGLE_SLIDES:
      return ResourceFileTypes.GOOGLE_SLIDES;
    case LinkExtension.PDF:
      return ResourceFileTypes.PDF;
    case LinkExtension.IMAGE:
      return ResourceFileTypes.IMAGE;
    default:
      break;
  }
};

export const getIconLabelForPlaygroundItems = (): { [index: string]: string | JSX.Element } => ({
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: <EmojiEvents fontSize="small" />
});

export const getIconLabelForBusinessItems = (): { [index: string]: JSX.Element } => ({
  1: <Insights fontSize="small" />,
  2: <TipsAndUpdates fontSize="small" />,
  3: <SettingsSuggest fontSize="small" />
});

export const getIconHeaderForBusinessItems = (
  width?: string,
  height?: string
): { [index: string]: JSX.Element } => ({
  ceo: <PeopleIcon width={width ? width : ''} height={height ? height : ''} />,
  board: <BoardIcon width={width ? width : ''} height={height ? height : ''} />,
  strategy: <StrategyIcon width={width ? width : ''} height={height ? height : ''} />,
  finance: <FinanceIcon width={width ? width : ''} height={height ? height : ''} />,
  people: <PeopleIcon width={width ? width : ''} height={height ? height : ''} />,
  product: <ProductIcon width={width ? width : ''} height={height ? height : ''} />,
  marketing: <MarketingIcon width={width ? width : ''} height={height ? height : ''} />,
  sales: <SalesIcon width={width ? width : ''} height={height ? height : ''} />,
  customerSuccess: <CustomerSuccsess width={width ? width : ''} height={height ? height : ''} />,
  operations: <OperationsIcon width={width ? width : ''} height={height ? height : ''} />,
  market: <MarketIcon width={width ? width : ''} height={height ? height : ''} />,
  competition: <CompetitionIcon width={width ? width : ''} height={height ? height : ''} />,
  partnerships: <Partnerships width={width ? width : ''} height={height ? height : ''} />,
  suppliers: <SuppliersIcon width={width ? width : ''} height={height ? height : ''} />,
  esg: <EsgIcon width={width ? width : ''} height={height ? height : ''} />
});

export const getColorForBusinessItems = (): { [index: string]: string } => ({
  ceo: alpha(palette.yellow[1], 0.2),
  board: alpha(palette.yellow[1], 0.2),
  strategy: alpha(palette.green[5], 0.2),
  finance: alpha(palette.blue[4], 0.2),
  people: alpha(palette.purple[1], 0.2),
  product: alpha(palette.blue[4], 0.2),
  marketing: alpha(palette.blue[4], 0.2),
  sales: alpha(palette.blue[4], 0.2),
  customerSuccess: alpha(palette.blue[4], 0.2),
  operations: alpha(palette.purple[1], 0.2),
  market: alpha(palette.blue[3], 0.2),
  competition: alpha(palette.blue[3], 0.2),
  partnerships: alpha(palette.green[5], 0.2),
  suppliers: alpha(palette.green[5], 0.2)
});

export const months = [
  '',
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const metricPeriodData = {
  monthly: [
    { value: 0, label: 'Select month', alias: '' },
    { value: 1, label: 'January', alias: 'Jan' },
    { value: 2, label: 'February', alias: 'Feb' },
    { value: 3, label: 'March', alias: 'Mar' },
    { value: 4, label: 'April', alias: 'Apr' },
    { value: 5, label: 'May', alias: 'May' },
    { value: 6, label: 'June', alias: 'Jun' },
    { value: 7, label: 'July', alias: 'Jul' },
    { value: 8, label: 'August', alias: 'Aug' },
    { value: 9, label: 'September', alias: 'Sep' },
    { value: 10, label: 'October', alias: 'Oct' },
    { value: 11, label: 'November', alias: 'Nov' },
    { value: 12, label: 'December', alias: 'Dec' }
  ],
  quarterly: [
    { value: 0, label: 'Select quarter', alias: '' },
    { value: 1, label: 'Q1', alias: 'Q1' },
    { value: 2, label: 'Q2', alias: 'Q2' },
    { value: 3, label: 'Q3', alias: 'Q3' },
    { value: 4, label: 'Q4', alias: 'Q4' }
  ],
  semiannually: [
    { value: 0, label: 'Select half year', alias: '' },
    { value: 1, label: 'H1', alias: 'H1' },
    { value: 2, label: 'H2', alias: 'H2' }
  ],
  annually: [{ value: 1, label: '', alias: 'Y' }]
};


export const getFileType = (fileName: string): string => {
  const parts = fileName.split('.');

  if (parts.length < 2) {
    return 'unknown';
  }

  const suffix = parts.pop()?.toLowerCase();

  const fileTypes: { [key: string]: string } = {
    'jpg': 'image/jpeg',
    'jpeg': 'image/jpeg',
    'png': 'image/png',
    'gif': 'Image',
    'bmp': 'Image',
    'tiff': 'Image',
    'svg': 'Vector Image',
    'webp': 'Image',
    'mp3': 'audio',
    'wav': 'audio',
    'flac': 'audio',
    'aac': 'audio',
    'ogg': 'audio',
    'm4a': 'audio',
    'mp4': 'video',
    'mkv': 'video',
    'mov': 'video',
    'avi': 'video',
    'wmv': 'video',
    'flv': 'video',
    'pdf': ResourceFileTypes.PDF,
    'doc': ResourceFileTypes.OFFICE_DOC,
    'docx': ResourceFileTypes.OFFICE_DOC,
    'xls': ResourceFileTypes.OFFICE_EXCEL,
    'xlsx': ResourceFileTypes.OFFICE_EXCEL,
    'ppt': ResourceFileTypes.OFFICE_POWER_POINT,
    'pptx': ResourceFileTypes.OFFICE_POWER_POINT,
    'txt': 'Text',
    'csv': 'Spreadsheet',
    'json': 'Data',
    'xml': 'Data',
    'html': 'Web Page',
    'htm': 'Web Page',
    'css': 'Stylesheet',
    'js': 'JavaScript',
    'ts': 'TypeScript',
    'jsx': 'JavaScript',
    'tsx': 'TypeScript',
    'zip': 'Archive',
    'rar': 'Archive',
    '7z': 'Archive',
    'tar': 'Archive',
    'gz': 'Archive',
    'exe': 'Executable',
    'dll': 'Executable'
  };

  return fileTypes[suffix] || 'unknown';
}
