import {
  Box,
  FormControlLabel,
  Input,
  Stack,
  Switch,
  Typography,
  alpha,
  styled
} from '@mui/material';

import palette from '../../../../theme/palette';

export const KeyResultTitle = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '12px',
  color: palette.common.darkBlue
}));

export const BusinessAreaTitle = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px',
  color: palette.common.darkBlue
}));

export const ProgressTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 700,
  fontSize: '12px',
  color: theme.palette.common.darkBlue
}));

export const FormCheckbox = styled(FormControlLabel)(({ theme }) => ({
  margin: '0',
  span: {
    padding: '0',
    fontSize: theme.typography.body2.fontSize
  }
}));

export const InitiativeTitle = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 'bold',
  fontSize: '14px',
  color: palette.common.darkBlue,
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));

export const InitiativesTitle = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px',
  color: palette.common.darkBlue
}));

export const EmptyStateText = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '13px',
  color: palette.grey[2000]
}));

export const AddNew = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 'bold',
  fontSize: '14px',
  cursor: 'pointer',
  ':hover': { opacity: 0.8 },
  color: palette.primary.main
}));

export const KeyResultValue = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px',
  color: palette.common.darkBlue
}));

export const KeyResultEmpty = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 400,
  cursor: 'pointer',
  fontSize: '12px',
  width: 'max-content',
  borderBottom: `0.5px solid ${palette.primary.main}`,
  ':hover': { opacity: 0.8 },
  color: palette.primary.main
}));

export const StatusTitle = styled(Stack)<{
  color: string;
}>(({ color }) => ({
  borderRadius: '16px',
  padding: '2px 5px',
  width: '74px',
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '11px',
  textAlign: 'center',
  color: palette.common.darkBlue,
  backgroundColor: alpha(color, 0.2)
}));

export const BigPrioritySelectionOptionContainer = styled(Stack)<{
  backgroundColor: string;
}>(({ backgroundColor }) => ({
  background: backgroundColor,
  flexDirection: 'row',
  alignItems: 'center',
  gap: 1,
  width: '80px',
  height: '20px',
  borderRadius: '16px'
}));

export const SelectionTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '12px',
  color: palette.common.darkBlue,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
});

export const CheckCircle = styled(Box)<{
  color: string;
}>(({ color }) => ({
  background: 'white',
  borderRadius: '50%',
  height: '12px',
  width: '12px',
  border: `4px solid ${color}`,
  margin: '6px'
}));

export const AddInitiativeRow = styled(Input)({
  borderRadius: 0,
  border: 'none',
  marginLeft: '53px',
  padding: '5px 10px',
  input: { fontWeight: 400, fontSize: '14px', color: palette.common.darkBlue },
  textArea: { fontWeight: 400, fontSize: '14px', color: palette.common.darkBlue },
  borderBottom: `1px solid #f0f0f0`,
  borderRight: `1px solid #f0f0f0`,
  '& .MuiInput-input': {
    '&::placeholder': {
      fontSize: '14px',
      fontFamily: 'Plus Jakarta Sans'
    }
  }
});

export const ArchivedToggle = styled(Switch)({
  marginTop: 2,
  width: 25,
  height: 13,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: '1.5px 2px',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: palette.primary.main
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    backgroundColor: '#fff',
    width: 10,
    height: 10,
    borderRadius: 6
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    boxSizing: 'border-box',
    backgroundColor: palette.grey[700]
  }
});

export const ViewArchivedTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '13px',
  fontWeight: 700,
  color: palette.grey[3100]
});

export const ObjectiveTitle = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  cursor: 'pointer',
  fontSize: '13px',
  color: palette.primary.main,
  textDecoration: 'underline'
}));
