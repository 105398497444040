import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { AnyObject } from 'antd/es/_util/type';
import Column from 'antd/es/table/Column';
import { FilterValue, SorterResult, SortOrder } from 'antd/es/table/interface';

import { DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Chip, Grid, Stack, styled, Tooltip } from '@mui/material';
import { Table, TableProps } from 'antd';
import { orderBy } from 'lodash';

import {
  ActionPlanInitiativeDetailedDto,
  ActionPlanInitiativeDto,
  ActionPlanInitiativeDtoPriority,
  ActionPlanInitiativeDtoStatus,
  ActionPlanObjectiveDto,
  SharedWorkspaceMeetingActivityDtoAction,
  SharedWorkspaceMeetingActivityDtoEntityType,
  SharedWorkspaceMeetingDto
} from '../../../../api/generated';
import { ReactComponent as GoalArrow } from '../../../../assets/goal-arrow.svg';
import AddButton from '../../../../components/Common/AddButton';
import TableRow from '../../../../components/Table/TableRow';
import TeamsSelect from '../../../../components/Team/TeamsSelect';
import UserView from '../../../../components/User/UserView';
import useInitiativeSidebar from '../../../../hooks/context-providers/useInitiativeSidemenu';
import useModal from '../../../../hooks/context-providers/useModal/useModal';
import useObjectiveSidebar from '../../../../hooks/context-providers/useObjectiveSidebar';
import useActionPlanInitiative from '../../../../hooks/useActionPlanInitiative';
import useSharedWorkspaceMeeting from '../../../../hooks/useSharedWorkspaceMeeting';
import useSharedWorkspaceMeetingActivity from '../../../../hooks/useSharedWorkspaceMeetingActivity';
import useTags from '../../../../hooks/useTags';
import useTeams from '../../../../hooks/useTeam';
import useUsers from '../../../../hooks/useUsers';
import palette from '../../../../theme/palette';
import {
  formatValue,
  initiativesPriorityColorsMap,
  initiativesPriorityComparison,
  initiativesStatusMetaDataMap
} from '../../../../utils/actionPlanUtil';
import AppPopover from '../../../ActionPlan/components/AppPopover/AppPopover';
import { ActionsButton, ClearAllButton, ClearAllButtonText } from '../../SpaceStyles';
import {
  AddNew,
  BigPrioritySelectionOptionContainer,
  CheckCircle,
  EmptyStateText,
  InitiativeTitle,
  KeyResultValue,
  ObjectiveTitle,
  SelectionTitle,
  StatusTitle
} from './SpaceInitiativesTableStyles';
import SuggestionsButton from '../../../../components/SuggestionsButton/SuggestionsButton';
import sleep from 'sleep-promise';
import useActionPlanObjective from '../../../../hooks/useActionPlanObjective';
import InitiativeSuggestionCard from '../../../../components/InitiativeSuggestionCard/InitiativeSuggestionCard';

const LazyLoadedAddInitiativeModal = React.lazy(
  () => import('../../../../modals/InitiativeModal/InitiativeModal')
);

type OnChange = NonNullable<TableProps<ActionPlanInitiativeDetailedDto>['onChange']>;
type Filters = Parameters<OnChange>[1];
type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

interface SpaceInitiativesTableProps {
  initiatives: ActionPlanInitiativeDetailedDto[];
  objective?: ActionPlanObjectiveDto;
  refetch: () => void;
  includeArchived: boolean;
  setIncludeArchived: (value: boolean) => void;
  includeAssignee?: boolean;
  includeOwner?: boolean;
  includeTeams?: boolean;
  includeTags?: boolean;
  includeOkr?: boolean;
  includeSuggestions?: boolean;
  meeting?: SharedWorkspaceMeetingDto;
  onAddClick?: () => void
  onCreated?: (id: string) => void;
  small?: boolean;
}

const InitiativesTable = styled(Table)(() => ({
  '&.ant-table-wrapper': {
    '.ant-table': {
      '.ant-table-container': {
        '.ant-table-content': {
          '.ant-table-tbody': {
            '.ant-table-row': {
              '.ant-table-cell': {
                padding: '5px 10px'
              }
            }
          }
        }
      }
    }
  }
}));

const SpaceInitiativesTable = ({
  initiatives,
  objective,
  refetch,
  includeArchived,
  setIncludeArchived,
  includeAssignee,
  includeTeams,
  includeOwner,
  includeSuggestions,
  includeTags,
  includeOkr,
  meeting,
  onAddClick,
  onCreated,
  small
}: SpaceInitiativesTableProps) => {
  const [initiativeList, setInitiativeList] =
    useState<ActionPlanInitiativeDetailedDto[]>(initiatives);

  useEffect(() => {
    if (initiatives) {
      setInitiativeList(orderBy(initiatives, item => item.index, 'asc'));
    }
  }, [initiatives, setInitiativeList]);

  const { usersList } = useUsers();
  const { teams } = useTeams();
  const { accountTags } = useTags();
  const { showModal } = useModal();

  const [filteredInfo, setFilteredInfo] = useState<Filters>({});
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});
  const [suggestionListOpen, setSuggestionListOpen] = useState(false);
  const [loadSuggestion, setLoadSuggestion] = useState<boolean>(false);



  const clearAll = useCallback(() => {
    setFilteredInfo({});
    setSortedInfo({});
  }, [setFilteredInfo, setSortedInfo]);

  const { setCurrentObjective, setCurrentInitiative, setRefetch, setOpen } = useInitiativeSidebar();
  const { patchMeeting } = useSharedWorkspaceMeeting();
  const { createActivity } = useSharedWorkspaceMeetingActivity();
  const { bulkPatchActionPlanInitiatives } = useActionPlanInitiative();
  const { getInitiativesSuggestions, initiativeSuggestionList } = useActionPlanObjective(
    objective?.id
  );

  const oneSuggestionsClick = useCallback(() => {
    if (!suggestionListOpen) {
      setLoadSuggestion(true);
      sleep(1000);

      getInitiativesSuggestions().then(() => {
        setLoadSuggestion(false);
        setSuggestionListOpen(prev => !prev);
      });
    } else {
      setSuggestionListOpen(prev => !prev);
    }
  }, [getInitiativesSuggestions, suggestionListOpen]);

  const onAddInitiativeClick = useCallback(() => {
    const modal = showModal(LazyLoadedAddInitiativeModal, {
      onCancel: () => {
        modal.hide();
      },
      onConfirm: (initiativeTitle: string, id: string) => {
        if (meeting) {
          patchMeeting(meeting.spaceId, meeting.id, {
            data: { ...meeting.data, initiativeIds: [...(meeting.data.initiativeIds || []), id] }
          }).then(() => {
            refetch();
            createActivity({
              meetingId: meeting.id,
              entityType: SharedWorkspaceMeetingActivityDtoEntityType.initiative,
              entityName: initiativeTitle,
              entityId: id,
              action: SharedWorkspaceMeetingActivityDtoAction.create
            });
          });
        }
        if (onCreated) onCreated(id);
        refetch();
        modal.hide();
      }
    });
  }, [patchMeeting, meeting, showModal, refetch, createActivity, onCreated]);

  const addInitiativeSuggestionClick = useCallback(
    (initiativeSuggestion?: string) => {
      const modal = showModal(LazyLoadedAddInitiativeModal, {
        onClose: () => {
          modal.hide();
        },
        onCancel: () => {
          modal.hide();
        },
        onConfirm: (initiativeTitle: string, id: string) => {
          setSuggestionListOpen(false);
          if (meeting) {
            patchMeeting(meeting.spaceId, meeting.id, {
              data: { ...meeting.data, initiativeIds: [...(meeting.data.initiativeIds || []), id] }
            }).then(() => {
              refetch();
              createActivity({
                meetingId: meeting.id,
                entityType: SharedWorkspaceMeetingActivityDtoEntityType.initiative,
                entityName: initiativeTitle,
                entityId: id,
                action: SharedWorkspaceMeetingActivityDtoAction.create
              });
            });
          }
          if (onCreated) onCreated(id);
          refetch();
          modal.hide();
        },
        objective: objective,
        initiativeSuggestion: initiativeSuggestion,
        businessArea: objective?.businessArea
      });
    },
    [showModal, objective, createActivity, meeting, patchMeeting, refetch, onCreated]
  );

  const {
    setOpen: openObjective,
    setCurrentObjective: setCurrentObjectiveSidebar,
    setRefetch: setRefetchObjective
  } = useObjectiveSidebar();

  const objectives = useMemo(() => {
    const objectivesMap = new Map<string, ActionPlanObjectiveDto>();

    initiatives?.forEach(initiative => {
      if (initiative.objective?.id && !objectivesMap.has(initiative.objective.id)) {
        objectivesMap.set(initiative.objective.id, initiative.objective);
      }
    });

    return Array.from(objectivesMap.values());
  }, [initiatives]);

  const filtersOn = Object.keys(filteredInfo)?.length > 0 || Object.keys(sortedInfo)?.length > 0;

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1
      }
    })
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = initiativeList?.findIndex(i => i.id === active.id);
      const overIndex = initiativeList?.findIndex(i => i.id === over?.id);
      const newList = arrayMove(initiativeList, activeIndex, overIndex);
      setInitiativeList(newList);
      const items = newList.map((obj, i) => ({
        id: obj.id,
        index: i
      }));
      bulkPatchActionPlanInitiatives(items);
    }
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const actions = [
    {
      title: `${includeArchived ? 'Hide' : 'Show'} Archived`,
      icon: includeArchived ? (
        <VisibilityOffOutlinedIcon fontSize="small" />
      ) : (
        <VisibilityOutlinedIcon fontSize="small" />
      ),
      onAction: () => {
        setAnchorEl(null);
        setIncludeArchived(!includeArchived);
      }
    }
  ];

  return (
    <Stack width="100%" gap={2}>
      <Stack width="100%" direction="row">
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Stack>
            {filtersOn && (
              <ClearAllButton onClick={clearAll}>
                <HighlightOffIcon fontSize="small" />
                <ClearAllButtonText>Clear all</ClearAllButtonText>
              </ClearAllButton>
            )}
          </Stack>

          <Stack direction="row" alignItems="center" gap="4px">
            <AddButton
              onClick={onAddClick ? onAddClick : onAddInitiativeClick} />
            {includeSuggestions &&
              <SuggestionsButton
                opened={suggestionListOpen}
                isLoading={loadSuggestion}
                onClick={oneSuggestionsClick}
              />}
            <ActionsButton
              onClick={event => {
                event.stopPropagation();
                setAnchorEl(event.currentTarget);
              }}
            >
              <MoreHorizIcon />
            </ActionsButton>

            <AppPopover
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              handleClose={() => setAnchorEl(null)}
              customActions={actions}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack
        gap={1}>
        {suggestionListOpen && initiativeSuggestionList?.map((suggestion, index) => (
          <InitiativeSuggestionCard
            key={index}
            title={suggestion}
            onAddSuggestion={() => addInitiativeSuggestionClick(suggestion)}
          />
        ))}

        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            items={initiativeList?.map(i => i.id) || []}
            strategy={verticalListSortingStrategy}
          >
            <InitiativesTable
              size="middle"
              bordered
              rowKey={(record: ActionPlanInitiativeDetailedDto) => record.id}
              pagination={false}
              dataSource={initiativeList}
              components={{
                body: {
                  row: TableRow
                }
              }}
              locale={{
                emptyText: (
                  <Stack alignItems="center" gap="5px">
                    <EmptyStateText>No Initiatives</EmptyStateText>
                    <AddNew onClick={onAddClick}>Add New</AddNew>
                  </Stack>
                )
              }}
              onRow={(record: ActionPlanInitiativeDetailedDto) => ({
                style: {
                  cursor: 'pointer',
                  opacity: record.isArchived ? 0.5 : 1
                },
                onClick: () => {
                  setCurrentObjective(record.objective);
                  setCurrentInitiative(record);
                  setRefetch(() => refetch);
                  setOpen(true);
                }
              })}
              onChange={(
                pagination,
                filters: Record<string, FilterValue | null>,
                sorter: SorterResult<AnyObject> | SorterResult<AnyObject>[]
              ) => {
                setFilteredInfo(filters);
                setSortedInfo(sorter as Sorts);
              }}
            >
              <Column
                filterSearch
                title="Initiative"
                dataIndex="title"
                width={small && "360px"}
                key="title"
                ellipsis={small ? true : false}
                filters={initiatives?.map(initiative => ({
                  text: initiative.title,
                  value: initiative.title
                }))}
                onFilter={(value, record) => record.title?.includes(value as string)}
                filteredValue={filteredInfo?.title || null}
                sortOrder={sortedInfo.columnKey === 'title' ? sortedInfo.order : null}
                render={(title: string) => <Tooltip title={title}><InitiativeTitle>{title}</InitiativeTitle></Tooltip>}
                sorter={{
                  compare: (a: ActionPlanInitiativeDto, b: ActionPlanInitiativeDto) =>
                    a.title.localeCompare(b.title),
                  multiple: 1
                }}
              />

              <Column
                align="center"
                title="Status"
                dataIndex="status"
                key="status"
                render={(status: ActionPlanInitiativeDtoStatus) => (
                  <Stack alignItems="center">
                    <StatusTitle color={initiativesStatusMetaDataMap[status].circleColor}>
                      {initiativesStatusMetaDataMap[status].label}
                    </StatusTitle>
                  </Stack>
                )}
                filteredValue={filteredInfo?.status || null}
                filters={Object.keys(initiativesStatusMetaDataMap).map(status => ({
                  text: initiativesStatusMetaDataMap[status].label,
                  value: status
                }))}
                filterOnClose
                onFilter={(value, record) => record.status === value}
              />

              <Column
                align="center"
                title="Priority"
                dataIndex="priority"
                key="priority"
                render={(priority: ActionPlanInitiativeDtoPriority) => (
                  <Stack alignItems="center">
                    <BigPrioritySelectionOptionContainer
                      backgroundColor={initiativesPriorityColorsMap[priority].backgroundColor}
                    >
                      <CheckCircle color={initiativesPriorityColorsMap[priority].checkedColor} />
                      <SelectionTitle>
                        {initiativesPriorityColorsMap[priority].label}
                      </SelectionTitle>
                    </BigPrioritySelectionOptionContainer>
                  </Stack>
                )}
                filters={Object.keys(initiativesPriorityColorsMap).map(priority => ({
                  text: initiativesPriorityColorsMap[priority].label,
                  value: priority
                }))}
                filterOnClose
                onFilter={(value, record) => record.priority === value}
                sorter={{
                  compare: (a: ActionPlanInitiativeDto, b: ActionPlanInitiativeDto) =>
                    initiativesPriorityComparison[a.priority] -
                    initiativesPriorityComparison[b.priority],

                  multiple: 2
                }}
                filteredValue={filteredInfo?.priority || null}
                sortOrder={sortedInfo.columnKey === 'priority' ? sortedInfo.order : null}
              />

              {includeOwner && (
                <Column
                  title="Owner"
                  dataIndex="ownerId"
                  key="ownerId"
                  filterSearch
                  filters={usersList?.map(user => ({
                    text: user.firstName + ' ' + user.lastName,
                    value: user.id
                  }))}
                  filterOnClose
                  onFilter={(value, record) => record.ownerId === value}
                  filteredValue={filteredInfo?.ownerId || null}
                  render={(ownerId: string) => (
                    <Stack alignItems="center">
                      <UserView size="30px" userId={ownerId} viewOnly nameTooltip onlyAvatar />
                    </Stack>
                  )}
                  sorter={{
                    compare: (a: ActionPlanInitiativeDto, b: ActionPlanInitiativeDto, sortOrder: SortOrder) => {
                      const ownerA = usersList.find(user => user.id === a.ownerId)?.firstName;
                      const ownerB = usersList.find(user => user.id === b.ownerId)?.firstName;

                      if (sortOrder === "ascend") {
                        return (ownerA || "z").localeCompare(ownerB || "z");
                      } else {
                        return (ownerA || "a").localeCompare(ownerB || "a");
                      }
                    },
                    multiple: 4
                  }}
                  sortOrder={sortedInfo.columnKey === 'ownerId' ? sortedInfo.order : null}
                />
              )}

              {includeAssignee && (
                <Column
                  title="Assignee"
                  dataIndex="assigneeId"
                  key="assigneeId"
                  filterSearch
                  filters={usersList?.map(user => ({
                    text: user.firstName + ' ' + user.lastName,
                    value: user.id
                  }))}
                  filterOnClose
                  onFilter={(value, record) => record.assigneeId === value}
                  filteredValue={filteredInfo?.assigneeId || null}
                  render={(assigneeId: string) => (
                    <Stack alignItems="center">
                      <UserView size="30px" userId={assigneeId} viewOnly onlyAvatar />
                    </Stack>
                  )}
                />
              )}

              {includeTeams && (
                <Column
                  title="Teams"
                  align="center"
                  dataIndex="teamIds"
                  key="teamIds"
                  render={(teamIds: string[]) => (
                    <Stack alignItems="center">
                      <TeamsSelect size="30px" teamIds={teamIds} viewOnly tooltip />
                    </Stack>
                  )}
                  filterSearch
                  filters={teams?.map(team => ({ text: team.name, value: team.id }))}
                  filterOnClose
                  onFilter={(value, record) => record.teamIds?.includes(value as string)}
                  filteredValue={filteredInfo?.teamIds || null}
                />
              )}

              {includeOkr && (
                <Column
                  title="OKR"
                  dataIndex="objective"
                  key="objective"
                  filterSearch
                  filters={objectives?.map(objective => ({
                    text: objective.title,
                    value: objective.id
                  }))}
                  filterOnClose
                  onFilter={(value, record) => record.objective?.id === value}
                  filteredValue={filteredInfo?.objective || null}
                  render={(objective: ActionPlanObjectiveDto) => (
                    <Stack alignItems="start" width="100%">
                      <ObjectiveTitle
                        onClick={event => {
                          event.stopPropagation();
                          setCurrentObjectiveSidebar(objective);
                          setRefetchObjective(() => refetch);
                          openObjective(true);
                        }}
                      >
                        {objective?.title}
                      </ObjectiveTitle>
                      <Stack direction="row" alignItems="center" gap="5px">
                        <KeyResultValue>
                          {formatValue(
                            objective?.keyResult.unit,
                            objective?.keyResult.startingPoint || 0
                          )}
                        </KeyResultValue>

                        <GoalArrow />

                        <Stack gap="4px" direction="row" alignItems="center">
                          🎯
                          <KeyResultValue>
                            {formatValue(
                              objective?.keyResult.unit,
                              objective?.keyResult.value || 0
                            )}
                          </KeyResultValue>
                        </Stack>
                      </Stack>

                      <KeyResultValue>{objective?.keyResult.subject}</KeyResultValue>
                    </Stack>
                  )}
                />
              )}

              {includeTags && (
                <Column
                  title="Tags"
                  align="center"
                  dataIndex="tags"
                  key="tags"
                  render={(tags: string[]) => (
                    <Grid spacing={1} container>
                      {tags?.map(tag => (
                        <Grid key={tag} item>
                          <Tooltip title={tag}>
                            <Chip
                              label={tag}
                              sx={{
                                fontSize: '12px',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                maxWidth: '150px',
                                borderRadius: '8px',
                                backgroundColor: palette.blue[8],
                                color: palette.common.darkBlue
                              }}
                              variant="outlined"
                            />
                          </Tooltip>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                  filterSearch
                  filters={accountTags?.tags?.map(tag => ({ text: tag, value: tag }))}
                  filterOnClose
                  onFilter={(value, record) => record.tags?.includes(value as string)}
                  filteredValue={filteredInfo?.tags || null}
                />
              )}
            </InitiativesTable>
          </SortableContext>
        </DndContext>
      </Stack>
    </Stack>
  );
};
export default SpaceInitiativesTable;
