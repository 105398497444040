import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { AttachMoney, Percent, Tag } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  DialogProps,
  InputAdornment,
  MenuItem,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { unabbreviateNumber } from 'js-abbreviation-number';
import { enqueueSnackbar } from 'notistack';

import {
  ActionPlanObjectiveDetailedDto,
  ActionPlanObjectiveDtoType,
  BusinessAreaMetadataDto,
  BusinessAreaMetadataDtoType,
  KeyResultUnit,
  MetricDefinitionDto,
  MetricDefinitionDtoPeriod,
  MetricDefinitionDtoSummarize,
  MetricDefinitionDtoTrendType
} from '../../api/generated';
import { TOAST_VARIANTS } from '../../components/NotificationToast/NotificationToast';
import TeamsSelect from '../../components/Team/TeamsSelect';
import UserView from '../../components/User/UserView';
import useActionPlanBoardContext, {
  mainBoardID
} from '../../hooks/context-providers/useActionPlanBoardContext';
import useActionPlanObjective from '../../hooks/useActionPlanObjective';
import useAuth, { AdminRoleId } from '../../hooks/useAuth';
import useBusinessAreaModulesMetadata from '../../hooks/useBusinessAreaModulesMetadata';
import useMetricDefinition from '../../hooks/useMetricDefinition';
import actionPlanTemplates, { ActionPlanTemplate } from '../../mappings/actionPlanTemplates';
import Editor from '../../screens/BizAreaModules/components/Editor';
import palette from '../../theme/palette';
import { UserTypes } from '../../utils/commonTypes';
import { generateEmptySpaces } from '../../utils/textFormater';
import {
  AutoCompleteBlock,
  CancelText,
  CreateButton,
  DescriptionContainer,
  FormInput,
  InputDescription,
  InputTitle,
  KeyResultTypeSelection,
  MetricDropDown,
  MetricDropDownTextInputSelect,
  ModalContainer,
  PeriodDropDown,
  RedAsterisk,
  SelectInputContainer,
  TextInputSelect,
  Title,
  TrendDropDown,
  UnitIcon,
  ValueInput,
  VisibleToDropDown
} from './ObjectiveModalStyles';

interface ObjectiveModalProps extends DialogProps {
  businessArea?: string;
  onCancel: () => void;
  onConfirm: (id?: string, title?: string) => void;
  title?: string;
  krValue?: number;
  krMetric?: MetricDefinitionDto;
}

export enum Visiblity {
  EVERYONE = 'everyone',
  ADMINS_ONLY = 'adminsOnly'
}

export const keyResultTypes = [
  {
    value: KeyResultUnit.number,
    label: '#',
    icon: <Tag />
  },
  {
    value: KeyResultUnit.currency,
    label: '$',
    icon: <AttachMoney />
  },
  {
    value: KeyResultUnit.percentage,
    label: '%',
    icon: <Percent />
  }
] as const;

const ObjectiveModal: FC<ObjectiveModalProps> = ({
  businessArea,
  onCancel,
  onConfirm,
  krValue,
  krMetric,
  title,
  ...props
}: ObjectiveModalProps) => {
  const { metrics } = useMetricDefinition(null, true);

  const updateKeyResult = (value: string) => {
    setKeyResultValue(value);
  };

  const { businessAreasMetadata } = useBusinessAreaModulesMetadata();
  const [currentBusinessArea, setCurrentBusinessArea] = useState<BusinessAreaMetadataDto>(null);
  useEffect(() => {
    if (businessArea) {
      setCurrentBusinessArea(
        businessAreasMetadata.find(ba => ba.id === businessArea) || {
          name: '',
          id: ''
        }
      );
    }
  }, [businessAreasMetadata, businessArea]);

  const template: ActionPlanTemplate = actionPlanTemplates.find(
    temp => temp.businessArea === currentBusinessArea?.id
  );

  const [keyResultType, setKeyResultType] = useState<KeyResultUnit>(
    template ? template.krUnit : KeyResultUnit.number
  );

  const [period, setPeriod] = useState<MetricDefinitionDtoPeriod>(
    MetricDefinitionDtoPeriod.monthly
  );
  const [trend, setTrend] = useState<MetricDefinitionDtoTrendType>(
    MetricDefinitionDtoTrendType.more
  );
  const [summarizeType, setSummarizeType] = useState<MetricDefinitionDtoSummarize>(
    MetricDefinitionDtoSummarize.lastValue
  );

  const [teamIds, setTeamIds] = useState<string[]>([]);

  const [objectiveTitle, setObjectiveTitle] = useState(title || '');
  const [description, setDescription] = useState('');
  const [keyResultDescription, setkeyResultDescription] = useState('');
  const { createActionPlanObjective, refetchDetailedObjectives } = useActionPlanObjective();
  const [startingPointValue, setStartingPointValue] = useState('');
  const [keyResultValue, setKeyResultValue] = useState(krValue ? krValue : '');
  const [visibility, setVisibility] = useState<Visiblity>(Visiblity.EVERYONE);
  const isValidObjective = !!objectiveTitle && !!keyResultValue && !!keyResultDescription;
  const { selectedBoard } = useActionPlanBoardContext();
  const { user } = useAuth();
  const [ownerId, setOwnerId] = useState<string>(user?.id);

  const [chosenMetric, setChosenMetric] = useState<MetricDefinitionDto | string | null>(
    krMetric || null
  );
  const [showTrendOptions, setShowTrendOptions] = useState<boolean>(false);

  useEffect(() => {
    if (chosenMetric !== null) {
      if (typeof chosenMetric === 'string') {
        setkeyResultDescription(chosenMetric);
        setShowTrendOptions(true);
      } else {
        setkeyResultDescription(chosenMetric.name);
        setShowTrendOptions(false);
      }
    } else {
      setkeyResultDescription('');
    }
  }, [chosenMetric, setkeyResultDescription]);

  const constructedObjective: Partial<ActionPlanObjectiveDetailedDto> = useMemo(() => {
    try {
      const startingPoint = unabbreviateNumber(startingPointValue || '0', ['', 'k', 'm', 'b']) || 0;
      const ketResult = unabbreviateNumber(keyResultValue || '0', ['', 'k', 'm', 'b']) || 0;

      return {
        type: ActionPlanObjectiveDtoType.businessArea,
        businessArea: currentBusinessArea?.id,
        title: objectiveTitle,
        description: description,
        boardId: selectedBoard?.id !== mainBoardID ? selectedBoard.id : null,
        ownerId: ownerId || null,
        teamIds: teamIds,
        keyResult: {
          unit: keyResultType,
          value: ketResult,
          subject: keyResultDescription,
          startingPoint: startingPoint,
          period: period,
          trendType: trend,
          summarize: summarizeType
        },
        metricId: typeof chosenMetric !== 'string' ? chosenMetric.id : null,
        adminOnly: visibility === Visiblity.ADMINS_ONLY
      };
    } catch (error) {
      console.error(error);
    }
  }, [
    startingPointValue,
    keyResultValue,
    currentBusinessArea?.id,
    objectiveTitle,
    description,
    selectedBoard?.id,
    ownerId,
    teamIds,
    keyResultType,
    keyResultDescription,
    period,
    trend,
    summarizeType,
    chosenMetric,
    visibility
  ]);

  const createObjective = useCallback(() => {
    if (isValidObjective)
      createActionPlanObjective(constructedObjective)
        .then(created => {
          onConfirm(created?.id, created?.title);
          refetchDetailedObjectives();
          enqueueSnackbar({
            variant: 'custom',
            customProps: {
              caption: 'Objective Added Successfully',
              variant: TOAST_VARIANTS.SUCCESS
            }
          });
        })
        .catch(() => {
          enqueueSnackbar({
            variant: 'custom',
            customProps: {
              caption: 'Error Adding Objective',
              variant: TOAST_VARIANTS.ERROR
            }
          });
        });
  }, [
    constructedObjective,
    onConfirm,
    refetchDetailedObjectives,
    isValidObjective,
    createActionPlanObjective
  ]);

  const handleBaSelected = (
    event: React.SyntheticEvent,
    newValue: BusinessAreaMetadataDto | null
  ) => {
    setCurrentBusinessArea(newValue);
  };

  return (
    <ModalContainer maxWidth="lg" aria-labelledby="add-objective" {...props}>
      <Stack direction="row" justifyContent="space-between">
        <Title>{`Create new ${currentBusinessArea?.name || ''} objective`}</Title>
        <CloseIcon
          sx={{ cursor: 'pointer', ':hover': { transform: 'scale:1.5' } }}
          onClick={() => onCancel()}
        />
      </Stack>
      <Stack gap={2} marginTop={1}>
        {!businessArea && (
          <Stack marginBottom={1}>
            <Stack direction="row">
              <InputTitle>Business Area</InputTitle>
              <RedAsterisk>{generateEmptySpaces(1)}*</RedAsterisk>
            </Stack>

            <AutoCompleteBlock
              height="40px"
              disableClearable
              options={businessAreasMetadata.filter(
                ba => ba.actionPlanDefaultIndex || ba.type === BusinessAreaMetadataDtoType.custom
              )}
              getOptionLabel={(option: BusinessAreaMetadataDto) => option?.name || ''}
              onChange={handleBaSelected}
              value={currentBusinessArea}
              renderInput={params => (
                <TextInputSelect {...params} placeholder="Choose Business Area" />
              )}
            />
          </Stack>
        )}

        <Stack direction="row" alignItems="start" justifyContent="space-between">
          <Stack width="100%">
            <Stack direction="row">
              <InputTitle>Objective</InputTitle>
              <RedAsterisk>{generateEmptySpaces(1)}*</RedAsterisk>
            </Stack>

            <ValueInput
              fullWidth
              value={objectiveTitle}
              onChange={event => setObjectiveTitle(event.currentTarget.value)}
              placeholder={template ? 'e.g. ' + template.objective : 'Type Objective'}
            />
          </Stack>
        </Stack>
        <Stack width="100%">
          <InputTitle>Description</InputTitle>
          <DescriptionContainer>
            <Editor
              fontSize="14px"
              showBorder={false}
              defaultValue={description}
              value={description}
              onChange={value => setDescription(value)}
            />
          </DescriptionContainer>
        </Stack>

        <Stack
          justifyContent="space-between"
          width="100%"
          direction="row"
          alignItems="start"
          gap={2}
        >
          <Stack gap="4px">
            <InputTitle>Owner</InputTitle>
            <UserView
              onlyAvatar
              size="40px"
              tooltip="Owner"
              userId={ownerId}
              onChange={(userId: string) => setOwnerId(userId)}
            />
          </Stack>

          <Stack>
            <InputTitle>Teams</InputTitle>
            <TeamsSelect size="40px" tooltip teamIds={teamIds} onChange={setTeamIds} />
          </Stack>

          {(user.type !== UserTypes.USER || user.roleId === AdminRoleId) && (
            <Stack width="30%">
              <InputTitle>Visible to</InputTitle>
              <VisibleToDropDown
                value={visibility}
                onChange={event => setVisibility(event.target.value as Visiblity)}
              >
                <MenuItem value={Visiblity.EVERYONE}>
                  <Typography variant="caption">Everyone</Typography>
                </MenuItem>
                <MenuItem value={Visiblity.ADMINS_ONLY}>
                  <Typography variant="caption">Admins only</Typography>
                </MenuItem>
              </VisibleToDropDown>
            </Stack>
          )}
        </Stack>

        <Stack direction="row" width="100%" gap={2} justifyContent="space-between">
          <Stack width="75%">
            <Stack direction="row" alignItems="center">
              <InputTitle>Key result (Yearly)</InputTitle>

              <RedAsterisk>{generateEmptySpaces(1)}*</RedAsterisk>
            </Stack>
            <InputDescription>
              Choose an existing metric for this KR or name a new metric
            </InputDescription>

            <Stack direction="row">
              <Stack>
                <SelectInputContainer>
                  <FormInput
                    sx={{
                      borderRadius: '8px 0px 0px 8px'
                    }}
                    value={keyResultValue}
                    disableUnderline
                    placeholder={
                      template
                        ? template.krValue
                        : keyResultType === 'currency'
                        ? '300k / 2.5m / 10000'
                        : 'Insert value'
                    }
                    onChange={e => updateKeyResult(e.target.value.replace(/[^kmb.\d-]/g, ''))}
                    startAdornment={
                      <KeyResultTypeSelection
                        id="outlined-select-keyresult"
                        value={keyResultType}
                        onChange={(event: SelectChangeEvent<KeyResultUnit>) =>
                          setKeyResultType(event.target.value as KeyResultUnit)
                        }
                      >
                        {keyResultTypes.map(keyResultType => (
                          <MenuItem key={keyResultType.value} value={keyResultType.value}>
                            <UnitIcon> {keyResultType.icon}</UnitIcon>
                          </MenuItem>
                        ))}
                      </KeyResultTypeSelection>
                    }
                  />
                </SelectInputContainer>
              </Stack>

              <Stack width="100%">
                <MetricDropDown
                  noOptionsText="No available metrics"
                  options={metrics}
                  freeSolo
                  getOptionLabel={(option: MetricDefinitionDto | string) =>
                    typeof option === 'string' ? option : option.name
                  }
                  renderOption={(
                    props: React.HTMLAttributes<HTMLLIElement>,
                    option: MetricDefinitionDto | string
                  ) => (
                    <li
                      {...props}
                      key={typeof option === 'string' ? crypto.randomUUID() : option.id}
                    >
                      {typeof option === 'string' ? option : option.name}
                    </li>
                  )}
                  onInputChange={(event: React.SyntheticEvent, value: string) =>
                    setChosenMetric(value)
                  }
                  onChange={(
                    event: React.SyntheticEvent,
                    newValue: MetricDefinitionDto | string | null
                  ) => setChosenMetric(newValue)}
                  value={chosenMetric}
                  renderInput={params => (
                    <MetricDropDownTextInputSelect
                      {...params}
                      placeholder="Choose metric / write new metric name"
                    />
                  )}
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack marginTop="25px" width="30%">
            <InputTitle>Baseline</InputTitle>
            <FormInput
              value={startingPointValue}
              disableUnderline
              placeholder={
                template
                  ? template.krStartingPoint
                  : keyResultType === 'currency'
                  ? '300k / 2.5m / 100'
                  : 'Insert value'
              }
              onChange={e => setStartingPointValue(e.target.value.replace(/[^kmb.\d-]/g, ''))}
              startAdornment={
                <InputAdornment position="start">
                  <UnitIcon>
                    {Object.values(keyResultTypes).find(type => type.value === keyResultType).icon}
                  </UnitIcon>
                </InputAdornment>
              }
            />
          </Stack>
        </Stack>

        {chosenMetric && showTrendOptions && (
          <Stack gap={1} marginTop={2}>
            <InputDescription>Progress settings for new metric</InputDescription>
            <Stack direction="row" alignItems="start" justifyContent="space-between">
              <Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <InputTitle>Period</InputTitle>
                  <Tooltip title="Progress will be calculated for the chosen period">
                    <InfoOutlinedIcon
                      style={{
                        color: palette.common.darkBlue,
                        opacity: 0.6,
                        width: '18px',
                        height: '18px'
                      }}
                    />
                  </Tooltip>
                </Stack>

                <PeriodDropDown
                  value={period}
                  onChange={event => setPeriod(event.target.value as MetricDefinitionDtoPeriod)}
                >
                  <MenuItem value={MetricDefinitionDtoPeriod.monthly}>
                    <Typography variant="caption">Monthly</Typography>
                  </MenuItem>
                  <MenuItem value={MetricDefinitionDtoPeriod.quarterly}>
                    <Typography variant="caption">Quarterly</Typography>
                  </MenuItem>
                  <MenuItem value={MetricDefinitionDtoPeriod.semiannually}>
                    <Typography variant="caption">Semiannually</Typography>
                  </MenuItem>
                  <MenuItem value={MetricDefinitionDtoPeriod.annually}>
                    <Typography variant="caption">Annually</Typography>
                  </MenuItem>
                </PeriodDropDown>
              </Stack>

              <Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <InputTitle>Trend</InputTitle>
                  <Tooltip title="Determine if the progress trend should be higer or lower">
                    <InfoOutlinedIcon
                      style={{
                        color: palette.common.darkBlue,
                        opacity: 0.6,
                        width: '18px',
                        height: '18px'
                      }}
                    />
                  </Tooltip>
                </Stack>
                <TrendDropDown
                  value={trend}
                  onChange={(e: SelectChangeEvent) =>
                    setTrend(e.target.value as MetricDefinitionDtoTrendType)
                  }
                >
                  <MenuItem value={MetricDefinitionDtoTrendType.more}>
                    <Typography variant="caption"> Higher is better</Typography>
                  </MenuItem>
                  <MenuItem value={MetricDefinitionDtoTrendType.less}>
                    <Typography variant="caption">Lower is better</Typography>
                  </MenuItem>
                </TrendDropDown>
              </Stack>

              <Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <InputTitle>Aggregation Method</InputTitle>
                  <Tooltip title="How the data points are aggregated to form the metric monthly value">
                    <InfoOutlinedIcon
                      style={{
                        color: palette.common.darkBlue,
                        opacity: 0.6,
                        width: '18px',
                        height: '18px'
                      }}
                    />
                  </Tooltip>
                </Stack>
                <TrendDropDown
                  value={summarizeType}
                  onChange={(e: SelectChangeEvent) =>
                    setSummarizeType(e.target.value as MetricDefinitionDtoSummarize)
                  }
                >
                  <MenuItem value={MetricDefinitionDtoSummarize.lastValue}>
                    <Typography variant="caption">Last Value</Typography>
                  </MenuItem>

                  <MenuItem value={MetricDefinitionDtoSummarize.avg}>
                    <Typography variant="caption">Average</Typography>
                  </MenuItem>

                  <MenuItem value={MetricDefinitionDtoSummarize.sum}>
                    <Typography variant="caption">Sum</Typography>
                  </MenuItem>
                </TrendDropDown>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <CancelText
          onClick={() => {
            onCancel();
          }}
        >
          Cancel
        </CancelText>
        <CreateButton disabled={!isValidObjective} onClick={createObjective}>
          Create
        </CreateButton>
      </Stack>
    </ModalContainer>
  );
};

export default ObjectiveModal;
