import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Stack } from '@mui/material';

import { PostDto } from '../../../api/generated';
import { ReactComponent as ValidatedAnswerAvatar } from '../../../assets/validatedAnswerAvatar.svg';
import { ReactComponent as ValidatedAnswerLogo } from '../../../assets/validatedAnswerLogo.svg';
import { toPostView } from '../../../services/linker';
import palette from '../../../theme/palette';
import FreeTextViewer from '../../BizAreaModules/components/FreeTextViewer';
import { ValidatedAnswerContainer, TagsContainer, ValidatedAnswerText, Tag } from '../ForumStyles';
import AuthorView from './AuthorView';
import { PostContainer, PostFooter, TitleContainer, TitleText } from './PostCardStyles';
import SavePost from './SavePost';

interface PostCardProps {
  post: PostDto;
  setFilter?: (value: string) => void;
}

const PostCard = ({ post, setFilter }: PostCardProps) => {
  const navigate = useNavigate();

  return (
    <PostContainer onClick={() => navigate(toPostView(post.id))}>
      <TitleContainer>
        <TitleText>{post.title}</TitleText>

        <Stack direction="row" alignItems="center" gap={4}>
          <TagsContainer>
            {post?.tags.map(tag => (
              <Tag
                key={tag}
                onClick={event => {
                  event.stopPropagation();
                  if (setFilter) setFilter(tag);
                }}
              >
                {tag}
              </Tag>
            ))}
          </TagsContainer>

          <SavePost post={post} />
        </Stack>
      </TitleContainer>

      <Stack direction="row" minHeight="10px">
        <Stack width="100%" marginLeft="5px">
          {post.content && (
            <FreeTextViewer
              cursor="pointer"
              fontColor={palette.grey[1200]}
              fontSize="14px"
              fontFamily="Plus Jakarta Sans"
              value={post.content}
            />
          )}
        </Stack>
      </Stack>
      <PostFooter>
        <AuthorView
          userId={post.user.userId}
          userType={post.user.userType}
          industry={post.user.industry}
          createdAt={post.createdAt}
        />

        <Stack direction="row" alignItems="center" gap={2}>
          {post.journeyAnswer && (
            <ValidatedAnswerContainer>
              <ValidatedAnswerAvatar width="25px" height="25px" />
              <ValidatedAnswerText>Contribution by</ValidatedAnswerText>
              <ValidatedAnswerLogo />
            </ValidatedAnswerContainer>
          )}
        </Stack>
      </PostFooter>
    </PostContainer>
  );
};

export default PostCard;
