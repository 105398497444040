import { Components } from '@mui/material/styles';

const MuiIconButton: Components['MuiIconButton'] = {
  styleOverrides: {
    root: {
      marginRight: 0
    }
  }
};

export default MuiIconButton;
