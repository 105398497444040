import React, { useEffect, useRef, useState } from 'react';
import { Comment } from 'react-loader-spinner';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { Drawer, InputAdornment } from '@mui/material';

import { useCreateAnswer } from '../../../api/generated';
import Loader from '../../../components/Loader/Loader';
import useAskJourneyDrawer from '../../../hooks/context-providers/useAskJourneyDrawer';
import useAuth from '../../../hooks/useAuth';
import palette from '../../../theme/palette';
import { AnswerContent, InputForAnswer, ContentText } from './AskJourneyDrawerStyles';

type ChatMessage = {
  text: string;
  finished?: boolean;
};

const AskJourneyDrawer = () => {
  const [question, setQuestion] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { tokens } = useAuth();
  const encodedToken = btoa(tokens.idToken);
  const url = process.env.REACT_APP_API_BASE.replaceAll('https', 'wss') + '/askUs';
  const { sendJsonMessage, lastJsonMessage, readyState, getWebSocket } = useWebSocket<ChatMessage>(
    url + '?token=' + encodedToken,
    {
      shouldReconnect: () => true,
      onError: (event: WebSocketEventMap['error']) => console.log(event)
    }
  );
  const [isWriting, setIsWriting] = useState<boolean>(false);
  const [pause, setPause] = useState<boolean>(false);
  const [answer, setAnswer] = useState<string>('');
  const [openAnswer, setOpenAnswer] = useState<boolean>(false);
  const { isOpen, setIsOpen, setIsAnswering } = useAskJourneyDrawer();

  useEffect(() => {
    if (lastJsonMessage !== null) {
      if (!pause) {
        setAnswer(lastJsonMessage.text);
      }

      if (lastJsonMessage.finished) {
        setIsWriting(false);
      } else {
        if (!isWriting) {
          setIsWriting(true);
          setIsAnswering(true);
        }

        if (!pause && isLoading && readyState == ReadyState.OPEN) {
          setIsLoading(false);
        }
      }
    }
  }, [lastJsonMessage, isLoading, isWriting, pause, readyState, setIsAnswering]);

  useEffect(() => {
    if (!isWriting) {
      setIsAnswering(false);
    }
  }, [isWriting, setIsAnswering]);

  const onSubmit = async () => {
    setOpenAnswer(true);
    setPause(false);
    setIsLoading(true);
    sendJsonMessage({ text: question });
  };

  const { mutateAsync: createAnswer } = useCreateAnswer();

  const handleClose = async () => {
    if (!isWriting && answer) {
      try {
        await createAnswer({ data: { question: question, openAiAnswer: lastJsonMessage.text } });
      } catch (err) {
        console.log(err);
      } finally {
        setQuestion('');
        setAnswer('');
        getWebSocket()?.close();
        setPause(true);
        setOpenAnswer(false);
      }
    }

    setIsOpen(false);
  };

  const answerRef = useRef(null);

  return (
    <>
      <Drawer
        anchor="right"
        open={isOpen}
        variant="temporary"
        onClose={handleClose}
        SlideProps={{
          style: {
            borderRadius: 5
          }
        }}
        ModalProps={{
          keepMounted: true
        }}
        PaperProps={{
          style: {
            position: 'absolute',
            top: 100,
            right: 20,
            width: 500,
            height: openAnswer ? answerRef?.current?.clientHeight + 100 : 55,
            padding: 10,
            maxHeight: 600
          }
        }}
      >
        <InputForAnswer
          sx={{ borderColor: 'white' ,p:1, pl:0}}
          value={question}
          placeholder="Write your question and enter.."
          multiline
          onChange={event => {
            const { value } = event.target;
            setQuestion(value);
          }}
          onKeyPress={e => {
            if (e.key === 'Enter') {
              onSubmit();
              e.preventDefault();
            }
          }}
          startAdornment={
            <InputAdornment position="start">
              <Comment
                height={35}
                width={35}
                visible={true}
                wrapperStyle={{}}
                color={palette.background.default}
                backgroundColor={palette.primary.main}
              />
            </InputAdornment>
          }
          disableUnderline
        />
        <AnswerContent>
          {isLoading ? <Loader /> : <ContentText ref={answerRef}>{answer}</ContentText>}
        </AnswerContent>
      </Drawer>
    </>
  );
};

export default AskJourneyDrawer;
