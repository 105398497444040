import { UserFeatureFeature } from '../api/generated';
import { NavigationItemsKey } from '../hooks/useNavigationListManifest';
import {
  toAccountSettings,
  toActionPlan,
  toAskJourney,
  toBusinessArea,
  toMeetings,
  toMetrics
} from '../services/linker';
import businessAreasMetaData from './businessAreasMetaData';

type FeatureRoutes = {
  [key: string]: {
    feature: UserFeatureFeature;
    route: string;
  };
};

const featureRoutes: FeatureRoutes = {
  [NavigationItemsKey.Meetings]: {
    feature: UserFeatureFeature.meetings,
    route: toMeetings() + '/*'
  },
  [NavigationItemsKey.AskJourney]: { feature: UserFeatureFeature.ask, route: toAskJourney() },
  [NavigationItemsKey.Metrics]: { feature: UserFeatureFeature.metrics, route: toMetrics() },
  [NavigationItemsKey.ActionPlan]: {
    feature: UserFeatureFeature.actionPlan,
    route: toActionPlan()
  },
  [NavigationItemsKey.AccountSettings]: {
    feature: UserFeatureFeature.settings,
    route: toAccountSettings()
  },
  [businessAreasMetaData.board.id]: {
    feature: UserFeatureFeature.businessAreaboard,
    route: toBusinessArea(businessAreasMetaData.board.id)
  },
  [businessAreasMetaData.market.id]: {
    feature: UserFeatureFeature.businessAreamarket,
    route: toBusinessArea(businessAreasMetaData.market.id)
  },
  [businessAreasMetaData.competition.id]: {
    feature: UserFeatureFeature.businessAreacompetition,
    route: toBusinessArea(businessAreasMetaData.competition.id)
  },
  [businessAreasMetaData.strategy.id]: {
    feature: UserFeatureFeature.businessAreastrategy,
    route: toBusinessArea(businessAreasMetaData.strategy.id)
  },
  [businessAreasMetaData.sales.id]: {
    feature: UserFeatureFeature.businessAreasales,
    route: toBusinessArea(businessAreasMetaData.sales.id)
  },
  [businessAreasMetaData.marketing.id]: {
    feature: UserFeatureFeature.businessAreamarketing,
    route: toBusinessArea(businessAreasMetaData.marketing.id)
  },
  [businessAreasMetaData.customerSuccess.id]: {
    feature: UserFeatureFeature.businessAreacustomerSuccess,
    route: toBusinessArea(businessAreasMetaData.customerSuccess.id)
  },
  [businessAreasMetaData.people.id]: {
    feature: UserFeatureFeature.businessAreapeople,
    route: toBusinessArea(businessAreasMetaData.people.id)
  },
  [businessAreasMetaData.finance.id]: {
    feature: UserFeatureFeature.businessAreafinance,
    route: toBusinessArea(businessAreasMetaData.finance.id)
  },
  [businessAreasMetaData.operations.id]: {
    feature: UserFeatureFeature.businessAreaoperations,
    route: toBusinessArea(businessAreasMetaData.operations.id)
  },
  [businessAreasMetaData.esg.id]: {
    feature: UserFeatureFeature.businessAreaesg,
    route: toBusinessArea(businessAreasMetaData.esg.id)
  }
};

export default featureRoutes;
