import { useCallback, useMemo } from 'react';

import {
  NoteDto,
  useBulkPatchNotes,
  useCreateNote,
  useDeleteNote,
  useListNotes,
  usePatchNote
} from '../api/generated';

export const useNotes = () => {
  const { data: notesResponse, isLoading: notesLoading, refetch: refetchNotes } = useListNotes();

  const { mutateAsync: mutateCreateNote } = useCreateNote();

  const { mutateAsync: mutatePatchNote } = usePatchNote();

  const { mutateAsync: mutateDeleteNote } = useDeleteNote();

  const { mutateAsync: mutateBulkPatchNotes } = useBulkPatchNotes();

  const createNote = useCallback(
    async (note: Partial<NoteDto>) => {
      const response = await mutateCreateNote({ data: note });
      return response?.items[0]?.id;
    },
    [mutateCreateNote]
  );

  const deleteNote = useCallback(
    async (id: string) => {
      await mutateDeleteNote({ id });
    },
    [mutateDeleteNote]
  );

  const patchNote = useCallback(
    async (id: string, note: Partial<NoteDto>) => {
      await mutatePatchNote({ id, data: note });
    },
    [mutatePatchNote]
  );

  const bulkPatchNotes = useCallback(
    async (data: NoteDto[]) => {
      await mutateBulkPatchNotes({ data: { items: data } });
    },
    [mutateBulkPatchNotes]
  );

  const notesList = useMemo(() => notesResponse?.items || [], [notesResponse?.items]);

  const patchNotesListOrder = useCallback(
    (startIndex: number, endIndex: number, notesList: NoteDto[]) => {
      const notesDraft = Array.from(notesList);
      const [removedObjective] = notesDraft.splice(startIndex, 1);
      notesDraft.splice(endIndex, 0, removedObjective);
      const newindexAndIdNotessList = notesDraft.map((obj, i) => ({
        id: obj.id,
        index: i
      }));
      return newindexAndIdNotessList;
    },
    []
  );

  return useMemo(
    () => ({
      notesList,
      notesLoading,
      createNote,
      patchNote,
      bulkPatchNotes,
      patchNotesListOrder,
      deleteNote,
      refetchNotes
    }),
    [
      bulkPatchNotes,
      createNote,
      deleteNote,
      notesList,
      notesLoading,
      patchNote,
      patchNotesListOrder,
      refetchNotes
    ]
  );
};

export default useNotes;
