import React, { useEffect, useState } from 'react';

import AddBoxIcon from '@mui/icons-material/AddBox';

import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';

import {
  CircularProgress,
  Stack,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses
} from '@mui/material';
import { orderBy } from 'lodash';


import {
  MetricAggregationDataPointDto,
  MetricDataPointDto,
  MetricDefinitionDto,
  MetricDefinitionDtoPeriod,
  MetricDefinitionDtoSource,
  MetricTargetDefinitionDto
} from '../../../../../../api/generated';
import useAuth from '../../../../../../hooks/useAuth';
import useMetricDataPoints from '../../../../../../hooks/useMetricDataPoints';
import useMetricDefinition from '../../../../../../hooks/useMetricDefinition';
import palette from '../../../../../../theme/palette';
import {
  getCurrentPeriodDate,
  getDatePeriod,
  getPeriodDate,
  toUtcIsoDate
} from '../../../../../../utils/dateUtil';

import {
  AddButtonContainer,
  AddNewText,
  ColumnTitle,
  EmptyDataPointsTitle,
  TooltipText
} from './MetricDataPointsTableStyles';
import MetricDataPointRow from '../MetricDataPointRow/MetricDataPointRow';

export type TargetDetails = {
  target: MetricTargetDefinitionDto;
  index: number;
};

export type PeriodData = {
  index: number;
  label: string;
};
;

const ActionTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: palette.grey[1300],
    maxWidth: 400,
    border: '1px solid #dadde9'
  }
}));


const MetricDataPointsTable = ({
  currentMetric,
  refetch
}: {
  currentMetric: MetricDefinitionDto;
  refetch: () => void;
}) => {
  const isAuto = currentMetric.source === MetricDefinitionDtoSource.auto;
  const {
    metricDefinition,
    refetchMetricDefinition,
    calcMetricDefinitionProgress
  } = useMetricDefinition(currentMetric.id);

  const {
    metricDatapoints: aggregatedMetricDataPoints,
    refetchMetricDataPoints: refetchAggregatedDataPoints,
    metricDataPointsLoading,
    rawMetricDatapoints,
    refetchRawMetricDataPoints,
    rawMetricDataPointsLoading
  } = useMetricDataPoints(currentMetric.id, {
    period: currentMetric?.period !== MetricDefinitionDtoPeriod.annually ? MetricDefinitionDtoPeriod.monthly : MetricDefinitionDtoPeriod.annually,
    summarize: currentMetric.summarize
  }, !isAuto);


  const { currentAccount } = useAuth();
  const monthToStart = currentAccount?.config?.fiscalYearStartMonth || 1;

  const [rawDataPoints, setRawDataPoints] = useState<MetricDataPointDto[]>([]);
  const [aggregatedDataPoints, setAggregatedDataPoints] = useState<MetricAggregationDataPointDto[]>([]);

  useEffect(() => {
    if (aggregatedMetricDataPoints) {
      setAggregatedDataPoints(aggregatedMetricDataPoints);
    }
  }, [aggregatedMetricDataPoints, setAggregatedDataPoints]);

  useEffect(() => {
    if (rawMetricDatapoints) {
      setRawDataPoints(rawMetricDatapoints);
    }
  }, [rawMetricDatapoints, setRawDataPoints]);


  const getTimestampForNewDataPoint = () => {
    let timestamp: Date;
    if (rawDataPoints?.length > 0) {
      const lastTimestamp = new Date(
        orderBy(rawDataPoints, item => item.createdAt, 'desc')[0].createdAt
      );

      switch (currentMetric.period) {
        default:
        case MetricDefinitionDtoPeriod.monthly: {
          timestamp = new Date(
            lastTimestamp.getMonth() === 11
              ? lastTimestamp.getFullYear() + 1
              : lastTimestamp.getFullYear(),
            lastTimestamp.getMonth() === 11 ? 1 : lastTimestamp.getMonth() + 2,
            0
          );
          break;
        }
        case MetricDefinitionDtoPeriod.semiannually: {
          const periodNumber = getDatePeriod(currentMetric.period, monthToStart, lastTimestamp);
          if (periodNumber === 2) {
            const periodDate = getPeriodDate(currentMetric.period, monthToStart, 1);
            timestamp = new Date(
              lastTimestamp.getFullYear() + 1,
              periodDate.getMonth(),
              periodDate.getDate()
            );
          } else {
            const periodDate = getPeriodDate(currentMetric.period, monthToStart, periodNumber + 1);

            timestamp = new Date(
              lastTimestamp.getFullYear(),
              periodDate.getMonth(),
              periodDate.getDate()
            );
          }
          break;
        }

        case MetricDefinitionDtoPeriod.quarterly: {
          const periodNumber = getDatePeriod(currentMetric.period, monthToStart, lastTimestamp);

          if (periodNumber === 4) {
            const periodDate = getPeriodDate(currentMetric.period, monthToStart, 1);
            timestamp = new Date(
              lastTimestamp.getFullYear() + 1,
              periodDate.getMonth(),
              periodDate.getDate()
            );
          } else {
            const periodDate = getPeriodDate(currentMetric.period, monthToStart, periodNumber + 1);
            timestamp = new Date(
              lastTimestamp.getFullYear(),
              periodDate.getMonth(),
              periodDate.getDate()
            );
          }
          break;
        }
        case MetricDefinitionDtoPeriod.annually: {
          timestamp = new Date(
            lastTimestamp.getFullYear() + 1,
            lastTimestamp.getMonth(),
            lastTimestamp.getDate()
          );
          break;
        }
      }
    } else {
      timestamp = getCurrentPeriodDate(currentMetric.period, monthToStart);
    }

    return timestamp;
  };

  const onAddNew = () => {
    const timestamp = getTimestampForNewDataPoint();
    if (isAuto) {
      setAggregatedDataPoints(prev => [{ date: toUtcIsoDate(timestamp) }, ...prev]);
    } else {
      setRawDataPoints(prev => [{ id: crypto.randomUUID(), createdAt: toUtcIsoDate(timestamp) }, ...prev]);
    }
  };

  const onCancelNew = async (id: string) => {
    setRawDataPoints(prev => prev.filter(dp => dp.id !== id));
  };



  const refetchData = () => {
    refetch();
    refetchMetricDefinition();
    if (isAuto) {
      refetchAggregatedDataPoints();
    } else {
      refetchRawMetricDataPoints();
    }

    calcMetricDefinitionProgress(currentMetric.id);
  }
  

  return (
    <Stack padding="20px">
      <Stack marginBottom={1} direction="row" alignItems="start" justifyContent="space-between">
        <Stack width="70%" direction="row" alignItems="center" justifyContent="space-between">
          <ColumnTitle>Date</ColumnTitle>
          <ColumnTitle>Value</ColumnTitle>
        </Stack>

        {!isAuto &&
          <Stack direction="row" alignItems="center" gap="5px">
            <ActionTooltip
              title={<TooltipText>Add data point</TooltipText>}
            >
              <AddButtonContainer onClick={onAddNew}>
                <AddBoxIcon
                  sx={{
                    width: 35,
                    height: 35
                  }}
                />
              </AddButtonContainer>
            </ActionTooltip>
          </Stack>}
      </Stack>

      {!metricDefinition || metricDataPointsLoading || rawMetricDataPointsLoading ? (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Stack>
          {isAuto && aggregatedDataPoints?.length > 0 ? (
            orderBy(aggregatedDataPoints, item => new Date(item.date), 'desc').map((dp, index) => (
              <MetricDataPointRow
                key={index}
                period={currentMetric.period}
                metricDefinitionId={currentMetric.id}
                timestamp={new Date(dp.date)}
                value={dp.value}
                refetch={refetchData}
                isAuto={true} />
            ))
          ) : !isAuto && rawDataPoints?.length > 0 ? (
            orderBy(rawDataPoints, item => new Date(item.createdAt), 'desc').map(
              dp => (
                <MetricDataPointRow
                  key={dp.id}
                  id={dp.id}
                  period={currentMetric.period}
                  metricDefinitionId={currentMetric.id}
                  timestamp={new Date(dp.createdAt)}
                  value={dp.value}
                  onCancelNew={onCancelNew}
                  refetch={refetchData}
                  isAuto={false} />
              )
            )
          ) : (
            <Stack gap={1} marginTop={10} alignItems="center" justifyContent="center">
              <QueryStatsOutlinedIcon
                sx={{ color: palette.primary.main, width: '60px', height: '60px' }}
              />
              <Stack direction="row" alignItems="center" gap={1}>
                <EmptyDataPointsTitle>No data points yet.</EmptyDataPointsTitle>
                <AddNewText onClick={onAddNew}>{isAuto ? 'Add target' : 'Add now'}</AddNewText>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default MetricDataPointsTable;
