import React, { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import EmailIcon from '@mui/icons-material/Email';
import { Box, InputLabel, Stack } from '@mui/material';

import { useForgotPassword } from '../../api/generated';
import Head from '../../components/Head/Head';
import Input from '../../components/Input/Input';
import FormLayout from '../../components/Layouts/FormLayout';
import { toLogin } from '../../services/linker';
import {
  AuthCard,
  CancelButton,
  SubmitButton,
  SubmitButtonLabel
} from './AuthForgotPasswordStyles';

const AuthForgotPassword = () => {
  const { handleSubmit, control } = useForm({ reValidateMode: 'onSubmit' });
  const [isSent, setIsSent] = useState(false);
  const { mutateAsync: authForgotPassword, isLoading } = useForgotPassword();

  const onSubmit = async (data: FieldValues) => {
    await authForgotPassword({ data });
    setIsSent(true);
  };

  return (
    <AuthCard>
      <Head template="forgot password" />
      <FormLayout
        header={!isSent && 'Forgot password'}
        boldSubHeader
        subHeader={
          isSent
            ? 'We have just sent you an e-mail with a reset link!'
            : "We'll email you instructions on how to reset your password"
        }
      >
        {!isSent && (
          <Stack
            component="form"
            noValidate
            autoComplete="off"
            mt={2}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack>
              <Input
                placeholder="Enter your email"
                control={control}
                defaultValue=""
                name="email"
                type="email"
                variant="outlined"
                label={<InputLabel>Email</InputLabel>}
                sx={{ mb: 3, pointerEvents: isSent ? 'none' : 'auto', opacity: isSent ? 0.4 : 1 }}
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address'
                  }
                }}
              />
            </Stack>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              mt={1}
            >
              <CancelButton href={toLogin()}>Cancel</CancelButton>
              <SubmitButton
                loading={isLoading}
                type="submit"
                size="medium"
                disabled={isSent}
                endIcon={<EmailIcon />}
              >
                <SubmitButtonLabel>Send email</SubmitButtonLabel>
              </SubmitButton>
            </Box>
          </Stack>
        )}
      </FormLayout>
    </AuthCard>
  );
};

export default AuthForgotPassword;
