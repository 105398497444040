import isPropValid from '@emotion/is-prop-valid';
import { Stack, Typography, styled } from '@mui/material';

import palette from '../../theme/palette';

export const SuggestionsText = styled(Typography, { shouldForwardProp: isPropValid })(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 700,
  fontSize: '12px',
  color: 'white',
  paddingRight: 5,
  cursor: 'pointer',
  whiteSpace: 'nowrap'
}));

export const SuggestionsButtonContainer = styled(Stack, { shouldForwardProp: isPropValid })<{
  opened: boolean;
}>(() => ({
  backgroundColor: palette.blue[11],
  flexDirection: 'row',
  borderRadius: '50%',
  paddingRight: '2px',
  height: '20px',
  display: 'flex',
  cursor: 'pointer',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  width: '20px',
  WebkitTransition: 'max-width 0.5s'
}));
