import React, { useState } from 'react';

import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { Drawer, Tooltip } from '@mui/material';
import { Stack } from '@mui/system';
import { closeSnackbar, enqueueSnackbar } from 'notistack';

import { useSendContactUsMessage } from '../../api/generated';
import useContactUs from '../../hooks/context-providers/useContactUs';
import { TOAST_VARIANTS } from '../NotificationToast/NotificationToast';
import TextArea from '../../screens/Dashboard/components/TextArea';
import palette from '../../theme/palette';
import { CloseIcon, SendButton, Title } from './ContactUsStyles';

const ContactUs = () => {
  const { mutateAsync: sendContactUseMessage } = useSendContactUsMessage();

  const { open, setOpen } = useContactUs();
  const [message, setMessage] = useState<string>('');

  const handleClick = () => {
    setOpen(prev => !prev);
  };

  const handleClose = () => {
    setOpen(false);
    setMessage('');
  };

  const onSend = () => {
    sendContactUseMessage({ data: { message: message } }).then(() => {
      enqueueSnackbar({
        variant: 'custom',
        customProps: {
          title: 'Thank you for contacting Journey Support',
          caption: 'Someone from our customer success team will get back to you via email as soon as possible.',
          variant: TOAST_VARIANTS.SUCCESS,
          cta: {
            onClick: () => {
              closeSnackbar();
            }
          }
        }
      });
    });
    handleClose();
  };

  return (
    <div>
      <Drawer
        sx={{
          zIndex: 1201,
          '& .MuiDrawer-paper': {
            marginTop: 6,
            marginRight: 4,
            height: 'max-content',
            minHeight: '300px',
            width: '25%',
            borderRadius: '8px',
            border: `1px solid ${palette.border.grey}`,
            backgroundColor: palette.background.paper
          }
        }}
        anchor={'right'}
        open={open}
        onClose={handleClose}
      >
        <Stack
          sx={{
            padding: 1
          }}
          alignItems="center"
          gap={2}
        >
          <Stack
            width="100%"
            alignSelf="start"
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Title>Have a question, request or feedback?</Title>
            <CloseIcon sx={{ alignSelf: 'end' }} onClick={() => setOpen(false)} />
          </Stack>

          <TextArea
            styles={{ minHeight: '150px', mt: 2, padding: 1 }}
            multiline
            placeholder="Write us a message..."
            value={message}
            onChange={value => {
              setMessage(value);
            }}
          />
          <SendButton onClick={onSend} disabled={message === ''}>
            Send
          </SendButton>
        </Stack>
      </Drawer>
      <Stack
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          ':hover': {
            svg: {
              path: {
                fill: palette.primary.main
              }
            }
          }
        }}
      >
        <Tooltip title="Contact us">
          <HelpOutlineOutlinedIcon sx={{ opacity: 0.5, width: '28px', height: '28px' }} />
        </Tooltip>
      </Stack>
    </div>
  );
};
export default ContactUs;
