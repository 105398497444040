import React, { useMemo, useState, MouseEvent, useEffect } from 'react';

import { ClickAwayListener, Divider, Stack, Tooltip } from '@mui/material';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import { ClearButton, ControlButton, FilterContainer, FilterCount, FilterCountContainer, FilterIconAndCount, Title } from './UserFilterStyles';
import FilterPopper from '../../FilterPopper/FilterPopper';
import SelectOwner from '../../../screens/ActionPlan/components/Filter/SelectOwner/SelectOwner';
import { AccountUserDto, AccountUserDtoType } from '../../../api/generated';
import useUsers from '../../../hooks/useUsers';
import SearchInput from '../../Input/SearchInput';

type UserFilterProps = {
  appliedUsers: string[];
  onApplyUsers: (userIds: string[]) => void;
  options?: string[];
  includeAssignee?: boolean;
  title: string;
};

const UserFilter = ({ title, appliedUsers, onApplyUsers, options, includeAssignee }: UserFilterProps) => {
  const { usersList, isLoading } = useUsers();
  const [openFilter, setOpenFilter] = useState<HTMLDivElement | null>(null);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [userIds, setUserIds] = useState<string[]>(appliedUsers || []);
  const [users, setUsers] = useState<AccountUserDto[]>([]);

  useEffect(() => {
    setUsers(options ? usersList.filter(user => options.includes(user.id)) : usersList);
  }, [usersList, setUsers, options]);

  const isOpenFilter = useMemo(() => !!openFilter, [openFilter]);

  const openFilterPopup = (event: React.MouseEvent<HTMLDivElement>) => {
    setOpenFilter(event.currentTarget);
  };

  const onChangeUser = (userId: string) => {
    const newList = userIds.includes(userId)
      ? userIds.filter(owner => owner !== userId)
      : [...userIds, userId];
    setUserIds(newList);
    onApplyUsers(newList);
  };

  const onClearAllOwners = () => {
    setUserIds([]);
  };

  const onClearAll = () => {
    onClearAllOwners();
  }

  const onSelectAllOwners = () => {
    setUserIds(users?.map(u => u.id));
  };

  const closeFilterPopup = () => {
    setOpenFilter(null);
  };

  const applyClearAll = () => {
    onApplyUsers([]);
    closeFilterPopup();
  };

  const selected = appliedUsers?.length > 0;

  const [filter, setFilter] = useState<string>('');

  const isMatchFilter = (user: AccountUserDto) => {
    const filtervalue = filter.toLowerCase();
    return user?.firstName?.toLowerCase().includes(filtervalue) ||
      user?.lastName?.toLowerCase().includes(filtervalue) ||
      filter === '';
  };

  return (
    <ClickAwayListener
      onClickAway={closeFilterPopup}>
      <Stack>
        <ControlButton
          selected={selected}
          onClick={openFilterPopup} direction="row">

          <Title>{title}</Title>
          <FilterIconAndCount  >
            <ArrowDropDownOutlinedIcon />
            {selected && (
              <FilterCountContainer>
                <FilterCount>{appliedUsers.length}</FilterCount>
              </FilterCountContainer>
            )}
          </FilterIconAndCount>
          {selected &&
            <ClearButton
              onClick={(event: MouseEvent<HTMLDivElement>) => {
                event.stopPropagation();
                onClearAll();
                applyClearAll();
              }}>
              <Tooltip
                title="Clear">
                <ClearOutlinedIcon sx={{ width: "18px", height: "18px" }} />
              </Tooltip>
            </ClearButton>}
        </ControlButton>
        <FilterPopper
          open={isOpenFilter}
          anchorEl={openFilter}>
          <FilterContainer>
            <SearchInput
              value={filter}
              name="search"
              noBorder
              height='32px'
              onChange={value => setFilter(value)}
              placeholder={"Search " + title}
            />
            <Divider />
            {!isLoading && users && (
              <Stack>
                <SelectOwner
                  showOptions={false}
                  expanded={expanded}
                  onClickSummary={() => setExpanded(!expanded)}
                  checkedFunc={(ownerId: string) => userIds.includes(ownerId)}
                  onChangeOwner={onChangeUser}
                  onClearAll={onClearAllOwners}
                  onSelectAll={onSelectAllOwners}
                  owners={includeAssignee ? users.filter(user => isMatchFilter(user)) : users.filter(user => user.type !== AccountUserDtoType.assigneeUser && isMatchFilter(user))}
                />
              </Stack>

            )}
          </FilterContainer>
        </FilterPopper>
      </Stack>
    </ClickAwayListener>
  );
};

export default UserFilter;
