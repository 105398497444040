import React from 'react';

import { ActionPlanInitiativeDtoStatus } from '../../../api/generated';
import { initiativesStatusMetaDataMap } from '../../../utils/actionPlanUtil';
import { StatusCircle } from '../../InitiativesCard/InitiativesCardStyles';
import {
  InitiativeStatusCircleWrapper,
  InitiativeStatusLabelWrapper,
  InitiativeStatusLabel as InitiativeStatusLabelText
} from './InitiativeStatusLabelStyles';

type Props = {
  status: ActionPlanInitiativeDtoStatus;
};

const InitiativeStatusLabel = ({ status }: Props) => (
  <InitiativeStatusLabelWrapper>
    <InitiativeStatusCircleWrapper>
      <StatusCircle circleColor={initiativesStatusMetaDataMap[status].circleColor} />
    </InitiativeStatusCircleWrapper>
    <InitiativeStatusLabelText>
      {initiativesStatusMetaDataMap[status].label}
    </InitiativeStatusLabelText>
  </InitiativeStatusLabelWrapper>
);

export default InitiativeStatusLabel;
