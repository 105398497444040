import React from 'react';

import ListItemIcon from '@mui/material/ListItemIcon';

const Icon = ({
  icon,
  isOpen,
  selectedItem,
  title,
  ...restProps
}: {
  icon: React.ReactNode;
  isOpen: boolean;
  selectedItem: boolean;
  title?: string;
}) => (
  <ListItemIcon
    {...restProps}
    title={title}
    sx={{
      minWidth: 0,
      mr: isOpen ? 1.25 : 'auto',
      justifyContent: 'center',
      color: selectedItem ? 'green.1' : 'grey.500',
      width: 21,
      height: 21
    }}
  >
    {icon}
  </ListItemIcon>
);

export default Icon;
