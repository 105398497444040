import DoneIcon from '@mui/icons-material/Done';
import RepeatIcon from '@mui/icons-material/Repeat';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputBase,
  Stack,
  Typography,
  styled
} from '@mui/material';

import palette from '../../theme/palette';

const Container = styled(Accordion)({
 // border: `1px solid ${palette.grey[1000]}`
});

const Summary = styled(AccordionSummary)({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  borderRadius: '130px'
});

const Icon = styled(RepeatIcon)({
  color: palette.green[1],
  fontSize: '20px'
});

const Title = styled(Typography)({ fontSize: '14px', fontWeight: 700 });

const Description = styled(AccordionDetails)({
  padding: '16px'
});

const Content = styled(Typography)({ fontWeight: 400, fontSize: '13px' });

const SentToTitle = styled(Typography)({
  fontWeight: 500,
  fontSize: '14px'
});

const OptionalTitle = styled(Typography)({
  fontWeight: 300,
  fontSize: '14px',
  fontStyle: 'italic'
});

const EmailInput = styled(InputBase)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '13px',
  color: palette.common.darkBlue,
  padding: '15px 12px',
  height: '40px',
  borderRadius: '3px 3px 0 0'
});

const SentIcon = styled(DoneIcon)({
  color: palette.green[1]
});

const SendButton = styled(Stack)<{ disabled: boolean }>(({ disabled }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 24px',
  gap: '4px',
  width: '122px',
  height: '42px',
  borderRadius: '8px',
  color: 'white',
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 700,
  fontSize: '14px',
  background: disabled ? palette.grey[1800] : palette.green[1],
  cursor: !disabled && 'pointer',
  '&:hover': {
    opacity: disabled ? 1 : 0.8
  }
}));

export {
  Container,
  Icon,
  Summary,
  Title,
  Description,
  Content,
  SentToTitle,
  OptionalTitle,
  EmailInput,
  SentIcon,
  SendButton
};
