import React, { createContext, useContext, useState } from 'react';

import { ActionPlanObjectiveDetailedDto } from '../../api/generated';

interface useObjectiveSidebarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  currentObjective: ActionPlanObjectiveDetailedDto;
  setCurrentObjective: (objective: ActionPlanObjectiveDetailedDto) => void;
  refetch?: () => void;
  setRefetch?: (value: () => void) => void;
}

const Context = createContext<useObjectiveSidebarProps | null>(null);

const useObjectiveSidebar = () => useContext(Context);

export const ObjectiveSidebarProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [currentObjective, setCurrentObjective] = useState(null);
  const [refetch, setRefetch] = useState<() => void>();
  const value = {
    open,
    setOpen,
    currentObjective,
    setCurrentObjective,
    refetch,
    setRefetch
  };

  return <Context.Provider value={value}> {children}</Context.Provider>;
};

export default useObjectiveSidebar;
