import React, { Suspense, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';
import { Route, Routes, useLocation } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import AuthLayout from './components/Layouts/AuthLayout';
import { Loader, ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import { EnvVariables } from './enums/EnvVariables';
import { ProtectedProviders } from './hoc/AppProviders';
import useNewVersionUpdate from './hooks/useNewVersionUpdate';
import AuthForgotPassword from './screens/AuthForgotPassword/AuthForgotPassword';
import AuthResetPassword from './screens/AuthResetPassword/AuthResetPassword';
import AuthSignIn from './screens/AuthSignIn/AuthSignIn';
import AuthSignUp from './screens/AuthSignUp/AuthSignUp';
import Home from './screens/Home/Home';
import {
  LazyAccountSettings,
  LazyActionPlan,
  LazyAskJourney,
  LazyBusinessArea,
  LazyBusinessAreas,
  LazyChooseAccount,
  LazyCompetitiveAdvantages,
  LazyCompetitiveBigGoal,
  LazyCompetitiveLandscape,
  LazyForum,
  LazyGoogleAuth,
  LazyHubspotAuth,
  LazyIntegrations,
  LazyMeetingView,
  LazyMeetings,
  LazyMetricsManagement,
  LazyMissionAndVision,
  LazyPlayground,
  LazyPostView,
  LazyPrioritizationMap,
  LazyQuickBooksAuth,
  LazyRoadmap,
  LazySharedWorkspacesManagement,
  LazySpace,
  LazySpaceMeeting,
  LazyStrategyBusinessArea,
  LazyStrategyCenter,
  LazyStrategyDrilldown,
  LazyUserProfile,
  LazyWhiteboard,
  LazyWhiteboards
} from './screens/LazyLoadedScreens';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { useConfigureCustomAxios } from './services/customAxios';
import { RoutesMap, toAccountSettings } from './services/linker';
import theme from './theme/theme';

const withoutPrefix = (path, prefix) =>
  path.startsWith(prefix) ? path.slice(prefix.length + 1) : prefix;

const TRACKING_ID = process.env
  .REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID as EnvVariables.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;
const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN as EnvVariables.REACT_APP_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({ maskAllText: false, blockAllMedia: false })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 0.5,

  // Capture Replay for 30% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.3,
  replaysOnErrorSampleRate: 1.0
});

const App = () => {
  useConfigureCustomAxios();
  const { onServiceWorkerUpdate } = useNewVersionUpdate();
  const location = useLocation();
  const queryClient = new QueryClient();
  const isProduction = process.env.NODE_ENV === 'production';

  useEffect(() => {
    if (isProduction) {
      ReactGA.initialize(TRACKING_ID);
      ReactGA.send('pageview');
    }
  }, [isProduction]);

  if (isProduction) {
    serviceWorkerRegistration.register({ onUpdate: onServiceWorkerUpdate });
  } else {
    serviceWorkerRegistration.unregister();
  }

  return (
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <Routes location={location}>
              <Route path={RoutesMap.AUTH} element={<AuthLayout />}>
                <Route
                  path={withoutPrefix(RoutesMap.AUTH_LOGIN, RoutesMap.AUTH)}
                  element={<AuthSignIn />}
                />
                <Route
                  path={withoutPrefix(RoutesMap.AUTH_FORGOT_PASSWORD, RoutesMap.AUTH)}
                  element={<AuthForgotPassword />}
                />
                <Route
                  path={withoutPrefix(RoutesMap.AUTH_RESET, RoutesMap.AUTH)}
                  element={<AuthResetPassword />}
                />
                <Route
                  path={withoutPrefix(RoutesMap.AUTH_SIGNUP, RoutesMap.AUTH)}
                  element={<AuthSignUp />}
                />
              </Route>
              <Route element={<ProtectedRoute />}>
                <Route
                  path={RoutesMap.CHOOSE_ACCOUNT}
                  element={
                    <Suspense fallback={<Loader />}>
                      <LazyChooseAccount />
                    </Suspense>
                  }
                />
                <Route element={<ProtectedProviders />}>
                  <Route path={RoutesMap.HOME} element={<Home />} />
                  <Route path={RoutesMap.CONTROL_PANEL} element={<LazyBusinessAreas />} />
                  <Route path={RoutesMap.STRATEGY_CENTER} element={<LazyStrategyCenter />} />
                  <Route path={RoutesMap.BUSINESS_AREA} element={<LazyBusinessArea />} />
                  <Route
                    path={RoutesMap.BUSINESS_AREA_STRATEGY}
                    element={<LazyStrategyBusinessArea />}
                  />
                  <Route path={RoutesMap.BUSINESS_AREA_PLAYBOOK} element={<LazyBusinessArea />} />
                  <Route path={RoutesMap.MEETINGS} element={<LazyMeetings />} />
                  <Route path={RoutesMap.MEETING_VIEW} element={<LazyMeetingView />} />
                  <Route path={RoutesMap.ASK_JOURNEY} element={<LazyAskJourney />} />
                  <Route path={RoutesMap.ACCOUNT_SETTINGS} element={<LazyAccountSettings />} />
                  <Route path={RoutesMap.INTEGRATIONS} element={<LazyIntegrations />} />
                  <Route path={RoutesMap.USER_PROFILE} element={<LazyUserProfile />} />
                  <Route path={RoutesMap.METRICS} element={<LazyMetricsManagement />} />

                  <Route path={RoutesMap.FORUM} element={<LazyForum />} />
                  <Route path={RoutesMap.FORUM_POST} element={<LazyPostView />} />
                  <Route
                    path={RoutesMap.COMPETITIVE_LANDSCAPE}
                    element={<LazyCompetitiveLandscape />}
                  />
                  <Route
                    path={RoutesMap.COMPETITIVE_ADVANTAGES}
                    element={<LazyCompetitiveAdvantages />}
                  />
                  <Route
                    path={RoutesMap.SHARED_WORKSPACES_MANAGEMENT}
                    element={<LazySharedWorkspacesManagement />}
                  />
                  <Route path={RoutesMap.SHARED_WORKSPACE} element={<LazySpace />} />
                  <Route path={RoutesMap.SPACE_MEETING} element={<LazySpaceMeeting />} />
                  <Route path={RoutesMap.MISSION_VISION} element={<LazyMissionAndVision />} />
                  <Route path={RoutesMap.BIG_GOAL} element={<LazyCompetitiveBigGoal />} />
                  <Route path={RoutesMap.PLAYGROUND} element={<LazyPlayground />} />
                  <Route path={RoutesMap.ROADMAP} element={<LazyRoadmap />} />
                  <Route path={RoutesMap.STRATEGY_DRILL_DOWN} element={<LazyStrategyDrilldown />} />
                  <Route path={RoutesMap.PRIORITIZATION_MAP} element={<LazyPrioritizationMap />} />
                  <Route path={RoutesMap.ACTION_PLAN} element={<LazyActionPlan />} />
                  <Route path={RoutesMap.ACTION_PLAN_INITIATIVE} element={<LazyActionPlan />} />
                  <Route path={RoutesMap.WHITEBOARDS} element={<LazyWhiteboards />} />
                  <Route path={RoutesMap.WHITEBOARD} element={<LazyWhiteboard />} />
                  <Route path={RoutesMap.HUBSPOT_AUTH} element={<LazyHubspotAuth />} />
                  <Route path={RoutesMap.QUICKBOOKS_AUTH} element={<LazyQuickBooksAuth />} />
                  <Route
                    path={RoutesMap.GOOGLE_AUTH}
                    element={
                      <LazyGoogleAuth redirectUrl={toAccountSettings() + '?tab=integrations'} />
                    }
                  />
                </Route>
              </Route>
            </Routes>
          </ThemeProvider>
        </HelmetProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
};

export default App;
