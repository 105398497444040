import React from 'react';

import { ExpandMoreIconContainer, ShowMoreIcon } from './ExpandMoreIconStyles';

type Props = {
  width?: string;
  height?: string;
};

const ExpandMoreIcon = ({ width, height }: Props) => (
  <ExpandMoreIconContainer>
    <ShowMoreIcon sx={{ width: width, height: height }} />
  </ExpandMoreIconContainer>
);

export default ExpandMoreIcon;
