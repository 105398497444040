import React, { SetStateAction, createContext, useContext, useMemo, useState } from 'react';

interface ContactUsContextProps {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
}

const Context = createContext<ContactUsContextProps | null>(null);

const useContactUs = () => useContext(Context);

export const ContactUsProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const value = useMemo(
    () => ({
      open,
      setOpen
    }),
    [open]
  );

  return <Context.Provider value={value}> {children}</Context.Provider>;
};

export default useContactUs;
