
import { FormControlLabel, Stack, Typography, styled } from '@mui/material';
import palette from '../../../theme/palette';





export const ExcludeActionPlanContainer = styled(Stack)({
  background: palette.background.default,
  borderTop: `1px solid ${palette.border.grey}`,
  padding: "0px 5px",
  alignItems: "center",
  flexDirection: "row",
  borderRadius: "0px 0px 5px 5px"
});

export const FormCheckbox = styled(FormControlLabel)(() => ({
  margin: '0',
  span: {
    padding: '0',
    fontSize: "12px"
  }
}));

export const FilterContainer = styled(Stack)({
  maxWidth: '246px',
  minWidth: '200px',
  border: `1px solid ${palette.border.grey}`,
  borderRadius: '5px',
  maxHeight: '700px',
  backgroundColor: palette.background.default
});


export const ControlButton = styled(Stack)<{
  selected?: boolean;
}>(({ selected }) => ({
  alignItems: 'center',
  cursor: 'pointer',
  backgroundColor: selected && palette.background.lightGreen,
  color: selected ? palette.common.darkBlue : palette.grey[3100],
  borderRadius: "8px",
  padding: "2px 5px",
  svg: { path: { fill: selected ? palette.common.darkBlue : palette.grey[3100] } },
  ":hover": {
    svg: { path: { fill: palette.common.darkBlue } },
    color: palette.common.darkBlue,
    backgroundColor: !selected && palette.background.grey
  }
}));

export const Title = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '13px',
  fontWeight: 700
});;


export const ExcludeActionPlanTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '13px'
});;


export const FilterIconAndCount = styled(Stack)({
  position: 'relative'
});

export const FilterCount = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 600,
  fontSize: '10px',
  color: '#fff'
});

export const FilterCountContainer = styled(Stack)({
  position: 'absolute',
  top: '-12px',
  right: '-30px',
  backgroundColor: palette.primary.main,
  borderRadius: '50%',
  height: '18px',
  width: '18px',
  alignItems: 'center',
  justifyContent: 'center',
  letterSpacing: 0
});


export const SortClearButton = styled(Stack)({
  svg: { path: { fill: palette.grey[3100] } },
  ":hover": { opacity: 0.7 }
});


export const SortTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '13px',
  fontWeight: 700
});


export const SortHeader = styled(Stack)<{
  selected?: boolean;
}>(({ selected }) => ({
  flexDirection: "row",
  alignItems: "end",
  gap: '2px',
  cursor: 'pointer',
  backgroundColor: selected && palette.background.lightGreen,
  color: selected ? palette.common.darkBlue : palette.grey[3100],
  borderRadius: "8px",
  padding: "2px 5px",
  svg: { path: { fill: selected ? palette.common.darkBlue : palette.grey[3100] } },
  ":hover": {
    svg: { path: { fill: palette.common.darkBlue } },
    color: palette.common.darkBlue,
    backgroundColor: !selected && palette.background.grey
  }
}));


export const ClearButton = styled(Stack)({
  svg: { path: { fill: palette.grey[3100] } },
  ":hover": { opacity: 0.7 }
});

export const SortContainer = styled(Stack)({
  border: `1px solid ${palette.border.grey}`,
  borderRadius: '8px',
  background: palette.background.default
});

export const ControlItem = styled(Stack)<{
  selected?: boolean;
}>(({ selected }) => ({
  flexDirection: "row",
  background: selected ? palette.background.lightGreen : palette.background.default,
  padding: '10px 20px'
}));


export const SortValue = styled(Typography)<{ selected: boolean }>(({ selected }) => ({
  fontSize: '14px',
  fontWeight: '500',
  cursor: 'pointer',
  color: selected ? palette.green[1] : palette.common.darkBlue
}));

