import { MetricDefinitionDtoUnit } from '../api/generated';

const metricUnitTypesTitles = {
  [MetricDefinitionDtoUnit.percentage]: '%',
  [MetricDefinitionDtoUnit.currency]: '$',
  [MetricDefinitionDtoUnit.ratio]: 'ratio',
  [MetricDefinitionDtoUnit.number]: '#'
} as const;

export default metricUnitTypesTitles;
