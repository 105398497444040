import React, { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Button, InputBase, Stack, Typography } from '@mui/material';

import { Mfa, UserDetailsType } from '../../api/generated';
import Head from '../../components/Head/Head';
import PasswordInput from '../../components/Input/PasswordInput';
import FormLayout from '../../components/Layouts/FormLayout';
import useAuth from '../../hooks/useAuth';
import { generatePath, toChooseAccount, toForgotPassword, toHome } from '../../services/linker';
import typography from '../../theme/typography';
import { AuthCard, ErrorTitle, SubmitButton, ValueInput } from './AuthSignInStyles';

type FormValues = {
  email: string;
  password: string;
};

const AuthSignIn = () => {
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<FormValues>({
    reValidateMode: 'onBlur',
    shouldFocusError: true,
    mode: 'onBlur'
  });

  const navigate = useNavigate();
  const { isAuthenticated, signIn, signInLoading, userDetailsLoading, user, mfaSignIn, isLoadingAuthMfaSignin } = useAuth();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [mfaDetails, setMfaDetails] = useState<Mfa>(null);
  const [mfaCode, setMfaCode] = useState<string>('');



  useEffect(() => {
    if (!isAuthenticated || !user || userDetailsLoading) return;

    const chooseAccountUserTypes: Array<Partial<UserDetailsType>> = [
      UserDetailsType.jp,
      UserDetailsType.superAdmin,
      UserDetailsType.admin
    ];

    const shouldNavigateToChooseAccount = chooseAccountUserTypes.includes(user?.type);
    if (shouldNavigateToChooseAccount) {
      navigate(toChooseAccount());
    } else {
      const lastRedirectUrl = localStorage.getItem('lastRedirectedUrl');
      if (lastRedirectUrl && !lastRedirectUrl.includes('/choose')) {
        localStorage.setItem('lastRedirectedUrl', '');
        navigate(generatePath(lastRedirectUrl));
      } else {
        navigate(toHome());
      }
    }
  }, [isAuthenticated, navigate, user, userDetailsLoading]);

  const onSubmit = async (data: FieldValues) => {
    try {
      const signInDetails = await signIn(data);
      if (signInDetails.mfa?.session) {
        setMfaDetails(signInDetails.mfa);
      }

    } catch (error) {
      console.log(error);

      if (error?.response?.status === 401) {
        setErrorMessage('Incorrect email or password');
      } else {
        setErrorMessage('Something went wrong. Please try again later');
      }
    }
  };

  const onMfaSignIn = async () => {
    try {
      await mfaSignIn({
        challengeName: mfaDetails.challengeName,
        code: mfaCode,
        userId: mfaDetails.userId,
        session: mfaDetails.session
      })

    } catch (error) {
      console.log(error);

      if (error?.response?.status === 401) {
        setErrorMessage('Incorrect verification code');
      } else {
        setErrorMessage('Something went wrong. Please try again later');
      }
    }


  }

  return (
    <AuthCard>
      <Head template="login" />

      {mfaDetails ?
        <FormLayout header="Multi Factor Authentication">
          <Stack
            gap="30px"
            alignItems="center"
            padding="20px">

            <ValueInput
              placeholder='Enter code'
              disableUnderline
              value={mfaCode}
              onChange={event => setMfaCode(event.currentTarget.value)}
            />

            <SubmitButton
              disabled={!mfaCode}
              onClick={onMfaSignIn}
              loading={isLoadingAuthMfaSignin}
              variant="contained"
              size="medium"
              sx={{
                fontWeight: "bold",
                fontSize: "16px",
                fontFamily: 'Plus Jakarta Sans',
                pt: 4
              }}
            >
              Continue
            </SubmitButton>


          </Stack>
          {errorMessage !== '' && (
            <Stack alignItems="center" marginTop="7px">
              <Typography sx={{ color: 'error.main', fontSize: typography.body3 }}>
                {errorMessage}
              </Typography>
            </Stack>
          )}</FormLayout> :
        <FormLayout header="Sign in">
          <Stack
            padding="10px"
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack justifyContent="center" alignItems="center"></Stack>
            <Stack>
              <Stack sx={{ mb: 2 }}>
                <InputBase

                  onFocus={() => setErrorMessage('')}
                  {...register('email', {
                    required: 'Email is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address'
                    }
                  })}
                  placeholder="Enter your email"
                  name="email"
                  type="email"
                />
                {errors.email?.message && (
                  <ErrorTitle marginLeft="4px" marginTop="4px">
                    {errors.email.message}
                  </ErrorTitle>
                )}
              </Stack>
              <PasswordInput
                onFocus={() => setErrorMessage('')}
                mb={1}
                errors={errors}
                register={register} />
              <Button
                variant="text"
                size="large"
                sx={{
                  alignSelf: 'end',
                  mt: 0,
                  p: 0,
                  width: 'auto',
                  color: 'grey.600'
                }}
                href={toForgotPassword()}
              >
                Forgot your password?
              </Button>
              <Stack
                mt={2}
                justifyContent="center" alignItems="center">
                <SubmitButton
                  loading={signInLoading}
                  type="submit"
                  variant="contained"
                  size="medium"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    fontFamily: 'Plus Jakarta Sans',
                    pt: 4
                  }}
                >
                  Sign in
                </SubmitButton>
              </Stack>
              {errorMessage !== '' && (
                <Stack alignItems="center" marginTop="7px">
                  <Typography sx={{ color: 'error.main', fontSize: typography.body3 }}>
                    {errorMessage}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Stack>
        </FormLayout>}
    </AuthCard>
  );
};
export default AuthSignIn;
