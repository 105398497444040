import * as React from 'react';

import { Switch, SwitchProps, switchClasses } from '@mui/base';
import { alpha } from '@mui/material';
import { styled } from '@mui/system';

import palette from '../../theme/palette';

type CustomSwitchProps = SwitchProps & {
  toggleColor?: string;
};

const _CustomSwitch = ({ ...props }: CustomSwitchProps) => (
  <Switch
    slots={{
      root: Root
    }}
    {...props}
  />
);

const Root = styled('span')`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 32px;
  height: 18px;
  margin: 10px;
  cursor: pointer;

  &.${switchClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchClasses.track} {
    background: ${palette.grey[700]};
    border-radius: 16px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  & .${switchClasses.thumb} {
    display: block;
    width: 14px;
    height: 14px;
    top: 2px;
    left: 3px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  }

  &.${switchClasses.focusVisible} .${switchClasses.thumb} {
    background-color: ${palette.grey[1]};
    box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  }

  &.${switchClasses.checked} {
    .${switchClasses.thumb} {
      left: 16px;

      background-color: #fff;
    }
  }

  & .${switchClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;

const CustomSwitch = styled(_CustomSwitch)<{ toggleColor?: string }>(({ toggleColor }) => ({
  [`&.${switchClasses.checked}`]: {
    [`.${switchClasses.track}`]: {
      background: alpha(toggleColor ? toggleColor : palette.blue[3], 0.8)
    }
  }
}));

export default CustomSwitch;
