import React, { MouseEvent, useCallback, useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { useNavigate, useParams } from 'react-router-dom';

import { DeleteOutline } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress, SelectChangeEvent, Stack, alpha } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDebouncedCallback } from 'use-debounce';

import {
  AttachmentDto,
  SharedWorkspaceMeetingActivityDtoAction,
  SharedWorkspaceMeetingActivityDtoEntityType,
  SharedWorkspacePointDtoPriority,
  SharedWorkspacePointDtoStatus,
  SharedWorkspacePointDtoType
} from '../../api/generated';
import useModal from '../../hooks/context-providers/useModal/useModal';
import useSharedWorkspacePointSidebar from '../../hooks/context-providers/useSharedWorkspacePointSidebar';
import useAuth from '../../hooks/useAuth';
import useSharedWorkspaceMeetingActivity from '../../hooks/useSharedWorkspaceMeetingActivity';
import useSharedWorkspacePointComments from '../../hooks/useSharedWorkspacePointComments';
import useSharedWorkspacePoints from '../../hooks/useSharedWorkspacePoints';
import AppPopover from '../../screens/ActionPlan/components/AppPopover/AppPopover';
import Editor from '../../screens/BizAreaModules/components/Editor';
import { toSharedWorkspace } from '../../services/linker';
import palette from '../../theme/palette';
import AddUpdate from '../AddUpdate/AddUpdate';
import Attachments from '../Attachments/Attachments';
import CommentsList from '../CommentsList/CommentsList';
import UserView from '../User/UserView';
import SharedWorkspacePointPriority from './SharedWorkspacePointPriority/SharedWorkspacePointPriority';
import {
  PropertiesContainer,
  Container,
  InfoContainer,
  ValueInput,
  Actions,
  ActionsContainer,
  MoreHorizIcon,
  CloseIcon,
  DescriptionEdtiorContainer,
  SectionTitle,
  StatusSelect,
  StatusOption,
  DurationInput
} from './SharedWorkspacePointSidebarStyles';

const LazyLoadedAreYouSureModal = React.lazy(
  () => import('../../modals/AreYouSureModal/AreYouSureModal')
);

const SharedWorkspacePointSidebar = () => {
  const theme = useTheme();
  const {
    open,
    currentPoint,
    setOpen,
    setCurrentPoint,
    refetch: refetchData,
    currentMeetingId
  } = useSharedWorkspacePointSidebar();
  const [titleValue, setTitleValue] = useState(currentPoint?.content);
  const [description, setDescription] = useState<string>(currentPoint?.description);
  const { showModal } = useModal();

  const [isExploding, setIsExploding] = useState(false);
  const { pointId } = useParams();
  const { deletePoint, patchPoint, updatePoint } = useSharedWorkspacePoints();

  const refetch = useCallback(() => {
    if (refetchData) refetchData();
  }, [refetchData]);

  const {
    comments,
    createSharedWorkspacePointComment,
    deleteSharedWorkspacePointComment,
    patchSharedWorkspacePointComment,
    refetchComments
  } = useSharedWorkspacePointComments(currentPoint.id);

  const { createActivity } = useSharedWorkspaceMeetingActivity();

  const navigate = useNavigate();

  const { user } = useAuth();

  const createComment = async (content: string, taggedUserIds: string[]) => {
    await createSharedWorkspacePointComment({
      userId: user.id,
      content: content,
      pointId: currentPoint.id,
      taggedUserIds: taggedUserIds
    });
  };

  const onClose = useCallback(() => {
    const sharedWorkspaceId = currentPoint.sharedWorkspaceId;
    setCurrentPoint(null);
    setOpen(false);

    if (pointId) navigate(toSharedWorkspace(sharedWorkspaceId));
  }, [pointId, currentPoint, setOpen, setCurrentPoint, navigate]);

  const debouncedPatchTitle = useDebouncedCallback((title: string) => {
    patchSharedWorkspacePointComment(currentPoint.id, { content: title }).then(() => refetch());
  }, 500);

  const deletePointClick = useCallback(() => {
    setAnchorEl(null);
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        modal.hide();
      },
      onConfirm: () => {
        deletePoint(currentPoint.id).then(() => {
          refetch();
          onClose();
        });
        modal.hide();
      },
      modalTitle: `Are you sure you want to delete \n"${currentPoint.content}"?`,
      confirmButtonIcon: <DeleteOutline />,
      confirmButtonTitle: 'Delete'
    });
  }, [currentPoint, deletePoint, refetch, onClose, showModal]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const editAndDeletePopoverOpen = Boolean(anchorEl);

  const onClickActions = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onChangeTitle = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setTitleValue(event.currentTarget.value);
    debouncedPatchTitle(event.currentTarget.value);
  };

  const onBlurTitle = () => {
    patchPoint(currentPoint.id, {
      content: titleValue
    }).then(() => refetch());
  };

  const [status, setStatus] = useState<SharedWorkspacePointDtoStatus>(currentPoint.status);
  const [ownerId, setOwnerId] = useState<string>(currentPoint.ownerId);
  const [priority, setPriority] = useState<SharedWorkspacePointDtoPriority>(currentPoint.priority);
  const [durationInMinutes, setDurationInMinutes] = useState<string>(
    currentPoint?.durationInMinutes ? currentPoint?.durationInMinutes?.toString() + 'm' : null
  );

  const updateOwnerId = (userId: string) => {
    setOwnerId(userId);
    patchPoint(currentPoint.id, { ownerId: userId }).then(() => {
      refetch();
    });
  };

  const updatePriority = (priority: SharedWorkspacePointDtoPriority) => {
    setPriority(priority);
    patchPoint(currentPoint.id, { priority: priority }).then(() => {
      refetch();
      if (currentMeetingId)
        createActivity({
          meetingId: currentMeetingId,
          entityType:
            currentPoint.type === SharedWorkspacePointDtoType.highlight
              ? SharedWorkspaceMeetingActivityDtoEntityType.highlight
              : SharedWorkspaceMeetingActivityDtoEntityType.issue,
          entityId: currentPoint.id,
          entityName: currentPoint.content,
          action: SharedWorkspaceMeetingActivityDtoAction.update,
          newValue:
            priority === SharedWorkspacePointDtoPriority.H
              ? 'High'
              : priority === SharedWorkspacePointDtoPriority.M
              ? 'Medium'
              : 'Low'
        });
    });
  };

  const updateDuration = (duration: string) => {
    const regex = /^(?:[1-9]|[1-9][0-9])m?$/;

    if (regex.test(duration)) {
      setDurationInMinutes(duration);
      patchPoint(currentPoint.id, { durationInMinutes: parseInt(duration) }).then(() => {
        refetch();
      });
    } else if (duration === '') {
      setDurationInMinutes(duration);
      updatePoint(currentPoint.id, { ...currentPoint, durationInMinutes: null }).then(() => {
        refetch();
      });
    }
  };

  const statusColors = {
    pending: alpha(palette.grey[1400], 0.2),
    inProgress: alpha(palette.blue[8], 0.9),
    resolved: alpha(palette.primary.main, 0.2)
  };

  const updateStatus = (newStatus: SharedWorkspacePointDtoStatus) => {
    if (newStatus === SharedWorkspacePointDtoStatus.resolved) {
      setIsExploding(true);
    }
    setStatus(newStatus);
    patchPoint(currentPoint.id, { status: newStatus }).then(() => {
      refetch();
      if (currentMeetingId) {
        createActivity({
          meetingId: currentMeetingId,
          entityType:
            currentPoint.type === SharedWorkspacePointDtoType.highlight
              ? SharedWorkspaceMeetingActivityDtoEntityType.highlight
              : SharedWorkspaceMeetingActivityDtoEntityType.issue,
          entityId: currentPoint.id,
          entityName: currentPoint.content,
          action: SharedWorkspaceMeetingActivityDtoAction.update,
          newValue:
            newStatus === SharedWorkspacePointDtoStatus.inProgress
              ? 'In Progress'
              : newStatus === SharedWorkspacePointDtoStatus.resolved
              ? 'Resolved'
              : 'New'
        });
      }
    });
  };

  const [descriptionUpdating, setDescriptionUpdating] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<AttachmentDto[]>(currentPoint.attachments || []);

  const addAttachment = (name: string) => {
    setAttachments([...attachments, { name }]);

    patchPoint(currentPoint.id, { attachments: [...attachments, { name }] }).then(() => refetch());
  };

  const deleteAttachment = (name: string) => {
    const newList = attachments?.filter(att => att.name !== name);
    setAttachments(newList);
    patchPoint(currentPoint.id, { attachments: newList }).then(() => refetch());
  };

  const updateDescription = useDebouncedCallback(
    (text: string) =>
      patchPoint(currentPoint.id, { description: text }).then(() => {
        refetch();
        setDescriptionUpdating(false);
      }),
    1000
  );

  return (
    <Container anchor="right" open={open} onClose={onClose} theme={theme}>
      <Stack>
        <PropertiesContainer>
          <Stack width="100%" gap="5px">
            <InfoContainer>
              <Stack gap={1} width="100%">
                <ValueInput
                  multiline
                  defaultValue={currentPoint?.content}
                  onChange={onChangeTitle}
                  placeholder="Type a task"
                  onBlur={onBlurTitle}
                />
              </Stack>

              <Actions>
                <ActionsContainer onClick={onClickActions}>
                  <MoreHorizIcon />
                </ActionsContainer>
                <ActionsContainer>
                  <CloseIcon onClick={onClose} />
                </ActionsContainer>
              </Actions>
            </InfoContainer>

            <Stack
              width="100%"
              direction="row"
              gap={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <DescriptionEdtiorContainer width="95%">
                <Editor
                  showBorder={false}
                  value={description}
                  defaultValue={description}
                  onChange={content => {
                    setDescription(content);
                    setDescriptionUpdating(true);
                    updateDescription(content);
                  }}
                  placeholder="Add a description..."
                />
              </DescriptionEdtiorContainer>
              {descriptionUpdating && <CircularProgress size={20} />}
            </Stack>

            <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
              <UserView size="30px" onChange={updateOwnerId} userId={ownerId} />

              <Stack direction="row" alignItems="center" gap={2}>
                {currentPoint.type === SharedWorkspacePointDtoType.issue && (
                  <DurationInput
                    disableUnderline
                    placeholder="Duration"
                    value={durationInMinutes}
                    onChange={event => updateDuration(event.target.value)}
                  />
                )}

                {currentPoint.type === SharedWorkspacePointDtoType.issue && (
                  <SharedWorkspacePointPriority priority={priority} onSelection={updatePriority} />
                )}

                {isExploding && (
                  <ConfettiExplosion
                    duration={2500}
                    zIndex={9999}
                    onComplete={() => setIsExploding(false)}
                    particleCount={200}
                  />
                )}
                <StatusSelect
                  MenuProps={{
                    MenuListProps: {
                      sx: {
                        padding: 0
                      }
                    }
                  }}
                  status={status}
                  IconComponent={KeyboardArrowDownIcon}
                  value={status}
                  onChange={(event: SelectChangeEvent<SharedWorkspacePointDtoStatus>) =>
                    updateStatus(event.target.value as SharedWorkspacePointDtoStatus)
                  }
                >
                  <StatusOption
                    color={statusColors[SharedWorkspacePointDtoStatus.pending]}
                    value={SharedWorkspacePointDtoStatus.pending}
                  >
                    New
                  </StatusOption>
                  <StatusOption
                    color={statusColors[SharedWorkspacePointDtoStatus.inProgress]}
                    value={SharedWorkspacePointDtoStatus.inProgress}
                  >
                    In Progress
                  </StatusOption>
                  <StatusOption
                    color={statusColors[SharedWorkspacePointDtoStatus.resolved]}
                    value={SharedWorkspacePointDtoStatus.resolved}
                  >
                    Resolved
                  </StatusOption>
                </StatusSelect>
              </Stack>
            </Stack>
          </Stack>
        </PropertiesContainer>

        <Stack marginTop={2} gap={2}>
          <SectionTitle>Attachments</SectionTitle>
          <Attachments
            parnetId={currentPoint.id}
            attachments={attachments}
            onUpload={addAttachment}
            onDelete={name => deleteAttachment(name)}
          />
        </Stack>

        <Stack marginTop={2}>
          <SectionTitle>Comments</SectionTitle>
          <AddUpdate refetch={refetchComments} createComment={createComment} />
          <Stack paddingLeft="10px">
            <CommentsList
              patchComment={patchSharedWorkspacePointComment}
              comments={comments}
              deleteComment={deleteSharedWorkspacePointComment}
              refetchComments={refetchComments}
            />
          </Stack>
        </Stack>
      </Stack>
      <AppPopover
        anchorEl={anchorEl}
        open={editAndDeletePopoverOpen}
        handleClose={() => setAnchorEl(null)}
        onDelete={deletePointClick}
      />
    </Container>
  );
};

export default SharedWorkspacePointSidebar;
