import { useCallback, useMemo } from 'react';

import {
  SearchEntityRequest,
  SharedWorkspaceDto,
  useCreateSharedWorkspace,
  useDeleteSharedWorkspace,
  useGetSharedWorkspace,
  useListSharedWorkspaces,
  usePatchSharedWorkspace,
  useSearchSharedWorkspaces,
  useUpdateSharedWorkspace
} from '../api/generated';

const useSharedWorkspaces = (sharedWorkspaceId?: string) => {
  const {
    data: sharedWorkspacesResponse,
    isLoading: sharedWorkspacesLoading,
    refetch: refetchSharedWorkspaces
  } = useListSharedWorkspaces();

  const {
    data: sharedWorkspaceResponse,
    refetch: refetchSharedWorkspace,
    isLoading: sharedWorkspaceLoading
  } = useGetSharedWorkspace(sharedWorkspaceId, {
    query: { queryKey: ['sharedWorkspace', sharedWorkspaceId], enabled: !!sharedWorkspaceId }
  });

  const { mutateAsync: mutateDeleteSharedWorkspace } = useDeleteSharedWorkspace();
  const { mutateAsync: mutatePatchSharedWorkspace } = usePatchSharedWorkspace();
  const { mutateAsync: mutateUpdateSharedWorkspace } = useUpdateSharedWorkspace();
  const { mutateAsync: mutateCreateSharedWorkspace } = useCreateSharedWorkspace();
  const { mutateAsync: mutateSearchSharedWorkspaces } = useSearchSharedWorkspaces();

  const createSharedWorkspace = useCallback(
    async (sharedWorkspace: Partial<SharedWorkspaceDto>) => {
      const response = await mutateCreateSharedWorkspace({ data: sharedWorkspace });
      return response?.items[0]?.id;
    },
    [mutateCreateSharedWorkspace]
  );

  const deleteSharedWorkspace = useCallback(
    async (id: string) => {
      await mutateDeleteSharedWorkspace({ id });
    },
    [mutateDeleteSharedWorkspace]
  );

  const patchSharedWorkspace = useCallback(
    async (id: string, sharedWorkspace: Partial<SharedWorkspaceDto>) => {
      await mutatePatchSharedWorkspace({ id, data: sharedWorkspace });
    },
    [mutatePatchSharedWorkspace]
  );

  const updateSharedWorkspace = useCallback(
    async (id: string, sharedWorkspace: Partial<SharedWorkspaceDto>) => {
      await mutateUpdateSharedWorkspace({ id, data: sharedWorkspace });
    },
    [mutateUpdateSharedWorkspace]
  );

  const searchSharedWorkspaces = useCallback(
    async (data: SearchEntityRequest) => {
      const response = await mutateSearchSharedWorkspaces({ data });
      return response?.items || [];
    },
    [mutateSearchSharedWorkspaces]
  );

  const sharedWorkspaces = useMemo(
    () => sharedWorkspacesResponse?.items,
    [sharedWorkspacesResponse]
  );

  const sharedWorkspace = useMemo(
    () => sharedWorkspaceResponse?.items[0],
    [sharedWorkspaceResponse]
  );

  return {
    sharedWorkspaces,
    refetchSharedWorkspace,
    sharedWorkspacesLoading,
    sharedWorkspace,
    sharedWorkspaceLoading,
    deleteSharedWorkspace,
    patchSharedWorkspace,
    updateSharedWorkspace,
    refetchSharedWorkspaces,
    createSharedWorkspace,
    searchSharedWorkspaces
  };
};

export default useSharedWorkspaces;
