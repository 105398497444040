import React, { FC } from 'react';

import isPropValid from '@emotion/is-prop-valid';
import { Box, Stack, Typography, styled } from '@mui/material';

import palette from '../../theme/palette';

const Container = styled(Stack)({
  padding: '10px 20px '
});

const Title = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 700,
  fontSize: '20px',
  color: palette.common.darkBlue
});

const TitleDescription = styled(Typography, { shouldForwardProp: isPropValid })<{
  boldSubHeader: boolean;
}>(({ boldSubHeader }) => ({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: boldSubHeader ? 800 : 300,
  fontSize: boldSubHeader ? '18px' : '14px',
  color: boldSubHeader ? palette.common.darkBlue : palette.grey[600]
}));

interface FormLayoutProps {
  children: React.ReactNode;
  header: string;
  subHeader?: string;
  boldSubHeader?: boolean;
}

const FormLayout: FC<FormLayoutProps> = ({ children, header, subHeader, boldSubHeader }) => (
  <Container>
    <Box textAlign="center">
      <Title>{header}</Title>
      {subHeader && (
        <TitleDescription boldSubHeader={boldSubHeader} mt={2}>
          {subHeader}
        </TitleDescription>
      )}
    </Box>
    {children}
  </Container>
);

export default FormLayout;
