import React, { SetStateAction, createContext, useContext, useState } from 'react';

type AskJourneyDrawerStateContext = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
  isAnswering: boolean;
  setIsAnswering: React.Dispatch<SetStateAction<boolean>>;
};
export const AskJourneyDrawerContext = createContext<AskJourneyDrawerStateContext | null>(null);

const useAskJourneyDrawer = () => useContext(AskJourneyDrawerContext);
export const AskJourneyDrawerProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAnswering, setIsAnswering] = useState<boolean>(false);

  const value = { isOpen, setIsOpen, isAnswering, setIsAnswering };

  return (
    <AskJourneyDrawerContext.Provider value={value}>
      {children}
    </AskJourneyDrawerContext.Provider>
  );
};

export default useAskJourneyDrawer;
