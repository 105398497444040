import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toHome } from "../../services/linker";
import React from "react";
import palette from "../../theme/palette";

const GoHomeButton = () => {
    const navigate = useNavigate();
    return (
        <Stack
            sx={{
                marginTop: 4,
                backgroundColor: palette.primary.main,
                color: "white",
                padding: 2,
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "6px",
                cursor: "pointer",
                ":hover": {
                    opacity: 0.8
                },
                fontSize: "20px",
                fontWeight: 700,
                width: "max-content"
            }}
            onClick={
                () => navigate(toHome())
            }>Go Back</Stack>
    );
};


export default GoHomeButton;