import React from 'react';

import { Menu, MenuItem, SelectChangeEvent, Stack } from '@mui/material';

import {  Timeframe } from '../../../../api/generated';
import {
  InputTitle,
  KeyResultTypeSelection,
  MonthsDuration,
  SelectInputContainer
} from '../../../../modals/InitiativeModal/InitiativeModalStyles';
import { generateArrayOfUpcomingYears } from '../../../../utils/dateUtil';
import { months } from '../../../../utils/filesUtil';

type TimeRangePopoverProps = {
  timeframe: Timeframe;
  onChangeTimeframe: (timeframe: Timeframe) => void;
  anchorEl: Element;
  open: boolean;
  handleClose: (event) => void;
};

const TimeRangePopover = ({
  timeframe,
  onChangeTimeframe,
  anchorEl,
  open,
  handleClose
}: TimeRangePopoverProps) => {
  const yearsArray = generateArrayOfUpcomingYears(6);
  return (
    <Menu
      id="edit-priority-menu"
      aria-labelledby="edit-priority-button"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 22,
        horizontal: 'right'
      }}
      sx={{
        filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.13))',
        borderRadius: '6px'
      }}
    >
      <Stack padding="10px" direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row">
          <Stack direction="row" alignItems="center" gap={1}>
            <InputTitle>Start on</InputTitle>
            <Stack direction="row" alignItems="center">
              <SelectInputContainer direction="row" alignItems="center">
                <KeyResultTypeSelection
                  id="outlined-select-year"
                  value={timeframe.year}
                  onChange={(event: SelectChangeEvent<string>) =>
                    onChangeTimeframe({
                      year: parseInt(event.target.value),
                      month: timeframe.month,
                      durationInMonths: timeframe.durationInMonths
                    })
                  }
                >
                  {yearsArray.map(year => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </KeyResultTypeSelection>
              </SelectInputContainer>

              <SelectInputContainer direction="row" alignItems="center" sx={{ marginLeft: '8px' }}>
                <KeyResultTypeSelection
                  id="outlined-select-month"
                  value={timeframe.month}
                  onChange={(event: SelectChangeEvent<number>) =>
                    onChangeTimeframe({
                      year: timeframe.year,
                      month:
                        typeof event.target.value === 'string'
                          ? parseInt(event.target.value)
                          : event.target.value,
                      durationInMonths: timeframe.durationInMonths
                    })
                  }
                >
                  {Object.values(months).map(
                    (month, index) =>
                      index > 0 && (
                        <MenuItem key={index} value={index}>
                          {month}
                        </MenuItem>
                      )
                  )}
                </KeyResultTypeSelection>
              </SelectInputContainer>
            </Stack>
            <InputTitle>for</InputTitle>
            <MonthsDuration
              inputProps={{ min: 1, max: 12 }}
              id="standard-number"
              type="number"
              value={timeframe.durationInMonths}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onChangeTimeframe({
                  year: timeframe.year,
                  month: timeframe.month,
                  durationInMonths:
                    typeof event.target.value === 'string'
                      ? parseInt(event.target.value)
                      : event.target.value
                })
              }
            />
            <InputTitle>
              {timeframe.durationInMonths === 1 ? 'month' : 'months'}
            </InputTitle>
          </Stack>
        </Stack>
      </Stack>
    </Menu>
  );
};

export default TimeRangePopover;
