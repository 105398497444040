import React from 'react';

import { Stack } from '@mui/material';

import { ReactComponent as JourneyLogo } from '../../../assets/journey.svg';

const JourneyLogoSection = () => (
  <Stack sx={{ height: '120px' }} alignItems="center">
    <JourneyLogo />
  </Stack>
);

export default JourneyLogoSection;
