import { useCallback, useMemo } from 'react';

import {
  SharedWorkspaceMeetingActivityDto,
  useCreateMeetingActivity,
  useListMeetingActivities
} from '../api/generated';

const useSharedWorkspaceMeetingActivity = (meetingId?: string) => {
  const {
    data: activityResponse,
    isLoading: activityLoading,
    refetch: refetchActivity
  } = useListMeetingActivities(meetingId, {
    query: {
      queryKey: ['sharedWorkspaceMeetingActivity', meetingId],
      enabled: !!meetingId
    }
  });

  const { mutateAsync: mutateCreateMeetingActivity } = useCreateMeetingActivity();

  const createActivity = useCallback(
    async (data: Partial<SharedWorkspaceMeetingActivityDto>) => {
      const response = await mutateCreateMeetingActivity({ data });
      return response?.items?.[0];
    },
    [mutateCreateMeetingActivity]
  );


  const activity = useMemo(() => activityResponse?.items || [], [activityResponse]);



  return {
    activity,
    activityLoading,
    refetchActivity,
    createActivity
  };
};

export default useSharedWorkspaceMeetingActivity;
