import React, { SetStateAction, createContext, useContext, useState } from 'react';

interface WorkSpaceSideBarContext {
  isSharedWorkSpaceSidebarOpened: boolean;
  setIsSharedWorkSpaceSidebarOpened: React.Dispatch<SetStateAction<boolean>>;
  sharedWorkspaceId: string;
  setSharedWorkspaceId: React.Dispatch<SetStateAction<string>>;
  notesPointMap: Map<string, string>;
  setNotesPoint: (sharedWorkspaceId: string, value: string) => void;

}

const Context = createContext<WorkSpaceSideBarContext | null>(null);

const useSharedWorkspaceSidemenu = () => useContext(Context);

export const SharedWorkspaceSidebarProvider = ({ children }: { children: React.ReactNode }) => {
  const [isSharedWorkSpaceSidebarOpened, setIsSharedWorkSpaceSidebarOpened] = useState(true);
  const [sharedWorkspaceId, setSharedWorkspaceId] = useState(null);
  const [notesPointMap, setNotesPointMap] = useState<Map<string, string>>(new Map());


  const setNotesPoint =
    (sharedWorkspaceId: string, value: string) => {
      setNotesPointMap(prev => new Map([...prev, [sharedWorkspaceId, value]]));
    }

  const value = {
    isSharedWorkSpaceSidebarOpened,
    setIsSharedWorkSpaceSidebarOpened,
    sharedWorkspaceId,
    setSharedWorkspaceId,
    notesPointMap,
    setNotesPoint
  };


  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default useSharedWorkspaceSidemenu;
