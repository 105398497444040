import React from 'react';

import { Menu, Stack } from '@mui/material';

import { ActionPlanInitiativeDtoPriority } from '../../../../api/generated';
import PrioritySelector from '../../../../components/PrioritySelector/PrioritySelector';

type PrioritySelectorPopoverProps = {
  anchorEl: Element;
  open: boolean;
  priority: ActionPlanInitiativeDtoPriority;
  handleClose: () => void;
  onSelection: (priority: ActionPlanInitiativeDtoPriority) => void;
};

const PrioritySelectorPopover = ({
  anchorEl,
  open,
  priority,
  handleClose,
  onSelection
}: PrioritySelectorPopoverProps) => (
  <Menu
    id="edit-priority-menu"
    aria-labelledby="edit-priority-button"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 22,
      horizontal: 'right'
    }}
    sx={{
      filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.13))',
      borderRadius: '6px'
    }}
  >
    <Stack sx={{ padding: '6px 10px' }}>
      <PrioritySelector
        priority={priority}
        onSelection={(priority: ActionPlanInitiativeDtoPriority): void => onSelection(priority)}
      />
    </Stack>
  </Menu>
);

export default PrioritySelectorPopover;
