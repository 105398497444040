import { useCallback, useMemo } from 'react';

import {
  ActionPlanInitiativeDto,
  SearchDetailedInitiativeRequest,
  SearchEntityRequest,
  UserFeatureFeature,
  useBulkPatchInitiatives,
  useCreateActionPlanInitiative,
  useDeleteActionPlanInitiative,
  useDuplicateActionPlanInitiative,
  useGetActionPlanInitiative,
  useListActionPlanInitiatives,
  useListLatestUpdatedInitiatives,
  usePatchActionPlanInitiative,
  useSearchActionPlanInitiativeDetailed,
  useSearchActionPlanInitiatives,
  useUpdateActionPlanInitiative
} from '../api/generated';
import useUserFeature from './useUserFeature';

export const useActionPlanInitiative = (
  initiativeId?: string,
  includeArchived?: boolean,
  objectiveIds?: string[]
) => {
  const { isFeatureBlocked } = useUserFeature();

  const isActionPlanEnabled = !isFeatureBlocked(UserFeatureFeature.actionPlan);

  const {
    data: initiativeResponse,
    isLoading: initiativeLoading,
    refetch: refetchInitiative
  } = useGetActionPlanInitiative(initiativeId, {
    query: {
      queryKey: ['objectiveId', initiativeId],
      enabled: !!initiativeId && isActionPlanEnabled
    }
  });

  const {
    data: initiativeListData,
    isLoading: initiativeListLoading,
    refetch: refetchInitiatives
  } = useListActionPlanInitiatives(
    {
      includeArchived: includeArchived || null,
      objectiveIds: objectiveIds ? objectiveIds.toString() : null
    },
    {
      query: {
        queryKey: ['initiativeList', objectiveIds, includeArchived],
        enabled: isActionPlanEnabled
      }
    }
  );

  const { data: lastInitiativeListData } = useListLatestUpdatedInitiatives({
    query: {
      queryKey: ['lastInitiativeList'],
      enabled: isActionPlanEnabled
    }
  });

  const { mutateAsync: mutateCreateActionPlanInitiative, isPending: createInitiativeLoading } =
    useCreateActionPlanInitiative();
  const { mutateAsync: mutatePatchActionPlanInitiative } = usePatchActionPlanInitiative();
  const { mutateAsync: mutateUpdateActionPlanInitiative } = useUpdateActionPlanInitiative();
  const { mutateAsync: mutateDeleteActionPlanInitiative } = useDeleteActionPlanInitiative();
  const { mutateAsync: mutateBulkPatchActionPlanInitiatives } = useBulkPatchInitiatives();
  const { mutateAsync: mutateDuplicateActionPlanInitiative } = useDuplicateActionPlanInitiative();
  const { mutateAsync: mutateSearchActionPlanInitiatives, isPending: searchInitiativesLoading } =
    useSearchActionPlanInitiatives();
  const { mutateAsync: mutateSearchDetailedInitiatives, isPending: searchDetailedInitiativesLoading } =
    useSearchActionPlanInitiativeDetailed();

  const searchInitiatives = useCallback(
    async (data: SearchEntityRequest, includeArchived: boolean) => {
      const response = await mutateSearchActionPlanInitiatives({
        data,
        params: { includeArchived: includeArchived }
      });
      return response?.items || [];
    },
    [mutateSearchActionPlanInitiatives]
  );

  const searchDetailedInitiatives = useCallback(
    async (data: SearchDetailedInitiativeRequest, includeArchived: boolean) => {
      const response = await mutateSearchDetailedInitiatives({
        data,
        params: { includeArchived: includeArchived }
      });
      return response?.items || [];
    },
    [mutateSearchDetailedInitiatives]
  );

  const createActionPlanInitiative = useCallback(
    async (initiative: Partial<ActionPlanInitiativeDto>) => {
      const response = await mutateCreateActionPlanInitiative({ data: initiative });
      return response?.items?.[0];
    },
    [mutateCreateActionPlanInitiative]
  );

  const patchActionPlanInitiative = useCallback(
    async (id: string, initiative: Partial<ActionPlanInitiativeDto>) => {
      await mutatePatchActionPlanInitiative({ id, data: initiative });
    },
    [mutatePatchActionPlanInitiative]
  );

  const updateActionPlanInitiative = useCallback(
    async (id: string, initiative: Partial<ActionPlanInitiativeDto>) => {
      await mutateUpdateActionPlanInitiative({ id, data: initiative });
    },
    [mutateUpdateActionPlanInitiative]
  );

  const deleteActionPlanInitiative = useCallback(
    async (id: string, deleteMetric?: boolean) => {
      await mutateDeleteActionPlanInitiative({ id: id, params: { deleteMetric: deleteMetric } });
    },
    [mutateDeleteActionPlanInitiative]
  );

  const bulkPatchActionPlanInitiatives = useCallback(
    async (data: ActionPlanInitiativeDto[]) => {
      await mutateBulkPatchActionPlanInitiatives({ data: { items: data } });
    },
    [mutateBulkPatchActionPlanInitiatives]
  );

  const duplicateActionPlanInitiative = useCallback(
    async (id: string, objectiveId: string) => {
      await mutateDuplicateActionPlanInitiative({ id, params: { objectiveId: objectiveId } });
    },
    [mutateDuplicateActionPlanInitiative]
  );

  const initiative = useMemo(
    () => initiativeResponse?.items[0] || null,
    [initiativeResponse?.items]
  );

  const initiativeList = useMemo(
    () => initiativeListData?.items || [],
    [initiativeListData?.items]
  );

  const lastInitiativeList = useMemo(
    () => lastInitiativeListData?.items || [],
    [lastInitiativeListData?.items]
  );

  return useMemo(
    () => ({
      initiativeList,
      refetchInitiatives,
      initiativeListLoading,
      initiative,
      initiativeLoading,
      refetchInitiative,
      createActionPlanInitiative,
      createInitiativeLoading,
      updateActionPlanInitiative,
      patchActionPlanInitiative,
      duplicateActionPlanInitiative,
      bulkPatchActionPlanInitiatives,
      deleteActionPlanInitiative,
      lastInitiativeList,
      searchInitiatives,
      searchInitiativesLoading,
      searchDetailedInitiatives,
      searchDetailedInitiativesLoading
    }),
    [
      initiativeList,
      refetchInitiatives,
      initiativeListLoading,
      initiative,
      initiativeLoading,
      refetchInitiative,
      createActionPlanInitiative,
      createInitiativeLoading,
      updateActionPlanInitiative,
      patchActionPlanInitiative,
      duplicateActionPlanInitiative,
      bulkPatchActionPlanInitiatives,
      deleteActionPlanInitiative,
      lastInitiativeList,
      searchInitiatives,
      searchInitiativesLoading,
      searchDetailedInitiatives,
      searchDetailedInitiativesLoading
    ]
  );
};

export default useActionPlanInitiative;
