import IsPropValid from '@emotion/is-prop-valid';
import { Box, Stack, Typography, styled } from '@mui/material';

import palette from '../../theme/palette';

const Container = styled(Stack)({
  padding: '16px',
  gap: '15px',
  background: palette.common.darkBlue,
  boxShadow: '0px 10px 20px rgba(28, 39, 76, 0.03)',
  borderRadius: '8px'
});

const Title = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 700,
  fontSize: '18px',
  color: 'white'
});

const TitleDescription = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 400,
  fontSize: '13px',
  color: 'white',
  opacity: 0.8
});

const OptionsTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 400,
  fontSize: '13px',
  color: 'white'
});

const BigPrioritySelectionOptionContainer = styled(Stack, { shouldForwardProp: IsPropValid })<{
  backgroundColor: string;
}>(({ backgroundColor }) => ({
  background: backgroundColor,
  flexDirection: 'row',
  alignItems: 'center',
  padding: '4px 8px 4px 8px',
  gap: '4px',
  width: 'fit-content',
  height: '30px',
  borderRadius: '16px',
  cursor: 'pointer'
}));

const CheckCircle = styled(Box, { shouldForwardProp: IsPropValid })<{
  checked: boolean;
  checkedColor: string;
}>(({ checked, checkedColor }) => ({
  background: 'white',
  borderRadius: '50%',
  height: '15px',
  width: '15px',
  border: checked && `5.6px solid ${checkedColor}`,
  margin: '6px'
}));

const SelectionTitle = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontWeight: 500,
  fontSize: '13px',
  color: palette.common.darkBlue
});

const DoneButton = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 2px',
  width: '96px',
  height: '32px',
  color: 'white',
  background: palette.green[1],
  borderRadius: '8px',
  cursor: 'pointer',
  ':hover': {
    transform: 'scale(1.06)'
  }
});

export {
  Container,
  Title,
  TitleDescription,
  OptionsTitle,
  BigPrioritySelectionOptionContainer,
  CheckCircle,
  SelectionTitle,
  DoneButton
};
