import React, { SetStateAction, createContext, useContext, useState } from 'react';


type ActionPlanArchivedStateContext = {
  includeArchived: boolean;
  setIncludeArchived: React.Dispatch<SetStateAction<boolean>>;
  initiativeIncludeArchived: boolean;
  setInitiativeIncludeArchived: React.Dispatch<SetStateAction<boolean>>;
};

export const ActionPlanArchivedContext =
  createContext<ActionPlanArchivedStateContext | null>(null);

const useActionPlanArchivedContext = () => useContext(ActionPlanArchivedContext);
export const ActionPlanArchivedProvider = ({ children }: { children: React.ReactNode }) => {
  const [includeArchived, setIncludeArchived] = useState<boolean>(false);
  const [initiativeIncludeArchived, setInitiativeIncludeArchived] = useState<boolean>(false);
  const value = { includeArchived, setIncludeArchived, initiativeIncludeArchived, setInitiativeIncludeArchived };


  return (
    <ActionPlanArchivedContext.Provider value={value}>
      {children}
    </ActionPlanArchivedContext.Provider>
  );
};

export default useActionPlanArchivedContext;






