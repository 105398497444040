/* eslint-disable react/prop-types */
import React, { ReactNode, Fragment, Suspense } from 'react';

import { v4 as uid } from 'uuid';

import ModalContext from './ModalContext';
import { DestroyByRootIdFn, DestroyFn, HideFn, ShowFn, UpdateFn } from './ModalContextTypes';
import reducer, { initialState, ModalActions } from './ModalReducer';

export interface ModalProviderProps {
  children: ReactNode;
  /**
   * Enable it if you want to use mui < 5 version
   */
  legacy?: boolean;
  /**
   * Enable it if you want to wrap the modals with the Suspense feature.
   * @see https://beta.reactjs.org/reference/react/Suspense
   */
  suspense?: boolean;
  /**
   * Custom fallback for the Suspense fallback
   * @see https://beta.reactjs.org/reference/react/Suspense#displaying-a-fallback-while-content-is-loading
   */
  fallback?: ReactNode | null;
}

export default function ModalProvider({
  children,
  legacy = false,
  suspense = true,
  fallback = null
}: ModalProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const MISSED_MODAL_ID_ERROR_MESSAGE = '[ERROR]: Modal ID is missing';
  const MISSED_MODAL_ROOT_ID_ERROR_MESSAGE = '[ERROR]: Modal root ID is missing';

  const update = React.useCallback<UpdateFn>(
    (id, { ...props }) => {
      if (!id) {
        console.error(MISSED_MODAL_ID_ERROR_MESSAGE);
        return;
      }

      dispatch({
        type: ModalActions.UPDATE,
        payload: {
          id,
          props
        }
      });
    },
    [dispatch]
  );

  const hide = React.useCallback<HideFn>(
    id => {
      if (!id) {
        console.error(MISSED_MODAL_ID_ERROR_MESSAGE);
        return;
      }

      dispatch({
        type: ModalActions.HIDE,
        payload: {
          id
        }
      });
    },
    [dispatch]
  );

  const destroy = React.useCallback<DestroyFn>(
    id => {
      if (!id) {
        console.error(MISSED_MODAL_ID_ERROR_MESSAGE);
        return;
      }

      dispatch({
        type: ModalActions.DESTROY,
        payload: {
          id
        }
      });
    },
    [dispatch]
  );

  const destroyByRootId = React.useCallback<DestroyByRootIdFn>(
    rootId => {
      if (!rootId) {
        console.error(MISSED_MODAL_ROOT_ID_ERROR_MESSAGE);
        return;
      }

      dispatch({
        type: ModalActions.DESTROY_BY_ROOT_ID,
        payload: {
          rootId
        }
      });
    },
    [dispatch]
  );

  const show = React.useCallback<ShowFn>(
    (component, props, options) => {
      let id = uid();

      if (options && options.rootId) {
        id = `${options.rootId}.${id}`;
      }

      dispatch({
        type: ModalActions.SHOW,
        payload: {
          id,
          component,
          props,
          options
        }
      });

      return {
        id,
        hide: () => hide(id),
        destroy: () => destroy(id),
        update: newProps => update(id, newProps)
      };
    },
    [dispatch, hide, destroy, update]
  );

  const renderState = () =>
    Object.keys(state).map(id => {
      const { component: Component, props, options } = state[id];

      const handleClose = (...args: unknown[]) => {
        if (options && options.destroyOnClose) {
          destroy(id);
        } else {
          hide(id);
        }

        if (props && props.onClose && typeof props.onClose === 'function') {
          props.onClose(...args);
        }
      };

      const handleExited = (...args: unknown[]) => {
        if (props?.onExited && typeof props.onExited === 'function') {
          props.onExited(...args);
        }

        if (
          props?.TransitionProps?.onExited &&
          typeof props.TransitionProps.onExited === 'function'
        ) {
          props.TransitionProps.onExited(...args);
        }

        destroy(id);
      };

      let extraProps = {};

      if (!legacy) {
        extraProps = {
          TransitionProps: {
            ...props?.TransitionProps,
            onExited: handleExited
          }
        };
      } else {
        extraProps = {
          onExited: handleExited
        };
      }

      return (
        <Component
          {...props}
          key={id}
          onClose={handleClose}
          {...(options && !options.destroyOnClose && extraProps)}
        />
      );
    });

  const SuspenseWrapper = suspense ? Suspense : Fragment;

  return (
    <ModalContext.Provider
      value={{
        state,
        updateModal: update,
        hideModal: hide,
        destroyModal: destroy,
        showModal: show,
        destroyModalsByRootId: destroyByRootId
      }}
    >
      {children}
      <SuspenseWrapper fallback={fallback}>{renderState()}</SuspenseWrapper>
    </ModalContext.Provider>
  );
}
