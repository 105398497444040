import React, { useState } from 'react';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { CircularProgress, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useDebouncedCallback } from 'use-debounce';

import { MeetingDto } from '../../api/generated';
import useMeetings from '../../hooks/useMeetings';
import Editor from '../../screens/BizAreaModules/components/Editor';
import palette from '../../theme/palette';
import {
  Container,
  Content,
  Description,
  Icon,
  SendButton,
  Summary,
  Title
} from './FollowUpStyles';

type FollowUpProps = {
  meeting: MeetingDto;
  editable?: boolean;
};

const FollowUp = ({ meeting, editable = true }: FollowUpProps) => {
  const [expanded, setExpanded] = useState(false);
  const [editorChangeLoading, setEditorChangeLoading] = useState(false);
  const {
    updateMeetingFollowUp,
    sendMeetingFollowUp,
    refetchCurrentMeetingDetails,
    sendFollowUpLoading
  } = useMeetings();

  const debouncedUpdateFollowUp = useDebouncedCallback(
    (value: string) =>
      updateMeetingFollowUp(meeting?.id, value).then(() => setEditorChangeLoading(false)),
    500
  );

  const currentMeetingFollowUps = meeting?.followUps;

  return (
    <Container sx={{ borderRadius: '16px !important' }} disableGutters expanded={expanded}>
      <Summary
        onClick={() => setExpanded(prev => !prev)}
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      >
        <Icon />
        <Title marginLeft="8px">Follow up</Title>
      </Summary>
      <Description>
        {editable ? (
          <Editor
            onChange={value => {
              setEditorChangeLoading(true);
              debouncedUpdateFollowUp(value);
            }}
            defaultValue={currentMeetingFollowUps?.content}
          />
        ) : (
          <Content>{currentMeetingFollowUps?.content}</Content>
        )}
        <Stack marginTop="12px">
          <Stack direction="row" alignItems="center" justifyContent="end">
            <Stack direction="row" alignItems="center" marginLeft="20px">
              {(editorChangeLoading || sendFollowUpLoading) && (
                <CircularProgress size="20px" sx={{ marginRight: '8px' }} />
              )}
              {currentMeetingFollowUps?.sentAt && (
                <Typography sx={{ marginRight: '8px', fontSize: '12px', color: palette.grey[500] }}>
                  Sent at: {moment(currentMeetingFollowUps?.sentAt).toDate().toDateString()}
                </Typography>
              )}
              <SendButton
                disabled={sendFollowUpLoading || editorChangeLoading}
                onClick={() =>
                  !editorChangeLoading &&
                  !sendFollowUpLoading &&
                  sendMeetingFollowUp(meeting.id).then(() => refetchCurrentMeetingDetails())
                }
              >
                {currentMeetingFollowUps?.sentAt ? 'Resend' : 'Send'}
              </SendButton>
            </Stack>
          </Stack>
        </Stack>
      </Description>
    </Container>
  );
};

export default FollowUp;
