import React, { useState } from 'react';

import { Link, Skeleton, styled, Typography } from '@mui/material';
import { Stack } from '@mui/system';

import { ResourceDto, useDeleteGoogleDriveFile, useDeleteResource } from '../../../api/generated';
import DeleteItem from '../../../components/Common/DeleteItem';
import palette from '../../../theme/palette';
import { getResourceFileExtenstionIcon } from '../../../utils/filesUtil';

import Moment from 'react-moment';

const ResourceText = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 400,
  fontSize: '13px',
  color: palette.common.darkBlue,
  cursor: 'pointer'
});

const ResourceDate = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 400,
  fontSize: '10px',
  color: palette.grey[700]
});

interface ResourceProps {
  resource: ResourceDto;
  refetch: () => void;
}


const Resource = ({ resource, refetch }: ResourceProps) => {
  const { mutateAsync: deleteResource, isPending: isDeleteResourceLoading } = useDeleteResource();
  const { mutateAsync: deleteGoogleDriveResource, isPending: isDeleteDriveFileLoading } = useDeleteGoogleDriveFile();
  const [hover, setHover] = useState<boolean>(false);
  const isDriveResource = !resource.createdAt;

  return (
    resource ?
      <Stack
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        sx={{
          mb: 1,
          ':hover': { backgroundColor: palette.grey[100] }
        }}
        direction="row"
        alignItems="center"
      >
        <Link
          href={resource.link}
          target="_blank"
          rel="noreferrer"
          style={{ width: '100%', textDecoration: 'none' }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap={1}>
              <Stack width="42px" minHeight="36px">
                {getResourceFileExtenstionIcon(resource.type)}
              </Stack>
              <ResourceText>{resource.title}</ResourceText>

            </Stack>
            {isDriveResource &&
              <ResourceDate>{<Moment fromNow ago>
                {resource.updatedAt}
              </Moment>} ago</ResourceDate>
            }

          </Stack>

        </Link>
        {hover &&
          <DeleteItem
            loading={isDriveResource ? isDeleteDriveFileLoading : isDeleteResourceLoading}
            title={isDriveResource ? 'File' : 'Link'}
            id={resource.id}
            doDelete={id => isDriveResource ? deleteGoogleDriveResource({ id }) : deleteResource({ id })}
            refetch={refetch}
            iconSize="small"
            toolTip={isDriveResource ? 'Delete file' : 'Remove link'}
          />}
      </Stack>

      :
      <Stack spacing={1}>
        <Skeleton

          animation="pulse"
          variant="circular" />
        <Skeleton animation="wave" />
      </Stack>
  );
};

export default React.memo(Resource);
