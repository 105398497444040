import React, { useEffect, useState } from 'react';

import AddBoxIcon from '@mui/icons-material/AddBox';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import {
  CircularProgress,
  Stack,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses
} from '@mui/material';
import { orderBy } from 'lodash';

import {
  MetricDefinitionDto,
  MetricDefinitionDtoPeriod,
  MetricTargetDefinitionDto
} from '../../../../../../api/generated';
import useMetricSidebarView from '../../../../../../hooks/context-providers/useMetricSidebarView';
import useAuth from '../../../../../../hooks/useAuth';
import useMetricDefinition from '../../../../../../hooks/useMetricDefinition';
import palette from '../../../../../../theme/palette';
import {
  getCurrentPeriodDate,
  getDatePeriod,
  getPeriodDate,
  toUtcIsoDate
} from '../../../../../../utils/dateUtil';
import MetricTargetRow from '../MetricDataPointRow/MetricTargetRow';
import {
  AddButtonContainer,
  AddNewText,
  ColumnTitle,
  EmptyDataPointsTitle,
  TooltipText
} from './MetricDataPointsTableStyles';

export type TargetDetails = {
  target: MetricTargetDefinitionDto;
  index: number;
};

export type PeriodData = {
  index: number;
  label: string;
};
const ActionTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: palette.grey[1300],
    maxWidth: 400,
    border: '1px solid #dadde9'
  }
}));

const MetricTargetsTable = ({
  currentMetric,
  refetch
}: {
  currentMetric: MetricDefinitionDto;
  refetch: () => void;
}) => {
  const {
    metricDefinition,
    refetchMetricDefinition,
    calcMetricDefinitionProgress,
    patchMetricDefinition
  } = useMetricDefinition(currentMetric.id);

  const { currentAccount } = useAuth();
  const monthToStart = currentAccount?.config?.fiscalYearStartMonth || 1;

  const [targets, setTargets] = useState<MetricTargetDefinitionDto[]>([]);

  const { setCurrentMetric } = useMetricSidebarView();

  useEffect(() => {
    if (metricDefinition) {
      setCurrentMetric(metricDefinition);
      if (metricDefinition?.targets) {
        setTargets(orderBy(metricDefinition.targets, item => new Date(item.timestamp), 'desc'));
      } else {
        setTargets([]);
      }
    }
  }, [metricDefinition, setTargets, setCurrentMetric]);

  const getTimestampForNewDataPoint = () => {
    let timestamp: Date;
    if (targets?.length > 0) {
      const lastTimestamp = new Date(orderBy(targets, item => item.timestamp, 'desc')[0].timestamp);

      switch (currentMetric.period) {
        default:
        case MetricDefinitionDtoPeriod.monthly: {
          timestamp = new Date(
            lastTimestamp.getMonth() === 11
              ? lastTimestamp.getFullYear() + 1
              : lastTimestamp.getFullYear(),
            lastTimestamp.getMonth() === 11 ? 1 : lastTimestamp.getMonth() + 2,
            0
          );
          break;
        }
        case MetricDefinitionDtoPeriod.semiannually: {
          const periodNumber = getDatePeriod(currentMetric.period, monthToStart, lastTimestamp);
          if (periodNumber === 2) {
            const periodDate = getPeriodDate(currentMetric.period, monthToStart, 1);
            timestamp = new Date(
              lastTimestamp.getFullYear() + 1,
              periodDate.getMonth(),
              periodDate.getDate()
            );
          } else {
            const periodDate = getPeriodDate(currentMetric.period, monthToStart, periodNumber + 1);

            timestamp = new Date(
              lastTimestamp.getFullYear(),
              periodDate.getMonth(),
              periodDate.getDate()
            );
          }
          break;
        }

        case MetricDefinitionDtoPeriod.quarterly: {
          const periodNumber = getDatePeriod(currentMetric.period, monthToStart, lastTimestamp);

          if (periodNumber === 4) {
            const periodDate = getPeriodDate(currentMetric.period, monthToStart, 1);
            timestamp = new Date(
              lastTimestamp.getFullYear() + 1,
              periodDate.getMonth(),
              periodDate.getDate()
            );
          } else {
            const periodDate = getPeriodDate(currentMetric.period, monthToStart, periodNumber + 1);
            timestamp = new Date(
              lastTimestamp.getFullYear(),
              periodDate.getMonth(),
              periodDate.getDate()
            );
          }
          break;
        }
        case MetricDefinitionDtoPeriod.annually: {
          timestamp = new Date(
            lastTimestamp.getFullYear() + 1,
            lastTimestamp.getMonth(),
            lastTimestamp.getDate()
          );
          break;
        }
      }
    } else {
      timestamp = getCurrentPeriodDate(currentMetric.period, monthToStart);
    }

    return timestamp;
  };

  const onAddNew = () => {
    const timestamp = getTimestampForNewDataPoint();

    setTargets(prev => [{ timestamp: toUtcIsoDate(timestamp) }, ...prev]);
  };

  const onCancelNew = async (index: number) => {
    setTargets(prev => prev.splice(index, 0));
  };

  const updateTarget = async (index: number, target: MetricTargetDefinitionDto) => {
    const newList = targets;
    newList[index] = target;
    patchMetricDefinition(currentMetric.id, { targets: newList }).then(() => {
      setTargets(newList);
      refetchData();
      calcMetricDefinitionProgress(currentMetric.id);
    });
  };

  const removeTarget = async (timestamp: string, value: number) => {
    const newList = targets.filter(tar => tar.timestamp !== timestamp && tar.value !== value);
    patchMetricDefinition(currentMetric.id, { targets: newList }).then(() => {
      setTargets(newList);
      calcMetricDefinitionProgress(currentMetric.id);
    });
  };

  const refetchData = () => {
    if (refetch) refetch();
    refetchMetricDefinition();
    calcMetricDefinitionProgress(currentMetric.id);
  };

  return (
    <Stack padding="20px">
      <Stack marginBottom={1} direction="row" alignItems="start" justifyContent="space-between">
        <Stack width="70%" direction="row" alignItems="center" justifyContent="space-between">
          <ColumnTitle>Date</ColumnTitle>
          <ColumnTitle>Value</ColumnTitle>
        </Stack>

        <Stack direction="row" alignItems="center" gap="5px">
          <ActionTooltip title={<TooltipText>Add target</TooltipText>}>
            <AddButtonContainer onClick={onAddNew}>
              <AddBoxIcon
                sx={{
                  width: 35,
                  height: 35
                }}
              />
            </AddButtonContainer>
          </ActionTooltip>
        </Stack>
      </Stack>

      {!metricDefinition ? (
        <Stack justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Stack>
          {targets?.length > 0 ? (
            targets.map((dp, index) => (
              <MetricTargetRow
                key={index}
                targetIndex={index}
                target={dp.value}
                period={currentMetric.period}
                metricDefinitionId={currentMetric.id}
                timestamp={dp.timestamp}
                refetch={refetchData}
                onCancelNew={onCancelNew}
                updateTarget={updateTarget}
                removeTarget={removeTarget}
              />
            ))
          ) : (
            <Stack gap={1} marginTop={10} alignItems="center" justifyContent="center">
              <QueryStatsOutlinedIcon
                sx={{ color: palette.primary.main, width: '60px', height: '60px' }}
              />
              <Stack direction="row" alignItems="center" gap={1}>
                <EmptyDataPointsTitle>No targets yet.</EmptyDataPointsTitle>
                <AddNewText onClick={onAddNew}>Add target</AddNewText>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default MetricTargetsTable;
