import React, { } from 'react';


import { Checkbox, Tooltip } from '@mui/material';

// import { ReactComponent as Checked } from '../../assets/checked.svg';
// import { ReactComponent as UnChecked } from '../../assets/unchecked.svg';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';



type AppCheckboxProps = {
  checked: boolean;
  checkboxOnClick: () => void;
  width?: number | string;
  height?: number | string;
};

const AppCheckbox = ({
  checked,
  checkboxOnClick,
  width = "20px",
  height = "20px"
}: AppCheckboxProps) =>

(
<Tooltip
arrow
placement="top"

            title='Mark as completed'>
  <Checkbox
    checkedIcon={<CircleCheckedFilled width={width} height={height} />}
    icon={<CircleUnchecked width={width} height={height} />}
    edge="start"
    inputProps={{width:width, height:height}}
    checked={checked}
    onClick={checkboxOnClick}
    disableRipple
    sx={{padding:"0px"}}
  />
</Tooltip>

);


export default AppCheckbox;
