import { Components } from '@mui/material/styles';

import palette from '../../theme/palette';
import typography from '../typography';

const MuiTextField: Components['MuiTextField'] = {
  styleOverrides: {
    root: {
      color: palette.grey['700'],
      ...typography.body2,
      lineHeight: '17px',
      position: 'relative',
      transform: 'unset',
      marginBottom: '0.5rem',
      padding: '12px 16px',
      background: 'white',
      border: `1px solid ${palette.border.grey}`,
      borderRadius: '8px',
      input: {
        '&::placeholder': {
          color: palette.grey[1100],
          fontSize: '5px'
        }
      }
    }
  }
};

export default MuiTextField;
