import React, { SetStateAction, createContext, useContext, useState } from 'react';

import { DataSourceDtoProviderType } from '../../api/generated';

type AcountSettingsStateContext = {
  selectedProviderId: string;
  setSelectedProviderId: React.Dispatch<SetStateAction<string>>;
  selectedProviderType: DataSourceDtoProviderType;
  setSelectedProviderType: React.Dispatch<SetStateAction<DataSourceDtoProviderType>>;
};

export const AcountSettingsContext = createContext<AcountSettingsStateContext | null>(null);

const useAcountSettingsContext = () => useContext(AcountSettingsContext);
export const AcountSettingsProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedProviderId, setSelectedProviderId] = useState<string>('');
  const [selectedProviderType, setSelectedProviderType] = useState<DataSourceDtoProviderType>(null);

  const value = {
    selectedProviderId,
    setSelectedProviderId,
    selectedProviderType,
    setSelectedProviderType
  };

  return <AcountSettingsContext.Provider value={value}>{children}</AcountSettingsContext.Provider>;
};

export default useAcountSettingsContext;
