import React, { useState } from 'react';

import DoneIcon from '@mui/icons-material/Done';
import { Stack } from '@mui/material';

import { ActionPlanInitiativeDtoPriority } from '../../api/generated';
import palette from '../../theme/palette';
import { initiativesPriorityColorsMap } from '../../utils/actionPlanUtil';
import { generateEmptySpaces } from '../../utils/textFormater';
import PrioritySelectorTool from '../PrioritySelectorTool/PrioritySelectorTool';
import {
  BigPrioritySelectionOptionContainer,
  CheckCircle,
  PrioritisationToolTitle,
  RedAsterisk,
  SelectionTitle,
  Title
} from './PrioritySelectorStyles';

type PrioritySelectorProps = {
  priority: ActionPlanInitiativeDtoPriority;
  onSelection: (priority: ActionPlanInitiativeDtoPriority) => void;
};

const PrioritySelector = ({ priority, onSelection }: PrioritySelectorProps) => {
  const [prioritizationToolOpen, setPrioritizationToolOpen] = useState(false);
  const [priorityToolUsed, setPriorityToolUsed] = useState(false);

  return (
    <>
      {!prioritizationToolOpen ? (
        <Stack>
          <Stack 
          height="21px"
          direction="row" 
          alignItems="center" 
          justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <Title>Priority {generateEmptySpaces(1)}</Title>
              <RedAsterisk>*</RedAsterisk>
            </Stack>
            <Stack
              onClick={() => setPrioritizationToolOpen(prev => !prev)}
              sx={{
                cursor: 'pointer',
                ':hover': {
                  transform: 'scale(1.06)'
                }
              }}
              direction="row"
              alignItems="center"
            >
              <PrioritisationToolTitle>Prioritization tool</PrioritisationToolTitle>
              {priorityToolUsed && (
                <DoneIcon sx={{ color: palette.green[1], marginLeft: '4px', fontSize: '15px' }} />
              )}
            </Stack>
          </Stack>
          <Stack direction="row" gap="6.5px" marginTop="2px">
            <BigPrioritySelectionOptionContainer
              onClick={() => onSelection(ActionPlanInitiativeDtoPriority.H)}
              backgroundColor={
                initiativesPriorityColorsMap[ActionPlanInitiativeDtoPriority.H].backgroundColor
              }
            >
              <CheckCircle
                checked={priority === ActionPlanInitiativeDtoPriority.H}
                checkedColor={
                  initiativesPriorityColorsMap[ActionPlanInitiativeDtoPriority.H].checkedColor
                }
              />
              <SelectionTitle>
                {initiativesPriorityColorsMap[ActionPlanInitiativeDtoPriority.H].label}
              </SelectionTitle>
            </BigPrioritySelectionOptionContainer>
            <BigPrioritySelectionOptionContainer
              onClick={() => onSelection(ActionPlanInitiativeDtoPriority.M)}
              backgroundColor={
                initiativesPriorityColorsMap[ActionPlanInitiativeDtoPriority.M].backgroundColor
              }
            >
              <CheckCircle
                checked={priority === ActionPlanInitiativeDtoPriority.M}
                checkedColor={
                  initiativesPriorityColorsMap[ActionPlanInitiativeDtoPriority.M].checkedColor
                }
              />
              <SelectionTitle>
                {initiativesPriorityColorsMap[ActionPlanInitiativeDtoPriority.M].label}
              </SelectionTitle>
            </BigPrioritySelectionOptionContainer>
            <BigPrioritySelectionOptionContainer
              onClick={() => onSelection(ActionPlanInitiativeDtoPriority.L)}
              backgroundColor={
                initiativesPriorityColorsMap[ActionPlanInitiativeDtoPriority.L].backgroundColor
              }
            >
              <CheckCircle
                checked={priority === ActionPlanInitiativeDtoPriority.L}
                checkedColor={
                  initiativesPriorityColorsMap[ActionPlanInitiativeDtoPriority.L].checkedColor
                }
              />
              <SelectionTitle>
                {initiativesPriorityColorsMap[ActionPlanInitiativeDtoPriority.L].label}
              </SelectionTitle>
            </BigPrioritySelectionOptionContainer>
          </Stack>
        </Stack>
      ) : (
        <PrioritySelectorTool
          onCloseTool={() => setPrioritizationToolOpen(false)}
          onToolSubmitted={(priority: ActionPlanInitiativeDtoPriority): void => {
            onSelection(priority);
            setPriorityToolUsed(true);
            setPrioritizationToolOpen(false);
          }}
        />
      )}
    </>
  );
};

export default PrioritySelector;
