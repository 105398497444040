import React from 'react';

import {
  ExpandLessIconContainer,
  ShowLessIcon,
  ShowLessIconNotRotated
} from './ExpandLessIconStyles';

type Props = {
  rotateExpanded?: boolean;
  width?: string;
  height?: string;
};

const ExpandLessIcon = ({ rotateExpanded = false, width, height }: Props) => (
  <ExpandLessIconContainer>
    {rotateExpanded ? <ShowLessIcon sx={{ width: width, height: height }} /> : <ShowLessIconNotRotated sx={{ width: width, height: height }} />}
  </ExpandLessIconContainer>
);

export default ExpandLessIcon;
