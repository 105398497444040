import React, { useMemo, useState, MouseEvent } from 'react';
import { ClearButton, ControlItem, SortContainer, SortHeader, SortValue, SortValueAdditional, Title } from './ActionItemsSortStyles';
import { ClickAwayListener, Divider, Stack, Tooltip } from '@mui/material';
import { ReactComponent as SortIcon } from '../../../assets/sort.svg';
import FilterPopper from '../../FilterPopper/FilterPopper';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';



export const SortType = {
  TIME: 'time',
  DUE_DATE: 'dueDate'
} as const;

type ActionItemsSortProps = {
  appliedSort: string;
  onApplySort: (sortType: string) => void;
};

const ActionItemsSort = ({ appliedSort, onApplySort }: ActionItemsSortProps) => {

  const [openSort, setOpenSort] = useState<HTMLDivElement | null>(null);
  const isOpenSort = useMemo(() => !!openSort, [openSort]);
  const openSortPopup = (event: React.MouseEvent<HTMLDivElement>) => {
    setOpenSort(event.currentTarget);
  };

  const closeSortPopup = () => {
    setOpenSort(null);
  };



  return (
    <ClickAwayListener
      onClickAway={() => {
        closeSortPopup();
      }}
    >
      <Stack
      >
        <SortHeader
          selected={appliedSort !== null}
          onClick={openSortPopup}
        >
          <SortIcon width="18px" height="18px" />
          <Title>Sort</Title>
          {appliedSort !== null &&
            <ClearButton
              onClick={(event: MouseEvent<HTMLDivElement>) => {
                event.stopPropagation();
                onApplySort(null);
              }}>
              <Tooltip
                title="Clear">
                <ClearOutlinedIcon sx={{ width: "16px", height: "16px" }} />
              </Tooltip>
            </ClearButton>}
        </SortHeader>
        <FilterPopper open={isOpenSort} anchorEl={openSort}>
          <SortContainer>

            <Stack>
              <ControlItem
              borderRadius="8px 8px 0px 0px"
                selected={SortType.TIME === appliedSort}
                onClick={() => {
                  onApplySort(SortType.TIME);
                  closeSortPopup();
                }}
                key={SortType.TIME}
              >
                <SortValue selected={SortType.TIME === appliedSort}>Time added</SortValue>
                <SortValueAdditional selected={SortType.TIME === appliedSort}>
                  (last to oldest)
                </SortValueAdditional>
              </ControlItem>
              <Divider flexItem />
              <ControlItem
                borderRadius="0px 0px 8px 8px"
                selected={SortType.DUE_DATE === appliedSort}
                onClick={() => {
                  onApplySort(SortType.DUE_DATE);
                  closeSortPopup();
                }}
                key={SortType.DUE_DATE}
              >
                <SortValue selected={SortType.DUE_DATE === appliedSort}>Due date</SortValue>
                <SortValueAdditional selected={SortType.DUE_DATE === appliedSort}>
                  (nearest to farthest)
                </SortValueAdditional>
              </ControlItem>
            </Stack>

          </SortContainer>
        </FilterPopper>
      </Stack>
    </ClickAwayListener>
  );
};

export default ActionItemsSort;
