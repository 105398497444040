import React, { memo } from 'react';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Tooltip } from '@mui/material';

import palette from '../../theme/palette';
import { Chip, ChipText, Title } from './ChipSelectionStyles';

export type ChipItem<T> = {
  label: string;
  value: T;
  checked: boolean;
  tooltip?: string;
};

type ChipSelectionProps<T> = {
  title?: string;
  items: Array<ChipItem<T>>;
  onChange: (value: ChipItem<T>['value']) => void;
  hideCheckoutMark?:boolean;
};

const ChipSelection = <T,>({ title, items, onChange , hideCheckoutMark = false }: ChipSelectionProps<T>) => (
  <Stack direction="row" gap="8px" alignItems="center">
    {title && <Title>{title}</Title>}
    {items.map(({ label, value, tooltip, checked }) => (
      <Chip direction="row" key={label} onClick={() => onChange(value)} checked={checked}>
        {checked && !hideCheckoutMark && <CheckOutlinedIcon sx={{ fontSize: '15px', color: palette.green[1] }} />}
        <ChipText ml="6px">{label}</ChipText>
        {tooltip && (
          <Tooltip title={tooltip}>
            <Stack justifyContent="center">
              <InfoOutlinedIcon sx={{ fontSize: '15px', marginLeft: '6px' }} />
            </Stack>
          </Tooltip>
        )}
      </Chip>
    ))}
  </Stack>
);

export default memo(ChipSelection);
