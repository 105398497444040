import { useCallback, useMemo, useState } from 'react';

const usePagination = <T>(data: Array<T>, itemsPerPage: number) => {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = useMemo(() => Math.ceil(data.length / itemsPerPage), [data.length, itemsPerPage]);
  const begin = useMemo(() => (currentPage - 1) * itemsPerPage, [currentPage, itemsPerPage]);
  const end = useMemo(() => begin + itemsPerPage, [begin, itemsPerPage]);

  const currentData = useMemo(() => data.slice(begin, end), [begin, data, end]);

  const next = useCallback(() => {
    setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
  }, [maxPage]);

  const prev = useCallback(() => {
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  }, []);

  const jump = useCallback((pageNumber: number) => {
    setCurrentPage(pageNumber);
  }, []);

  return useMemo(
    () => ({ next, prev, jump, currentData, currentPage, maxPage, begin, end }),
    [begin, currentData, currentPage, end, jump, maxPage, next, prev]
  );
};

export default usePagination;
