import { useCallback, useMemo } from 'react';

import {
  CommentDto,
  PostDto,
  SavedPostsDto,
  useCreateComment,
  useCreatePost,
  useCreateSavedPosts,
  useDeleteComment,
  useDeletePost,
  useGetPost,
  useGetSavedPosts,
  useListComments,
  useListPosts,
  usePatchComment,
  usePatchPost,
  usePatchSavedPosts,
  useRegenerateAnswer
} from '../api/generated';

const useForum = (postId?: string, limit?: number) => {
  const {
    data: postsData,
    isLoading: isPostsLoading,
    refetch: refetchPosts
  } = useListPosts(
    {
      limit: limit || null

    }
  );

  const {
    data: postData,
    isLoading: isPostLoading,
    refetch: refetchPost
  } = useGetPost(postId, {
    query: { queryKey: ['postData', postId], enabled: !!postId }
  });

  const {
    data: commentsData,
    isLoading: isCommentsLoading,
    refetch: refetchComments
  } = useListComments(
    { postId: postId },
    {
      query: { queryKey: ['commentsData', postId], enabled: !!postId }
    }
  );

  const {
    data: savedPostsData,
    isLoading: isSavedPostsLoading,
    refetch: refetchSavedPosts
  } = useGetSavedPosts();

  const { mutateAsync: mutateCreatePost } = useCreatePost();
  const { mutateAsync: mutatePatchPost } = usePatchPost();
  const { mutateAsync: mutateDeletePost } = useDeletePost();
  const { mutateAsync: mutateCreateSavedPosts } = useCreateSavedPosts();
  const { mutateAsync: mutatePatchSavedPosts } = usePatchSavedPosts();
  const { mutateAsync: mutateRegenerateAnswer } = useRegenerateAnswer();

  const { mutateAsync: mutateCreateComment } = useCreateComment();
  const { mutateAsync: mutateDeleteComment } = useDeleteComment();
  const { mutateAsync: mutatePatchComment } = usePatchComment();

  const createPost = useCallback(
    async (post: Partial<PostDto>) => {
      const response = await mutateCreatePost({ data: post });
      return response?.items?.[0] || null;
    },
    [mutateCreatePost]
  );

  const patchPost = useCallback(
    async (id: string, post: Partial<PostDto>) => {
      await mutatePatchPost({ id, data: post });
    },
    [mutatePatchPost]
  );

  const regenerateAnswer = useCallback(
    async (id: string) => {
      await mutateRegenerateAnswer({ id });
    },
    [mutateRegenerateAnswer]
  );

  const deletePost = useCallback(
    async (id: string) => {
      await mutateDeletePost({ id });
    },
    [mutateDeletePost]
  );

  const createSavedPosts = useCallback(
    async (savedPosts: Partial<SavedPostsDto>) => {
      await mutateCreateSavedPosts({ data: savedPosts });
    },
    [mutateCreateSavedPosts]
  );

  const patchSavedPosts = useCallback(
    async (savedPosts: Partial<SavedPostsDto>) => {
      await mutatePatchSavedPosts({ data: savedPosts });
    },
    [mutatePatchSavedPosts]
  );

  const createComment = useCallback(
    async (comment: Partial<CommentDto>) => {
      await mutateCreateComment({ data: comment });
    },
    [mutateCreateComment]
  );

  const patchComment = useCallback(
    async (id: string, comment: Partial<CommentDto>) => {
      await mutatePatchComment({ id, data: comment });
    },
    [mutatePatchComment]
  );

  const deleteComment = useCallback(
    async (id: string) => {
      await mutateDeleteComment({ id });
    },
    [mutateDeleteComment]
  );

  const postList = useMemo(() => postsData?.items || [], [postsData?.items]);

  const savedPosts = useMemo(() => savedPostsData?.items?.[0] || null, [savedPostsData?.items]);

  const currentPost = useMemo(() => postData?.items?.[0] || null, [postData?.items]);

  const commentList = useMemo(() => commentsData?.items || [], [commentsData?.items]);

  return {
    postList,
    isPostsLoading,
    refetchPosts,
    createPost,
    patchPost,
    regenerateAnswer,
    deletePost,
    savedPosts,
    isSavedPostsLoading,
    refetchSavedPosts,
    createSavedPosts,
    patchSavedPosts,
    currentPost,
    refetchPost,
    isPostLoading,
    commentList,
    isCommentsLoading,
    refetchComments,
    createComment,
    patchComment,
    deleteComment
  };
};

export default useForum;
