import React, { useCallback, useEffect, useState } from 'react';

import { IconButton, Stack, styled, Tooltip } from '@mui/material';
import { Table, TableProps } from "antd";
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import Column from 'antd/es/table/Column';

import { AddNew, DueDate, EmptyStateText, StatusTitle, TaskTitle } from './TasksTableStyles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';



import { ActionPlanActionItemDto, ActionPlanActionItemDtoStatus, SharedWorkspaceMeetingDto } from '../../api/generated';
import useTaskSidebar from '../../hooks/context-providers/useTaskSidebar';
import useUsers from '../../hooks/useUsers';
import useModal from '../../hooks/context-providers/useModal/useModal';
import { actionItemStatusMap } from '../../utils/actionPlanUtil';
import UserView from '../User/UserView';
import AppDatePicker from '../Common/AppDatePicker';
import { getDate, toUtcIsoDate } from '../../utils/dateUtil';
import palette from '../../theme/palette';
import useActionPlanActionItem from '../../hooks/useActionPlanActionItem';
import dayjs, { Dayjs } from 'dayjs';
import { FilterValue, SorterResult, SortOrder } from 'antd/es/table/interface';
import TaskActions from '../TaskActions/TaskActions';
import { AnyObject } from 'antd/es/_util/type';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { orderBy } from 'lodash';
import TableRow from '../Table/TableRow';
import TeamsSelect from '../Team/TeamsSelect';
import useTeams from '../../hooks/useTeam';
import { SizeType } from 'antd/es/config-provider/SizeContext';




type OnChange = NonNullable<TableProps<ActionPlanActionItemDto>['onChange']>;
type Filters = Parameters<OnChange>[1];
type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

const LazyLoadedActionItemModal = React.lazy(
  () => import('../../modals/ActionItemModal/ActionItemModal')
);




interface TasksTableViewProps {
  tasks: ActionPlanActionItemDto[];
  refetch: () => void;
  meeting?: SharedWorkspaceMeetingDto;
  filteredInfo: Filters;
  sortedInfo: Sorts;
  setFilteredInfo: (filters: Filters) => void;
  setSortedInfo: (sorted: Sorts) => void;
  includeTeams?: boolean;
  includeAssignee?: boolean;
  size?: SizeType;
  onAddClick?: () => void;
  pagination?: boolean;
}


const TableContainer = styled(Table)(() => ({
  "&.ant-table-wrapper": {
    ".ant-table": {
      ".ant-table-container": {
        ".ant-table-content": {
          ".ant-table-tbody": {
            ".ant-table-row": {
              ".ant-table-cell": {
                padding: "5px 10px"
              }
            }
          }
        }
      }
    }

  }
}));




const TasksTable = ({
  tasks,
  refetch,
  meeting,
  filteredInfo,
  setFilteredInfo,
  sortedInfo,
  setSortedInfo,
  includeTeams = true,
  includeAssignee = true,
  size,
  onAddClick,
  pagination
}: TasksTableViewProps) => {
  const [taskList, setTaskList] = useState<ActionPlanActionItemDto[]>(tasks);

  useEffect(() => {
    if (tasks) {
      setTaskList(orderBy(tasks, item => item.index, 'asc'));
    }
  }, [tasks, setTaskList]);

  const { usersList } = useUsers();
  const { teams } = useTeams();
  const { showModal } = useModal();
  const { setCurrentActionItem, setRefetch, setOpen } = useTaskSidebar();
  const { patchActionPlanActionItem, bulkPatchActionPlanActionItems } = useActionPlanActionItem();
  const minDate = Date.parse('01 Jan 1000 00:00:00 GMT');
  const maxDate = Date.parse('01 Jan 4000 00:00:00 GMT');
  const updateDueDate = (id: string, date: Dayjs) => {
    patchActionPlanActionItem(id, { dueDate: toUtcIsoDate(date.toDate()) }).then(() => refetch());
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [currentTask, setCurrentTask] = useState<ActionPlanActionItemDto>(null);

  const addTaskClick = useCallback(
    () => {
      const modal = showModal(LazyLoadedActionItemModal, {
        onCancel: () => {
          modal.hide();
        },
        onConfirm: () => {
          refetch();
          modal.hide();
        }
      });
    },
    [showModal, refetch]
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1
      }
    })
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      const activeIndex = taskList?.findIndex(i => i.id === active.id);
      const overIndex = taskList?.findIndex(i => i.id === over?.id);
      const newList = arrayMove(taskList, activeIndex, overIndex);
      setTaskList(newList);
      const items = newList.map((obj, i) => ({
        id: obj.id,
        index: i
      }));
      bulkPatchActionPlanActionItems(items);

    }
  };

  return (
    <Stack
      width="100%"
    >


      <Stack
      >
        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            items={taskList?.map(i => i.id) || []}
            strategy={verticalListSortingStrategy}
          >
            <TableContainer
              bordered
              size={size ? size : "middle"}
              rowKey={(record: ActionPlanActionItemDto) => record.id}
              pagination={pagination ?
                {
                  responsive: true
                } : false}
              dataSource={taskList}
              components={{
                body: {
                  row: TableRow
                }
              }}
              locale={{
                emptyText:
                  <Stack
                    alignItems="center"
                    gap="5px">
                    <EmptyStateText>No Tasks</EmptyStateText>
                    <AddNew
                      onClick={onAddClick ? onAddClick : addTaskClick} >Add New</AddNew>
                  </Stack>

              }}
              onRow={(record: ActionPlanActionItemDto) => (
                {
                  style: {
                    cursor: 'pointer',
                    opacity: record.isArchived ? 0.5 : 1
                  },
                  onClick: () => {
                    setCurrentActionItem(record);
                    setRefetch(() => refetch);
                    setOpen(true);
                  }
                })
              }
              onChange={(
                pagination,
                filters: Record<string, FilterValue | null>,
                sorter: SorterResult<AnyObject> | SorterResult<AnyObject>[]) => {
                setFilteredInfo(filters);
                setSortedInfo(sorter as Sorts);
              }}
            >

              <Column
                width={size === "small" && "250px"}
                ellipsis={size === "small" ? true : false}
                filterSearch
                title="Task"
                dataIndex="title"
                key="title"
                filters={tasks?.map(task => ({ text: task.title, value: task.title }))}
                onFilter={(value, record) => record.title.includes(value as string)}
                render={(title: string) => <TaskTitle >{title}</TaskTitle>}
                sorter={
                  {
                    compare: (a: ActionPlanActionItemDto, b: ActionPlanActionItemDto) => a.title.localeCompare(b.title),
                    multiple: 1
                  }
                }
                filteredValue={filteredInfo?.title || null}
                sortOrder={sortedInfo.columnKey === 'title' ? sortedInfo.order : null}



              />



              <Column
                align='center'
                width="50px"
                title="Status"
                dataIndex="status"
                key="status"
                render={(status: ActionPlanActionItemDtoStatus) =>
                  <Stack
                    alignItems="center">

                    <StatusTitle
                      color={actionItemStatusMap[status].color}>
                      {actionItemStatusMap[status].label}
                    </StatusTitle>
                  </Stack>

                }
                filters={Object.keys(actionItemStatusMap).map(status => ({ text: actionItemStatusMap[status].label, value: status }))}
                filterOnClose
                onFilter={(value, record) => record.status === value}
                filteredValue={filteredInfo?.status || null}


              />

              <Column
                align='center'
                width={size === "small" ? "60px" : "100px"}
                title="Due Date"
                dataIndex="dueDate"
                key="dueDate"
                render={(dueDate: string, record: ActionPlanActionItemDto) =>

                  <Stack
                    onClick={e => e.stopPropagation()}
                    alignItems="center">
                    <AppDatePicker
                      component={
                        dueDate ? (
                          <DueDate
                            expired={
                              record.status !== ActionPlanActionItemDtoStatus.completed
                                ? new Date(dueDate).setHours(0, 0, 0, 0) <
                                new Date().setHours(0, 0, 0, 0)
                                : false
                            }
                          >
                            {getDate(dueDate)}
                          </DueDate>
                        ) : (
                          <Tooltip title="Set due date">
                            <Stack
                              sx={{
                                svg: { path: { fill: palette.grey[500] } },
                                ':hover': { opacity: 0.8 }
                              }}
                            >
                              <EventOutlinedIcon fontSize="small" />
                            </Stack>
                          </Tooltip>
                        )
                      }
                      value={dueDate ? dayjs(dueDate) : null}
                      onChange={(date: Dayjs) => updateDueDate(record.id, date)}
                    />
                  </Stack>

                }


                sorter={

                  {
                    compare: (a: ActionPlanActionItemDto, b: ActionPlanActionItemDto, sortOrder: SortOrder) => {

                      if (sortOrder === "ascend") {
                        return (a.dueDate ? Date.parse(a.dueDate) : maxDate) - (b.dueDate ? Date.parse(b.dueDate) : maxDate)
                      } else {
                        return (a.dueDate ? Date.parse(a.dueDate) : minDate) - (b.dueDate ? Date.parse(b.dueDate) : minDate)
                      }

                    },
                    multiple: 3
                  }



                }
                sortOrder={sortedInfo.columnKey === 'dueDate' ? sortedInfo.order : null}

              />



              <Column
                width="60px"
                title="Owner"
                dataIndex="ownerId"
                key="ownerId"
                filterSearch
                filters={usersList?.map(user => ({ text: user.firstName + " " + user.lastName, value: user.id }))}
                filterOnClose
                onFilter={(value, record) => record.ownerId === value}
                render={(ownerId: string) =>
                  <Stack
                    alignItems="center"
                  >
                    <UserView
                      size='30px'
                      userId={ownerId}
                      viewOnly
                      nameTooltip
                      onlyAvatar
                    />

                  </Stack>


                }
                filteredValue={filteredInfo?.ownerId || null}
                sorter={{
                  compare: (a: ActionPlanActionItemDto, b: ActionPlanActionItemDto, sortOrder: SortOrder) => {
                    const ownerA = usersList.find(user => user.id === a.ownerId)?.firstName;
                    const ownerB = usersList.find(user => user.id === b.ownerId)?.firstName;

                    if (sortOrder === "ascend") {
                      return (ownerA || "z").localeCompare(ownerB || "z");
                    } else {
                      return (ownerA || "a").localeCompare(ownerB || "a");
                    }
                  },
                  multiple: 4
                }}
                sortOrder={sortedInfo.columnKey === 'ownerId' ? sortedInfo.order : null}
              />

              {includeAssignee && <Column
                width="40px"
                title="Assignee"
                dataIndex="assigneeId"
                key="assigneeId"
                filterSearch
                filters={usersList?.map(user => ({ text: user.firstName + " " + user.lastName, value: user.id }))}
                filterOnClose
                onFilter={(value, record) => record.assigneeId === value}
                render={(assigneeId: string) =>
                  <Stack
                    alignItems="center"
                  >
                    <UserView
                      size='30px'
                      userId={assigneeId}
                      viewOnly
                      nameTooltip
                      onlyAvatar
                    />

                  </Stack>


                }
                filteredValue={filteredInfo?.assigneeId || null}
                sorter={{
                  compare: (a: ActionPlanActionItemDto, b: ActionPlanActionItemDto, sortOrder: SortOrder) => {
                    const assigneeA = usersList.find(user => user.id === a.assigneeId)?.firstName;
                    const assigneeB = usersList.find(user => user.id === b.assigneeId)?.firstName;

                    if (sortOrder === "ascend") {
                      return (assigneeA || "z").localeCompare(assigneeB || "z");
                    } else {
                      return (assigneeA || "a").localeCompare(assigneeB || "a");
                    }
                  },
                  multiple: 4
                }}
                sortOrder={sortedInfo.columnKey === 'assigneeId' ? sortedInfo.order : null}

              />
              }

              {includeTeams &&
                <Column
                  title="Teams"
                  align='center'
                  dataIndex="teamIds"
                  key="teamIds"
                  render={(teamIds: string[]) =>
                    <Stack
                      alignItems="center">
                      <TeamsSelect
                        size='30px'
                        tooltip
                        teamIds={teamIds}
                        viewOnly
                      /></Stack>}

                  filterSearch
                  filters={teams?.map(team => ({ text: team.name, value: team.id }))}
                  filterOnClose
                  onFilter={(value, record) => record.teamIds?.includes(value as string)}
                  filteredValue={filteredInfo?.teamIds || null}
                />}



              <Column
                width="45px"
                title="Actions"
                dataIndex="actions"
                key="actions"
                render={(_, record: ActionPlanActionItemDto) =>
                  <Stack
                    alignItems="center">


                    <IconButton
                      sx={{ padding: 0 }}
                      onClick={event => {
                        event.stopPropagation();
                        setCurrentTask(record);
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </Stack>
                }
              />


            </TableContainer >
          </SortableContext>
        </DndContext>
      </Stack>

      <TaskActions
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        currentTask={currentTask}
        setCurrentTask={setCurrentTask}
        refetch={() => refetch()}
        meeting={meeting}
      />
    </Stack>
  );
};
export default TasksTable;
