import React from 'react';

import { AddIcon, Container, Description } from './InitiativeSuggestionCardStyles';

type InitiativeSuggestionCardProps = {
  title: string;
  onAddSuggestion: () => void;
};

const InitiativeSuggestionCard = ({
  title,
  onAddSuggestion
}: InitiativeSuggestionCardProps) => (
  <Container direction="row" alignItems="flex-start" justifyContent="space-between">
    <Description>{title}</Description>
    <AddIcon onClick={onAddSuggestion} />
  </Container>
);

export default InitiativeSuggestionCard;
