import { alpha, Badge, BadgeProps, Input, Stack, styled, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import palette from "../../../../theme/palette";




export const AddTaskeRow = styled(Input)({
    border: `1px solid #f0f0f0`,
    borderTop: "none",
    padding: '5px 10px',
    borderRadius: "0px",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    input: { fontWeight: 400, fontSize: '14px', color: palette.common.darkBlue },
    textArea: { fontWeight: 400, fontSize: '14px', color: palette.common.darkBlue },
    "& .MuiInput-input": {

        "&::placeholder": {
            fontSize: "14px",
            fontFamily: 'Plus Jakarta Sans'
        }
    }
});



export const TaskViewGroup = styled(ToggleButtonGroup)(() => ({
    height: '25px'
}));

export const TaskViewButton = styled(ToggleButton)(() => ({
    padding: '2px 5px 2px 2px',
    gap: "2px",
    alignItems: "center",
    fontFamily: 'Plus Jakarta Sans',
    fontSize: "12px",
    textTransform: 'none',
    color: alpha(palette.grey[3100], 0.5),
    '&.Mui-selected': {
        backgroundColor: alpha(palette.primary.main, 0.2),
        color: palette.primary.main
    }
}));


export const ArchiveBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
        fontFamily: 'Plus Jakarta Sans',
        color: palette.primary.main,
        border: `1px solid ${palette.primary.main}`,
        backgroundColor: "white",
        height: '20px',
        width: '20px',
        minWidth: 0,
        minHeight: 0,
        fontWeight: "bold",
        fontSize: '11px'
    }
}));

export const ArchiveCompletedButton = styled(Stack)({
    cursor: 'pointer',
    gap: '3px',
    borderRadius: '4px',
    padding: "0px 5px",
    height: "25px",
    svg: { path: { fill: alpha(palette.grey[3100], 0.5) } },
    flexDirection: 'row',
    alignItems: 'center',
    ':hover': { opacity: 0.6 }
});

export const ArchiveCompletedButtonText = styled(Typography)(() => ({
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '12px',
    color: palette.grey[3100]
}));


export const Title = styled(Typography)(() => ({
    fontWeight: 700,
    fontSize: '15px',
    color: palette.common.darkBlue
}));