import IsPropValid from '@emotion/is-prop-valid';
import { Close } from '@mui/icons-material';
import {
  Autocomplete,
  Avatar,
  Dialog,
  Input,
  InputBase,
  Select,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  alpha,
  styled
} from '@mui/material';

import palette from '../../theme/palette';
import { randomBgColor } from '../../utils/avatarHelper';

export const ModalContainer = styled(Dialog)({
  margin: 'auto',
  '.MuiPaper-root': {
    padding: '20px',
    gap: '26px',
    background: 'white',
    border: `1px solid ${palette.grey[1800]}`,
    boxShadow: '0px 10px 20px rgba(28, 39, 76, 0.03)',
    borderRadius: '16px'
  }
});

export const HeaderBlock = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between'
});

export const CloseIcon = styled(Close)({
  cursor: 'pointer',
  ':hover': { transform: 'scale:1.5' }
});

export const ContentBlock = styled(Stack)({
  gap: '10px'
});

export const AutoCompleteBlock = styled(Autocomplete)({
  '& .MuiFormControl-root': {
    padding: '10px 0px 15px 0px'
  }
});

export const DropDownContainer = styled(Stack)({
  paddingTop: '6px',
  width: '40%'
});

export const TextInputSelect = styled(TextField)(({ theme }) => ({
  border: 'none',
  resize: 'none',
  width: '100%',
  height: 80,
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    alignItems: 'baseline',

    p: 1.5,
    border: `1px solid ${palette.border.grey}`,
    backgroundColor: theme.palette.background.paper,
    '& .MuiOutlinedInput-input': {
      p: 0,
      '&::placeholder': { fontFamily: "'Plus Jakarta Sans'", fontSize: '13px' }
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': { borderColor: theme.palette.primary.main }
    }
  }
}));
export const DropDownSelect = styled(Select)(({ theme }) => ({
  height: '40px',
  backgroundColor: theme.palette.background.default,
  borderRadius: '10px',
  border: `1px solid ${palette.border.grey}`,
  '& .MuiSelect-select': {
    backgroundColor: theme.palette.background.default
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));

export const Title = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 800,
  fontSize: '14px',
  color: palette.common.darkBlue
});

export const InputTitle = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '14px',
  color: palette.common.darkBlue,
  display: 'flex',
  flexDirection: 'row'
});

export const RedAsterisk = styled(Typography)({
  color: 'red',
  height: '18px'
});

export const ValueInput = styled(InputBase)({
  padding: '12px 16px',
  height: '40px',
  background: 'white',
  border: `1px solid ${palette.border.grey}`,
  borderRadius: '10px',
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '13px',
  color: palette.common.darkBlue
});

export const FormInput = styled(Input)(({ theme }) => ({
  height: '48px',
  backgroundColor: theme.palette.secondary.main,
  borderRadius: '8px',
  padding: '5px 10px',
  fontSize: theme.typography.body2.fontSize
}));

export const UnitIcon = styled(Stack)(({ theme }) => ({
  borderRadius: '50%',
  padding: '5px',
  backgroundColor: alpha(theme.palette.primary.main, 0.2),
  color: theme.palette.primary.main,
  svg: {
    width: 17,
    height: 17
  }
}));

export const PlanValueInput = styled(InputBase)({
  all: 'unset',
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '13px',
  color: palette.common.darkBlue
});

export const SelectInputContainer = styled(Stack)({
  background: 'white',
  borderRadius: '8px',
  flexDirection: 'row',
  width: '40%',
  '.MuiInputBase-root': {
    padding: 0,
    background: 'white'
  }
});

export const SpaceBeetwenContainer = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'start',
  paddingTop: '20px',
  borderTop: `1px solid ${palette.grey[1800]}`
});

export const KeyResultTypeSelection = styled(Select)({
  '.MuiOutlinedInput-notchedOutline': { border: 0, padding: 0 },
  '& .MuiOutlinedInput-input': {
    marginLeft: '10px'
  }
});

export const CancelText = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontWeight: 500,
  fontSize: '14px',
  color: palette.grey[1100],
  cursor: 'pointer'
});

export const CreateButton = styled(Stack, { shouldForwardProp: IsPropValid })<{
  disabled: boolean;
}>(({ disabled, theme }) => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '12px 24px',
  width: '140px',
  height: '42px',
  color: theme.palette.secondary.main,
  opacity: disabled ? 0.4 : 1,
  background: disabled ? palette.grey[1800] : palette.green[1],
  borderRadius: '8px',
  cursor: disabled ? 'not-allowed' : 'pointer',
  ':hover': {
    opacity: !disabled && 0.8
  }
}));

export const OwnerAvatar = styled(Avatar)<{ name: string }>(({ name }) => ({
  fontSize: '11px',
  borderRadius: '16px',
  height: '18px',
  width: '18px',
  background: randomBgColor(name)
}));

export const InitiativeSelect = styled(Autocomplete)({
  '& .MuiFormControl-root': {
    margin: 0,
    padding: 0,
    height: '40px'
  },
  '& .MuiInputBase-root': {
    padding: 0
  },
  '& .MuiInputBase-input::placeholder': {
    fontSize: '14px'
  },
  '& .MuiTextField-root': {
    padding: 0
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
});



export const VisibilityToggle = styled(ToggleButtonGroup)(() => ({
  backgroundColor: palette.blue[5],
  height: "30px",
  borderRadius: '20px',
  gap: "4px",
  padding: '3px',
  '& .MuiToggleButton-root': {
    border: 'none',
    '&.Mui-selected': {
      backgroundColor: 'white',
      color: palette.common.darkBlue,
      fontWeight: 'bold'
    },
    '&:hover': {
      backgroundColor: 'white'
    },
    "&.MuiToggleButtonGroup-firstButton": {
      borderRadius: '20px'
    },
    "&.MuiToggleButtonGroup-lastButton": {
      borderRadius: '20px'
    }
  }
}));

export const VisibilityToggleButton = styled(ToggleButton)(() => ({
  borderRadius: "30px",
  border: "none",
  alignItems: "center",
  textTransform: 'none',
  color: palette.grey[3400],
  fontFamily: 'Plus Jakarta Sans',
  fontSize: "10px",
  '&.Mui-selected': {
    backgroundColor: 'white',
    color: 'black'
  },
  '&:hover': {
    backgroundColor: 'white'
  }
}));
