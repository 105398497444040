import React from 'react';

import ExpandLessIcon from './ExpandLessIcon/ExpandLessIcon';
import ExpandMoreIcon from './ExpandMoreIcon/ExpandMoreIcon';


type Props = {
  expanded: boolean;
  rotateExpanded?: boolean;
  width?: string;
  height?: string;
};

const ExpandIcon = ({ expanded, rotateExpanded = false, width, height }: Props) =>
  expanded ? <ExpandLessIcon rotateExpanded={rotateExpanded} width={width} height={height} /> : <ExpandMoreIcon width={width} height={height}/>;

export default ExpandIcon;
