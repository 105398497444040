import React, { createContext, useContext, useState } from 'react';

import { SharedWorkspaceMeetingDto } from '../../api/generated';


interface useMeetingNotesSidebarProps {
  open: boolean;
  currentMeeting?: SharedWorkspaceMeetingDto;
  setOpen: (open: boolean) => void;
  setCurrentMeeting?: (currentMeeting: SharedWorkspaceMeetingDto) => void;
  refetch?: () => void;
  setRefetch?: (value: () => void) => void;
  currentSpaceId?: string;
  setCurrentSpaceId?: (currentSpaceId: string) => void;

}

const Context = createContext<useMeetingNotesSidebarProps | null>(null);

const useSpaceMeetingNotesSidebar = () => useContext(Context);

export const SpaceMeetingNotesSidebarProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [currentMeeting, setCurrentMeeting] = useState<SharedWorkspaceMeetingDto>(null);
  const [currentSpaceId, setCurrentSpaceId] = useState<string>('');

  const value = {
    open,
    setOpen,
    currentMeeting,
    setCurrentMeeting,
    currentSpaceId,
    setCurrentSpaceId
  };

  return <Context.Provider value={value}> {children}</Context.Provider>;
};

export default useSpaceMeetingNotesSidebar;
