import { Components } from '@mui/material/styles';

import palette from '../../theme/palette';
import typography from '../typography';

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    xLarge: true;
  }
}

const MuiButton: Components['MuiButton'] = {
  variants: [
    {
      props: { size: 'xLarge' },
      style: {
        width: 236
      }
    }
  ],
  defaultProps: {
    disableRipple: true
  },
  styleOverrides: {
    root: {
      ...typography.body2,
      lineHeight: '17px',
      boxShadow: 'unset',
      borderRadius: 35,
      textTransform: 'none',
      '&.Mui-focusVisible': {
        outline: `1px solid ${palette.primary.main}`
      },
      '&.Mui-disabled': {
        color: palette.grey['700']
      }
    },
    contained: {
      color: palette.secondary.main
    },
    outlined: {
      color: palette.primary.main,
      border: `2px solid ${palette.primary.main}`,
      '&:hover': {
        border: `2px solid ${palette.primary.main}`
      }
    },
    text: {
      '&:hover': {
        backgroundColor: 'transparent',
        textDecoration: 'underline'
      }
    },
    sizeSmall: {
      width: 100
    },
    sizeMedium: {
      width: 136
    },
    sizeLarge: {
      width: 160
    }
  }
};

export default MuiButton;
