import React, { useEffect, useState } from 'react';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Stack } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';

import {
  MetricDefinitionDtoPeriod,
  MetricTargetDefinitionDto
} from '../../../../../../api/generated';
import AppDatePicker from '../../../../../../components/Common/AppDatePicker';
import { CircleProgress } from '../../../../../../components/InitiativeSidebar/InitiativeSidebarStyles';
import AppPopover from '../../../../../ActionPlan/components/AppPopover/AppPopover';
import { RowContainer, Value, ValueInput } from './MetricDataPointRowStyles';
import { toUtcIsoDate } from '../../../../../../utils/dateUtil';

type MetricTargetRowProps = {
  metricDefinitionId: string;
  targetIndex: number;
  timestamp: string;
  target: number;
  period: MetricDefinitionDtoPeriod;
  refetch: () => void;
  onCancelNew: (index: number) => Promise<void>;
  updateTarget: (index: number, target: MetricTargetDefinitionDto) => Promise<void>;
  removeTarget: (timestamp: string, value: number) => Promise<void>;
};

const MetricTargetRow = ({
  timestamp,
  targetIndex,
  target,
  period,
  refetch,
  onCancelNew,
  updateTarget,
  removeTarget
}: MetricTargetRowProps) => {

  const isNew = target === undefined;
  const [editMode, setEditMode] = useState<boolean>(isNew);

  const [hovered, setHovered] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [targetDataPoint, setTargetDataPoint] = useState<MetricTargetDefinitionDto>(
    target !== null
      ? { timestamp: timestamp, value: target }
      : null
  );


  const [isPending, setIsPending] = useState<boolean>(false);

  const onDateChange = (dateValue: Dayjs) => {
    setTargetDataPoint(prev => ({ ...prev, timestamp: toUtcIsoDate(dateValue.toDate()) }));
  }

  useEffect(() => {
    setEditMode(target === undefined);
  }, [target, setEditMode]);


  useEffect(() => {
    if (timestamp) {
      setTargetDataPoint(
        target !== null
          ? { timestamp: timestamp, value: target }
          : null
      );
    }
  }, [timestamp, target, setTargetDataPoint]);


  const onCancelEdit = () => {
    if (isNew) {
      onCancelNew(targetIndex);
    } else {
      setTargetDataPoint(
        target !== null
          ? { timestamp: timestamp, value: target }
          : null
      );
    }

    setEditMode(false);
  };

  const updateTargetValue = async () => {
    if (targetDataPoint?.value !== null && targetDataPoint?.timestamp) {
      if (target !== null) {
        if (targetDataPoint?.value !== target || targetDataPoint?.timestamp !== timestamp) {
          await updateTarget(targetIndex, targetDataPoint).then(() => refetch());
        }
      } else {
        await updateTarget(targetIndex, targetDataPoint).then(() => refetch());
      }
    }
  };

  const onSave = async () => {
    setIsPending(true);

    updateTargetValue().then(() => {
      setEditMode(false);
      setIsPending(false);
    });
  };

  const onDelete = async () => {
    setAnchorEl(null);
    removeTarget(targetDataPoint.timestamp, targetDataPoint.value).then(() => refetch());
  };



  return (
    <RowContainer onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      {isPending ? (
        <CircleProgress />
      ) : (
        <>
          {editMode ? (
            <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center" gap={2} width="180px">
                <AppDatePicker
                  inputFormat={period === MetricDefinitionDtoPeriod.annually ? 'YYYY' : 'MMM YYYY'}
                  height="40px"
                  views={
                    period === MetricDefinitionDtoPeriod.annually ? ['year'] : ['month', 'year']
                  }
                  value={dayjs(targetDataPoint.timestamp)}
                  onChange={onDateChange}
                />
              </Stack>


              <ValueInput
                disableUnderline
                type="number"
                placeholder="Enter target"
                value={targetDataPoint?.value}
                onChange={event =>
                  setTargetDataPoint({
                    timestamp: targetDataPoint.timestamp,
                    value: parseFloat(event.target.value)
                  })
                }
              />

              <Stack direction="row" alignItems="center" gap={1}>

                <IconButton onClick={onSave}>
                  <DoneOutlinedIcon fontSize="small" />
                </IconButton>


                <IconButton onClick={onCancelEdit}>
                  <CloseOutlinedIcon fontSize="small" />
                </IconButton>
              </Stack>
            </Stack>
          ) : (
            <Stack width="70%" direction="row" alignItems="center" justifyContent="space-between">
              <Value>
                {new Date(timestamp).toLocaleString([], {
                  month: 'short',
                  year: 'numeric'
                })}
              </Value>

              <Value>{targetDataPoint?.value}</Value>


            </Stack>
          )}

          {hovered && !editMode && (
            <IconButton onClick={event => setAnchorEl(event.currentTarget)} sx={{ p: 0 }}>
              <MoreHorizIcon fontSize="small" />
            </IconButton>
          )}

          <AppPopover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            handleClose={() => {
              setAnchorEl(null);
              setHovered(false);
            }}
            onDelete={onDelete}
            onEdit={() => {
              setEditMode(true);
              setAnchorEl(null);
              setHovered(false);
            }}
          />
        </>
      )}
    </RowContainer>
  );
};

export default MetricTargetRow;
