import { Typography, styled } from '@mui/material';

import palette from '../../../../../../theme/palette';

export const OwnerTitle = styled(Typography)({
  color: palette.common.darkBlue,
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px',
  fontWeight: 500
});
