import React, { createContext, useContext, useMemo, useState } from 'react';

interface WorkSpaceSideBarContext {
  isWorkSpaceSidebarOpened: boolean;
  setIsWorkSpaceSidebarOpened: (isOpen: boolean) => void;
}

const Context = createContext<WorkSpaceSideBarContext | null>(null);

const useWorkspaceSidebar = () => useContext(Context);

export const WorkspaceSidebarProvider = ({ children }: { children: React.ReactNode }) => {
  const [isWorkSpaceSidebarOpened, setIsWorkSpaceSidebarOpened] = useState(true);

  const value = useMemo(
    () => ({
      isWorkSpaceSidebarOpened,
      setIsWorkSpaceSidebarOpened
    }),
    [isWorkSpaceSidebarOpened]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default useWorkspaceSidebar;
