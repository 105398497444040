import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { TableProps } from 'antd/es/table';

import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TableRowsIconOutlined from '@mui/icons-material/TableRowsOutlined';
import ViewKanbanOutlinedIcon from '@mui/icons-material/ViewKanbanOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Stack } from '@mui/material';
import { ReactComponent as HideInitiativeTasksIcon } from '../../../../assets/hide-initiative-tasks.svg';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import {
  ActionPlanActionItemDto,
  ActionPlanActionItemDtoStatus,
  SharedWorkspaceMeetingActivityDtoAction,
  SharedWorkspaceMeetingActivityDtoEntityType,
  SharedWorkspaceMeetingDto
} from '../../../../api/generated';
import { SortType } from '../../../../components/ActionItemsList/ActionItemsSort/ActionItemsSort';
import AddButton from '../../../../components/Common/AddButton';
import TasksTable from '../../../../components/TasksTable/TasksTable';
import useModal from '../../../../hooks/context-providers/useModal/useModal';
import useActionPlanActionItem from '../../../../hooks/useActionPlanActionItem';
import useAuth from '../../../../hooks/useAuth';
import useSharedWorkspaceMeeting from '../../../../hooks/useSharedWorkspaceMeeting';
import useSharedWorkspaceMeetingActivity from '../../../../hooks/useSharedWorkspaceMeetingActivity';
import AppPopover from '../../../ActionPlan/components/AppPopover/AppPopover';
import TasksBoard from '../../../ActionPlan/components/TasksBoard/TasksBoard';
import { ActionsButton, ClearAllButton, ClearAllButtonText } from '../../SpaceStyles';
import { AddTaskeRow, Title } from './SpaceMeetingTasksStyles';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import sleep from 'sleep-promise';
import SuggestionsButton from '../../../../components/SuggestionsButton/SuggestionsButton';
import InitiativeSuggestionCard from '../../../../components/InitiativeSuggestionCard/InitiativeSuggestionCard';


export enum TasksView {
  TABLE = 'Table',
  KANBAN = 'Kanban'
}

type OnChange = NonNullable<TableProps<ActionPlanActionItemDto>['onChange']>;
type Filters = Parameters<OnChange>[1];
type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

const LazyLoadedActionItemModal = React.lazy(
  () => import('../../../../modals/ActionItemModal/ActionItemModal')
);

const LazyLoadedAreYouSureModal = React.lazy(
  () => import('../../../../modals/AreYouSureModal/AreYouSureModal')
);

type SpaceMeetingTasksProps = {
  initiativeIds?: string[];
  teamIds?: string[];
  sourceSpaceId?: string;
  userIds?: string[];
  meeting?: SharedWorkspaceMeetingDto;
  refetch?: () => void;
  includeTeams?: boolean;
  includeAssignee?: boolean;
  size?: SizeType;
  title?: string;
  pagination?: boolean;
  includeSuggestions?: boolean;
  showTasksTypeFilter?: boolean;
};

const SpaceMeetingTasks = ({
  initiativeIds,
  teamIds,
  userIds,
  sourceSpaceId,
  meeting,
  refetch,
  includeTeams = true,
  includeAssignee = true,
  showTasksTypeFilter = true,
  size,
  title,
  includeSuggestions,
  pagination
}: SpaceMeetingTasksProps) => {
  const { showModal } = useModal();
  const { patchMeeting } = useSharedWorkspaceMeeting();
  const { createActivity } = useSharedWorkspaceMeetingActivity();
  const [includeArchived, setIncludeArchived] = useState<boolean>(false);
  const [hideInitiativeTasks, setHideInitiativeTasks] = useState<boolean>(false);
  const { user } = useAuth();
  const [newTaskTitle, setNewTaskTitle] = useState<string>('');
  const [tasksView, setTasksView] = useState<TasksView>(TasksView.TABLE);
  const [suggestionListOpen, setSuggestionListOpen] = useState(false);
  const [loadSuggestion, setLoadSuggestion] = useState<boolean>(false);

  const {
    actionItemsList: actionItems,
    refetchActionItems,
    actionItemsLoading,
    searchActionItemssLoading,
    bulkPatchActionPlanActionItems,
    createActionPlanActionItem,
    actionItemsSuggestionList,
    getactionItemsSuggestions
  } = useActionPlanActionItem(
    initiativeIds?.length === 1 ? initiativeIds[0] : null,
    null,
    includeArchived,
    userIds || null,
    initiativeIds || null,
    true,
    sourceSpaceId,
    teamIds || null
  );
  const [actionItemList, setActionItemList] = useState<ActionPlanActionItemDto[]>([]);

  const oneSuggestionsClick = useCallback(() => {
    if (!suggestionListOpen) {
      setLoadSuggestion(true);
      sleep(1000);

      getactionItemsSuggestions().then(() => {
        setLoadSuggestion(false);
        setSuggestionListOpen(prev => !prev);
      });
    } else {
      setSuggestionListOpen(prev => !prev);
    }
  }, [getactionItemsSuggestions, suggestionListOpen]);

  useEffect(() => {
    if (actionItems?.length > 0) {
      setActionItemList(
        actionItems?.filter(task => hideInitiativeTasks ? !task.initiativeId : true).sort(
          (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        )
      );
    }
  }, [actionItems, hideInitiativeTasks]);

  const refetchData = useCallback(() => {
    refetchActionItems();
    if (refetch) refetch();
  }, [refetchActionItems, refetch]);

  const [filteredInfo, setFilteredInfo] = useState<Filters>({});
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});

  const clearAll = useCallback(() => {
    setFilteredInfo({});
    setSortedInfo({});
  }, [setFilteredInfo, setSortedInfo]);

  const completedTasks = useMemo(
    () =>
      actionItemList.filter(task => task.status === ActionPlanActionItemDtoStatus.completed) || [],
    [actionItemList]
  );

  const archiveCompletedTasks = useCallback(() => {
    const items = completedTasks
      .filter(task => !task.isArchived)
      .map(task => ({
        id: task.id,
        isArchived: true
      }));

    bulkPatchActionPlanActionItems(items).then(() => refetchData());
  }, [completedTasks, bulkPatchActionPlanActionItems, refetchData]);

  const archiveCompletedTasksClicked = useCallback(() => {
    const modal = showModal(LazyLoadedAreYouSureModal, {
      onClose: () => {
        modal.hide();
      },
      onConfirm: () => {
        archiveCompletedTasks();
        modal.hide();
      },
      modalTitle: `Do you want to archive ${completedTasks.length} completed tasks? `,
      confirmButtonIcon: <ArchiveOutlinedIcon />,
      confirmButtonTitle: 'Archive'
    });
  }, [archiveCompletedTasks, showModal, completedTasks]);

  const filtersOn = Object.keys(filteredInfo)?.length > 0 || Object.keys(sortedInfo)?.length > 0;

  const createTask = () => {
    createActionPlanActionItem({
      index: 0,
      title: newTaskTitle,
      status: ActionPlanActionItemDtoStatus.pending,
      ownerId: user?.id,
      ...(sourceSpaceId && { metadata: { sourceSpaceId: sourceSpaceId } }),
      initiativeId: initiativeIds?.length === 1 ? initiativeIds[0] : null
    }).then(created => {
      if (meeting)
        patchMeeting(meeting.spaceId, meeting.id, {
          data: {
            ...meeting.data,
            actionItemIds: [...(meeting.data.actionItemIds || []), created.id]
          }
        }).then(() => {
          if (refetch) refetch();
          createActivity({
            meetingId: meeting.id,
            entityType: SharedWorkspaceMeetingActivityDtoEntityType.task,
            entityName: created.title,
            entityId: created.id,
            action: SharedWorkspaceMeetingActivityDtoAction.create
          });
        });
      refetchData();
      setNewTaskTitle('');
    });
  };

  const onAddClick = useCallback(() => {
    const modal = showModal(LazyLoadedActionItemModal, {
      onCancel: () => {
        modal.hide();
      },
      onConfirm: (title: string, id: string) => {
        if (meeting)
          patchMeeting(meeting.spaceId, meeting.id, {
            data: { ...meeting.data, actionItemIds: [...(meeting.data.actionItemIds || []), id] }
          }).then(() => {
            if (refetch) refetch();
            createActivity({
              meetingId: meeting.id,
              entityType: SharedWorkspaceMeetingActivityDtoEntityType.task,
              entityName: title,
              entityId: id,
              action: SharedWorkspaceMeetingActivityDtoAction.create
            });
          });
        refetchData();
        modal.hide();
      },
      sourceIitiativeId: initiativeIds?.length === 1 ? initiativeIds[0] : null,
      sourceSpaceId: sourceSpaceId
    });
  }, [patchMeeting, meeting, showModal, refetch, createActivity, refetchData, sourceSpaceId, initiativeIds]);

  const onAddSuggestion = useCallback(
    (suggestedTitle?: string) => {
      const modal = showModal(LazyLoadedActionItemModal, {
        onClose: () => {
          modal.hide();
        },
        onCancel: () => {
          modal.hide();
        },
        onConfirm: async () => {
          modal.hide();
          refetchData();
          setSuggestionListOpen(false);
          if (refetch) refetch();
        },
        suggestedTitle: suggestedTitle,
        sourceIitiativeId: initiativeIds?.length === 1 ? initiativeIds[0] : null,
        hideActionPlan: initiativeIds?.length === 1
      });
    },
    [showModal, refetch, initiativeIds, refetchData]
  );


  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const actions = [
    {
      title: `Switch to ${tasksView === TasksView.TABLE ? 'Board' : 'Table'} view`,
      icon:
        tasksView === TasksView.TABLE ? (
          <ViewKanbanOutlinedIcon sx={{ width: '22px', height: '20px' }} />
        ) : (
          <TableRowsIconOutlined sx={{ width: '22px', height: '20px' }} />
        ),
      onAction: () => {
        setAnchorEl(null);
        if (tasksView === TasksView.TABLE) setTasksView(TasksView.KANBAN);
        else setTasksView(TasksView.TABLE);
      }
    },
    {
      title: includeArchived ? 'Hide Archived' : 'Show Archived',
      icon: includeArchived ? (
        <VisibilityOffOutlinedIcon fontSize="small" />
      ) : (
        <VisibilityOutlinedIcon fontSize="small" />
      ),
      onAction: () => {
        setAnchorEl(null);
        setIncludeArchived(!includeArchived);
      }
    }
  ];

  if (showTasksTypeFilter) {
    actions.push({
      title: `${hideInitiativeTasks ? 'Show' : 'Hide'} Initiative Tasks`,
      icon: hideInitiativeTasks ? (
        <AccountTreeOutlinedIcon fontSize="small" />
      ) : (
        <HideInitiativeTasksIcon fontSize="small" />
      ),
      onAction: () => {
        setAnchorEl(null);
        setHideInitiativeTasks(!hideInitiativeTasks);
      }
    });
  }

  if (completedTasks?.length > 0) {
    actions.push({
      title: `Archive completed`,
      icon: <ArchiveOutlinedIcon fontSize="small" />,
      onAction: () => {
        setAnchorEl(null);
        archiveCompletedTasksClicked();
      }
    });
  }

  return (
    <Stack padding="0px 15px 15px 15px">
      <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between">
        <Stack
          direction="row"
          alignItems="center"
          gap={2}>
          {title && <Title>{title}</Title>}
          <Stack>

            {filtersOn &&
              <ClearAllButton
                onClick={clearAll}>
                <HighlightOffIcon fontSize='small' />
                <ClearAllButtonText>Clear all filters</ClearAllButtonText>
              </ClearAllButton>}
          </Stack>

        </Stack>

        <Stack direction="row" alignItems="center" gap="4px">
          <AddButton onClick={onAddClick} />

          {includeSuggestions &&
            <SuggestionsButton
              opened={suggestionListOpen}
              isLoading={loadSuggestion}
              onClick={oneSuggestionsClick}
            />}

          <ActionsButton
            onClick={event => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreHorizIcon />
          </ActionsButton>

          <AppPopover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            handleClose={() => setAnchorEl(null)}
            customActions={actions}
          />
        </Stack>
      </Stack>

      <Stack marginTop={1}>

        <Stack gap={1} marginBottom={1}>
          {suggestionListOpen && actionItemsSuggestionList?.map((suggestion, index) => (
            <InitiativeSuggestionCard
              key={index}
              title={suggestion}
              onAddSuggestion={() => onAddSuggestion(suggestion)}
            />
          ))}
        </Stack>


        {tasksView === TasksView.TABLE ? (
          <>
            <TasksTable
              tasks={actionItemList}
              refetch={refetchData}
              filteredInfo={filteredInfo}
              setFilteredInfo={setFilteredInfo}
              sortedInfo={sortedInfo}
              setSortedInfo={setSortedInfo}
              includeTeams={includeTeams}
              includeAssignee={includeAssignee}
              onAddClick={onAddClick}
              size={size}
              pagination={pagination}
            />

            {actionItemList?.length > 0 &&
              <AddTaskeRow
                disableUnderline
                placeholder="Type and enter to add new Task"
                value={newTaskTitle}
                onChange={event => setNewTaskTitle(event.currentTarget.value)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    if (newTaskTitle !== '') {
                      createTask();
                    } else {
                      event.stopPropagation();
                    }
                  } else {
                    event.stopPropagation();
                  }
                }}
              />
            }
          </>
        ) : (
          <Stack padding="0px 20px">
            <TasksBoard
              actionItemsList={actionItemList}
              actionItemsLoading={searchActionItemssLoading || actionItemsLoading}
              sortType={SortType.TIME}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default SpaceMeetingTasks;
