import React from 'react';
import { Radio } from 'react-loader-spinner';
import { useLocation } from 'react-router-dom';

import { Box, Stack } from '@mui/material';

import useMeetingNotesDrawer from '../../../../hooks/context-providers/useMeetingNotesDrawer';
import useMeetings from '../../../../hooks/useMeetings';
import { RoutesMap } from '../../../../services/linker';
import palette from '../../../../theme/palette';
import {
  Container,
  InProgressText,
  MeetingNotesText,
  MeetingStatusButton
} from './LiveMeetingStatusStyles';

const LiveMeetingStatus = () => {
  const location = useLocation();
  const { setIsOpen: setIsMeetingNotesDrawerOpen } = useMeetingNotesDrawer();
  const { liveMeeting, refetchLiveMeetingDetails } = useMeetings();

  const openMeetingNotes = () => {
    refetchLiveMeetingDetails().then(() => {
      setIsMeetingNotesDrawerOpen(true);
    });
  };

  const shouldShowNewMeeting = location.pathname !== RoutesMap.CHOOSE_ACCOUNT;

  return (
    <Box sx={{ width: 'auto' }}>
      {shouldShowNewMeeting && liveMeeting && (
        <Container color="white" padding="10px" alignItems="center" direction="row">
          {liveMeeting && (
            <Stack direction="row" alignItems="center">
              <Radio
                height={30}
                width={30}
                colors={[palette.green[1], palette.green[1], palette.green[1]]}
                visible={true}
              />
              <InProgressText>Meeting in progress</InProgressText>
            </Stack>
          )}

          <Stack direction="row">
            <MeetingStatusButton
              onClick={openMeetingNotes}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <MeetingNotesText>Meeting notes</MeetingNotesText>
            </MeetingStatusButton>
          </Stack>
        </Container>
      )}
    </Box>
  );
};

export default LiveMeetingStatus;
