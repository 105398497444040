import { useCallback } from 'react';

import {
  useDeleteFile,
  useGenerateUploadUrl,
  useGenerateDownloadUrl
} from '../api/generated';

const useFiles = () => {

  const { mutateAsync: mutateGenerateDownloadUrl } = useGenerateDownloadUrl();
  const { mutateAsync: mutateGenerateUploadUrl } = useGenerateUploadUrl();
  const { mutateAsync: mutateDeleteFile } = useDeleteFile();

  const generateDownloadUrl = useCallback(
    async (key: string, name: string) => {
      const response = await mutateGenerateDownloadUrl({ name: name, params: { key: key } });
      return response?.items[0]?.fileUrl;
    },
    [mutateGenerateDownloadUrl]
  );


  const generateUploadUrl = useCallback(
    async (key: string, name: string) => {
      const response = await mutateGenerateUploadUrl({ name: name, params: { key: key } });
      return response?.items[0]?.uploadUrl;
    },
    [mutateGenerateUploadUrl]
  );


  const deleteFile = async (key: string, name: string) => await mutateDeleteFile({ name: name, params: { key: key } });



  return {
    generateDownloadUrl,
    generateUploadUrl,
    deleteFile
  };
};

export default useFiles;
