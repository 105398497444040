import { Autocomplete, Input, Select, Stack, TextField, Typography, styled } from '@mui/material';
import palette from '../../../../../../theme/palette';


export const RowContainer = styled(Stack)(() => ({
  padding: "30px 2px",
  height: "50px",
  alignItems: 'center',
  justifyContent: "space-between",
  flexDirection: 'row',
  borderTop: `1px solid ${palette.border.grey}`
}));


export const Value = styled(Typography)({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '15px',
  color: palette.common.darkBlue,
  textAlign: "left"
});

export const ValueInput = styled(Input)({
  height: '40px',
  width: '140px',
  pl: '50px',
  paddingTop: 0,
  textAlign: 'center',
  paddingBottom: 0,
  paddingRight: 10,
  justifyContent: 'space-around',
  alignItems: 'center',
  border: `1px solid ${palette.border.grey}`,
  borderRadius: '8px',
  fontSize: '15px'
});

export const PeriodDropDown = styled(Select)(() => ({
  height: '40px',
  width: "80px",

  backgroundColor: palette.background.default,
  borderRadius: '8px',
  border: `1px solid ${palette.border.grey}`,
  '& .MuiSelect-select': {
    backgroundColor: palette.background.default
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: "none"
  }
}));

export const PeriodOption = styled(Typography)({
  fontFamily: "'Plus Jakarta Sans'",
  fontSize: '14px',
  color: palette.common.darkBlue
});


export const YearSelect = styled(Stack)({
  fontFamily: 'Plus Jakarta Sans',
  alignItems: "center",
  justifyContent: "center",
  color: palette.grey[1100],
  fontSize: '16px',
  fontWeight: 500,
  height: "40px",
  border: `1px solid ${palette.border.grey}`,
  borderRadius: "6px",
  padding: 8,
  cursor: "pointer"
});





export const TextInputSelect = styled(TextField)(() => ({
  border: 'none',
  resize: 'none',
  '& .MuiOutlinedInput-notchedOutline': {
    border: "none"
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    padding: 0,
    border: `1px solid ${palette.border.grey}`,
    backgroundColor: palette.background.paper,

    '& .MuiOutlinedInput-input': {
      padding: 2,
      paddingLeft: 15,
      fontFamily: "'Plus Jakarta Sans'",
      '&::placeholder': { color: palette.grey[600], opacity: 1 }
    },
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        border: "none"
      }
    }
  }
}));


export const PeriodAutoComplete = styled(Autocomplete)<{ height?: string }>(
  ({ height }) => ({


    '& .MuiFormControl-root': {
      margin: 0

    },
    '& .MuiTextField-root': {
      padding: 0
    },
    '& .MuiInputBase-root': {
      padding: 0,
      display: 'flex',
      alignItems: 'center'
    },
    '& .MuiAutocomplete-inputRoot': {
      height: height && height,
      fontFamily: "Plus Jakarta Sans"
    },
    '& .MuiInputBase-input::placeholder': {
      fontSize: "14px",
      fontFamily: "Plus Jakarta Sans"
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: "none"
    }
  })
);

