import React, { createContext, useContext, useState } from 'react';

import { ActionPlanActionItemDto } from '../../api/generated';



interface useTaskSidebarProps {
  open: boolean;
  currentActionItem: ActionPlanActionItemDto;
  setOpen: (open: boolean) => void;
  setCurrentActionItem: (task: ActionPlanActionItemDto) => void;
  currentObjectiveId?: string;
  setCurrentObjectiveId?: (value: string) => void;
  refetch?: () => void;
  setRefetch?: (value: () => void) => void;

}

const Context = createContext<useTaskSidebarProps | null>(null);

const useTaskSidebar = () => useContext(Context);

export const TaskSidebarProvider = ({ children }: { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [currentActionItem, setCurrentActionItem] = useState(null);
  const [currentObjectiveId, setCurrentObjectiveId] = useState<string>('');
  const [refetch, setRefetch] = useState<() => void>();


  const value = {
    open,
    setOpen,
    currentActionItem,
    setCurrentActionItem,
    currentObjectiveId,
    setCurrentObjectiveId,
    refetch,
    setRefetch
  };

  return <Context.Provider value={value}> {children}</Context.Provider>;
};

export default useTaskSidebar;
