import React, { forwardRef } from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

import isValidProp from '@emotion/is-prop-valid';
import { InputBase, InputBaseProps, TextField, styled } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';

import palette from '../../theme/palette';

type OmittedTextFieldProps = Omit<TextFieldProps, 'defaultValue' | 'name'>;
type OmittedControllerProps<TFormValues> = Omit<ControllerProps<TFormValues>, 'render'>;
type CustomProps = { baseInput?: boolean; showBorder?: boolean };
export type InputProps<TFormValues> = OmittedTextFieldProps &
  OmittedControllerProps<TFormValues> &
  InputBaseProps &
  CustomProps;

const CustomTextField = styled(TextField, { shouldForwardProp: isValidProp })<{
  showBorder?: boolean;
}>(props => ({
  border: 0,
  '& .MuiFormLabel-root': {
    fontFamily: "'Plus Jakarta Sans'",
    fontWeight: 600,
    fontSize: '14px'
  },
  '& .MuiInputBase-formControl': {
    fontFamily: "'Plus Jakarta Sans'",
    fontWeight: 600,
    fontSize: '14px',
    background: 'white',
    border: props.showBorder && `1px solid ${palette.border.grey}`,
    borderRadius: '8px',
    padding: '5px'
  },
  '& .MuiInputBase::placeholder': {
    fontFamily: "'Plus Jakarta Sans'",
    fontWeight: 400,
    fontSize: '14px',
    color: palette.grey[1100]
  }
}));

const CustomInput = styled(InputBase, { shouldForwardProp: isValidProp })({
  border: 0
});

const RenderInput = forwardRef(function RenderTextField(
  { showBorder, ...props }: { showBorder: boolean },
  ref
) {
  return <CustomTextField inputRef={ref} showBorder={showBorder} fullWidth {...props} />;
});

const Input = <TFormValues extends Record<string, unknown>>({
  control,
  name,
  rules,
  defaultValue,
  baseInput,
  showBorder = true,
  ...nextProps
}: InputProps<TFormValues>) => {
  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error } }) => (
          <RenderInput
            showBorder={showBorder}
            {...field}
            {...nextProps}
            helperText={error?.message}
          />
        )}
        rules={rules}
      />
    );
  }
  if (baseInput) {
    return <CustomInput fullWidth defaultValue={defaultValue} {...nextProps} />;
  }
  return <RenderInput showBorder={showBorder} {...nextProps} />;
};

export default Input;
