import React from 'react';
import { MetricTabs } from '../../MetricViewSidebar';
import {
  TabContainer,
  TabLabel,
  TabLabelContainer,
  TabTitle,
  Tabs,
  TabsContainer
} from '../../MetricViewSidebarStyles';

const SidebarTabs = ({
  currentTab,
  setCurrentTab
}: {
  currentTab: MetricTabs;
  setCurrentTab: (selectedTab: MetricTabs) => void;
}) => (
  <TabsContainer>
    <Tabs value={currentTab} onChange={(_, selectedTab) => setCurrentTab(selectedTab)}>
      <TabContainer
        value={MetricTabs.METADATA}
        label={
          <TabLabel>
            <TabLabelContainer>
              <TabTitle>Chart</TabTitle>
            </TabLabelContainer>
          </TabLabel>
        }
      />
      <TabContainer
        value={MetricTabs.DATAPOINTS}
        label={
          <TabLabel>
            <TabLabelContainer>
              <TabTitle>Actuals</TabTitle>
            </TabLabelContainer>
          </TabLabel>
        }
      />
      <TabContainer
        value={MetricTabs.TARGETS}
        label={
          <TabLabel>
            <TabLabelContainer>
              <TabTitle>Targets</TabTitle>
            </TabLabelContainer>
          </TabLabel>
        }
      />
    </Tabs>
  </TabsContainer>
);

export default SidebarTabs;
