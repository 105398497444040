import { useCallback } from 'react';
import {
  CreateGoogleDriveFileRequestType,
  ResourceDto,
  UserFeatureFeature,
  useBulkPatchResources,
  useCreateGoogleDriveFile,
  useCreateResource,
  useDeleteResource,
  useListGoogleDriveFiles,
  useListResources,
  useUploadGoogleDriveFile
} from '../api/generated';
import useUserFeature from './useUserFeature';


export const useResource = (folderName?: string) => {
  const { data, refetch, isLoading: isResourceListLoading } = useListResources();

  const { isFeatureBlocked } = useUserFeature();

  const isADriveEnabled = !isFeatureBlocked(UserFeatureFeature.drive);

  const {
    data: driveFileResponse,
    isLoading: driveFilesLoading,
    refetch: refetchDriveFiles
  } = useListGoogleDriveFiles({ folderName: folderName ? folderName : null }, {
    query: {
      queryKey: ['driveFile'],
      enabled: isADriveEnabled
    }
  });

  const { mutateAsync: mutateCreateResourceFile, isPending: isCreateResourceFileLoading } =
    useCreateResource();
  const { mutateAsync: mutateBulkPatchResources } =
    useBulkPatchResources();
  const { mutateAsync: mutateDeleteResourceFile } = useDeleteResource();

  const { mutateAsync: mutateCreateDriveFile, isPending: isCreateFileLoading } =
    useCreateGoogleDriveFile();
  const { mutateAsync: mutateUploadDriveFile, isPending: isUploadFileLoading } =
    useUploadGoogleDriveFile();

  const isLoading: boolean = isResourceListLoading || isCreateResourceFileLoading;

  const createResourceFile = async (data: Omit<ResourceDto, 'id'>) => {
    const response = await mutateCreateResourceFile({ data });
    refetch();
    return response?.items?.[0]?.id || '';
  };

  const bulkPatchResources = useCallback(
    async (data: Partial<ResourceDto>[]) => {
      await mutateBulkPatchResources({ data: { items: data } });
    },
    [mutateBulkPatchResources]
  );

  const createGoogleDriveFile = async (type: CreateGoogleDriveFileRequestType, subFolderName?: string) => {
    const response = await mutateCreateDriveFile({ data: { type: type, subFolderName: subFolderName ? subFolderName : null } });
    refetch();
    return response?.items?.[0] || null;
  };

  const uploadGoogleDriveFile = async (file: Blob) => {
    await mutateUploadDriveFile({ data: { file: file } });
    refetch();
  };

  const deleteResource = async (id: string) => {
    await mutateDeleteResourceFile({ id });
    refetch();
  };

  const resourceFilesList = data?.items || [];

  const driveFiles = driveFileResponse?.items || [];

  return {
    resourceFilesList,
    driveFiles,
    refetchDriveFiles,
    driveFilesLoading,
    createResourceFile,
    deleteResource,
    isLoading,
    refetch,
    createGoogleDriveFile,
    isCreateFileLoading,
    uploadGoogleDriveFile,
    isUploadFileLoading,
    bulkPatchResources
  };
};

export default useResource;
