import { Close } from '@mui/icons-material';
import { Badge, BadgeProps, Dialog, Stack, Typography, alpha, styled } from '@mui/material';

import FreeTextViewer from '../../screens/BizAreaModules/components/FreeTextViewer';
import palette from '../../theme/palette';

export const ModalContainer = styled(Dialog)({
  '.MuiPaper-root': {
    margin: 0,
    padding: '5px 0px',
    width: '700px',
    gap: '5px',
    background: 'white',
    border: `2px solid ${palette.border.grey}`,
    boxShadow: '0px 10px 20px rgba(28, 39, 76, 0.03)',
    borderRadius: '10px'
  }
});

export const CloseButton = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'end',
  padding: '12px 20px',
  height: '35px',
  margin: 7,
  color: palette.secondary.main,
  background: palette.green[1],
  borderRadius: '8px',
  cursor: 'pointer',
  ':hover': {
    opacity: 0.8
  }
}));

export const Container = styled(Stack)(() => ({
  width: '500px',
  border: `2px solid ${palette.border.grey}`,
  borderRadius: '8px'
}));

export const MarkAsRead = styled(Stack)(() => ({
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'end',
  padding: '12px 5px',
  height: '30px',
  fontSize: '13px',
  color: palette.primary.main,
  backgroundColor: alpha(palette.primary.main, 0.1),
  borderRadius: '8px',
  cursor: 'pointer',
  ':hover': {
    opacity: 0.8
  }
}));

export const LinkDescription = styled(Stack)(() => ({
  color: 'white',
  backgroundColor: palette.primary.main,
  borderRadius: '4px',
  fontWeight: 'bolder',
  fontSize: '11px',
  fontFamily: 'Plus Jakarta Sans',
  padding: '4px 10px',
  ':hover': {
    opacity: 0.8
  }
}));

export const TitleContainer = styled(Stack)(() => ({
  padding: '10px 10px',
  width: '100%',
  justifyContent: 'space-between',
  flexDirection: 'row',
  alignItems: 'center',
  borderBottom: `1px solid ${palette.border.grey}`
}));

export const TitleText = styled(Typography)(() => ({
  paddingLeft: 2,
  fontWeight: 700,
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '15px',
  color: palette.primary.main
}));

export const NotificationList = styled(Stack)(() => ({
  gap: 1
}));

export const ViewAll = styled(Stack)(() => ({
  flexDirection: 'row',
  padding: 10,
  alignItems: 'center',
  justifyContent: 'center',
  gap: 2,
  cursor: 'pointer',
  ':hover': { opacity: 0.8 }
}));

export const ViewAllText = styled(Typography)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '13px',
  color: palette.grey[800]
}));

export const NotificationContainer = styled(Stack)<{ read?: boolean }>(({ read }) => ({
  padding: '10px 10px',
  cursor: 'pointer',
  backgroundColor: !read && palette.background.rowGrey,
  borderBottom: `1px solid ${palette.border.grey}`
}));

export const DaysAgoTitle = styled(Typography)(() => ({
  color: palette.grey[1100],
  fontWeight: 400,
  fontSize: '12px'
}));

export const NotificationTitle = styled(Typography)(() => ({
  fontWeight: 700,
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '15px',
  color: palette.common.darkBlue
}));

export const NotificationDescription = styled(FreeTextViewer)(() => ({
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '13px',
  color: palette.grey[1200],
  '& .ql-editor p': {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
}));

export const EmptyState = styled(Typography)(() => ({
  padding: '10px',
  alignSelf: 'center',
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '14px',
  color: palette.grey[3100]
}));

export const EmptyStateTitle = styled(Typography)(() => ({
  alignSelf: 'center',
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '20px',
  color: palette.common.darkBlue
}));

export const EmptyStateDescription = styled(Typography)(() => ({
  alignSelf: 'center',
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '15px',
  color: palette.grey[3100]
}));

export const NotificationBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    fontFamily: 'Plus Jakarta Sans',
    backgroundColor: palette.primary.main,
    right: 5,
    top: 5,
    height: '18px',
    width: '18px',
    minWidth: 0,
    minHeight: 0,
    padding: 5,
    fontWeight: 700,
    fontSize: '12px'
  }
}));

export const CloseIcon = styled(Close)(({ theme }) => ({
  cursor: 'pointer',
  zIndex: 2,
  padding: 1,
  ':hover': { backgroundColor: theme.palette.background.grey },
  borderRadius: '6px'
}));
