import React, { useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';

import {
  ActionPlanInitiativeDtoPriority,
  PriorityDetailsExpectedValue,
  PriorityDetailsResources
} from '../../api/generated';
import palette from '../../theme/palette';
import {
  BigPrioritySelectionOptionContainer,
  CheckCircle,
  Container,
  DoneButton,
  OptionsTitle,
  SelectionTitle,
  Title,
  TitleDescription
} from './PrioritySelectorToolStyles';

type PrioritySelectorToolProps = {
  onCloseTool: () => void;
  onToolSubmitted: (priority: ActionPlanInitiativeDtoPriority) => void;
};

const PrioritiesScoreMapping = {
  [PriorityDetailsResources.L]: 1,
  [PriorityDetailsResources.M]: 2,
  [PriorityDetailsResources.H]: 3,
  [PriorityDetailsExpectedValue.L]: 1,
  [PriorityDetailsExpectedValue.M]: 2,
  [PriorityDetailsExpectedValue.H]: 3,
  3: ActionPlanInitiativeDtoPriority.H,
  2: ActionPlanInitiativeDtoPriority.M,
  1: ActionPlanInitiativeDtoPriority.L,
  0: ActionPlanInitiativeDtoPriority.L
};

const PrioritySelectorTool = ({ onCloseTool, onToolSubmitted }: PrioritySelectorToolProps) => {
  const [resourcePriority, setResourcePriority] = useState<PriorityDetailsResources>(
    PriorityDetailsResources.L
  );
  const [expectedValue, setExpectedValue] = useState<PriorityDetailsExpectedValue>(
    PriorityDetailsExpectedValue.L
  );

  const computedPriority = useMemo(() => {
    const resourcePriorityScore = PrioritiesScoreMapping[resourcePriority];
    const expectedValueScore = PrioritiesScoreMapping[expectedValue];
    // if resource is not high, we dont consider it priority changing
    if (resourcePriorityScore < 3) {
      return PrioritiesScoreMapping[expectedValueScore];
    } else {
      // if resource is high, we downgrade the overall priority by 1 (if 0 its still low, considered in the mapping above)
      return PrioritiesScoreMapping[expectedValueScore - 1];
    }
  }, [expectedValue, resourcePriority]);

  return (
    <Container>
      <Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Title>Prioritization tool</Title>
          <CloseIcon
            onClick={onCloseTool}
            sx={{
              color: 'white',
              fontSize: '15px',
              cursor: 'pointer',
              ':hover': {
                transform: 'scale(1.06)'
              }
            }}
          />
        </Stack>
        <TitleDescription>
          fill out to determine the intiative&apos;s priority level.
        </TitleDescription>
      </Stack>
      <Stack>
        <OptionsTitle>Expected Resources invested</OptionsTitle>
        <Stack direction="row" justifyContent="space-between" marginTop="4px">
          <BigPrioritySelectionOptionContainer
            onClick={() => setResourcePriority(PriorityDetailsResources.H)}
            backgroundColor={palette.red[3]}
          >
            <CheckCircle
              checked={resourcePriority === PriorityDetailsResources.H}
              checkedColor={palette.red[4]}
            />
            <SelectionTitle>High</SelectionTitle>
          </BigPrioritySelectionOptionContainer>
          <BigPrioritySelectionOptionContainer
            onClick={() => setResourcePriority(PriorityDetailsResources.M)}
            backgroundColor={palette.yellow[2]}
          >
            <CheckCircle
              checked={resourcePriority === PriorityDetailsResources.M}
              checkedColor={palette.yellow[3]}
            />
            <SelectionTitle>Medium</SelectionTitle>
          </BigPrioritySelectionOptionContainer>
          <BigPrioritySelectionOptionContainer
            onClick={() => setResourcePriority(PriorityDetailsResources.L)}
            backgroundColor={palette.green[6]}
          >
            <CheckCircle
              checked={resourcePriority === PriorityDetailsResources.L}
              checkedColor={palette.green[7]}
            />
            <SelectionTitle>Low</SelectionTitle>
          </BigPrioritySelectionOptionContainer>
        </Stack>
      </Stack>
      <Stack>
        <OptionsTitle>Expected Value</OptionsTitle>
        <Stack direction="row" justifyContent="space-between" marginTop="4px">
          <BigPrioritySelectionOptionContainer
            onClick={() => setExpectedValue(PriorityDetailsExpectedValue.H)}
            backgroundColor={palette.red[3]}
          >
            <CheckCircle
              checked={expectedValue === PriorityDetailsExpectedValue.H}
              checkedColor={palette.red[4]}
            />
            <SelectionTitle>High</SelectionTitle>
          </BigPrioritySelectionOptionContainer>
          <BigPrioritySelectionOptionContainer
            onClick={() => setExpectedValue(PriorityDetailsExpectedValue.M)}
            backgroundColor={palette.yellow[2]}
          >
            <CheckCircle
              checked={expectedValue === PriorityDetailsExpectedValue.M}
              checkedColor={palette.yellow[3]}
            />
            <SelectionTitle>Medium</SelectionTitle>
          </BigPrioritySelectionOptionContainer>
          <BigPrioritySelectionOptionContainer
            onClick={() => setExpectedValue(PriorityDetailsExpectedValue.L)}
            backgroundColor={palette.green[6]}
          >
            <CheckCircle
              checked={expectedValue === PriorityDetailsExpectedValue.L}
              checkedColor={palette.green[7]}
            />
            <SelectionTitle>Low</SelectionTitle>
          </BigPrioritySelectionOptionContainer>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="end">
        <DoneButton onClick={() => onToolSubmitted(computedPriority)}>Done</DoneButton>
      </Stack>
    </Container>
  );
};

export default PrioritySelectorTool;
