
import { AddBox } from "@mui/icons-material";
import palette from "../../theme/palette";
import React from "react";

interface AddButtonProps {
  width?: string;
  height?: string;
  onClick: () => void;
}

const AddButton = ({ width, height, onClick }: AddButtonProps) => (
  <AddBox
    onClick={onClick}
    sx={{
      width: width ? width : 30,
      height: height ? height : 30,
      color: palette.primary.main,
      cursor: 'pointer',
      ':hover': { opacity: 0.8 }
    }}
  />
)


export default AddButton;