import React from 'react';

import { AccordionSummary, Checkbox, Stack } from '@mui/material';

import { AccountUserDto } from '../../../../../api/generated';
import ExpandIcon from '../../../../../components/ExpandIcon/ExpandIcon';
import { FormCheckbox } from '../../../../BusinessArea/components/BusinessArea/BusinessAreaStyles';
import {
  ActionTitle,
  ControlItem,
  SelectedActionTitle
} from '../../FilterAndSort/FilterAndSortRowStyles';
import {
  ClearAllButton,
  ScrollableAccordion,
  SelectAllButton
} from '../FilterStyles';
import SelectOwnerOption from './SelectOwnerOption/SelectOwnerOption';
import AppCheckbox from '../../../../../components/Checkbox/AppCheckbox';

type Props = {
  owners: AccountUserDto[];
  onClearAll: () => void;
  onSelectAll: () => void;
  onChangeOwner: (ownerId: string) => void;
  checkedFunc: (ownerId: string) => boolean;
  expanded: boolean;
  onClickSummary?: () => void;
  appliedCount?: number;
  title?: string;
  showOptions?: boolean;
};

const SelectOwner = ({
  onClearAll,
  onSelectAll,
  owners,
  checkedFunc,
  onChangeOwner,
  expanded,
  onClickSummary,
  appliedCount,
  showOptions = true,
  title
}: Props) =>

  showOptions ?


    <ScrollableAccordion expanded={expanded} disableGutters>
      < AccordionSummary onClick={onClickSummary} expandIcon={< ExpandIcon expanded={expanded} />}>
        <ActionTitle>{title ? title : "Owner"}</ActionTitle>
        {
          appliedCount !== undefined && (
            <SelectedActionTitle>{`(${appliedCount})`}</SelectedActionTitle>
          )
        }
      </AccordionSummary >
      <ControlItem direction="row" justifyContent="space-between">
        <ClearAllButton onClick={onClearAll}>Clear all</ClearAllButton>
        <SelectAllButton onClick={onSelectAll}>Select all</SelectAllButton>
      </ControlItem>
      {
        owners?.map((owner, id) => (
          <ControlItem disableTopBorder={id === 0} disableBottomBorder key={owner.id}>
            <FormCheckbox
              label={<SelectOwnerOption owner={owner} />}
              control={
                <Stack marginRight={1}>
                  <AppCheckbox width="18px" height="18px" checked={checkedFunc(owner.id)} checkboxOnClick={() => onChangeOwner(owner.id)} /></Stack>
              }
            />
          </ControlItem>
        ))
      }
    </ScrollableAccordion>
    : <Stack>{owners?.map((owner, id) => (
      <ControlItem disableTopBorder={id === 0} disableBottomBorder key={owner.id}>
        <FormCheckbox
          label={<SelectOwnerOption owner={owner} />}
          control={
            <Stack marginRight={1}>
              <Checkbox size="small" checked={checkedFunc(owner.id)} onClick={() => onChangeOwner(owner.id)} /></Stack>
          }
        />
      </ControlItem>
    ))}</Stack>;

export default SelectOwner;
