import React, { SetStateAction, createContext, useContext } from 'react';

import useStorage, { STORAGE_TYPE } from '../useStorage';

type AcademyModeStateContext = {
  enabled: boolean;
  setEnabled: React.Dispatch<SetStateAction<boolean>>;
};

export const AcademyModeContext = createContext<AcademyModeStateContext | null>(null);

const useAcademyMode = () => useContext(AcademyModeContext);
export const AcademyModeProvider = ({ children }: { children: React.ReactNode }) => {
  const [enabled, setEnabled] = useStorage<boolean>(STORAGE_TYPE.LOCAL, 'academyModeEnabled', true);

  const value = { enabled, setEnabled };

  return <AcademyModeContext.Provider value={value}>{children}</AcademyModeContext.Provider>;
};

export default useAcademyMode;
