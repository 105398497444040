import React, { SetStateAction, createContext, useContext, useState } from 'react';

type MeetingNotesDrawerStateContext = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<SetStateAction<boolean>>;
};
export const MeetingNotesDrawerContext = createContext<MeetingNotesDrawerStateContext | null>(null);

const useMeetingNotesDrawer = () => useContext(MeetingNotesDrawerContext);
export const MeetingNotesDrawerProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const value = { isOpen, setIsOpen };

  return (
    <MeetingNotesDrawerContext.Provider value={value}>
      {children}
    </MeetingNotesDrawerContext.Provider>
  );
};

export default useMeetingNotesDrawer;
