import { Components } from '@mui/material/styles';
import typography from '../typography';

const MuiFormHelperText: Components['MuiFormHelperText'] = {
  styleOverrides: {
    root: {
      marginRight: 0,
      marginLeft: 0,
      ...typography.body3,
      lineHeight: '14.4px'
    }
  }
};

export default MuiFormHelperText;
