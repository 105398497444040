import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import "./Card.css";
import { CardData } from "../KanbanBoard";
import { CardContainer, CardTitle, DueDate } from "./CardStyles";
import OwnerAndAssignee from "../../OwnerAndAssignee/OwnerAndAssignee";


import { ActionPlanActionItemDtoStatus } from "../../../api/generated";
import { getDate } from "../../../utils/dateUtil";

import { IconButton, Stack } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AppPopover from "../../../screens/ActionPlan/components/AppPopover/AppPopover";
import palette from "../../../theme/palette";

type CardProps = {
  boardId: string;
  id: string;
  index: number;
  removeCard: (boardId: string, cardId: string) => void;
  card: CardData;
  onClick: (id: string) => void;
};

const Card = ({ boardId, id, index, card, onClick, removeCard }: CardProps) => {
  const [hovered, setHovered] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);

  const pastDue = card.status !== ActionPlanActionItemDtoStatus.completed
    ? new Date(card.dueDate).setHours(0, 0, 0, 0) <
    new Date().setHours(0, 0, 0, 0)
    : false;

  return (
    <Draggable
      key={id.toString()}
      draggableId={id.toString()}
      index={index}
    >
      {provided => (

        <CardContainer
          sx={{ opacity: card.isArchived ? 0.6 : 1 }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={() => onClick(card.id)}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Stack
            direction="row"
            alignItems="start"
            justifyContent="space-between">
            <CardTitle>{card.title}</CardTitle>

            {hovered &&
              <IconButton onClick={event => { event.stopPropagation(); setAnchorEl(event.currentTarget); }} sx={{ p: 0 }}>
                <MoreHorizIcon fontSize="small" />
              </IconButton>
            }

            <AppPopover
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              handleClose={() => {
                setAnchorEl(null);
                setHovered(false);
              }}
              onDelete={() => { setAnchorEl(null); removeCard(boardId, id) }}
            />

          </Stack>

          <Stack
            alignSelf="end"
            direction="row"
            alignItems="center"
            gap={1}>

            {card.dueDate &&
              <DueDate
                sx={{ color: pastDue ? palette.red[2] : palette.grey[1100] }}
              >
                {getDate(card.dueDate)}
              </DueDate>}

            <OwnerAndAssignee
              sx={{ gap: 1 }}
              ownerId={card.ownerId}
              assigneeId={card.assigneeId}
              onlyAvatar
            />
          </Stack>

        </CardContainer>

      )}
    </Draggable>
  );
};



export default Card;
