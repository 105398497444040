import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { ListItem } from '@mui/material';

const navItemStyles = (selected: boolean, isSideBarOpen: boolean) => ({
  display: 'flex',
  alignItems: 'center',
  '&:hover': { backgroundColor: 'blue.1' },
  padding: isSideBarOpen && '0px 8px;',
  width: isSideBarOpen ? '210px' : '37px',
  height: '40px',
  borderRadius: '10px',
  backgroundColor: selected && 'blue.1'
});

const subNavItemStyles = (selected: boolean) => ({
  display: 'flex',
  alignItems: 'center',
  '&:hover': { backgroundColor: 'blue.1' },
  padding: '0px 8px;',
  width: '140px',
  height: '40px',
  borderRadius: '10px',
  backgroundColor: selected && 'blue.1'
});
interface NavigationItemProps {
  children: React.ReactNode;
  to: string;
  selected: boolean;
  onClick?: () => void;
  isSub?: boolean;
  isSideBarOpen?: boolean;
}
const NavigationItem: FC<NavigationItemProps> = ({
  children,
  to,
  selected,
  onClick,
  isSub = false,
  isSideBarOpen
}) => (
  <ListItem
    component={Link}
    to={to}
    onClick={onClick}
    disablePadding
    sx={isSub ? subNavItemStyles(selected) : navItemStyles(selected, isSideBarOpen)}
  >
    {children}
  </ListItem>
);

export default NavigationItem;
