import { useMemo } from 'react';

import {
  SharedWorkspaceUpdateDto,
  useCreateSharedWorkspaceUpdate,
  useDeleteSharedWorkspaceUpdate,
  useListSharedWorkspaceUpdates,
  usePatchSharedWorkspaceUpdate
} from '../api/generated';

const useSharedWorkspaceUpdates = (sharedWorkspaceId?: string) => {
  const {
    data: updatesResponse,
    isLoading: updatesLoading,
    refetch: refetechUpdates
  } = useListSharedWorkspaceUpdates(sharedWorkspaceId, {
    query: {
      queryKey: ['sharedWorkspaceUpdates', sharedWorkspaceId],
      enabled: !!sharedWorkspaceId
    }
  });

  const { mutateAsync: mutateDeleteUpdate } = useDeleteSharedWorkspaceUpdate();
  const { mutateAsync: mutateCreateUpdate } = useCreateSharedWorkspaceUpdate();
  const { mutateAsync: mutatePatchUpdate } = usePatchSharedWorkspaceUpdate();

  const createUpdate = async (data: Partial<SharedWorkspaceUpdateDto>) =>
    await mutateCreateUpdate({ data });

  const patchUpdate = async (id: string, data: Partial<SharedWorkspaceUpdateDto>) =>
    await mutatePatchUpdate({ id, data });

  const deleteUpdate = async (id: string) => await mutateDeleteUpdate({ id });

  const updates = useMemo(() => updatesResponse?.items || [], [updatesResponse]);

  return {
    updates,
    updatesLoading,
    createUpdate,
    patchUpdate,
    deleteUpdate,
    refetechUpdates
  };
};

export default useSharedWorkspaceUpdates;
