import { useCallback, useMemo } from 'react';

import {
  ActionPlanObjectiveCommentDto,
  UserFeatureFeature,
  useCreateActionPlanObjectiveComment,
  useDeleteActionPlanObjectiveComment,
  useListActionPlanObjectiveComments,
  usePatchActionPlanObjectiveComment,
  useSearchActionPlanObjectiveComments
} from '../api/generated';
import useUserFeature from './useUserFeature';

const useObjectiveComments = (objectiveId?: string) => {
  const { isFeatureBlocked } = useUserFeature();

  const isActionPlanEnabled = !isFeatureBlocked(UserFeatureFeature.actionPlan);

  const {
    data: commentsResponse,
    isLoading: commentsLoading,
    refetch: refetchComments
  } = useListActionPlanObjectiveComments(objectiveId, {
    query: {
      queryKey: ['objectiveComments', objectiveId],
      enabled: !!objectiveId && isActionPlanEnabled
    }
  });


  const { mutateAsync: mutateCreateObjectiveComment } = useCreateActionPlanObjectiveComment();
  const { mutateAsync: mutatePatchObjectiveComment } = usePatchActionPlanObjectiveComment();
  const { mutateAsync: mutateDeleteObjectiveComment } = useDeleteActionPlanObjectiveComment();
  const {
    mutateAsync: mutateSearchObjectivesComments,
    isPending: objectivesCommentsLoading
  } = useSearchActionPlanObjectiveComments();

  const createObjectiveComment = useCallback(
    async (objective: Partial<ActionPlanObjectiveCommentDto>) => {
      const response = await mutateCreateObjectiveComment({ data: objective });
      return response?.items[0]?.id;
    },
    [mutateCreateObjectiveComment]
  );

  const deleteObjectiveComment = useCallback(
    async (id: string) => {
      await mutateDeleteObjectiveComment({ id });
    },
    [mutateDeleteObjectiveComment]
  );

  const patchObjectiveComment = useCallback(
    async (id: string, comment: Partial<ActionPlanObjectiveCommentDto>) => {
      await mutatePatchObjectiveComment({ id, data: comment });
    },
    [mutatePatchObjectiveComment]
  );

  const searchObjectivesComments = useCallback(
    async (ownerOrAssigneeUserIds?: string[], entityIds?: string[]) => {
      const response = await mutateSearchObjectivesComments({ data: { ownerOrAssigneeUserIds: ownerOrAssigneeUserIds, entityIds: entityIds } });
      return response?.items;
    },
    [mutateSearchObjectivesComments]
  );

  const comments = useMemo(
    () => commentsResponse?.items || [],
    [commentsResponse?.items]
  );

  return {
    comments,
    commentsLoading,
    createObjectiveComment,
    deleteObjectiveComment,
    patchObjectiveComment,
    refetchComments,
    searchObjectivesComments,
    objectivesCommentsLoading
  };
};

export default useObjectiveComments;
