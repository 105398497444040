import { useCallback, useMemo } from 'react';

import {
  ActionPlanObjectiveDto,
  ActionPlanObjectiveDtoType,
  SearchEntityRequest,
  UserFeatureFeature,
  useBulkPatchObjectives,
  useCreateActionPlanObjective,
  useDeleteActionPlanObjective,
  useDuplicateActionPlanObjective,
  useGetActionPlanDetailedObjective,
  useGetInitiativeSuggestion,
  useListActionPlanDetailedObjectives,
  useListActionPlanObjectives,
  usePatchActionPlanObjective,
  useSearchActionPlanDetailedObjectives,
  useSearchActionPlanObjectives,
  useUpdateActionPlanObjective
} from '../api/generated';
import useUserFeature from './useUserFeature';
import useActionPlanBoardContext from './context-providers/useActionPlanBoardContext';

export const useActionPlanObjective = (
  objectiveId?: string,
  boardId?: string,
  businessArea?: string,
  objectivesType?: ActionPlanObjectiveDtoType,
  includeArchived?: boolean,
  ownerIds?: string[],
  objectiveIds?: string[]
) => {
  const { isFeatureBlocked } = useUserFeature();

  const isActionPlanEnabled = !isFeatureBlocked(UserFeatureFeature.actionPlan);
  const { selectedBoard } = useActionPlanBoardContext();

  const {
    data: detailedObjectivesResponse,
    isLoading: detailedObjectivesLoading,
    refetch: refetchDetailedObjectives
  } = useListActionPlanDetailedObjectives(
    {
      boardId: boardId || selectedBoard.id,
      businessArea: businessArea || null,
      includeArchived: includeArchived || null,
      ownerIds: ownerIds ? ownerIds.toString() : null,
      objectiveIds: objectiveIds ? objectiveIds.toString() : null

    },
    {
      query: {
        queryKey: ['detailedObjectives', boardId, selectedBoard?.id, businessArea, includeArchived, ownerIds, objectiveIds],
        enabled: isActionPlanEnabled
      }
    }
  );

  const {
    data: objectivesResponse,
    isLoading: objectivesLoading,
    refetch: refetchObjectives
  } = useListActionPlanObjectives(objectivesType ? { type: objectivesType } : null, {
    query: {
      queryKey: ['actionPlanObjectives', objectivesType],
      enabled: isActionPlanEnabled
    }
  });


  const {
    data: detailedObjectiveResponse,
    isLoading: detailedObjectiveLoading,
    refetch: refetchDetailedObjective
  } = useGetActionPlanDetailedObjective(
    objectiveId,
    {
      includeArchived: includeArchived || null
    },
    {
      query: {
        queryKey: ['objectiveId', objectiveId, includeArchived],
        enabled: !!objectiveId && isActionPlanEnabled
      }
    }
  );

  const {
    data: initiativeSuggestionsResponse,
    refetch: getInitiativesSuggestions,
    isLoading: initiativeSuggestionsLoading
  } = useGetInitiativeSuggestion(
    { objectiveId: objectiveId },
    {
      query: {
        queryKey: ['initiativeSuggestions', objectiveId],
        enabled: false
      }
    }
  );

  const { mutateAsync: mutateCreateActionPlanObjective } = useCreateActionPlanObjective();
  const { mutateAsync: mutatePatchActionPlanObjective } = usePatchActionPlanObjective();
  const { mutateAsync: mutateUpdateActionPlanObjective } = useUpdateActionPlanObjective();
  const { mutateAsync: mutateDeleteActionPlanObjective } = useDeleteActionPlanObjective();
  const { mutateAsync: mutateDuplicateActionPlanObjective } = useDuplicateActionPlanObjective();
  const { mutateAsync: mutateBulkPatchActionPlanObjectives } = useBulkPatchObjectives();
  const { mutateAsync: mutateSearchActionPlanObjectives, isPending: searchObjectivesLoading } = useSearchActionPlanObjectives();
  const { mutateAsync: mutateSearchActionPlanDetailedObjectives, isPending: searchObjectivesDetailedLoading } = useSearchActionPlanDetailedObjectives();


  const searchDetailedObjectives = useCallback(
    async (data: SearchEntityRequest, includeArchived: boolean) => {
      const response = await mutateSearchActionPlanDetailedObjectives({
        data,
        params: { includeArchived: includeArchived }
      });
      return response?.items || [];
    },
    [mutateSearchActionPlanDetailedObjectives]
  );

  const searchObjectives = useCallback(
    async (data: SearchEntityRequest) => {
      const response = await mutateSearchActionPlanObjectives({ data });
      return response?.items || [];
    },
    [mutateSearchActionPlanObjectives]
  );

  const createActionPlanObjective = useCallback(
    async (objective: Partial<ActionPlanObjectiveDto>) => {
      const response = await mutateCreateActionPlanObjective({ data: objective });
      return response?.items?.[0];
    },
    [mutateCreateActionPlanObjective]
  );


  const deleteActionPlanObjective = useCallback(
    async (id: string, deleteMetric?: boolean) => {
      await mutateDeleteActionPlanObjective({ id: id, params: { deleteMetric: deleteMetric } });
    },
    [mutateDeleteActionPlanObjective]
  );


  const patchActionPlanObjective = useCallback(
    async (id: string, objective: Partial<ActionPlanObjectiveDto>) => {
      await mutatePatchActionPlanObjective({ id, data: objective });
    },
    [mutatePatchActionPlanObjective]
  );

  const updateActionPlanObjective = useCallback(
    async (id: string, objective: Partial<ActionPlanObjectiveDto>) => {
      await mutateUpdateActionPlanObjective({ id, data: objective });
    },
    [mutateUpdateActionPlanObjective]
  );

  const duplicateActionPlanObjective = useCallback(
    async (id: string, boardId: string, businessArea: string) => {
      await mutateDuplicateActionPlanObjective({
        id,
        params: { boardId: boardId, businessArea: businessArea }
      });
    },
    [mutateDuplicateActionPlanObjective]
  );

  const bulkPatchActionPlanObjectives = useCallback(
    async (data: ActionPlanObjectiveDto[]) => {
      await mutateBulkPatchActionPlanObjectives({ data: { items: data } });
    },
    [mutateBulkPatchActionPlanObjectives]
  );

  const detailedObjective = useMemo(
    () => detailedObjectiveResponse?.items[0] || null,
    [detailedObjectiveResponse?.items]
  );

  const detailedObjectivesList = useMemo(
    () => detailedObjectivesResponse?.items || [],
    [detailedObjectivesResponse?.items]
  );

  const objectivesList = useMemo(
    () => objectivesResponse?.items || [],
    [objectivesResponse?.items]
  );

  const initiativeSuggestionList = useMemo(
    () => initiativeSuggestionsResponse?.items[0].suggestions || [],
    [initiativeSuggestionsResponse?.items]
  );

  const patchObjectivesListOrder = useCallback(
    (startIndex: number, endIndex: number, objectivesList: ActionPlanObjectiveDto[]) => {
      const objectivesDraft = Array.from(objectivesList);
      const [removedObjective] = objectivesDraft.splice(startIndex, 1);
      objectivesDraft.splice(endIndex, 0, removedObjective);
      const newindexAndIdObjectivesList = objectivesDraft.map((obj, i) => ({
        id: obj.id,
        index: i
      }));
      return newindexAndIdObjectivesList;
    },
    []
  );

  return useMemo(
    () => ({
      detailedObjectivesList,
      detailedObjectivesLoading,
      refetchDetailedObjectives,
      objectivesList,
      objectivesLoading,
      refetchObjectives,
      createActionPlanObjective,
      patchActionPlanObjective,
      updateActionPlanObjective,
      bulkPatchActionPlanObjectives,
      patchObjectivesListOrder,
      duplicateActionPlanObjective,
      deleteActionPlanObjective,
      detailedObjective,
      refetchDetailedObjective,
      detailedObjectiveLoading,
      initiativeSuggestionList,
      initiativeSuggestionsLoading,
      getInitiativesSuggestions,
      searchObjectives,
      searchObjectivesLoading,
      searchDetailedObjectives,
      searchObjectivesDetailedLoading
    }),
    [
      detailedObjectivesList,
      detailedObjectivesLoading,
      refetchDetailedObjectives,
      objectivesList,
      objectivesLoading,
      refetchObjectives,
      createActionPlanObjective,
      patchActionPlanObjective,
      updateActionPlanObjective,
      bulkPatchActionPlanObjectives,
      patchObjectivesListOrder,
      duplicateActionPlanObjective,
      deleteActionPlanObjective,
      detailedObjective,
      refetchDetailedObjective,
      detailedObjectiveLoading,
      initiativeSuggestionList,
      initiativeSuggestionsLoading,
      getInitiativesSuggestions,
      searchObjectives,
      searchObjectivesLoading,
      searchDetailedObjectives,
      searchObjectivesDetailedLoading
    ]
  );
};

export default useActionPlanObjective;
