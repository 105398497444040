import { useCallback, useMemo } from 'react';

import {
  SharedWorkspacePointCommentDto,
  useCreateSharedWorkspacePointComment,
  useDeleteSharedWorkspacePointComment,
  useListSharedWorkspacePointComments,
  usePatchSharedWorkspacePointComment
} from '../api/generated';


const useSharedWorkspacePointComments = (pointId?: string) => {

  const {
    data: commentsResponse,
    isLoading: commentsLoading,
    refetch: refetchComments
  } = useListSharedWorkspacePointComments(pointId, {
    query: {
      queryKey: ['pointComments', pointId],
      enabled: !!pointId
    }
  });


  const { mutateAsync: mutateCreateSharedWorkspacePointComment } = useCreateSharedWorkspacePointComment();
  const { mutateAsync: mutatePatchSharedWorkspacePointComment } = usePatchSharedWorkspacePointComment();
  const { mutateAsync: mutateDeleteSharedWorkspacePointComment } = useDeleteSharedWorkspacePointComment();


  const createSharedWorkspacePointComment = useCallback(
    async (comment: Partial<SharedWorkspacePointCommentDto>) => {
      const response = await mutateCreateSharedWorkspacePointComment({ data: comment });
      return response?.items?.[0];
    },
    [mutateCreateSharedWorkspacePointComment]
  );

  const deleteSharedWorkspacePointComment = useCallback(
    async (id: string) => {
      await mutateDeleteSharedWorkspacePointComment({ id });
    },
    [mutateDeleteSharedWorkspacePointComment]
  );

  const patchSharedWorkspacePointComment = useCallback(
    async (id: string, comment: Partial<SharedWorkspacePointCommentDto>) => {
      await mutatePatchSharedWorkspacePointComment({ id, data: comment });
    },
    [mutatePatchSharedWorkspacePointComment]
  );


  const comments = useMemo(
    () => commentsResponse?.items || [],
    [commentsResponse?.items]
  );

  return {
    comments,
    commentsLoading,
    createSharedWorkspacePointComment,
    deleteSharedWorkspacePointComment,
    patchSharedWorkspacePointComment,
    refetchComments
  };
};

export default useSharedWorkspacePointComments;
